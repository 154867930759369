import React, { Component } from "react"
import { Link } from "react-router-dom"
import { navigationPaths } from "../../constants/paths"

import * as agGridHelpers from "../../helpers/agGridHelpers"
import { Input, Grid, Button } from "semantic-ui-react"
import { useTranslation } from "react-i18next"

export default function ServiceSearch({ debouncedOnFilterChange, resetFiltering, filtersPresent }) {
  const { t } = useTranslation("services")
  return (
    <Grid columns="equal" className={"ag-theme-search"} stackable>
      <Grid.Column textAlign="left">
        <Input
          className="search"
          onChange={debouncedOnFilterChange}
          icon="search"
          placeholder={t("searchServicesLabel")}
        />
      </Grid.Column>
      <Grid.Column textAlign="right">
        {
          <Button className="white-button" onClick={resetFiltering}>
            {t("resetFiltersLabel")}
          </Button>
        }
      </Grid.Column>
    </Grid>
  )
}
