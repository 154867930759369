import React, { Component } from "react"
import PropTypes from "prop-types"

import HeaderLink from "./HeaderLink"

class HeaderLinks extends Component {
  static propTypes = {
    fleetLinks: PropTypes.array
  }

  static defaultProps = {
    fleetLinks: []
  }

  render() {
    const { fleetLinks } = this.props

    return fleetLinks.map((link, index) => {
      return <HeaderLink description={link.description} href={link.link} name={link.name} />
    })
  }
} // class HeaderLinks

export default HeaderLinks
