export const initialState = {
  policies: []
}

export default function (state = initialState, action) {
  // eslint-disable-next-line
  const { payload, type } = action

  switch (type) {
    case "MAINTENANCE_POLICIES_LOAD_SUCCEEDED":
      return {
        ...state,
        policies: action.payload.policies
      }
    default:
      return state
  }
}
