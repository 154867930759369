import React, { Component } from "react"
import { connect } from "react-redux"

import { navigationPaths } from "../../constants/paths"

import Alert from "../../components/shared/Alert"
import ForgotPasswordForm from "../../components/sessions/ForgotPasswordForm"
import NoSessionLayout from "../../layouts/NoSessionLayout"
import { withTranslation } from "react-i18next"

class ForgotPassword extends Component {
  constructor(props) {
    super(props)

    this.state = {
      alertMessage: "",
      alertType: "default",
      isSubmitting: false
    }
  }

  afterRequest(data) {
    this.setState({ isSubmitting: false })

    if (data.status === "success") {
      this.onRequestSuccess(data)
    } else {
      this.onRequestFailure(data)
    }
  }

  onSubmit(formData) {
    this.setState({ isSubmitting: true })

    this.props.dispatch({
      type: "FORGOT_PASSWORD_SAGA",
      payload: { formData: formData },
      callback: this.afterRequest.bind(this)
    })
  }

  onRequestFailure(data) {
    this.setState({
      alertMessage: data.alertMessage,
      alertType: data.alertType
    })
  }

  onRequestSuccess(data) {
    this.props.history.push({
      pathname: navigationPaths.signIn(),
      state: {
        alertMessage: data.alertMessage,
        alertType: data.alertType
      }
    })
  }

  render() {
    const { alertMessage, alertType, isSubmitting } = this.state
    const { t } = this.props
    return (
      <NoSessionLayout page="forgotPasswordPage">
        <Alert message={alertMessage} type={alertType} visible={alertMessage !== ""} />

        <div className={"no-session-content"}>
          <h1>{t('forgotPasswordLabel')}</h1>

          <ForgotPasswordForm isSubmitting={isSubmitting} onSubmit={this.onSubmit.bind(this)} t={t}/>
        </div>
      </NoSessionLayout>
    )
  }
} // class ForgotPassword

export default connect()(withTranslation('common')(ForgotPassword))
