import React, { Component } from "react"
import { connect } from "react-redux"

import { navigationPaths } from "../../constants/paths"

import Alert from "../../components/shared/Alert"
import ResetPasswordForm from "../../components/sessions/ResetPasswordForm"
import NoSessionLayout from "../../layouts/NoSessionLayout"
import { withTranslation } from "react-i18next"

class ResetPassword extends Component {
  constructor(props) {
    super(props)

    const queryString = this.props.location.search
    const queryParams = new URLSearchParams(queryString)

    this.state = {
      alertMessage: "",
      alertType: "default",
      isSubmitting: false,
      resetToken: queryParams.get("reset_password_token")
    }
  }

  afterRequest(data) {
    this.setState({ isSubmitting: false })

    if (data.status === "success") {
      this.onRequestSuccess(data)
    } else {
      this.onRequestFailure(data)
    }
  }

  onSubmit(formData) {
    this.setState({ isSubmitting: true })

    const { newPassword, newPasswordConfirmation } = formData

    if (newPassword !== newPasswordConfirmation) {
      this.setState({
        alertMessage: "New password and confirmation do not match",
        alertType: "error"
      })
    } else {
      this.props.dispatch({
        type: "RESET_PASSWORD_SAGA",
        payload: { formData: formData },
        callback: this.afterRequest.bind(this)
      })
    }
  }

  onRequestFailure(data) {
    this.setState({
      alertMessage: data.alertMessage,
      alertType: data.alertType
    })
  }

  onRequestSuccess(data) {
    this.props.history.push({
      pathname: navigationPaths.signIn(),
      state: {
        alertMessage: data.alertMessage,
        alertType: data.alertType
      }
    })
  }

  render() {
    const { alertMessage, alertType, isSubmitting, resetToken } = this.state
    const { t } = this.props
    return (
      <NoSessionLayout>
        <Alert message={alertMessage} type={alertType} visible={alertMessage !== ""} />

        <div className={"no-session-content"}>
          <h1>{t('resetPasswordLabel')}</h1>

          <ResetPasswordForm
            isSubmitting={isSubmitting}
            onSubmit={this.onSubmit.bind(this)}
            resetToken={resetToken}
            t={t}
          />
        </div>
      </NoSessionLayout>
    )
  }
} // class ResetPassword

export default connect()(withTranslation('common')(ResetPassword))
