export const initialState = {
  events: [],
  isLoading: false
}

export default function (state = initialState, action) {
  // eslint-disable-next-line
  const { payload, type } = action

  switch (type) {
    case "SET_GEOTAB_SYNC_EVENTS_LOADING":
      return {
        ...state,
        isLoading: true
      }
    case "UNSET_GEOTAB_SYNC_EVENTS_LOADING":
      return {
        ...state,
        isLoading: false
      }
    case "CREATE_GEOTAB_SYNC_EVENT_SUCCEEDED":
      return {
        ...state,
        events: [...state.events, payload.geotabSyncEvent]
      }
    case "LOAD_GEOTAB_SYNC_EVENTS_SUCCEEDED":
      return {
        ...state,
        events: payload.geotabSyncEvents
      }
    default:
      return state
  }
}
