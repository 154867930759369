export const initialState = {
  favoriteShops: [],
  shops: []
}

export default function (state = initialState, action) {
  // eslint-disable-next-line
  const { payload, type } = action

  switch (type) {
    case "SHOPS_FILTER_SUCCEEDED":
      return {
        ...state,
        shops: payload.shops
      }
    case "SHOPS_LOAD_SUCCEEDED":
      return {
        ...state,
        favoriteShops: payload.shops
      }
    case "SHOP_FAVORITE_SUCCEEDED":
      let updatedFavoriteShops = [...state.favoriteShops]
      // Toggle inclusion of the incoming shop in state.favoriteShops
      if (state.favoriteShops.find((shop) => shop.id === payload.shop.id)) {
        updatedFavoriteShops = updatedFavoriteShops.filter((shop) => shop.id !== payload.shop.id)
      } else {
        updatedFavoriteShops.push(payload.shop)
      }

      let updatedShops = [...state.shops].map((shop) => {
        return shop.id === payload.shop.id
          ? { ...shop, is_favorite_shop: payload.shop.is_favorite_shop }
          : shop
      })

      return {
        ...state,
        favoriteShops: updatedFavoriteShops,
        shops: updatedShops
      }
    default:
      return state
  }
}
