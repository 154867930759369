import { navigationPaths } from "../constants/paths"

export function navigateTo(path) {
  this.props.history.push({
    pathname: navigationPaths[path]()
  })
}

export function extractShellParams(params) {
  return {
    id: params.get("id"),
    authToken: params.get("token"),
    firstName: params.get("firstName"),
    lastName: params.get("lastName"),
    cellPhone: params.get("cellPhone"),
    email: params.get("email"),
    fleetName: params.get("fleet_name"),
    fleetNameEditable: [true, "true"].includes(params.get("fleet_name_editable"))
  }
}
