import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import * as maintenanceHelpers from "../../helpers/maintenanceHelpers"
import { Segment, Grid, Header } from "semantic-ui-react"
import { withTranslation } from "react-i18next"

class PastServicesStats extends PureComponent {
  /* This thing expects data from the agGrid, not direct from props.agGridFormattedOrders.
     Therefore it uses cost and status fields as defined in constants/services.js ag-grid row mapping function.
     */
  formattedAggregateTotal() {
    const total = this.total()
    return total ? `$${total.toFixed(2)}` : "-"
  }

  formattedAverageTotal() {
    const { agGridFormattedOrders } = this.props
    const total = this.total()
    return total ? `$${(total / agGridFormattedOrders.length).toFixed(2)}` : "-"
  }

  total() {
    const { agGridFormattedOrders } = this.props
    const total = agGridFormattedOrders.reduce(
      (total, o) => (total += o.total ? parseFloat(o.total) : 0),
      0
    )
    return total
  }

  render() {
    const { agGridFormattedOrders, t } = this.props
    return (
      <Grid columns="equal" stretched stackable>
        <Grid.Row>
          <Grid.Column>
            <Segment vertical className={"dash-stats-item item-width"}>
              {agGridFormattedOrders.length}
              <span>{t("completedLabel")}</span>
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment vertical className={"dash-stats-item item-width"}>
              {this.formattedAverageTotal()}
              <span>{t("averageTotalLabel")}</span>
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment vertical className={"dash-stats-item item-width"}>
              {this.formattedAggregateTotal()}
              <span>{t("aggregateTotalLabel")}</span>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }
}

export default withTranslation("services")(PastServicesStats)
