import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import { navigationPaths } from "../../constants/paths"

import Alert from "../../components/shared/Alert"
import ApplicationLayout from "../../layouts/ApplicationLayout"
import LoadingError from "../../components/shared/LoadingError"
import LoadingThrobber from "../../components/shared/LoadingThrobber"
import MiniLoader from "../../components/shared/MiniLoader"
import { Dropdown, Segment, Header, Divider, Input } from "semantic-ui-react"
import { getStyles } from "../../components/shared/StylesheetInjector"
import ApprovalsHistory from "./ApprovalsHistory"
import { Approvals, constants } from "caradvise_shared_components"
import {
  formattedApprovalContacts,
  formattedCancelationContacts,
  eligibleForCancelation
} from "../../helpers/activeMaintenanceHelpers"
import * as phoneNumberHelpers from "../../helpers/phoneNumberHelpers"
import * as numberHelpers from "../../helpers/numberHelpers"
import * as vehicleHelpers from "../../helpers/vehicleHelpers"
import { isTBC, isWex } from "../../helpers/userHelpers"
import { Redirect } from "react-router"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPencil } from "@fortawesome/pro-light-svg-icons"
import { formattedAddress } from "../../helpers/shopHelpers"
import { isFleetMaintenanceHubCanada } from "../../helpers/affiliationHelpers"
import { withTranslation } from "react-i18next"
const { ApprovalsEmptyState, ApprovalsForm, ApprovalsInfoModal } = Approvals
const serviceStatuses = constants.services.statuses
let styles = getStyles()
const { INITIATED_BY_USER, ACTIVE, AWAITING_APPROVAL } = constants.orders
styles = { ...styles, primaryColor: styles.carAdviseOrange }

class ApprovalsShow extends Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    isLoadingError: PropTypes.bool.isRequired,
    order: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      alertMessage: "",
      alertType: "default",
      editingPo: false,
      poNumber: (props.order || {}).po_number || "",
      isSubmitting: false,
      infoModalOpen: true
    }
  }

  componentDidMount() {
    this.fetchOrderData({ withPriceRanges: true })
  }

  onCancel = () => {
    this.setState({ isSubmitting: true })

    this.props.dispatch({
      type: "MAINTENANCE_CANCELATION_SAGA",
      payload: {
        submissionData: {
          id: this.props.order.id,
          status: "cancel"
        }
      },
      callback: this.afterSubmit
    })
  }

  onSubmit = (services, customerComments) => {
    this.setState({ isSubmitting: true }, () =>
      this.props.dispatch({
        type: "MAINTENANCE_APPROVAL_SAGA",
        payload: {
          submissionData: {
            id: this.props.order.id,
            order_services: services,
            customer_comments: customerComments,
            po_number: this.state.poNumber ? this.state.poNumber : this.props.order.poNumber
          }
        },
        callback: this.afterSubmit
      })
    )
  }

  afterSubmit = (status, data) => {
    if (status === "success") {
      this.onSubmitSuccess(data)
    } else {
      this.onSubmitFailure(data)
    }
  }

  onApprovalChange = async (services) => {
    const { order, currentUser } = this.props
    await this.setState({ loadingSums: true })
    this.fetchOrderData({ services: services })
  }

  afterShopOrderLoad = async () => {
    this.setState({ loadingSums: false })
  }

  fetchOrderData = (options = {}) => {
    this.props.dispatch({
      type: "SHOP_ORDER_LOAD_SAGA",
      payload: {
        shopOrderId: this.props.match.params.id,
        orderServices: options.services,
        priceAsApprovalsLogic: true,
        withPriceRanges: options.withPriceRanges
      },
      callback: this.afterShopOrderLoad
    })
  }

  onSubmitFailure(data) {
    this.setState({
      alertMessage: data.alertMessage,
      alertType: data.alertType,
      isSubmitting: false
    })

    window.scrollTo(0, 0)
  }

  onSubmitSuccess(data) {
    this.setState({
      alertMessage: data.alertMessage,
      alertType: data.alertType,
      isSubmitting: false,
      submitted: true
    })
  }

  toggleEditingPo = () => this.setState({ editingPo: !this.state.editingPo })
  setPo = (e) => this.setState({ poNumber: e.target.value })
  closeInfoModal = () => this.setState({ infoModalOpen: !this.state.infoModalOpen })

  approvalsInfoModal = () => {
    const { infoModalOpen } = this.state
    const { order, services } = this.props

    return (
      <ApprovalsInfoModal
        order={order}
        services={services}
        infoModalOpen={infoModalOpen}
        closeInfoModal={this.closeInfoModal}
        t={this.props.t}
      />
    )
  }

  handleSubmitPoNumber = () => {
    const { order, dispatch } = this.props

    this.setState({ isSubmitting: true })
    dispatch({
      type: "MAINTENANCE_UPDATE_SAGA",
      payload: {
        submissionData: { id: order.id, po_number: this.state.poNumber }
      },
      callback: () => this.setState({ isSubmitting: false, editingPo: false })
    })
  }

  handleCancelPoNumberEdit = () => {
    this.setState({ editingPo: false, poNumber: this.props.order.po_number || "" })
  }

  renderPoNumber = () => {
    const { order, t } = this.props
    const { editingPo, poNumber, isSubmitting } = this.state

    if (editingPo) {
      return (
        <>
          <Input value={poNumber || order.po_number} onChange={this.setPo} />
          {isSubmitting ? (
            <MiniLoader inverted={false} />
          ) : (
            <>
              <span
                style={{ paddingLeft: "7px" }}
                className="link"
                disabled={!isSubmitting}
                onClick={this.handleSubmitPoNumber}
              >
                {t("common:saveLabel")}
              </span>
              &nbsp;&nbsp;
              <span className="link" onClick={this.handleCancelPoNumberEdit}>
                {t("common:cancelLabel")}
              </span>
            </>
          )}
        </>
      )
    } else {
      return (
        <>
          {order.po_number || "N/A"}
          <FontAwesomeIcon
            style={{ paddingLeft: "7px", cursor: "pointer" }}
            icon={faPencil}
            onClick={this.toggleEditingPo}
          />
        </>
      )
    }
  }

  orderInfo = () => {
    const { order, users, t } = this.props
    const { shop } = order || {}

    return (
      <>
        <Header size="large">{vehicleHelpers.formattedName(order.vehicle)}</Header>
        <p>
          {t("dashboard:otherIdLabel")}: {order.vehicle.other_id || "N/A"}
        </p>
        <p>
          {t("poNumberLabel")}:{this.renderPoNumber()}
        </p>
        <p>
          <div>
            <strong>{t("shopLabel")}:</strong>
          </div>
          {`${shop.name} - ${formattedAddress(shop)}`}
          <div className={"active_order__detail"}>
            {phoneNumberHelpers.reformat(shop.main_phone_number)}
          </div>
        </p>
        <p>
          <div>
            <strong>{t("appointmentLabel")}:</strong>
          </div>
          {`${order.appointment_date_pretty} ${order.appointment_time_pretty}`}
        </p>
        <p>
          <div>
            <strong>{t("odometerLabel")}: </strong>

            {`${numberHelpers.formatWithCommas(order.miles)} ${
              isFleetMaintenanceHubCanada() ? "km" : "mi"
            }`}
          </div>
        </p>
        <Divider hidden />

        {order.state === AWAITING_APPROVAL && !order.can_approve && (
          <Segment>
            <p>{t("notEligibleToApproveLabel")}:</p>
            {formattedApprovalContacts(order, users)
              .split("\n")
              .map((c) => (
                <p>{c}</p>
              ))}
          </Segment>
        )}
        <Divider hidden />
      </>
    )
  }

  renderContent() {
    const { users, order, services, priceRanges, t, i18n } = this.props
    const currentUser = this.props.currentUser || this.state.transitionalCurrentUser || {}
    const { pays_through_platform, has_payment_method, payment_provider_type } = currentUser
    const { isSubmitting, submitted, loadingSums, editingPo, poNumber } = this.state
    const RejectionReasonsDropdown = (
      <Dropdown
        className="rejection-reasons-dropdown"
        placeholder={t("seletRejectionReasonLabel")}
        fluid
        selection
      />
    )

    if (!services || currentUser.id == null) return null

    if (submitted) return <Redirect to={navigationPaths.dashboard()} />

    if (services.length > 0) {
      const { braintree_payment_methods } = order
      if (braintree_payment_methods != null && braintree_payment_methods.length > 0) {
        braintree_payment_methods[0].default = true
        currentUser.has_cc_info_saved = true
      }
      return (
        <div>
          <span />
          <Header size="small" className={"dash-hd"}>
            {t("reviewShopOrderLabel")}
          </Header>
          <div style={{ backgroundColor: "white" }}>
            <ApprovalsForm
              isSubmitting={isSubmitting}
              onSubmit={this.onSubmit}
              onCancel={this.onCancel}
              eligibleForCancelation={eligibleForCancelation(order)}
              currentUserCanCancel={order.can_cancel}
              formattedCancelationContacts={formattedCancelationContacts(order, users)}
              order={order}
              services={services}
              user={currentUser}
              paymentListEl={
                pays_through_platform && (
                  <React.Fragment>
                    <Header>{t("noPaymentMethodsLabel")}</Header>
                    <span>
                      {isWex(currentUser)
                        ? t("contactCarAdviseLabel")
                        : t("addPaymentInSettingsLabel")}
                    </span>
                  </React.Fragment>
                )
              }
              styles={styles}
              hideChargeMessage={!pays_through_platform}
              paymentMethods={pays_through_platform && braintree_payment_methods}
              hasPaymentMethod={has_payment_method}
              paymentProviderType={payment_provider_type}
              hideSavings={true}
              hideRetailPricing={true}
              hidePriceRange={isTBC(currentUser)}
              hidePricing={[INITIATED_BY_USER, ACTIVE].includes(order.state)}
              priceRanges={priceRanges}
              customHeader={this.orderInfo()}
              canApprove={order.can_approve}
              loadingSums={!!loadingSums}
              onApprovalChange={this.onApprovalChange}
              customerCommentsCharLimit={150}
              rejectionReasonsDropdown={RejectionReasonsDropdown}
              t={t}
              i18n={i18n}
            />

            {/*
              Only exposing approvals history if pays_through_platform, since the backend's
              calculation of the order totals _might_ be slightly different and need to adjust
              for those that don't pay through platform.
            */}
            {pays_through_platform && <ApprovalsHistory shopOrderId={this.props.match.params.id} />}
          </div>
        </div>
      )
    } else {
      return (
        <div>
          <span />
          <Header size="small" className={"dash-hd"}>
            {t("reviewShopOrderLabel")}
          </Header>
          <Segment>
            <ApprovalsEmptyState t={this.props.t} />
          </Segment>
        </div>
      )
    }
  }

  render() {
    const { isLoading, isLoadingError, t } = this.props

    const { alertMessage, alertType, loadingSums } = this.state

    return (
      <ApplicationLayout>
        <Alert message={alertMessage} type={alertType} visible={alertMessage !== ""} />

        <LoadingThrobber visible={isLoading && !loadingSums} />

        <LoadingError visible={!isLoading && isLoadingError} />

        {(!isLoading || loadingSums) && !isLoadingError && (
          <div>
            {this.approvalsInfoModal()}
            {this.renderContent()}
          </div>
        )}
      </ApplicationLayout>
    )
  }
} // class ApprovalsShow

const mapStateToProps = (state) => ({
  isLoading: state.application.isLoading,
  isLoadingError: state.application.isLoadingError,
  users: state.users.users,
  order: state.shopOrders.shopOrder,
  services:
    state.shopOrders.shopOrder &&
    state.shopOrders.shopOrder.order_services &&
    state.shopOrders.shopOrder.order_services.filter(
      (service) => ![serviceStatuses["deleted"]].includes(service.status)
    ),
  priceRanges: state.shopOrders.priceRanges,
  currentUser: state.users.currentUser
})

export default connect(mapStateToProps)(
  withTranslation(["shopOrders", "approvalsEmptyState", "approvalsForm", "approvalsInfoModal"])(
    ApprovalsShow
  )
)
