import { call, put, takeEvery, select } from "redux-saga/effects"

import api from "../../utilities/api"
import * as apiErrorHelpers from "../../helpers/apiErrorHelpers"
import * as sessionHelpers from "../../helpers/sessionHelpers"
import { addOrUpdateSentryContext } from "../../utilities/sentry"
import { setLanguage } from "../../helpers/translationHelpers"

export function* createOrUpdateUser(action) {
  const formData = action.payload

  const verb = formData.id ? "UPDATE" : "CREATE"

  try {
    yield put({
      type: `USER_${verb}_REQUESTED`
    })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    const request = {
      url: `/fleet_users/${formData.id ? formData.id : ""}`,
      body: {
        id: formData.id,
        firstName: formData.firstName,
        lastName: formData.lastName,
        password: formData.password,
        password_confirmation: formData.password_confirmation,
        email: formData.email,
        cellPhone: formData.cellPhone,
        other_id: formData.other_id,
        roles: "role" in formData ? [formData.role] : formData.roles,
        affiliation_id: formData.affiliationId,
        vehicle_ids: formData.vehicle_ids,
        approval_fleet_ids: formData.approvalFleetIds,
        payment_id: formData.payment_id,
        zip: formData.zip,
        text_notification_preference_attributes: formData.text_notification_preference_attributes,
        email_notification_preference_attributes: formData.email_notification_preference_attributes,
        language: formData.language ? formData.language : null
      }
    }

    const response = yield call(
      formData.id ? api.utility.put : api.utility.post,
      api.path(request.url),
      { body: request.body }
    )

    apiErrorHelpers.catchErrors(response.body)
    const { language = "", country = "" } = response.body
    language && setLanguage(language, country)

    yield put({
      type: `USER_${verb}_SUCCEEDED`,
      payload: {
        user: response.body
      }
    })

    if (
      formData.id &&
      formData.id.toString() === sessionHelpers.getApplicationCookie("current_user_id")
    ) {
      addOrUpdateSentryContext(response.body)

      yield put({
        type: "CURRENT_USER_LOAD_SUCCEEDED",
        payload: {
          currentUser: response.body
        }
      })
    }

    const afterRequestData = {
      alertMessage: `User ${formData.id ? "updated" : "created"}`,
      alertType: "success"
    }

    yield call(action.callback, "success", afterRequestData)
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: `USER_${verb}_FAILED` })

    const afterRequestData = {
      alertMessage: apiErrorHelpers.toString(errors),
      alertType: "error"
    }

    yield call(action.callback, "failure", afterRequestData)

    apiErrorHelpers.consoleLog(errors)
  }
} // function* createOrUpdateUser

export function* updateShellUser(action) {
  const formData = action.payload

  try {
    yield put({
      type: "SHELL_USER_UPDATE_REQUESTED"
    })

    const request = {
      url: `/shell_customers/${formData.id}`,
      body: {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        cellPhone: formData.cellPhone,
        fleet_name: formData.fleetName,
        auth_token: formData.authToken,
        geotab_database_name: formData.geotabDatabaseName
      }
    }

    const response = yield call(api.utility.put, api.path(request.url), { body: request.body })

    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "SHELL_USER_UPDATE_SUCCEEDED"
    })

    const afterRequestData = {
      authenticationToken: response.body.authentication_token
    }

    yield call(action.callback, "success", afterRequestData)
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "SHELL_USER_UPDATE_FAILED" })

    const afterRequestData = {
      alertMessage: apiErrorHelpers.toString(errors),
      alertType: "error"
    }

    yield call(action.callback, "failure", afterRequestData)

    apiErrorHelpers.consoleLog(errors)
  }
} // function* updateShellUser

export function* deleteUser(action) {
  try {
    yield put({ type: "USER_DELETE_REQUESTED" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    const userId = action.payload.userId

    const request = {
      url: `/fleet_users/${userId}`,
      body: {}
    }

    const response = yield call(api.utility.del, api.path(request.url), { body: request.body })

    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "USER_DELETE_SUCCEEDED",
      payload: {
        user: response.body
      }
    })

    const afterRequestData = {
      alertMessage: "User deleted",
      alertType: "success"
    }

    yield call(action.callback, "success", afterRequestData)
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "USER_DELETE_FAILED" })

    const afterRequestData = {
      alertMessage: apiErrorHelpers.toString(errors),
      alertType: "error"
    }

    yield call(action.callback, "failure", afterRequestData)

    apiErrorHelpers.consoleLog(errors)
  }
} // function* deleteUser

export function* loadCurrentUser(action) {
  try {
    const payload = action.payload || {}
    if (!payload.skipLoadingCalls) {
      yield put({ type: "SET_LOADING" })
      yield put({ type: "UNSET_LOADING_ERROR" })
    }

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    yield put({ type: "CURRENT_USER_LOAD_REQUESTED" })

    const userId = sessionHelpers.getApplicationCookie("current_user_id")

    const userRequest = {
      url: `/fleet_users/${userId}`,
      body: {}
    }

    const userResponse = yield call(api.utility.get, api.path(userRequest.url), {
      body: userRequest.body
    })

    apiErrorHelpers.catchErrors(userResponse.body)

    addOrUpdateSentryContext(userResponse.body)
    const { language = "", country = "" } = userResponse.body
    language && setLanguage(language, country)

    yield put({
      type: "CURRENT_USER_LOAD_SUCCEEDED",
      payload: {
        currentUser: userResponse.body
      }
    })

    if (!payload.skipLoadingCalls) yield put({ type: "UNSET_LOADING" })
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "LOADING_FAILED" })
    yield put({ type: "SET_LOADING_ERROR" })
    yield put({ type: "UNSET_LOADING" })

    apiErrorHelpers.consoleLog(errors)
  }
} // function* loadCurrentUser

export function* loadCurrentUserPaymentMethods(action) {
  try {
    yield put({ type: "SET_LOADING" })
    yield put({ type: "UNSET_LOADING_ERROR" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    yield put({ type: "CURRENT_USER_LOAD_PAYMENT_METHODS_REQUESTED" })

    const userId = sessionHelpers.getApplicationCookie("current_user_id")

    const userRequest = {
      url: `/fleet_users/${userId}`,
      body: { for_payment_methods: true }
    }

    const response = yield call(api.utility.get, api.path(userRequest.url), {
      body: userRequest.body
    })

    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "CURRENT_USER_LOAD_PAYMENT_METHODS_SUCCEEDED",
      payload: {
        data: response.body
      }
    })

    yield put({ type: "UNSET_LOADING" })
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "LOADING_FAILED" })
    yield put({ type: "SET_LOADING_ERROR" })
    yield put({ type: "UNSET_LOADING" })

    apiErrorHelpers.consoleLog(errors)
  }
} // function* loadCurrentUserPaymentMethods

export function* loadUser(action) {
  try {
    yield put({ type: "SET_LOADING" })
    yield put({ type: "UNSET_LOADING_ERROR" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    ////////////////////////////
    //     LOAD USER DATA     //
    ////////////////////////////
    yield put({ type: "USER_LOAD_REQUESTED" })

    const userId = action.payload.userId

    const userRequest = {
      url: `/fleet_users/${userId}`,
      body: {}
    }

    const userResponse = yield call(api.utility.get, api.path(userRequest.url), {
      body: userRequest.body
    })

    apiErrorHelpers.catchErrors(userResponse.body)

    yield put({
      type: "USER_LOAD_SUCCEEDED",
      payload: {
        user: userResponse.body
      }
    })
    ////////////////////////////

    ////////////////////////////
    //    LOAD USERS DATA     //
    ////////////////////////////
    yield put({ type: "USERS_LOAD_REQUESTED" })

    const usersRequest = {
      url: "/fleet_users",
      body: {}
    }

    const usersResponse = yield call(api.utility.get, api.path(usersRequest.url), {
      body: usersRequest.body
    })

    apiErrorHelpers.catchErrors(usersResponse.body)

    yield put({
      type: "USERS_LOAD_SUCCEEDED",
      payload: {
        users: usersResponse.body
      }
    })
    ////////////////////////////

    ////////////////////////////
    //  LOAD USER ROLES DATA  //
    ////////////////////////////
    yield put({ type: "USER_ROLES_LOAD_REQUESTED" })

    const userRolesRequest = {
      url: "/roles",
      body: {}
    }

    const userRolesResponse = yield call(api.utility.get, api.path(userRolesRequest.url), {
      body: userRolesRequest.body
    })

    apiErrorHelpers.catchErrors(userRolesResponse.body)

    yield put({
      type: "USER_ROLES_LOAD_SUCCEEDED",
      payload: {
        userRoles: userRolesResponse.body
      }
    })
    ////////////////////////////

    ////////////////////////////
    //   LOAD VEHICLES DATA   //
    ////////////////////////////

    yield put({ type: "VEHICLES_LOAD_REQUESTED" })

    const vehiclesRequest = {
      url: "/vehicles",
      body: {}
    }

    const vehiclesResponse = yield call(api.utility.get, api.path(vehiclesRequest.url), {
      body: vehiclesRequest.body
    })

    apiErrorHelpers.catchErrors(vehiclesResponse.body)

    yield put({
      type: "VEHICLES_LOAD_SUCCEEDED",
      payload: {
        vehicles: vehiclesResponse.body
      }
    })

    yield put({
      type: "SET_VEHICLES_DATA_TYPE",
      payload: { dataType: "standard" }
    })
    ////////////////////////////

    ////////////////////////////
    //   LOAD SERVICES DATA   //
    ////////////////////////////
    yield put({ type: "SERVICES_LOAD_REQUESTED" })

    const servicesRequest = {
      url: "/services",
      body: { basic: true }
    }

    const servicesResponse = yield call(api.utility.get, api.path(servicesRequest.url), {
      body: servicesRequest.body
    })

    apiErrorHelpers.catchErrors(servicesResponse.body)

    yield put({
      type: "SERVICES_LOAD_SUCCEEDED",
      payload: {
        services: servicesResponse.body
      }
    })
    ////////////////////////////

    yield put({ type: "UNSET_LOADING" })
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "LOADING_FAILED" })
    yield put({ type: "SET_LOADING_ERROR" })
    yield put({ type: "UNSET_LOADING" })

    apiErrorHelpers.consoleLog(errors)
  }
} // function* loadUser

export function* loadUsers(action) {
  try {
    const defaultOptions = {
      includeServices: "true"
    }

    const payload = action.payload || {}

    const payloadOptions = payload.options || {}

    const options = {
      ...defaultOptions,
      ...payloadOptions
    }

    yield put({ type: "SET_LOADING" })
    yield put({ type: "UNSET_LOADING_ERROR" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    ////////////////////////////
    //    LOAD USERS DATA     //
    ////////////////////////////
    yield put({ type: "USERS_LOAD_REQUESTED" })

    const usersRequest = {
      url: "/fleet_users",
      body: {}
    }

    const usersResponse = yield call(api.utility.get, api.path(usersRequest.url), {
      body: usersRequest.body
    })

    apiErrorHelpers.catchErrors(usersResponse.body)

    const currentUserId = sessionHelpers.getApplicationCookie("current_user_id")
    yield put({
      type: "USERS_LOAD_SUCCEEDED",
      payload: {
        users: usersResponse.body,
        currentUserId: currentUserId
      }
    })
    ////////////////////////////

    ////////////////////////////
    // LOAD CURRENT USER DATA //
    ////////////////////////////

    yield put({ type: "CURRENT_USER_LOAD_SAGA" })

    ////////////////////////////

    ////////////////////////////
    //  LOAD USER ROLES DATA  //
    ////////////////////////////
    yield put({ type: "USER_ROLES_LOAD_REQUESTED" })

    const userRolesRequest = {
      url: "/roles",
      body: {}
    }

    const userRolesResponse = yield call(api.utility.get, api.path(userRolesRequest.url), {
      body: userRolesRequest.body
    })

    apiErrorHelpers.catchErrors(userRolesResponse.body)

    yield put({
      type: "USER_ROLES_LOAD_SUCCEEDED",
      payload: {
        userRoles: userRolesResponse.body
      }
    })
    ////////////////////////////

    ////////////////////////////
    //   LOAD VEHICLE DATA    //
    ////////////////////////////

    yield put({ type: "VEHICLES_LOAD_REQUESTED" })

    const vehiclesRequest = {
      url: "/vehicles",
      body: {}
    }

    const vehiclesResponse = yield call(api.utility.get, api.path(vehiclesRequest.url), {
      body: vehiclesRequest.body
    })

    apiErrorHelpers.catchErrors(vehiclesResponse.body)

    yield put({
      type: "VEHICLES_LOAD_SUCCEEDED",
      payload: {
        vehicles: vehiclesResponse.body
      }
    })

    yield put({
      type: "SET_VEHICLES_DATA_TYPE",
      payload: { dataType: "standard" }
    })
    ////////////////////////////

    if (options.includeServices === "true") {
      ////////////////////////////
      //   LOAD SERVICES DATA   //
      ////////////////////////////
      yield put({ type: "SERVICES_LOAD_REQUESTED" })

      const servicesRequest = {
        url: "/services",
        body: { basic: true }
      }

      const servicesResponse = yield call(api.utility.get, api.path(servicesRequest.url), {
        body: servicesRequest.body
      })

      apiErrorHelpers.catchErrors(servicesResponse.body)

      yield put({
        type: "SERVICES_LOAD_SUCCEEDED",
        payload: {
          services: servicesResponse.body
        }
      })
      ////////////////////////////
    }

    yield put({ type: "UNSET_LOADING" })
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "LOADING_FAILED" })
    yield put({ type: "SET_LOADING_ERROR" })
    yield put({ type: "UNSET_LOADING" })

    apiErrorHelpers.consoleLog(errors)
  }
} // function* loadUsers

export function* loadFmcUsers(action) {
  try {
    const payload = action.payload || {}

    yield put({ type: "SET_LOADING" })
    yield put({ type: "UNSET_LOADING_ERROR" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    ////////////////////////////////
    //    LOAD FMC USERS DATA     //
    ////////////////////////////////
    yield put({ type: "FMC_USERS_LOAD_REQUESTED" })

    const currentUserState = (state) => state.users.currentUser
    let currentUser = yield select(currentUserState)
    if (currentUser == null)
      yield call(() => loadCurrentUser({ payload: { skipLoadingCalls: true } }))
    currentUser = yield select(currentUserState)

    const request = {
      url: "/fleet_fmc_users",
      body: {
        affiliation_id: currentUser && currentUser.affiliation_id
      }
    }

    const response = yield call(api.utility.get, api.path(request.url), { body: request.body })

    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "FMC_USERS_LOAD_SUCCEEDED",
      payload: {
        fmcUsers: response.body
      }
    })
    //////////////////////////////////
    //  LOAD FMC USER ROLES DATA   //
    ////////////////////////////////
    yield put({ type: "USER_ROLES_LOAD_REQUESTED" })

    const userRolesRequest = {
      url: "/roles?role_type=fmc",
      body: {}
    }

    const userRolesResponse = yield call(api.utility.get, api.path(userRolesRequest.url), {
      body: userRolesRequest.body
    })

    apiErrorHelpers.catchErrors(userRolesResponse.body)

    yield put({
      type: "USER_ROLES_LOAD_SUCCEEDED",
      payload: {
        userRoles: userRolesResponse.body
      }
    })
    ////////////////////////////

    yield put({ type: "UNSET_LOADING" })
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "LOADING_FAILED" })
    yield put({ type: "SET_LOADING_ERROR" })
    yield put({ type: "UNSET_LOADING" })

    apiErrorHelpers.consoleLog(errors)
  }
} // function* loadFmcUsers

export default function* userSaga() {
  yield takeEvery("USER_CREATE_OR_UPDATE_SAGA", createOrUpdateUser)
  yield takeEvery("SHELL_USER_UPDATE_SAGA", updateShellUser)
  yield takeEvery("USER_DELETE_SAGA", deleteUser)
  yield takeEvery("USER_LOAD_SAGA", loadUser)
  yield takeEvery("CURRENT_USER_LOAD_SAGA", loadCurrentUser)
  yield takeEvery("CURRENT_USER_LOAD_PAYMENT_METHODS_SAGA", loadCurrentUserPaymentMethods)
  yield takeEvery("USERS_LOAD_SAGA", loadUsers)
  yield takeEvery("FMC_USERS_LOAD_SAGA", loadFmcUsers)
}
