const startYear = 1980
const numYears = 40

export const years = [...Array(numYears).keys()].map((i) => {
  const num = i + startYear
  return { label: String(num), value: num }
})

export const Date_Format = Object.freeze({
  YYYY_MM_DD: 'YYYY-MM-DD',
  MMMM_YYYY: "MMMM YYYY",
  MM_DD_YYYY: "MM-DD-YYYY",
  M_D_YY: "M/D/YY"
})