export function titleCase(string) {
  return string
    .replace("_", " ")
    .replace("-", " ")
    .replace(/\w\S*/g, function (characters) {
      return characters.charAt(0).toUpperCase() + characters.substr(1).toLowerCase()
    })
}

export function capitalCase(string) {
  return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1)
}

export function prettify(str) {
  return str
    .replace(/_/g, " ")
    .split(" ")
    .map((w) => capitalCase(w))
    .join(" ")
}

export function isEmail(str) {
  return str.match(EMAIL_REGEX)
}

export function isPhoneNumber(str) {
  return str.match(/^\d{10}$/)
}

export function isZip(str) {
  return str.match(/^\d{5}((-|\s)\d{4})?$/)
}

export const EMAIL_REGEX = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/

export function numberWithCommas(x, name) {
  return name.includes('M') ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' mi' : x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " km" 
}
