import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { manualMaintenanceHistoriesWithVehicle } from "../../helpers/maintenanceHistoryHelpers"
import Alert from "../../components/shared/Alert"
import ApplicationLayout from "../../layouts/ApplicationLayout"
import LoadingError from "../../components/shared/LoadingError"
import LoadingThrobber from "../../components/shared/LoadingThrobber"
import ServiceList from "../../components/services/ServiceList"
import moment from "moment"
import { PAST } from "../../constants/services"
import { Date_Format } from "../../constants/years"

class ServiceIndex extends Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    isLoadingError: PropTypes.bool.isRequired,
    vehicles: PropTypes.array.isRequired,
    manualMaintenanceHistories: PropTypes.array.isRequired,
    shopOrders: PropTypes.array.isRequired,
    maintenanceIntervals: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props)

    // NOTE: locationStateData is instantiated as a variable here rather than referencing this.props.location.state.someObj directly because the state key may not be present when navigating to this page from a <Link> that does not explicitly set it.
    const locationStateData = this.props.location.state || {}
    let startingDate = locationStateData.startingDate
      ? moment(locationStateData.startingDate, "YYYY-MM-DD HH:mm:ss Z").add(1, "day") // Shitty hack to account for UTC being off-one-day for the continental US
      : moment().subtract(1, "month")
    // sometimes shitty hack makes us go to day 2, subtract.
    if (startingDate.date() == 2) startingDate = startingDate.subtract(1, "day")

    this.state = {
      alertMessage: locationStateData.alertMessage || "",
      alertType: locationStateData.alertType || "default",
      startDate: startingDate.startOf("day"),
      endDate: moment(startingDate).add(1, "month").add(5, "days").endOf("day"),
      startingPage: locationStateData.startingPage,
      orderFilter: locationStateData.orderFilter
    }
  }

  handleChange = async (date, name) => {
    await this.setState({
      startingPage: PAST,
      [name]: date
    })
    this.refreshOrders({ skipUnsetLoading: false })
  }

  refreshOrders = async (opts) => {
    await this.props.dispatch({
      type: "SHOP_ORDERS_LOAD_SAGA",
      payload: {
        options: {
          startDate: this.state.startDate.format(Date_Format.YYYY_MM_DD),
          endDate: this.state.endDate.format(Date_Format.YYYY_MM_DD),
          captureActive: true,
          withMaintenanceHistory: true
        },

        skipUnsetLoading: opts.skipUnsetLoading
      }
    })
  }

  refreshVehicles = async () => {
    await this.props.dispatch({
      type: "VEHICLES_LOAD_SAGA",
      payload: {
        addMaintenance: true
      }
    })
  }

  refreshMaintenanceIntervals = async () => {
    await this.props.dispatch({
      type: "MAINTENANCE_INTERVAL_SAGA"
    })
  }

  async componentDidMount() {
    this.loading = true
    this.refreshMaintenanceIntervals()
    await this.refreshOrders({ skipUnsetLoading: true })
    await this.refreshVehicles()
    this.loading = false
  }

  renderContent() {
    const {
      isLoading,
      isLoadingError,
      shopOrders,
      vehicles,
      maintenanceIntervals,
      manualMaintenanceHistories,
      language
    } = this.props

    if (!isLoading && !isLoadingError && !this.loading && shopOrders && vehicles) {
      return (
        <ServiceList
          shopOrders={shopOrders.concat(manualMaintenanceHistories)}
          vehicles={vehicles}
          serviceSchedulesByVehicle={maintenanceIntervals.service_schedules_by_vehicle}
          history={this.props.history}
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          onChange={this.handleChange}
          startingPage={this.state.startingPage}
          orderFilter={this.state.orderFilter}
          language={language}
        />
      )
    } else {
      return null
    }
  }

  render() {
    const { isLoading, isLoadingError } = this.props

    const { alertMessage, alertType } = this.state

    return (
      <ApplicationLayout>
        <Alert message={alertMessage} type={alertType} visible={alertMessage !== ""} />

        <LoadingThrobber visible={isLoading} />

        <LoadingError visible={!isLoading && isLoadingError} />

        {this.renderContent()}
      </ApplicationLayout>
    )
  }
} // class UserIndex

const mapStateToProps = (state) => {
  const vehicles = state.vehicles.vehicles
  const manualHistory = state.maintenanceHistories.manualMaintenanceHistories
  const language = state.users.currentUser ? state.users.currentUser.language : ''

  return {
    isLoading: state.application.isLoading,
    isLoadingError: state.application.isLoadingError,
    shopOrders: state.shopOrders.shopOrders,
    vehicles: vehicles,
    maintenanceIntervals: state.maintenanceIntervals,
    manualMaintenanceHistories: manualMaintenanceHistoriesWithVehicle(manualHistory, vehicles),
    language
  }
}

export default connect(mapStateToProps)(ServiceIndex)
