import React, { Component } from "react"
import PropTypes from "prop-types"

import * as phoneNumberHelpers from "../../helpers/phoneNumberHelpers"
import { isOwnerManagerOrAdmin, checkUserRole } from "../../helpers/activeMaintenanceHelpers"
import CollapsibleList from "../shared/CollapsibleList"
import { formattedName } from "../../helpers/vehicleHelpers"

import { Table, Button } from "semantic-ui-react"
import UserModal from "./UserModal"
import { FMC } from "../../constants/roles"
import { withTranslation } from "react-i18next"

class UserListItem extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired
  }

  showDeleteButton() {
    const { user, currentUser } = this.props

    return (
      user &&
      currentUser &&
      user.id !== null &&
      user.id !== currentUser.id &&
      ((isOwnerManagerOrAdmin(currentUser) && checkUserRole(user)) ||
        (currentUser.roles != null && currentUser.roles.includes(FMC)))
    )
  }

  onDelete = () => {
    this.props.onDelete(this.props.user.id)
  }

  render() {
    const { user, loadUsers, onDelete, currentUser, isFMC, t } = this.props
    return (
      <Table.Row>
        <Table.Cell singleLine>{user.name}</Table.Cell>
        <Table.Cell>
          {user.roles.map((r) => (typeof r === "string" ? r : r.name)).join(", ")}
        </Table.Cell>
        <Table.Cell singleLine>{user.email}</Table.Cell>
        <Table.Cell singleLine>{phoneNumberHelpers.reformat(user.cellPhone)}</Table.Cell>
        {!isFMC && (
          <Table.Cell>
            <CollapsibleList items={user.vehicles.map((v) => formattedName(v))} t={t} />
          </Table.Cell>
        )}
        {isFMC && (
          <Table.Cell>
            <CollapsibleList
              items={(user.approval_fleets || []).map((fleet) => fleet.name)}
              t={t}
            />
          </Table.Cell>
        )}
        <Table.Cell>
          {isOwnerManagerOrAdmin(currentUser) && (
            <UserModal
              completionCallback={loadUsers}
              user={user}
              isFMC={isFMC}
              currentUser={currentUser}
            >
              <span className="link">{t("editLabel")}</span>
            </UserModal>
          )}
          &nbsp;
          {this.showDeleteButton() && (
            <span className="link" onClick={this.onDelete}>
              {t("deleteLabel")}
            </span>
          )}
        </Table.Cell>
      </Table.Row>
    )
  }
} // class UserListItem

export default withTranslation("common")(UserListItem)
