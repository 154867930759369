import React, { Component } from "react"
import PropTypes from "prop-types"

import Button from "../../elements/Button"
import Select from "../../elements/Select"
import TextField from "../../elements/TextField"

class PaymentForm extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,

    isSubmitting: PropTypes.bool,
    payment: PropTypes.object
  }

  static defaultProps = {
    isSubmitting: false,
    payment: {}
  }

  initialState = {
    billing_address: "",
    billing_zip: "",
    credit_card_cvc: "",
    credit_card_expiration: "",
    credit_card_number: "",
    email: "",
    id: null,
    name: "",
    type: ""
  }

  state = {
    ...this.initialState,
    ...this.props.payment
  }

  // TODO: Conditonal based on all fields, and cc fields if type is cc.
  allowSubmit() {
    const { isSubmitting } = this.props

    return !isSubmitting
  }

  onChange(field, value) {
    this.setState({ [field]: value })
  }

  render() {
    const { onSubmit } = this.props

    const {
      billing_address,
      billing_zip,
      credit_card_cvc,
      credit_card_expiration,
      credit_card_number,
      email,
      name,
      type
    } = this.state

    return (
      <div className={"payment-form"}>
        <div className={"row"}>
          <div className={"column--12"}>
            <Select
              className={"select"}
              onChange={(event) => this.onChange("type", event.target.value)}
              options={[
                { label: "Bill To", value: "bill_to" },
                { label: "Credit Card", value: "credit_card" }
              ]}
              placeholder={"SELECT TYPE"}
              value={type}
            />
          </div>
        </div>

        <div className={"row"}>
          <div className={"column--12"}>
            <TextField
              className={"text_field"}
              onChange={(event) => this.onChange("name", event.target.value)}
              placeholder={"PAYMENT NICKNAME"}
              value={name}
            />
          </div>
        </div>

        <div className={"row"}>
          <div className={"column--12"}>
            <TextField
              className={"text_field"}
              onChange={(event) => this.onChange("billing_address", event.target.value)}
              placeholder={"BILLING ADDRESS"}
              value={billing_address}
            />
          </div>
        </div>

        <div className={"row"}>
          <div className={"column--12"}>
            <TextField
              className={"text_field"}
              onChange={(event) => this.onChange("billing_zip", event.target.value)}
              placeholder={"BILLING ZIP"}
              value={billing_zip}
            />
          </div>
        </div>

        <div className={"row"}>
          <div className={"column--12"}>
            <TextField
              className={"text_field"}
              onChange={(event) => this.onChange("email", event.target.value)}
              placeholder={"EMAIL"}
              value={email}
            />
          </div>
        </div>

        <div className={"row"}>
          <div className={"column--12"}>
            <TextField
              className={"text_field"}
              disabled={type !== "credit_card"}
              onChange={(event) => this.onChange("credit_card_number", event.target.value)}
              placeholder={"CREDIT CARD NUMBER"}
              value={credit_card_number}
            />
          </div>
        </div>

        <div className={"row"}>
          <div className={"column--12"}>
            <TextField
              className={"text_field"}
              disabled={type !== "credit_card"}
              onChange={(event) => this.onChange("credit_card_expiration", event.target.value)}
              placeholder={"CREDIT CARD EXPIRATION"}
              value={credit_card_expiration}
            />
          </div>
        </div>

        <div className={"row"}>
          <div className={"column--12"}>
            <TextField
              className={"text_field"}
              disabled={type !== "credit_card"}
              onChange={(event) => this.onChange("credit_card_cvc", event.target.value)}
              placeholder={"CREDIT CARD CVC"}
              value={credit_card_cvc}
            />
          </div>
        </div>

        <div className={"submit-container"}>
          <Button
            className={"button--submit"}
            disabled={!this.allowSubmit()}
            label={"SUBMIT"}
            onClick={() => onSubmit(this.state)}
          />
        </div>
      </div>
    )
  }
} // class PaymentForm

export default PaymentForm
