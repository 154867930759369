import React, { Component } from "react"
import PropTypes from "prop-types"

import UpcomingMaintenance from "./UpcomingMaintenance"

class UpcomingMaintenances extends Component {
  static propTypes = {
    vehicles: PropTypes.array.isRequired,

    limit: PropTypes.number
  }

  static defaultProps = {
    limit: null
  }

  totalUpcomingMaintenanceCount() {
    const { vehicles } = this.props

    let count = 0

    vehicles.forEach((vehicle) => {
      count += vehicle.maintenance
        .map((maintenanceRecord) => (maintenanceRecord.status === "due" ? 1 : 0))
        .reduce((a, b) => a + b, 0)
    })

    return count
  }

  renderUpcomingMaintenances() {
    const { limit, vehicles } = this.props

    const renderLimit = limit || this.totalUpcomingMaintenanceCount()

    let componentsToRender = []

    vehicles.forEach((vehicle) => {
      vehicle.maintenance.forEach((upcomingMaintenance) => {
        if (upcomingMaintenance.status === "due") {
          if (componentsToRender.length < renderLimit) {
            componentsToRender.push(
              <UpcomingMaintenance
                key={componentsToRender.length}
                upcomingMaintenance={upcomingMaintenance}
                vehicle={vehicle}
              />
            )
          }
        }
      })
    })

    return componentsToRender
  }

  render() {
    return <div className={"dash-upcoming"}>{this.renderUpcomingMaintenances()}</div>
  }
} // class UpcomingMaintenances

export default UpcomingMaintenances
