import { call, put, takeEvery } from "redux-saga/effects"

import api from "../../utilities/api"
import * as apiErrorHelpers from "../../helpers/apiErrorHelpers"
import * as sessionHelpers from "../../helpers/sessionHelpers"

export function* loadPaymentMethods(action) {
  try {
    yield put({ type: "SET_LOADING" })
    yield put({ type: "SET_PAYMENT_METHODS_LOADING" })
    yield put({ type: "UNSET_LOADING_ERROR" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    yield put({ type: "PAYMENT_METHODS_LOAD_REQUESTED" })

    const request = {
      url: "/braintree_payment_methods",
      body: {}
    }

    const response = yield call(api.utility.get, api.path(request.url), { body: request.body })

    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "PAYMENT_METHODS_LOAD_SUCCEEDED",
      payload: {
        paymentMethods: response.body
      }
    })
    ///////////////////////////

    ///////////////////////////
    //   LOAD CLIENT TOKEN   //
    ///////////////////////////

    yield call(loadClientToken)

    ///////////////////////////

    yield put({ type: "UNSET_LOADING" })
    yield put({ type: "UNSET_PAYMENT_METHODS_LOADING" })
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "LOADING_FAILED" })
    yield put({ type: "SET_LOADING_ERROR" })
    yield put({ type: "UNSET_LOADING" })
    yield put({ type: "UNSET_PAYMENT_METHODS_LOADING" })

    apiErrorHelpers.consoleLog(errors)
  }
} // function* loadPaymentMethods

export function* createPaymentMethod(action) {
  try {
    yield put({ type: "SET_LOADING" })
    yield put({ type: "SET_PAYMENT_METHODS_LOADING" })
    yield put({ type: "UNSET_LOADING_ERROR" })

    // create payment method by updating the user's payment_method_nonce   //
    const payload = action.payload || {}
    const formData = payload.formData || {}

    api.setAuthApi({ Authorization: sessionHelpers.getApplicationCookie("authentication_token") })

    yield put({ type: "PAYMENT_METHOD_CREATE_REQUESTED" })

    const request = {
      url: `/braintree_payment_methods`,
      body: {
        payment_method_nonce: payload.nonce,
        ...formData
      }
    }
    const response = yield call(api.utility.post, api.path(request.url), { body: request.body })

    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "PAYMENT_METHOD_CREATE_SUCCEEDED",
      payload: {
        paymentMethod: response.body
      }
    })

    yield put({ type: "UNSET_LOADING" })
    yield put({ type: "UNSET_PAYMENT_METHODS_LOADING" })
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "LOADING_FAILED" })
    yield put({ type: "SET_LOADING_ERROR" })
    yield put({ type: "UNSET_LOADING" })
    yield put({ type: "UNSET_PAYMENT_METHODS_LOADING" })

    apiErrorHelpers.consoleLog(errors)
  }
} // function* createPaymentMethod

export function* updatePaymentMethod(action) {
  try {
    yield put({ type: "SET_LOADING" })
    yield put({ type: "SET_PAYMENT_METHODS_LOADING" })
    yield put({ type: "UNSET_LOADING_ERROR" })

    const payload = action.payload || {}
    const formData = payload.formData || {}

    api.setAuthApi({ Authorization: sessionHelpers.getApplicationCookie("authentication_token") })

    yield put({ type: "PAYMENT_METHOD_UPDATE_REQUESTED" })

    const request = {
      url: `/braintree_payment_methods/${payload.id}`,
      body: { ...formData }
    }
    const response = yield call(api.utility.put, api.path(request.url), { body: request.body })

    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "PAYMENT_METHOD_UPDATE_SUCCEEDED",
      payload: {
        paymentMethod: response.body
      }
    })

    yield put({ type: "UNSET_LOADING" })
    yield put({ type: "UNSET_PAYMENT_METHODS_LOADING" })
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "LOADING_FAILED" })
    yield put({ type: "SET_LOADING_ERROR" })
    yield put({ type: "UNSET_LOADING" })
    yield put({ type: "UNSET_PAYMENT_METHODS_LOADING" })

    apiErrorHelpers.consoleLog(errors)
  }
} // function* updatePaymentMethod

export function* deletePaymentMethod(action) {
  try {
    yield put({ type: "SET_LOADING" })
    yield put({ type: "SET_PAYMENT_METHODS_LOADING" })
    yield put({ type: "UNSET_LOADING_ERROR" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    yield put({ type: "PAYMENT_METHOD_DELETE_REQUESTED" })

    const payload = action.payload || {}
    const id = payload.id
    const request = {
      url: `/braintree_payment_methods/${id}`,
      body: {}
    }

    const response = yield call(api.utility.del, api.path(request.url), { body: request.body })

    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "PAYMENT_METHOD_DELETE_SUCCEEDED",
      payload: {
        paymentMethodId: id
      }
    })
    yield put({ type: "UNSET_LOADING" })
    yield put({ type: "UNSET_PAYMENT_METHODS_LOADING" })
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "LOADING_FAILED" })
    yield put({ type: "SET_LOADING_ERROR" })
    yield put({ type: "UNSET_LOADING" })
    yield put({ type: "UNSET_PAYMENT_METHODS_LOADING" })

    apiErrorHelpers.consoleLog(errors)
  }
} // function* deletePaymentMethod

export function* loadClientToken(action) {
  try {
    yield put({ type: "SET_LOADING" })
    yield put({ type: "SET_PAYMENT_METHODS_LOADING" })
    yield put({ type: "UNSET_LOADING_ERROR" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    yield put({ type: "BRAINTREE_CLIENT_TOKEN_LOAD_REQUESTED" })

    const request = {
      url: "/braintree_tokens/new",
      body: {}
    }

    const response = yield call(api.utility.get, api.path(request.url), { body: request.body })

    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "BRAINTREE_CLIENT_TOKEN_LOAD_SUCCEEDED",
      payload: {
        clientToken: response.body.client_token
      }
    })
    yield put({ type: "UNSET_LOADING" })
    yield put({ type: "UNSET_PAYMENT_METHODS_LOADING" })
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "LOADING_FAILED" })
    yield put({ type: "SET_LOADING_ERROR" })
    yield put({ type: "UNSET_LOADING" })
    yield put({ type: "UNSET_PAYMENT_METHODS_LOADING" })

    apiErrorHelpers.consoleLog(errors)
  }
} // function* loadClientToken

export default function* paymentMethodSaga() {
  yield takeEvery("PAYMENT_METHODS_LOAD_SAGA", loadPaymentMethods)
  yield takeEvery("PAYMENT_METHOD_CREATE_SAGA", createPaymentMethod)
  yield takeEvery("PAYMENT_METHOD_UPDATE_SAGA", updatePaymentMethod)
  yield takeEvery("PAYMENT_METHOD_DELETE_SAGA", deletePaymentMethod)
  yield takeEvery("BRAINTREE_CLIENT_TOKEN_LOAD_SAGA", loadClientToken)
}
