import * as React from "react"
import { Component, Link } from "react"
import { Button, ButtonProps, Label } from "semantic-ui-react"
import * as uuid from "uuid"

export class FileDownloadButton extends Component {
  constructor(props) {
    super(props)
    this.id = uuid.v1()
  }

  preventRefresh = (e) => {
    return false
  }

  render() {
    return (
      <a
        download
        href={this.props.downloadLink}
        target="_blank"
        rel="noopener"
        id={this.id}
        style={{ color: "unset" }}
        onClick={(e) => this.preventRefresh(e)}
      >
        <Button {...this.props} className="white-button" htmlFor={this.id} Download CSV Template />
      </a>
    )
  }
}

export default FileDownloadButton
