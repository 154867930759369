import React, { Component } from "react"
import PropTypes from "prop-types"

import * as orderServiceHelpers from "../../helpers/orderServiceHelpers"

class ServicesTotal extends Component {
  static propTypes = {
    services: PropTypes.array.isRequired
  }

  render() {
    const { services } = this.props

    return (
      <div className={"row-total"}>
        <div className={"dash-approvals-detail-item"}>TOTAL</div>

        <div className={"dash-approvals-detail-item service_request__item_total"}>
          {`$${orderServiceHelpers.getTotal(services).toFixed(2)}`}
        </div>
      </div>
    )
  }
} // class ServicesTotal

export default ServicesTotal
