import { preparedMaintenanceHistories } from "../../helpers/maintenanceHistoryHelpers"

export const initialState = {
  manualMaintenanceHistories: []
}

export default function (state = initialState, action) {
  const { payload, type } = action

  switch (type) {
    case "MAINTENANCE_HISTORIES_LOAD_SUCCEEDED":
      return {
        ...state,
        manualMaintenanceHistories: preparedMaintenanceHistories(payload.maintenanceHistories)
      }
    default:
      return state
  }
}
