import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"

export default () => {
  const shouldSendEvents =
    process.env.REACT_APP_ENVIRONMENT === "production" ||
    process.env.REACT_APP_ENVIRONMENT === "staging"

  Sentry.init({
    environment: process.env.REACT_APP_ENVIRONMENT,
    dsn: shouldSendEvents
      ? "https://358aa1b243784fa58f7e353b6902cd0c@o439534.ingest.sentry.io/5416627"
      : undefined,
    release: process.env.REACT_APP_SENTRY_RELEASE,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0
  })
}
