import React, { Component } from "react"
import PropTypes from "prop-types"
import { Table } from "semantic-ui-react"

import LoadingError from "../../components/shared/LoadingError"
import LoadingThrobber from "../../components/shared/LoadingThrobber"
import NoResults from "../../components/shared/NoResults"
import ShopListFilter from "./ShopListFilter"
import ShopListItem from "./ShopListItem"
import { withTranslation } from "react-i18next"

class ShopList extends Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    isLoadingError: PropTypes.bool.isRequired,
    onFavoriteSubmit: PropTypes.func.isRequired,
    shops: PropTypes.array.isRequired,
    previousFormData: PropTypes.object
  }

  render() {
    const {
      isLoading,
      isLoadingError,
      onFavoriteSubmit,
      shops,
      searchMode,
      previousFormData,
      asSubForm,
      handleSelectShop,
      disableSubmit,
      language,
      t
    } = this.props
    return (
      <div>
        <LoadingThrobber visible={isLoading} />

        <LoadingError visible={!isLoading && isLoadingError} />

        {searchMode && (
          <div style={isLoading ? { display: "none", height: "0px" } : {}}>
            {asSubForm && <span className="primary-button-color">{t("priceByLocationLabel")}</span>}
            <ShopListFilter
              asSubForm={asSubForm}
              filterShopsParams={this.props.filterShopsParams}
            />
          </div>
        )}

        {!isLoading && !isLoadingError && (
          <div>
            <Table
              stackable
              basic="very"
              width="100%"
              style={{ marginTop: `${searchMode ? 18 : 2}px` }}
            >
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell>{t("shopLabel")}</Table.HeaderCell>
                  {asSubForm && <Table.HeaderCell>{t("priceLabel")}</Table.HeaderCell>}
                  {searchMode && <Table.HeaderCell>{t("distanceLabel")}</Table.HeaderCell>}
                  <Table.HeaderCell>{t("addressLabel")}</Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                  {!asSubForm && <Table.HeaderCell></Table.HeaderCell>}
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {shops.map((shop, index) => {
                  return (
                    <ShopListItem
                      key={`shop-${index}`}
                      onFavoriteSubmit={onFavoriteSubmit}
                      shop={shop}
                      searchMode={searchMode}
                      previousFormData={previousFormData}
                      asSubForm={asSubForm}
                      handleSelectShop={handleSelectShop}
                      disableSubmit={disableSubmit}
                      language={language}
                    />
                  )
                })}
              </Table.Body>
            </Table>
          </div>
        )}

        <NoResults visible={!isLoading && !isLoadingError && shops.length === 0} />
      </div>
    )
  }
} // class ShopList

export default withTranslation("shops")(ShopList)
