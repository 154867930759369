import { call, put, takeEvery } from "redux-saga/effects"

import api from "../../utilities/api"
import * as apiErrorHelpers from "../../helpers/apiErrorHelpers"
import * as sessionHelpers from "../../helpers/sessionHelpers"

// NOTE: Part 2: Submits the initial service request data along with a selected schedule date/time to create the service request on the backend.
export function* createServiceRequest(action) {
  try {
    yield put({
      type: "SERVICE_REQUEST_CREATE_REQUESTED"
    })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    const request = {
      url: "/orders",
      body: {
        ...action.payload.formData
      }
    }

    const response = yield call(api.utility.post, api.path(request.url), { body: request.body })

    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "SERVICE_REQUEST_CREATE_SUCCEEDED"
    })

    const afterRequestData = {
      alert: action.payload.t("serviceRequestCreatedLabel"),
      alertType: "success"
    }

    yield call(action.callback, "success", afterRequestData)
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({
      type: "SERVICE_REQUEST_CREATE_FAILED"
    })

    const afterRequestData = {
      error: apiErrorHelpers.toString(errors)
    }

    yield call(action.callback, "failure", afterRequestData)

    apiErrorHelpers.consoleLog(errors)
  }
} // createServiceRequest()

export function* loadServiceRequest(action) {
  try {
    yield put({ type: "SET_LOADING" })
    yield put({ type: "UNSET_LOADING_ERROR" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    ////////////////////////
    //  LOAD SHOPS DATA   //
    ////////////////////////
    // NOTE: Shop data is only loaded if empty. This is so that "filtered" shop data is not overridden by "favorites" shop data every time.
    const shops = action.payload.shops || []

    if (shops.length === 0) {
      yield put({ type: "SHOPS_LOAD_REQUESTED" })

      const shopsRequest = {
        url: "/shops/favorites",
        body: {}
      }

      const shopsResponse = yield call(api.utility.get, api.path(shopsRequest.url), {
        body: shopsRequest.body
      })

      apiErrorHelpers.catchErrors(shopsResponse.body)

      yield put({
        type: "SHOPS_LOAD_SUCCEEDED",
        payload: {
          shops: shopsResponse.body
        }
      })
    }
    ////////////////////////

    ////////////////////////
    // LOAD VEHICLES DATA //
    ////////////////////////

    const vehicles = action.payload.vehicles || []
    if (!action.payload.vehicle || vehicles.length === 0) {
      yield put({ type: "VEHICLES_LOAD_REQUESTED" })

      const vehiclesRequest = {
        url: "/vehicles",
        body: {}
      }

      const vehiclesResponse = yield call(api.utility.get, api.path(vehiclesRequest.url), {
        body: vehiclesRequest.body
      })

      apiErrorHelpers.catchErrors(vehiclesResponse.body)

      yield put({
        type: "VEHICLES_LOAD_SUCCEEDED",
        payload: {
          vehicles: vehiclesResponse.body
        }
      })

      yield put({
        type: "SET_VEHICLES_DATA_TYPE",
        payload: { dataType: "standard" }
      })
    }

    ////////////////////////

    ////////////////////////
    // LOAD SERVICES DATA //
    ////////////////////////
    getAllServices()
    ////////////////////

    ////////////////////
    // LOAD CURRENT USER DATA //
    ////////////////////

    yield put({ type: "CURRENT_USER_LOAD_PAYMENT_METHODS_SAGA" })

    ////////////////////

    yield put({ type: "UNSET_LOADING" })
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "LOADING_FAILED" })
    yield put({ type: "SET_LOADING_ERROR" })
    yield put({ type: "UNSET_LOADING" })

    apiErrorHelpers.consoleLog(errors)
  }
} // function* loadServiceRequest

export function* getAllServices() {
  yield put({ type: "SERVICES_LOAD_REQUESTED" })

  const servicesRequest = {
    url: "/services",
    body: { basic: true }
  }

  const servicesResponse = yield call(api.utility.get, api.path(servicesRequest.url), {
    body: servicesRequest.body
  })

  apiErrorHelpers.catchErrors(servicesResponse.body)

  yield put({
    type: "SERVICES_LOAD_SUCCEEDED",
    payload: {
      services: servicesResponse.body
    }
  })
}

export function* getAvailableTimes(action) {
  try {
    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })
    const { services, shopId, vehicleId, date, callback } = action.payload

    const serviceDefinitionIds = services.map((service) => {
      return service.service_definition_id || service.id
    })
    let body = {
      vehicle_id: vehicleId,
      service_definition_ids: serviceDefinitionIds,
      date: date,
      days: 1
    }

    const response = yield call(api.utility.get, api.path(`/shops/${shopId}/schedule`), {
      body: body
    })

    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "AVAILABLE_TIMES_LOAD_SUCCEEDED",
      payload: { availableTimesData: response.body }
    })

    yield call(callback)
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield call(action.payload.callback, apiErrorHelpers.toString(errors))

    apiErrorHelpers.consoleLog(errors)
  }
} // getAvailableTimes()

export function* getServicePrices(action) {
  try {
    yield put({ type: "SET_SERVICE_PRICES_LOADING" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    const servicesRequest = {
      url: "/services",
      body: {
        ...action.payload,
        full_prices: true
      }
    }

    const servicesResponse = yield call(api.utility.get, api.path(servicesRequest.url), {
      body: servicesRequest.body
    })

    apiErrorHelpers.catchErrors(servicesResponse.body)

    yield put({
      type: "SERVICE_PRICES_LOAD_SUCCEEDED",
      payload: {
        servicePrices: servicesResponse.body
      }
    })
    yield put({ type: "UNSET_SERVICE_PRICES_LOADING" })
  } catch (errors) {
    // TODO: setup error handling on backend route and then here
    yield put({ type: "UNSET_SERVICE_PRICES_LOADING" })
  }
}

export default function* serviceRequestSaga() {
  yield takeEvery("SERVICE_REQUEST_CREATE_SAGA", createServiceRequest)
  yield takeEvery("SERVICE_REQUEST_LOAD_SAGA", loadServiceRequest)
  yield takeEvery("SERVICE_REQUEST_GET_AVAILABLE_TIMES_SAGA", getAvailableTimes)
  yield takeEvery("SERVICE_REQUEST_GET_PRICES_SAGA", getServicePrices)
  yield takeEvery("SERVICE_REQUEST_GET_ALL", getAllServices)
}
