import i18n from "i18next"
import {
  CANDA_COUNTRY_CODE,
  EN_CANDA_LOCALE,
  EN_LOCALE,
  FR_CANDA_LOCALE,
  FR_LOCALE
} from "../constants/users"

export function setLanguage(language, country) {
  const locale =
    language === EN_LOCALE && country === CANDA_COUNTRY_CODE
      ? EN_CANDA_LOCALE
      : language === FR_LOCALE
      ? FR_CANDA_LOCALE
      : EN_LOCALE
  i18n.changeLanguage(locale)
}
