export const initialState = {
  services: [],
  priceRanges: []
}

export default function (state = initialState, action) {
  // eslint-disable-next-line
  const { payload, type } = action

  switch (type) {
    case "SHOP_ORDERS_LOAD_SUCCEEDED":
      return {
        ...state,
        shopOrders: action.payload.shopOrders
      }
    case "SHOP_ORDER_LOAD_SUCCEEDED":
      return {
        ...state,
        shopOrder: action.payload.shopOrder
      }
    case "SHOP_ORDER_PRICE_RANGES_LOAD_SUCCEEDED":
      return {
        ...state,
        priceRanges: action.payload.priceRanges
      }
    case "MAINTENANCE_UPDATE_SUCCEEDED":
      return {
        ...state,
        shopOrder: { ...state.shopOrder, ...payload.order }
      }
    default:
      return state
  }
}
