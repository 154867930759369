import { call, put, takeEvery } from "redux-saga/effects"

import api from "../../utilities/api"
import * as apiErrorHelpers from "../../helpers/apiErrorHelpers"
import * as sessionHelpers from "../../helpers/sessionHelpers"

export function* loadAffiliation(action) {
  try {
    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    /////////////////////////////
    //  LOAD AFFILIATION DATA  //
    /////////////////////////////
    yield put({ type: "AFFILIATION_LOAD_REQUESTED" })

    const request = {
      url: "/affiliations/me",
      body: {}
    }

    const response = yield call(api.utility.get, api.path(request.url), { body: request.body })

    apiErrorHelpers.catchErrors(response.body)

    const affiliation = response.body

    yield put({
      type: "AFFILIATION_LOAD_SUCCEEDED",
      payload: {
        affiliation: affiliation
      }
    })
    return affiliation
    ////////////////////////////
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "AFFILIATION_LOAD_FAILED" })

    apiErrorHelpers.consoleLog(errors)
  }
} // function* loadAffiliation

export default function* affiliationSaga() {
  yield takeEvery("AFFILIATION_LOAD_SAGA", loadAffiliation)
}
