import React, { Component } from "react"
import PropTypes from "prop-types"
import { Table, Select } from "semantic-ui-react"
import * as userHelpers from "../../helpers/userHelpers"
import { withTranslation } from "react-i18next"
import { FMC, FMC_FLEET_ADMIN, OWNER } from "../../constants/roles"

class PaymentMethod extends Component {
  static propTypes = {
    onUpdate: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    paymentMethod: PropTypes.object.isRequired,
    setEditingId: PropTypes.func.isRequired,
    usersOnPaymentMethod: PropTypes.array.isRequired,
    allUsers: PropTypes.array.isRequired,
    isEditing: PropTypes.bool.isRequired
  }

  state = {
    formData: {
      customer_ids: this.props.usersOnPaymentMethod.map((u) => u.id)
    }
  }

  onChange(field, value) {
    let updatedFormData = { ...this.state.formData }
    updatedFormData[field] = value
    this.setState({ formData: updatedFormData })
  }

  renderUsers() {
    const {
      usersOnPaymentMethod,
      setEditingId,
      onDelete,
      paymentMethod,
      currentUserId,
      t,
      currentUser
    } = this.props
    const { id, owner_id, has_active_orders } = paymentMethod

    return (
      <React.Fragment>
        <Table.Cell>{usersOnPaymentMethod.map((u) => u.name).join(", ")}</Table.Cell>
        <Table.Cell>
          {(currentUser.roles.includes(FMC) ||
            currentUser.roles.includes(FMC_FLEET_ADMIN) ||
            currentUser.roles.includes(OWNER) ||
            owner_id === currentUserId) && (
            <React.Fragment>
              <span
                className="link"
                onClick={() => setEditingId(id)}
                style={{ paddingRight: "5px" }}
              >
                {t("editLabel")}
              </span>
              <span className="link" onClick={() => onDelete(id, has_active_orders)}>
                {t("deleteLabel")}
              </span>
            </React.Fragment>
          )}
        </Table.Cell>
      </React.Fragment>
    )
  }

  renderEditUsers() {
    const { isEditing, allUsers, onUpdate, setEditingId, paymentMethod, t } = this.props
    const { id } = paymentMethod
    const { formData } = this.state

    return (
      <React.Fragment>
        <Table.Cell>
          <Select
            onChange={(_, data) => this.onChange("customer_ids", data.value)}
            options={userHelpers.selectOptions(allUsers, { semantic: true })}
            placeholder={"Drivers"}
            value={formData.customer_ids}
            multiple
          />
        </Table.Cell>
        <Table.Cell>
          <span
            className="link"
            onClick={() => onUpdate(paymentMethod.id, formData)}
            style={{ paddingRight: "5px" }}
          >
            {t("updateLabel")}
          </span>
          <span className="link" onClick={() => setEditingId(undefined)}>
            {t("cancelLabel")}
          </span>
        </Table.Cell>
      </React.Fragment>
    )
  }

  render() {
    const { paymentMethod, isEditing } = this.props
    const {
      card_type,
      expiration_date,
      last_4,
      email,
      type,
      customer_name,
      customer_ids
    } = paymentMethod

    return (
      <Table.Row>
        <Table.Cell singleLine>
          {type === "credit_card" ? `${card_type} *-${last_4}` : `PayPal ${email}`}
        </Table.Cell>
        <Table.Cell>{customer_name}</Table.Cell>
        <Table.Cell>{expiration_date}</Table.Cell>
        {isEditing ? this.renderEditUsers() : this.renderUsers()}
      </Table.Row>
    )
  }
} // PaymentMethod

export default withTranslation("common")(PaymentMethod)
