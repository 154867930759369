import React from "react"
import ReactDOM from "react-dom"
import * as registerServiceWorker from "./registerServiceWorker"
import NewRelicInjector from "./components/shared/NewRelicInjector"
import SentryInjector from "./components/shared/SentryInjector"
import StylesheetInjector from "./components/shared/StylesheetInjector"
import App from "./App"

SentryInjector()
StylesheetInjector()
NewRelicInjector()

ReactDOM.render(<App />, document.getElementById("root"))
registerServiceWorker.unregister()
