import { createStore, applyMiddleware, compose } from "redux"
import rootReducer from "./reducers/rootReducer"
import rootSaga from "./sagas/rootSaga"
import createSagaMiddleware from "redux-saga"
import * as Sentry from "@sentry/react"

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const sagaMiddleware = createSagaMiddleware()
const sentryReduxEnhancer = Sentry.createReduxEnhancer()
const middlewares = [sagaMiddleware]

if (process.env.NODE_ENV === `development`) {
  const { createLogger } = require(`redux-logger`)

  middlewares.push(createLogger({ collapsed: true }))
}

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(...middlewares), sentryReduxEnhancer)
)

/* inits Saga middleware */
sagaMiddleware.run(rootSaga)

export default store
