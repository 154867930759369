import React, { Component } from "react"
import PropTypes from "prop-types"

import "../assets/stylesheets/elements/_error_field.scss"

class ErrorField extends Component {
  static propTypes = {
    label: PropTypes.string,
    displayError: PropTypes.bool,
    className: PropTypes.string
  }

  static defaultProps = {
    displayError: false,
    label: "",
    className: ""
  }

  render() {
    const { label, displayError, className } = this.props

    return (
      <p
        style={{ color: "red", display: `${displayError ? "inline" : "none"}` }}
        className={className}
      >
        {label}
      </p>
    )
  }
} // class ErrorField

export default ErrorField
