import React, { Component } from "react"
import PropTypes from "prop-types"
import { Button as SemanticButton } from "semantic-ui-react"

class Button extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,

    className: PropTypes.string,
    disabled: PropTypes.bool
  }

  static defaultProps = {
    className: "",
    disabled: false
  }

  render() {
    const { className, disabled, label, size, color, onClick } = this.props

    return (
      <SemanticButton
        size={size}
        color={color}
        className={className}
        disabled={disabled}
        onClick={onClick}
      >
        {label}
      </SemanticButton>
    )
  }
} // class Button

export default Button
