import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

import api from "../../utilities/api"
import * as apiErrorHelpers from "../../helpers/apiErrorHelpers"
import * as sessionHelpers from "../../helpers/sessionHelpers"
import * as shopHelpers from "../../helpers/shopHelpers"

export function* favoriteShop(action) {
  try {
    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    yield put({ type: "SHOP_FAVORITE_REQUESTED" })

    const shopId = action.payload.formData.id

    const request = {
      url: `/shops/${shopId}/toggle_favorite`,
      body: {
        ...action.payload.formData
      }
    }

    const response = yield call(api.utility.post, api.path(request.url), { body: request.body })

    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "SHOP_FAVORITE_SUCCEEDED",
      payload: {
        shop: response.body.v4_shop
      }
    })

    const afterRequestData = {
      alertMessage: "Preference updated",
      alertType: "success",
      status: "success"
    }

    yield call(action.callback, afterRequestData)
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({
      type: "SHOP_FAVORITE_FAILED"
    })

    const afterRequestData = {
      alertMessage: apiErrorHelpers.toString(errors),
      alertType: "error",
      status: "error"
    }

    yield call(action.callback, afterRequestData)

    apiErrorHelpers.consoleLog(errors)
  }
} // function* favoriteShop

export function* filterShops(action) {
  const { asSubForm } = action.payload
  try {
    if (asSubForm) {
      yield put({ type: "SET_INNER_LOADING" })
    } else {
      yield put({ type: "SET_LOADING" })
    }

    yield put({ type: "UNSET_LOADING_ERROR" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    yield put({ type: "SHOPS_FILTER_REQUESTED" })

    const zipCode = action.payload.filters.zip_code
    const latitude = action.payload.filters.searchLocation.latitude
    const longitude = action.payload.filters.searchLocation.longitude

    const params = { zip: zipCode, latitude: latitude, longitude: longitude }

    if (asSubForm) {
      const { filterShopsParams } = action.payload
      const vehicle_id = filterShopsParams.vehicle_id
      const services_requested = JSON.stringify(filterShopsParams.services_requested)
      params.vehicle_id = vehicle_id
      params.services_requested = services_requested
    }

    let url = api.constructURLWithParams("/shops?", params)

    const request = {
      url: url,
      body: {}
    }

    const response = yield call(api.utility.get, api.path(request.url), { body: request.body })

    apiErrorHelpers.catchErrors(response.body)

    const shops = shopHelpers.withPricing(response.body || [])

    yield put({
      type: "SHOPS_FILTER_SUCCEEDED",
      payload: {
        shops: shops
      }
    })

    if (asSubForm) {
      yield put({ type: "UNSET_INNER_LOADING" })
    } else {
      yield put({ type: "UNSET_LOADING" })
    }
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({
      type: "SHOPS_FILTER_FAILED"
    })

    yield put({ type: "SET_LOADING_ERROR" })

    if (asSubForm) {
      yield put({ type: "UNSET_INNER_LOADING" })
    } else {
      yield put({ type: "UNSET_LOADING" })
    }

    apiErrorHelpers.consoleLog(errors)
  }
} // function* filterShops

export function* loadShops(action) {
  try {
    yield put({ type: "SET_LOADING" })
    yield put({ type: "UNSET_LOADING_ERROR" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    ///////////////////////
    //  LOAD SHOPS DATA  //
    ///////////////////////
    yield put({ type: "SHOPS_LOAD_REQUESTED" })

    const shopsRequest = {
      url: "/shops/favorites",
      body: {}
    }

    const response = yield call(api.utility.get, api.path(shopsRequest.url), {
      body: shopsRequest.body
    })

    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "SHOPS_LOAD_SUCCEEDED",
      payload: {
        shops: response.body
      }
    })
    ///////////////////////

    yield put({ type: "UNSET_LOADING" })
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "LOADING_FAILED" })
    yield put({ type: "SET_LOADING_ERROR" })
    yield put({ type: "UNSET_LOADING" })

    apiErrorHelpers.consoleLog(errors)
  }
} // function* loadShops

export default function* shopSaga() {
  yield takeEvery("SHOP_FAVORITE_SAGA", favoriteShop)
  yield takeLatest("SHOPS_FILTER_SAGA", filterShops)
  yield takeEvery("SHOPS_LOAD_SAGA", loadShops)
}
