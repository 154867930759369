import React from "react"
import { Link } from "react-router-dom"
import { navigationPaths } from "../../constants/paths"
import { Button } from "semantic-ui-react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/pro-light-svg-icons"
import { useTranslation } from "react-i18next"

export default function AddMaintenanceHistoryButton({ text, floated, secondary }) {
  const { t } = useTranslation("common")

  return (
    <Link
      to={{
        pathname: navigationPaths.MaintenanceHistoryNew()
      }}
    >
      <Button className={secondary && "white-button"} floated={floated}>
        {text ? text : t("addMaintenanceHistoryLabel")}
        <FontAwesomeIcon icon={faPlus} />
      </Button>
    </Link>
  )
}
