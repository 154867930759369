import React, { Component } from "react"
import PrivacyPolicy from "../../components/sessions/PrivacyPolicy"
import { connect } from "react-redux"

class PrivacyPolicyPage extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div>
        <PrivacyPolicy />
      </div>
    )
  }
} // class PrivacyPolicy

export default connect()(PrivacyPolicyPage)
