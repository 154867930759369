import React, { Component } from "react"
import PropTypes from "prop-types"

import * as activeMaintenanceHelpers from "../../helpers/activeMaintenanceHelpers"

import Button from "../../elements/Button"

class ActiveMaintenanceAcceptAllButton extends Component {
  static propTypes = {
    onAcceptAllSubmit: PropTypes.func.isRequired,
    users: PropTypes.array.isRequired,
    vehicle: PropTypes.object.isRequired,

    isSubmitting: PropTypes.bool
  }

  static defaultProps = {
    isSubmitting: false
  }

  allowSubmit() {
    const { isSubmitting } = this.props
    return !isSubmitting
  }

  confirmApproval() {
    return window.confirm("Are you sure you want to accept all services?")
  }

  formattedApprovalContacts() {
    const { users, vehicle } = this.props

    return activeMaintenanceHelpers.formattedApprovalContacts(vehicle.active_order, users)
  }

  formattedApprovalData() {
    const activeMaintenance = this.props.vehicle.active_order

    const approvableStatuses = ["active", "awaiting_approval", "initiated"]

    return {
      id: activeMaintenance.id,
      status: "approved",
      order_services: activeMaintenance.order_services.map((service) => {
        let applicableStatus

        if (approvableStatuses.includes(service.status)) {
          applicableStatus = "approved"
        } else {
          applicableStatus = service.status
        }

        return {
          id: service.id,
          status: applicableStatus
        }
      })
    }
  }

  onAcceptAll(event) {
    // NOTE: This prevents routing by the encapsulating link.
    event.preventDefault()

    const { onAcceptAllSubmit } = this.props

    if (this.permitApproval()) {
      if (this.confirmApproval()) {
        onAcceptAllSubmit(this.formattedApprovalData())
      }
    }
  }

  permitApproval() {
    const currentUserCanApprove = this.props.vehicle.active_order.can_approve

    if (currentUserCanApprove) {
      return true
    } else {
      alert(`Please contact for approval:\n${this.formattedApprovalContacts()}`)
      return false
    }
  }

  render() {
    return (
      <Button
        className={"button--primary maintenance_approval__dashboard_button"}
        disabled={!this.allowSubmit()}
        label={"ACCEPT ALL"}
        onClick={(event) => this.onAcceptAll(event)}
      />
    )
  }
} // class ActiveMaintenanceAcceptAllButton

export default ActiveMaintenanceAcceptAllButton
