import React, { Component } from "react"
import PropTypes from "prop-types"
import Button from "../../elements/Button"
import PasswordField from "../../elements/PasswordField"
import TextField from "../../elements/TextField"
import MobileStoreButton from "../../elements/MobileStoreButton"
import * as browserHelpers from "../../helpers/browserHelpers"
import { getSubdomain } from "../shared/StylesheetInjector"
import {
  isFleetMaintenanceHub,
  isFleetMaintenanceHubCanada,
  isZubie
} from "../../helpers/affiliationHelpers"
import UserOrAdminSignIn from "./UserOrAdminSignIn"
import { elements } from "caradvise_shared_components"
import { withTranslation } from "react-i18next"

const { PrivacyPolicyAndTerms } = elements

class SignInForm extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,

    isSubmitting: PropTypes.bool
  }

  static defaultProps = {
    isSubmitting: false
  }

  constructor(props) {
    super(props)

    this.state = {
      formData: {
        email: "",
        password: ""
      },
      passwordInvisible: true
    }
  }

  allowSubmit = () => {
    const { isSubmitting } = this.props

    return !isSubmitting
  }

  onFieldChange = (field, value) => {
    let updatedFormData = { ...this.state.formData }
    updatedFormData[field] = value
    this.setState({ formData: updatedFormData })
  }

  onKeyDown = (event) => {
    const { onSubmit } = this.props

    if (event.key === "Enter") {
      onSubmit(this.state.formData)
    }
  }

  onClick = () => {
    this.props.onSubmit(this.state.formData)
  }

  onEmail = (event) => {
    this.onFieldChange("email", event.target.value)
  }

  onPassword = (event) => {
    this.onFieldChange("password", event.target.value)
  }
  onEyeIconClick = () => {
    this.setState({ passwordInvisible: !this.state.passwordInvisible })
  }

  usernameAndPassword = (t) => {
    const { email, password } = this.state.formData

    return (
      <div className={"sign-in-form"} onKeyDown={(event) => this.onKeyDown(event)}>
        <div className={"sign-in-email"}>
          <TextField
            className={"sign-in-input"}
            onChange={this.onEmail}
            placeholder={t("common:emailLabel")}
            value={email}
          />
        </div>
        <div className={"sign-in-password"}>
          <PasswordField
            className={"sign-in-input"}
            onChange={this.onPassword}
            placeholder={t("common:passwordLabel")}
            value={password}
            onIconClick={() => this.onEyeIconClick("password")}
            obfuscated={this.state.passwordInvisible}
          />
        </div>

        <div className={"sign-in-submit"}>
          {
            <button className={"link"} onClick={this.props.navigateToForgotPassword}>
              {t("common:forgotPasswordLabel")}
            </button>
          }
          <Button
            className={"button--submit"}
            disabled={!this.allowSubmit()}
            label={t("common:signInLabel")}
            onClick={this.onClick}
          />
        </div>
      </div>
    )
  }

  mobileStoreButton = (store, link) => {
    return <MobileStoreButton store={store} url={link} linkProps={{ title: "iOS Store Button" }} />
  }

  whiteLabelMobileStoreButton() {
    const iosBaseUrl = "https://itunes.apple.com/us/app/"
    const androidBaseUrl = "https://play.google.com/store/apps/"
    const appPaths = {
      fleetadvise: {
        ios: "fleetamerica/id1361299536?ls=1&mt=8",
        android: "details?id=com.caradvise_fleet_mobile"
      },
      fleetamerica: {
        ios: "fleetamerica/id1361299536?ls=1&mt=8",
        android: "details?id=com.caradvise_fleet_mobile"
      },
      zubie: {
        ios: "zubie-smart-maintenance/id1456945093?ls=1&mt=8",
        android: "details?id=com.caradvise_zubie"
      }
    }

    const subDomain = getSubdomain()
    if (appPaths[subDomain] == null) return null

    const iosButton = () => this.mobileStoreButton("ios", iosBaseUrl + appPaths[subDomain]["ios"])
    const androidButton = () =>
      this.mobileStoreButton("android", androidBaseUrl + appPaths[subDomain]["android"])

    const mobileButton = browserHelpers.browserIsSafari() ? iosButton() : androidButton()

    return <div>{browserHelpers.isMobileBrowser() && mobileButton}</div>
  }

  render() {
    const { t } = this.props
    const isZubieOrFMH = isZubie() || isFleetMaintenanceHub() || isFleetMaintenanceHubCanada()

    return (
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        {isZubieOrFMH && (
          <UserOrAdminSignIn usernameAndPassword={this.usernameAndPassword(t)} t={t} />
        )}
        {!isZubieOrFMH && this.usernameAndPassword(t)}
        <PrivacyPolicyAndTerms t={t} />
        {this.whiteLabelMobileStoreButton()}
      </div>
    )
  }
} // class SignInForm

export default withTranslation("privacyPolicyAndTerms")(SignInForm)
