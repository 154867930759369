import React, { Component } from "react"
import PropTypes from "prop-types"

import * as arrayHelpers from "../../helpers/arrayHelpers"
import { isOwnerManagerOrAdmin } from "../../helpers/activeMaintenanceHelpers"
import { newPolicyTemplate } from "../../constants/policies"

import MaintenancePolicy from "./MaintenancePolicy"
import { Table, Button } from "semantic-ui-react"
import { withTranslation } from "react-i18next"
import { isFleetMaintenanceHubCanada } from "../../helpers/affiliationHelpers"

class MaintenancePolicies extends Component {
  static propTypes = {
    onDelete: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    policies: PropTypes.array.isRequired,
    services: PropTypes.array.isRequired,
    users: PropTypes.array.isRequired,
    vehicles: PropTypes.array.isRequired,

    allowActions: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    onRedirect: PropTypes.func,
    user: PropTypes.object,
    vehicle: PropTypes.object
  }

  static defaultProps = {
    allowActions: true,
    isSubmitting: false,
    onRedirect: () => console.log("MaintenancePolicies component should redirect"),
    user: null,
    vehicle: null
  }

  state = {
    currentlyEditingId: null,
    policies: this.initialPolicies()
  }

  addNewPolicy() {
    const { user, vehicle } = this.props

    const { policies } = this.state

    let newPolicy = { ...newPolicyTemplate }

    if (user) {
      newPolicy["user_ids"] = user.id
    }
    if (vehicle) {
      newPolicy["vehicle_ids"] = vehicle.id
    }

    let updatedPolicies = arrayHelpers.prepend(policies, newPolicy)

    this.setState({ policies: updatedPolicies })
  }

  changeCurrentlyEditing(id) {
    this.setState({ currentlyEditingId: id })
  }

  handleCancel(policyId) {
    if (policyId === null) {
      this.removeNewPolicy()
    } else {
      this.setState({ currentlyEditingId: null })
    }
  }

  handleNewPolicy = () => {
    const { allowActions, onRedirect } = this.props

    if (allowActions) {
      this.addNewPolicy()
    } else {
      onRedirect()
    }
  }

  initialPolicies() {
    const { policies } = this.props

    return JSON.parse(JSON.stringify(policies))
  }

  isAddingNewPolicy() {
    const { policies } = this.state

    let isAdding = false

    policies.forEach(function (policy) {
      if (policy.id === null) {
        isAdding = true
      }
    })

    return isAdding
  }

  shouldDisplayButtons(policy) {
    const { allowActions } = this.props

    const { currentlyEditingId } = this.state

    if (!allowActions) {
      return false
    } else {
      // eslint-disable-next-line
      return (
        (!this.isAddingNewPolicy() && currentlyEditingId === null) ||
        currentlyEditingId === policy.id
      )
    }
  }

  removeNewPolicy() {
    const { policies } = this.state

    let updatedPolicies = arrayHelpers.removeObjectById(policies, null)

    this.setState({ policies: updatedPolicies })
  }

  renderMaintenancePolicies() {
    const {
      allowActions,
      isSubmitting,
      onDelete,
      onSubmit,
      services,
      users,
      vehicles,
      language
    } = this.props

    const { currentlyEditingId, policies } = this.state

    // NOTE: Sorts policies by ID (DESC) to maintain displayed order after create/update.
    // NOTE: Always displays new policies (null ID) at top.
    const sortedPolicies = policies.sort(function (a, b) {
      if (b.id === null) {
        return 1
      } else if (a.id > b.id) {
        return -1
      } else if (b.id > a.id) {
        return 1
      } else {
        return 0
      }
    })

    return sortedPolicies.map((policy, index) => {
      return (
        <MaintenancePolicy
          allowActions={allowActions}
          displayButtons={this.shouldDisplayButtons(policy)}
          isEditing={policy.id === currentlyEditingId || policy.id === null}
          key={index}
          isSubmitting={isSubmitting}
          onCancel={this.handleCancel.bind(this)}
          onDelete={onDelete}
          onEdit={this.changeCurrentlyEditing.bind(this)}
          onSubmit={onSubmit}
          policy={policy}
          services={services}
          users={users}
          vehicles={vehicles}
          language={language}
        />
      )
    })
  }

  render() {
    const { t } = this.props
    return (
      <React.Fragment>
        {isOwnerManagerOrAdmin(this.props.currentUser) && (
          <Button
            size="tiny"
            floated="right"
            disabled={this.isAddingNewPolicy() || this.state.currentlyEditingId}
            onClick={this.handleNewPolicy}
          >
            {t("addNewPolicyLabel")}
          </Button>
        )}
        <Table className="borderless">
          <Table.Header>
            <Table.Row>
              {[
                t("approvedByLabel"),
                t("common:vehiclesLabel"),
                isFleetMaintenanceHubCanada()
                  ? t("maxAmountLabel", { candian_dollar: "CAD" })
                  : t("maxAmountLabel"),
                t("servicesLabel"),
                ""
              ].map((s) => (
                <Table.HeaderCell singleLine>{s}</Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>
          <Table.Body>{this.renderMaintenancePolicies()}</Table.Body>
        </Table>
      </React.Fragment>
    )
  }
} // class MaintenancePolicies

export default withTranslation("settings")(MaintenancePolicies)
