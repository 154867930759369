export const initialState = {
  affiliation: {
    id: null,
    description: "",
    name: ""
  }
}

export default function (state = initialState, action) {
  const { payload, type } = action

  switch (type) {
    case "AFFILIATION_LOAD_SUCCEEDED":
      return {
        ...state,
        affiliation: payload.affiliation
      }
    default:
      return state
  }
}
