// NOTE: Expects to handle errors as arrays as much as possible. Even for a singluar error.

export function catchErrors(responseBody) {
  const errors = responseErrors(responseBody)

  if (errors.length > 0) throw errors
}

export function consoleLog(errors) {
  errors = toArray(errors)
  if (process.env.NODE_ENV === "development") {
    errors.forEach((error) => {
      console.log(`API ERROR: ${error}`)
    })
  }
}

// NOTE: The purpose of this function is to convert the strings raised by internal failures, to the expected array format. It is used at the top of saga catch blocks over toArray for explicit readability.
export function formatInternalErrors(errors) {
  return toArray(errors)
}

export function responseErrors(responseBody) {
  let errors = []
  if (!responseBody) responseBody = {}

  if (responseBody.error) {
    errors = [...errors, ...toArray(responseBody.error)]
  }

  if (responseBody.errors) {
    errors = [...errors, ...toArray(responseBody.errors)]
  }

  return errors
}

export function toArray(error) {
  if (Array.isArray(error)) {
    return error
  } else {
    return [error]
  }
}

export function toString(errors) {
  return errors.join(", ")
}
