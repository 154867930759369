import * as numberHelpers from "./numberHelpers"
import moment from "moment"
import { EN_LOCALE, FR_LOCALE } from "../constants/users"
import { Date_Format } from "../constants/years"
import { isFleetMaintenanceHubCanada } from "./affiliationHelpers"

export function getDriverNames(users, vehicleId) {
  let driverNames = []

  users.forEach(function (driver) {
    const vehicleIds = (driver.vehicles || []).map((vehicle) => vehicle.id)

    if (vehicleIds.includes(vehicleId)) {
      driverNames.push(driver.name)
    }
  })

  return driverNames
}

export function formatDate(date) {
  return date ? moment(date).format("L") : "-"
}

export function getActiveOrderServicesTotal(orderServices) {
  const serviceCosts = orderServices.map((orderService) => {
    if (orderService.total && orderService.status !== "rejected") {
      return parseFloat(orderService.total)
    } else {
      return 0.0
    }
  })

  return serviceCosts.reduce(function (a, b) {
    return a + b
  }, 0)
}

export function formattedCostPerMile(vehicle) {
  const { cost_per_mile } = vehicle

  if (cost_per_mile) {
    return parseFloat(cost_per_mile).toFixed(2)
  } else {
    return "-"
  }
}

export function formattedLastMaintenanceDate(vehicle, language) {
  if (vehicle == null) return "N/A"

  const { last_maintenance_date, last_maintenance_date_pretty } = vehicle

  if (last_maintenance_date_pretty) {
    return isFleetMaintenanceHubCanada()
      ? moment(last_maintenance_date_pretty).format(Date_Format.YYYY_MM_DD)
      : last_maintenance_date_pretty
  } else if (last_maintenance_date) {
    return isFleetMaintenanceHubCanada()
      ? moment(last_maintenance_date).format(Date_Format.YYYY_MM_DD)
      : moment(last_maintenance_date).format(Date_Format.M_D_YY)
  } else {
    return "N/A"
  }
}

export function formattedDriverNames(vehicle, users) {
  const names = getDriverNames(users, vehicle.id)

  if (names.length > 0) {
    return names.join(", ")
  } else {
    return "UNASSIGNED"
  }
}

export function formattedMiles(vehicle) {
  const { miles, kilometers } = vehicle

  return kilometers && isFleetMaintenanceHubCanada()
    ? numberHelpers.formatWithCommas(kilometers)
    : miles
    ? numberHelpers.formatWithCommas(miles)
    : "-"
}

export function formattedLastMaintenanceMiles(vehicle) {
  const { last_maintenance_miles } = vehicle

  if (last_maintenance_miles) {
    return numberHelpers.formatWithCommas(last_maintenance_miles)
  } else {
    return "-"
  }
}

// TODO: (MPH) Handle timezones as expected.
export function formattedLicensePlateExpiration(vehicle, language) {
  const { license_plate_expiration } = vehicle

  if (license_plate_expiration) {
    return isFleetMaintenanceHubCanada()
      ? moment(license_plate_expiration).format(Date_Format.YYYY_MM_DD)
      : moment(license_plate_expiration).format(Date_Format.MMMM_YYYY)
  } else {
    return "-"
  }
}

export function formattedLicensePlateNumber(vehicle, withState = false) {
  const { license_plate_number, license_plate_state } = vehicle

  let formattingArray = [license_plate_number]

  if (withState) {
    formattingArray.unshift(license_plate_state)
  }

  const formattedString = formattingArray.join(" ").trim()

  if (formattedString.length > 0) {
    return formattedString
  } else {
    return "-"
  }
}

export function formattedName(vehicle, options = {}) {
  if (!vehicle) return ""

  const { year, make, model, vin, license_plate_state, license_plate_number, other_id } = vehicle
  let name = year && make && model ? `${year} ${make} ${model}` : vin

  if (options.displayLicensePlateNumber && license_plate_number) {
    if (license_plate_state) {
      name += ` [${license_plate_state}: ${license_plate_number}]`
    } else {
      name += ` [${license_plate_number}]`
    }
  }

  if (other_id) {
    name += ` (${other_id})`
  }

  return name
}

export function formattedNames(vehicles, options = {}) {
  let names = vehicles.map((vehicle, index) => formattedName(vehicle, options))

  return names.join(", ")
}

export function selectOptions(vehicles, t, options = {}) {
  let vehicleOptions = []

  if (options.semantic)
    vehicles.forEach((vehicle) => {
      vehicleOptions.push({
        value: vehicle.id,
        text: t(vehicle.label) || formattedName(vehicle, options),
        key: `vehicle-${vehicle.id}`
      })
    })
  else
    vehicles.forEach((vehicle) => {
      vehicleOptions.push({
        label: formattedName(vehicle, options),
        value: vehicle.id
      })
    })

  return vehicleOptions
}
