export function reformat(phoneNumber) {
  const strippedPhoneNumber = ("" + phoneNumber).replace(/\D/g, "")

  const matches = strippedPhoneNumber.match(/^(\d{3})(\d{3})(\d{4})$/)

  if (matches) {
    return `(${matches[1]}) ${matches[2]}-${matches[3]}`
  } else {
    return null
  }
}
