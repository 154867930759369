import { call, put, takeEvery } from "redux-saga/effects"

import api from "../../utilities/api"
import * as apiErrorHelpers from "../../helpers/apiErrorHelpers"
import * as sessionHelpers from "../../helpers/sessionHelpers"

export function* loadShopOrderActivityItems(action) {
  try {
    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    yield put({ type: "SHOP_ORDER_ACTIVITY_ITEMS_SET_LOADING" })
    yield put({ type: "SHOP_ORDER_ACTIVITY_ITEMS_LOAD_REQUESTED" })

    const request = {
      url: "/shop_order_activity_items",
      body: { shop_order_id: action.payload.shopOrderId }
    }

    const response = yield call(api.utility.get, api.path(request.url), { body: request.body })

    apiErrorHelpers.catchErrors(response.body)

    const items = response.body

    yield put({
      type: "SHOP_ORDER_ACTIVITY_ITEMS_LOAD_SUCCEEDED",
      payload: {
        items: items
      }
    })
    yield put({ type: "SHOP_ORDER_ACTIVITY_ITEMS_UNSET_LOADING" })

    return items
    ////////////////////////////
  } catch (errors) {
    // eslint-disable-next-line
    yield put({ type: "SHOP_ORDER_ACTIVITY_ITEMS_UNSET_LOADING" })

    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "SHOP_ORDER_ACTIVITY_ITEMS_LOAD_FAILED" })

    apiErrorHelpers.consoleLog(errors)
  }
} // function* loadShopOrderActivityItems

export default function* shopOrderActivityItemSaga() {
  yield takeEvery("SHOP_ORDER_ACTIVITY_ITEMS_LOAD_SAGA", loadShopOrderActivityItems)
}
