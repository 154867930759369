export const applicationIdentifier = "fleetmd"
export const FLEET = "fleet"
export const FMC = "fmc"

// subdomains
export const CARVANA = "carvana"
export const FLEET_MAINTENANCE_HUB = "shell"
export const FLEET_MAINTENANCE_HUB_CANADA = "shellcanada"
export const FLEET_AMERICA = "fleetamerica"
export const FLEET_ADVISE = "fleetadvise"
export const ZUBIE = "zubie"

export const VEHICLE_INDEX = "vehicle_index"
export const MAINTENANCE_INDEX = "maintenance_policy"
export const FMC_USER = "fmc_user"
export const FMC_EDIT = "fmc_edit"
export const SETTINGS_USER = "settings_user"
export const PAYMENT = "payment"
