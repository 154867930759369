import React, { Component } from "react"
import PropTypes from "prop-types"

import Button from "../../elements/Button"
import TextField from "../../elements/TextField"
import { getSubdomain } from "../shared/StylesheetInjector"

class ChangePasswordForm extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,

    isSubmitting: PropTypes.bool
  }

  static defaultProps = {
    isSubmitting: false
  }

  state = {
    formData: {
      email: ""
    }
  }

  allowSubmit() {
    const { isSubmitting } = this.props

    const { email } = this.state.formData

    return !isSubmitting && email.length > 0
  }

  onFieldChange(field, value) {
    let updatedFormData = { ...this.state.formData }
    updatedFormData[field] = value
    this.setState({ formData: updatedFormData })
  }

  onKeyDown(event) {
    const { onSubmit } = this.props

    if (event.key === "Enter") {
      onSubmit(this.state)
    }
  }

  render() {
    const { onSubmit, t } = this.props

    const { email } = this.state.formData

    const subDomain = getSubdomain()

    return (
      <div className={"forgot_password_form"} onKeyDown={(event) => this.onKeyDown(event)}>
        <div className={"row"}>
          <div className={"column--12"}>
            <TextField
              className={"text_field"}
              onChange={(event) => this.onFieldChange("email", event.target.value)}
              placeholder={t('emailLabel')}
              value={email}
            />
          </div>
        </div>

        <div>
          <div className={"column--12"}>
            <Button
              disabled={subDomain !== "shell" && !this.allowSubmit()}
              className={
                !this.allowSubmit() && subDomain === "shell"
                  ? "disabled-button-shell button--primary forgot_password_form__button"
                  : (this.allowSubmit() && subDomain === "shell") || subDomain !== "shell"
                  ? "button--primary forgot_password_form__button"
                  : ""
              }
              disabled={!this.allowSubmit()}
              label={t('resetPasswordLabel')}
              onClick={() => onSubmit(this.state.formData)}
            />
          </div>
        </div>
      </div>
    )
  }
} // class ChangePasswordForm

export default ChangePasswordForm
