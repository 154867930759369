export const initialState = {
  paymentMethods: []
}

export default function (state = initialState, action) {
  const { payload, type } = action

  switch (type) {
    case "PAYMENT_METHODS_LOAD_SUCCEEDED":
      return {
        ...state,
        paymentMethods: action.payload.paymentMethods
      }
    case "PAYMENT_METHOD_CREATE_SUCCEEDED":
      return {
        ...state,
        paymentMethods: [action.payload.paymentMethod].concat(state.paymentMethods)
      }
    case "PAYMENT_METHOD_UPDATE_SUCCEEDED":
      const paymentMethod = action.payload.paymentMethod

      return {
        ...state,
        paymentMethods: state.paymentMethods.map((pm) => {
          return pm.id === paymentMethod.id ? paymentMethod : pm
        })
      }
    case "PAYMENT_METHOD_DELETE_SUCCEEDED":
      const id = action.payload.paymentMethodId

      return {
        ...state,
        paymentMethods: state.paymentMethods.filter((pm) => pm.id !== id)
      }
    case "BRAINTREE_CLIENT_TOKEN_LOAD_SUCCEEDED":
      return {
        ...state,
        clientToken: action.payload.clientToken
      }
    case "SET_PAYMENT_METHODS_LOADING":
      return {
        ...state,
        isLoading: true
      }
    case "UNSET_PAYMENT_METHODS_LOADING":
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}
