import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Link } from "react-router-dom"

import { navigationPaths } from "../../constants/paths"

import Alert from "../../components/shared/Alert"
import ApplicationLayout from "../../layouts/ApplicationLayout"
import ChangePasswordForm from "../../components/sessions/ChangePasswordForm"
import PaymentList from "../../components/payments/PaymentList"

class AccountShow extends Component {
  static propTypes = {
    payments: PropTypes.array.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      alertMessage: "",
      alertType: "default",
      isSubmitting: false
    }
  }

  afterRequest(data) {
    if (data.status === "success") {
      this.onRequestSuccess(data)
    } else {
      this.onRequestFailure(data)
    }
  }

  onChangePasswordSubmit(formData) {
    const { newPassword, newPasswordConfirmation } = formData

    if (newPassword !== newPasswordConfirmation) {
      this.setState({
        alertMessage: "New password and confirmation do not match",
        alertType: "error"
      })
    } else {
      this.props.dispatch({
        type: "CHANGE_PASSWORD_SAGA",
        payload: { formData: formData },
        callback: this.afterRequest.bind(this)
      })
    }
  }

  onRequestFailure(data) {
    this.setState({
      alertMessage: data.alertMessage,
      alertType: data.alertType
    })
  }

  onRequestSuccess(data) {
    this.setState({
      alertMessage: data.alertMessage,
      alertType: data.alertType
    })
  }

  resetAlerts() {
    this.setState({
      alertMessage: "",
      alertType: "default"
    })
  }

  renderContent() {
    const { payments } = this.props

    const { isSubmitting } = this.state

    return (
      <div>
        <div className={"row"}>
          <div className={"column--12"}>
            <div className={"dash-hd"}>CHANGE PASSWORD</div>

            <ChangePasswordForm
              isSubmitting={isSubmitting}
              onSubmit={this.onChangePasswordSubmit.bind(this)}
            />
          </div>
        </div>

        {/* NOTE (MPH): Payments section is disabled */}
        {false && (
          <div className={"row"}>
            <div className={"column--12"}>
              <div className={"dash-hd"}>
                PAYMENTS <strong>({payments.length})</strong>
                &nbsp;&nbsp;
                <Link to={navigationPaths.paymentsNew()} className={"button--add"}>
                  + ADD PAYMENT
                </Link>
              </div>

              <PaymentList payments={payments} />
            </div>
          </div>
        )}
      </div>
    )
  }

  render() {
    const { alertMessage, alertType } = this.state

    return (
      <ApplicationLayout>
        <Alert message={alertMessage} type={alertType} visible={alertMessage !== ""} />

        {this.renderContent()}
      </ApplicationLayout>
    )
  }
} // class AccountShow

const mapStateToProps = (state) => ({
  payments: state.payments.payments
})

export default connect(mapStateToProps)(AccountShow)
