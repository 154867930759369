import React from "react"
import { shellSignInUrl } from "../../helpers/affiliationHelpers"
import NoSessionLayout from "../../layouts/NoSessionLayout"

export default function ShellLogoutUser() {
  return (
    <NoSessionLayout>
      <div className={"left-aligned-form-container"} style={{ textAlign: "center" }}>
        <h2 style={{ padding: "0 10vh 0 10vh" }}>
          You've successfully logged out of
          <br /> Fleet Maintenance Hub.
        </h2>
        <h4>
          {`To log back in, please visit `}
          <br />
          <a href={shellSignInUrl()}>Shell Account Manager Online</a>
        </h4>
      </div>
    </NoSessionLayout>
  )
} // class ShellLogoutUser
