import React, { Component } from "react"
import PropTypes from "prop-types"

import Button from "../../elements/Button"
import Disclaimer from "../../components/shared/Disclaimer"
import { withTranslation } from "react-i18next"

class FleetImpersonation extends Component {
  static propTypes = {
    fleet: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,

    isSubmitting: PropTypes.bool
  }

  static defaultProps = {
    isSubmitting: false
  }

  render() {
    const { fleet, isSubmitting, onSubmit, t } = this.props

    return (
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div className={"fleet_impersonation_form"}>
          <Button
            disabled={isSubmitting}
            label={t("administerFleetLabel")}
            onClick={() => onSubmit(fleet.id)}
          />
        </div>

        <Disclaimer
          className={"fleet_impersonation__disclaimer"}
          message={t("administerDescLabel")}
        />
      </div>
    )
  }
} // class FleetImpersonation

export default withTranslation("fleetImpersonation")(FleetImpersonation)
