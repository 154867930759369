export const initialState = {
  availableTimesData: []
}

export default function (state = initialState, action) {
  const { payload, type } = action

  switch (type) {
    case "AVAILABLE_TIMES_LOAD_SUCCEEDED":
      return {
        ...state,
        availableTimesData: payload.availableTimesData
      }
    default:
      return state
  }
}
