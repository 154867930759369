import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import {
  ACTIVE_STATUSES,
  PAID_STATUSES,
  DISPLAY_STATUSES,
  INACTIVE_STATUSES
} from "../../constants/statuses"
import { constants } from "caradvise_shared_components"
import { Link } from "react-router-dom"
import { navigationPaths } from "../../constants/paths"
import LoadingThrobber from "../../components/shared/LoadingThrobber"
import {
  orderGridColumnDefs,
  orderGridRowData,
  maintGridColumnDefs,
  maintGridRowData,
  UPCOMING,
  ACTIVE,
  PAST,
  SCHEDULE_BUTTON_FIELD,
  MANUAL_MAINTENANCE_TYPE
} from "../../constants/services"
import * as agGridHelpers from "../../helpers/agGridHelpers"
import { Segment, Menu, Modal, Checkbox } from "semantic-ui-react"
import { AgGridReact } from "ag-grid-react"
// TODO: Replace these with those in _ag_grid.scss. See note there.
import "ag-grid-community/dist/styles/ag-grid.css"
import "ag-grid-community/dist/styles/ag-theme-material.css"
import moment from "moment"
import debounce from "debounce"
import ServiceSearch from "./ServiceSearch"
import ActiveServicesStats from "./ActiveServicesStats"
import PastServicesStats from "./PastServicesStats"
import UpcomingServiceSummaries from "./UpcomingServiceSummaries"
import ScheduleServiceButton from "../../components/shared/ScheduleServiceButton"
import ScheduleServiceHeader from "../../components/shared/ScheduleServiceHeader"
import { Button } from "semantic-ui-react"
import * as maintenanceHelpers from "../../helpers/maintenanceHelpers"
import { Date_Format } from "../../constants/years"
import { withTranslation } from "react-i18next"
import { isFleetMaintenanceHubCanada } from "../../helpers/affiliationHelpers"
import frGrid from "../../locales/fr-ca/grid.json"
import enGrid from "../../locales/en/grid.json"
import { FR_LOCALE } from "../../constants/users"
import CustomNoRowsOverlay from "../shared/CustomNoRowsOverlay"

const { AWAITING_APPROVAL } = constants.orders
const DUE_FILTER_ALL = "all"
const DUE_FILTER_DUE_SOON = "dueSoon"
const DUE_FILTER_PAST_DUE = "pastDue"

class ServiceList extends Component {
  constructor(props) {
    super(props)
    this.debouncedOnQuickFilterChange = debounce(this.onQuickFilterChange, 300)
    this.debouncedOnSizeToFitChange = debounce(this.onSizeToFitChange, 100)
  }

  static propTypes = {
    shopOrders: PropTypes.array.isRequired,
    onChange: PropTypes.func
  }

  state = {
    startDate: moment(this.props.startDate).startOf("day"),
    endDate: moment(this.props.endDate).startOf("day"),
    dueFilter: DUE_FILTER_ALL,
    activePage: this.props.startingPage || PAST,
    orderFilter: this.props.orderFilter,
    sizeToFit: true,
    showGrid: true,
    filteredItems: [],
    pages: [
      {
        name: UPCOMING,
        label: "upcomingIntervalsLabel",
        filter: (orders, vehicles) =>
          maintenanceHelpers.upcomingMaintenanceData({
            vehicles,
            serviceSchedulesByVehicle: this.props.serviceSchedulesByVehicle
          }),
        rowData: maintGridRowData,
        columnDefs: maintGridColumnDefs
      },
      {
        name: ACTIVE,
        label: "activeOrdersLabel",
        filter: (orders) =>
          orders
            .filter((o) =>
              this.state.orderFilter
                ? this.state.orderFilter.indexOf(o.status) !== -1
                : ACTIVE_STATUSES.indexOf(o.status) !== -1
            )
            .sort((a, b) =>
              Date.parse(a.appointment_datetime) > Date.parse(b.appointment_datetime) ? 1 : -1
            ),
        rowData: orderGridRowData,
        columnDefs: orderGridColumnDefs
      },
      {
        name: PAST,
        label: "pastOrdersLabel",
        filter: (orders) =>
          orders
            .filter((o) => PAID_STATUSES.indexOf(o.status) !== -1)
            .sort((a, b) =>
              Date.parse(a.appointment_datetime) > Date.parse(b.appointment_datetime) ? -1 : 1
            ),
        rowData: orderGridRowData,
        columnDefs: orderGridColumnDefs
      }
    ]
  }

  servicesCellRenderer = (cellParams) => {
    const services = cellParams.value
    return (
      <Modal size="mini" trigger={<span>{services}</span>}>
        <Modal.Content>{services}</Modal.Content>
      </Modal>
    )
  }

  schedulerCellRenderer = (cellParams) => {
    if (cellParams && cellParams.value) {
      const { maintenance, vehicle } = cellParams.value

      return <ScheduleServiceButton secondary vehicle={vehicle} maintenance={maintenance} />
    }
  }

  statusCellRenderer = (cellParams, t) => {
    if (cellParams && cellParams.value) {
      const { status, id } = cellParams.value
      if (status !== AWAITING_APPROVAL) {
        return t(DISPLAY_STATUSES[status])
      } else {
        return (
          <Link to={navigationPaths.approvalsShow(id)}>
            <Button>{t("reviewLabel")}</Button>
          </Link>
        )
      }
    }
  }

  viewOrder = (cell) => {
    if (cell.colDef.field !== SCHEDULE_BUTTON_FIELD) {
      // cell.data.status being null or not differentiates ShopOrder vs Vehicle
      if (cell.data.status == null) {
        const path = navigationPaths.vehicleShow
        this.props.history.push(path(cell.data.id))
      } else if (cell.data.maintenance_type === MANUAL_MAINTENANCE_TYPE) {
        const path = navigationPaths.vehicleShow
        this.props.history.push(path(cell.data.vehicle_id))
      } else {
        const path = navigationPaths.approvalsShow
        this.props.history.push(path(cell.data.id))
      }
    }
  }

  noRowsTemplate() {
    return `<span> ${this.props.t("noOrdersInFleet")} </span>`
  }

  handleChangeStart = (date) => {
    this.props.onChange(date.startOf("day"), "startDate")
  }

  handleChangeEnd = (date) => {
    this.props.onChange(date.startOf("day"), "endDate")
  }

  handlePageChange = async (page) => {
    this.saveFilterModel()
    await this.setState({ activePage: page })
    this.restorePageFilterModel()
    this.setFilteredItems()
    if (this.state.sizeToFit) this.api.sizeColumnsToFit()
  }

  // Note getDataAsCSV on api allows for downloading as CSV.
  onGridReady = (params) => {
    this.api = params.api
    this.columnApi = params.columnApi
    if (this.state.sizeToFit) params.api.sizeColumnsToFit()
    this.setFilteredItems()
    window.addEventListener("resize", () => this.state.sizeToFit && this.api.sizeColumnsToFit())
  }

  onQuickFilterChange = (_, event) => {
    this.setState({ quickFilterText: event.value })
  }

  saveFilterModel = () => {
    const oldFilter = this.state.filterModels || {}
    oldFilter[this.state.activePage] = this.api.getFilterModel()
    this.setState({ filterModels: oldFilter })
  }

  restorePageFilterModel = () =>
    this.api.setFilterModel(this.state.filterModels[this.state.activePage])
  setFilteredItems = () =>
    this.setState({ filteredItems: this.api.getModel().rowsToDisplay.map((r) => r.data) })
  resetFiltering = () => {
    this.api.setFilterModel([])
    this.setState({ orderFilter: null })
  }

  onSizeToFitChange = (event, syntheticEvent) => {
    this.setState({ sizeToFit: syntheticEvent.checked })
    if (syntheticEvent.checked) this.api.sizeColumnsToFit()
    else this.redrawGrid()
  }

  redrawGrid() {
    this.setState({ showGrid: false })
    setTimeout(() => this.setState({ showGrid: true }), 500)
  }

  externalFilterChanged = (newValue) => {
    this.setState({ dueFilter: newValue }, () => this.api.onFilterChanged())
  }

  isExternalFilterPresent = () => {
    // if dueFilter is not DUE_FILTER_ALL, then we are filtering
    return this.state.dueFilter != DUE_FILTER_ALL
  }

  doesExternalFilterPass = (node) => {
    const dueFilter = this.state.dueFilter
    switch (dueFilter) {
      case DUE_FILTER_DUE_SOON:
        return node.data.due_status === maintenanceHelpers.DUE_SOON
      case DUE_FILTER_PAST_DUE:
        return node.data.due_status === maintenanceHelpers.PAST_DUE
      default:
        return true
    }
  }

  customGridOptions = () => {
    return {
      onFilterChanged: this.setFilteredItems,
      onCellClicked: this.viewOrder,
      isExternalFilterPresent: this.isExternalFilterPresent,
      doesExternalFilterPass: this.doesExternalFilterPass
    }
  }

  downloadOrders = () => {
    const { startDate, endDate } = this.state
    this.props.dispatch({ type: "SHOP_ORDERS_DOWNLOAD_SAGA", payload: { startDate, endDate } })
  }

  renderUpcomingServiceSummaries = (vehicles, t) => {
    return (
      <UpcomingServiceSummaries
        serviceSchedulesByVehicle={this.props.serviceSchedulesByVehicle}
        onClickPastDue={this.externalFilterChanged.bind(this, DUE_FILTER_PAST_DUE)}
        onClickDueSoon={this.externalFilterChanged.bind(this, DUE_FILTER_DUE_SOON)}
        onClickAll={() => this.externalFilterChanged(DUE_FILTER_ALL)}
        vehicles={vehicles}
      />
    )
  }

  render() {
    const { shopOrders, vehicles, language, t } = this.props
    const { pages, activePage, filteredItems, sizeToFit, showGrid } = this.state
    const page = pages.find((p) => p.name === activePage)
    const items = page.filter.call(this, shopOrders, vehicles)

    return (
      <div>
        <span />
        <ScheduleServiceHeader header={t("dashboard:shopOrdersLabel")} />
        <Segment.Group>
          <Segment className="menu-container">
            <Menu pointing secondary stackable>
              {pages.map((page) => (
                <Menu.Item
                  name={t(page.label)}
                  active={page.name === activePage}
                  onClick={this.handlePageChange.bind(this, page.name)}
                />
              ))}

              {activePage === PAST && (
                <Menu.Item position="right" className="skinny-menu-button">
                  <div className={"service-daterange"}>
                    <div className={"service-datepicker"}>
                      {t("fromLabel")}:
                      <DatePicker
                        selected={this.state.startDate}
                        selectsStart
                        dateFormat={
                          isFleetMaintenanceHubCanada()
                            ? Date_Format.YYYY_MM_DD
                            : Date_Format.MM_DD_YYYY
                        }
                        endDate={this.state.endDate}
                        onChange={this.handleChangeStart}
                      />
                    </div>
                    <div className={"service-datepicker"}>
                      {t("toLabel")}:
                      <DatePicker
                        selected={this.state.endDate}
                        dateFormat={
                          isFleetMaintenanceHubCanada()
                            ? Date_Format.YYYY_MM_DD
                            : Date_Format.MM_DD_YYYY
                        }
                        selectsEnd
                        startDate={this.state.startDate}
                        onChange={this.handleChangeEnd}
                      />
                    </div>
                    <Button
                      secondary
                      icon="download"
                      content={t("downloadLabel")}
                      onClick={this.downloadOrders}
                    />
                  </div>
                </Menu.Item>
              )}
            </Menu>
          </Segment>
          <Segment style={{ border: "none" }}>
            <ServiceSearch
              debouncedOnFilterChange={this.debouncedOnQuickFilterChange}
              resetFiltering={this.resetFiltering}
            />

            {page.name === UPCOMING && this.renderUpcomingServiceSummaries(vehicles, t)}
            {page.name === ACTIVE && (
              <ActiveServicesStats
                orders={shopOrders.filter((o) => INACTIVE_STATUSES.indexOf(o.status) === -1)}
              />
            )}
            {page.name === PAST && <PastServicesStats agGridFormattedOrders={filteredItems} />}
          </Segment>
          <Segment
            className={"ag-theme-material tab-container"}
            style={{
              height: agGridHelpers.dynamicGridHeight({ items })
            }}
          >
            <Checkbox
              label={t("sizeToFitLabel")}
              onChange={this.debouncedOnSizeToFitChange}
              checked={sizeToFit}
            />
            {showGrid ? (
              <AgGridReact
                columnDefs={page.columnDefs.call(
                  this,
                  activePage,
                  this.servicesCellRenderer,
                  this.schedulerCellRenderer,
                  (cellParams) => this.statusCellRenderer(cellParams, t),
                  t
                )}
                defaultColDef={{
                  sortable: true,
                  filter: true
                }}
                localeText={language === FR_LOCALE ? frGrid : enGrid}
                rowData={page.rowData.call(this, items, language)}
                rowSelection={"single"}
                onGridReady={this.onGridReady}
                gridOptions={this.customGridOptions()}
                frameworkComponents={{
                  customNoRowsOverlay: CustomNoRowsOverlay
                }}
                noRowsOverlayComponent={"customNoRowsOverlay"}
                noRowsOverlayComponentParams={{
                  translationFile: "services",
                  label: "noOrdersInFleet"
                }}
                quickFilterText={this.state.quickFilterText}
                cacheQuickFilter={true}
                pagination={true}
                paginationPageSize={agGridHelpers.PAGINATION_AMOUNT}
                suppressMenuHide={true}
                suppressMovableColumns={false}
                suppressCellSelection={true}
              ></AgGridReact>
            ) : (
              <LoadingThrobber visible />
            )}
          </Segment>
        </Segment.Group>
      </div>
    )
  }
} // class ServiceList

export default connect()(withTranslation("services")(ServiceList))
