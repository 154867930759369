import React, { Component } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { navigationPaths } from "../../constants/paths"
import { formattedSelectedServices } from "../../helpers/serviceHelpers"
import * as vehicleHelpers from "../../helpers/vehicleHelpers"
import { Segment, Grid } from "semantic-ui-react"
import arrowGray from "../../assets/images/arrow-gray.png"
import { withTranslation } from "react-i18next"

class UpcomingMaintenance extends Component {
  static propTypes = {
    upcomingMaintenance: PropTypes.object.isRequired,
    vehicle: PropTypes.object.isRequired
  }

  checkForActiveOrder(event, t) {
    const { active_order } = this.props.vehicle

    if (active_order) {
      event.preventDefault()
      alert(t("orderExistsAlert"))
    }
  }

  serviceNames() {
    const { upcomingMaintenance } = this.props

    let names = []

    upcomingMaintenance.services.forEach((service) => {
      names.push(service.name)
    })

    return names.join(", ")
  }

  render() {
    const { upcomingMaintenance, vehicle, t } = this.props

    return (
      <Link className={"row-link"} to={navigationPaths.vehicleShow(vehicle.id)}>
        <Segment className={"dash-upcoming-vehicles-container"}>
          <Grid columns="equal">
            <Grid.Row verticalAlign="middle">
              <Grid.Column>
                <Link
                  className={"button--schedule"}
                  onClick={(event) => this.checkForActiveOrder(event, t)}
                  to={{
                    pathname: navigationPaths.serviceRequestNew(),
                    state: {
                      selectedServices: formattedSelectedServices({
                        services: upcomingMaintenance.services,
                        vehicle
                      }),
                      vehicleId: vehicle.id
                    }
                  }}
                >
                  SCHEDULE
                </Link>
              </Grid.Column>

              <Grid.Column>{vehicleHelpers.formattedName(vehicle)}</Grid.Column>

              <Grid.Column>{this.serviceNames()}</Grid.Column>

              <Grid.Column>{upcomingMaintenance.name}</Grid.Column>

              <Grid.Column>
                {vehicleHelpers.formattedLastMaintenanceMiles(vehicle)}
                <br />
                Last Maint.
              </Grid.Column>

              <Grid.Column>
                <div className={"arrow-container"}>
                  <img alt={""} className={"arrow-gray"} src={arrowGray} />
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </Link>
    )
  }
} // class UpcomingMaintenance

export default withTranslation("services")(UpcomingMaintenance)
