import React, { PureComponent } from "react"
import { Segment, Header, Divider } from "semantic-ui-react"
import * as vehicleHelpers from "../../helpers/vehicleHelpers"
import * as stringHelpers from "../../helpers/stringHelpers"
import { ScatterChart, Scatter, XAxis, YAxis, Tooltip } from "recharts"
import { getStyles } from "../shared/StylesheetInjector"
import { withTranslation } from "react-i18next"
import { KILOMETERS, MILES } from "../../constants/vehicles"
import { isFleetMaintenanceHubCanada } from "../../helpers/affiliationHelpers"

class MileageCostsWidget extends PureComponent {
  state = {}

  componentDidMount() {
    window.addEventListener("resize", this.resize)
    this.resize()
  }

  resize = () => {
    if (document.getElementById("mileage-costs"))
      this.setState({ width: document.getElementById("mileage-costs").clientWidth - 28 * 2 })
  }

  navigateTo = (vehicle) => this.props.navigateTo(vehicle)

  render() {
    const styles = getStyles()
    const { vehicles, t, language } = this.props
    const { width } = this.state
    const milesLabel = isFleetMaintenanceHubCanada() ? t("kilometersLabel") : "Miles"

    let data = vehicles.filter((v) => v.cost_per_mile > 0)
    let dummy = data.length === 0
    if (data.length === 0) {
      for (let i = 0; i < 15; i++) {
        data.push({
          miles: parseInt((Math.random() * 100000).toFixed(0)),
          cost_per_mile: parseFloat(Math.random().toFixed(2)) + 0.1
        })
      }
    }

    return (
      <React.Fragment>
        <span />
        <Header size="small" className={"dash-hd hide-margin-on-computer-up"}>
          {isFleetMaintenanceHubCanada() ? t("kilometersHeaderLabel") : "MILEAGE"}
        </Header>
        <Segment id="mileage-costs">
          <Divider hidden />
          {width && data.length > 0 && (
            <ScatterChart width={width} height={173}>
              <XAxis
                label={{ value: milesLabel, position: "insideBottom", offset: 0 }}
                dataKey={isFleetMaintenanceHubCanada() ? KILOMETERS : MILES}
                type="number"
                tickLine={false}
                name={milesLabel}
                tickFormatter={(miles) => parseInt(miles / 1000) + "K"}
              />
              <YAxis
                label={{
                  value: isFleetMaintenanceHubCanada()
                    ? t("costPerKilometerLabel")
                    : t("costPerMileLabel"),
                  angle: -90,
                  position: "center"
                }}
                dataKey={"cost_per_mile"}
                type="number"
                textAnchor="left"
                axisLine={false}
                tickLine={false}
                name={t("vehicleLabel")}
                tickFormatter={(cost) => cost * 100 + "c"}
              />
              <Scatter
                name="Cost Per Mile"
                data={data}
                dataKey="cost_per_mile"
                fill={dummy ? styles.rechartsNeutralColor : styles.rechartsSecondaryColor}
                onClick={dummy ? null : this.navigateTo}
              />
              {!dummy && (
                <Tooltip
                  formatter={(value, name, props) =>
                    name === milesLabel
                      ? stringHelpers.numberWithCommas(value, name)
                      : vehicleHelpers.formattedName(props.payload)
                  }
                  cursor={{ strokeDasharray: "3 3" }}
                />
              )}
            </ScatterChart>
          )}
        </Segment>
      </React.Fragment>
    )
  }
}

export default withTranslation("dashboard")(MileageCostsWidget)
