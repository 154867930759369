import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import enCommon from "../locales/en/common.json"
import frCaCommon from "../locales/fr-ca/common.json"
import enCaCommon from "../locales/en-ca/common.json"
import enFMCDashboard from "../locales/en/fmcDashboard.json"
import frCaFMCDashboard from "../locales/fr-ca/fmcDashboard.json"
import enCaFMCDashboard from "../locales/en-ca/fmcDashboard.json"
import enSiteHeader from "../locales/en/siteHeader.json"
import frCaSiteHeader from "../locales/fr-ca/siteHeader.json"
import enCaSiteHeader from "../locales/en-ca/siteHeader.json"
import enDashboard from "../locales/en/dashboard.json"
import frCaDashboard from "../locales/fr-ca/dashboard.json"
import enCaDashboard from "../locales/en-ca/dashboard.json"
import enFMCUsers from "../locales/en/fmcUsers.json"
import frCaFMCUsers from "../locales/fr-ca/fmcUsers.json"
import enCaFMCUsers from "../locales/en-ca/fmcUsers.json"
import enFleetImpersonation from "../locales/en/fleetImpersonation.json"
import frCaFleetImpersonation from "../locales/fr-ca/fleetImpersonation.json"
import enCaFleetImpersonation from "../locales/en-ca/fleetImpersonation.json"
import enVehicleIndex from "../locales/en/vehicleIndex.json"
import frCaVehicleIndex from "../locales/fr-ca/vehicleIndex.json"
import enCaVehicleIndex from "../locales/en-ca/vehicleIndex.json"
import enServices from "../locales/en/services.json"
import frCaServices from "../locales/fr-ca/services.json"
import enCaServices from "../locales/en-ca/services.json"
import enShops from "../locales/en/shops.json"
import frCaShops from "../locales/fr-ca/shops.json"
import enCaShops from "../locales/en-ca/shops.json"
import enSettings from "../locales/en/settings.json"
import frCaSettings from "../locales/fr-ca/settings.json"
import enCaSettings from "../locales/en-ca/settings.json"
import enMaintenanceHistory from "../locales/en/maintenanceHistory.json"
import frCaMaintenanceHistory from "../locales/fr-ca/maintenanceHistory.json"
import enCaMaintenanceHistory from "../locales/en-ca/maintenanceHistory.json"
import enPayment from "../locales/en/payment.json"
import frCaPayment from "../locales/fr-ca/payment.json"
import enCaPayment from "../locales/en-ca/payment.json"
import enVehicle from "../locales/en/vehicle.json"
import frCaVehicle from "../locales/fr-ca/vehicle.json"
import enCaVehicle from "../locales/en-ca/vehicle.json"
import enScheduleRequest from "../locales/en/scheduleRequest.json"
import frCaScheduleRequest from "../locales/fr-ca/scheduleRequest.json"
import enCaScheduleRequest from "../locales/en-ca/scheduleRequest.json"
import enShopOrders from "../locales/en/shopOrders.json"
import frCaShopOrders from "../locales/fr-ca/shopOrders.json"
import enCaShopOrders from "../locales/en-ca/shopOrders.json"
import enFleets from "../locales/en/fleets.json"
import frCaFleets from "../locales/fr-ca/fleets.json"
import enCaFleets from "../locales/en-ca/fleets.json"
import enMembershipCard from "../locales/en/membershipCard.json"
import frCaMembershipCard from "../locales/fr-ca/membershipCard.json"
import enCaMembershipCard from "../locales/en-ca/membershipCard.json"
import enApprovalHistory from "../locales/en/approvalHistory.json"
import frCaApprovalHistory from "../locales/fr-ca/approvalHistory.json"
import enCaApprovalHistory from "../locales/en-ca/approvalHistory.json"

import { locales } from "caradvise_shared_components"

import { EN_LOCALE } from "../constants/users"
import {
  COMMON,
  FMC_DASHBOARD,
  SITE_HEADER,
  DASHBOARD,
  FMC_USERS,
  FLEET_IMPERSONATION,
  VEHICLE_INDEX,
  SERVICES,
  SHOPS,
  SETTINGS,
  MAINTENANCE_HISTORY,
  PAYMENT,
  VEHICLE,
  SCHEDULE_REQUEST,
  SHOP_ORDERS,
  APPROVAL_EMPTY_STATE,
  APPROVALS_FORM,
  APPROVALS_FORM_HEADER,
  APPROVALS_FORM_FIELDS,
  APPROVALS_FORM_FIELD,
  APPROVALS_INFO_MODEL,
  APPROVALS_FORM_NOTES,
  APPROVALS_FORM_TOTALS,
  APPROVALS_FORM_PAYMENT_METHOD,
  APPROVALS_FORM_CANCEL,
  APPROVALS_FORM_SUBMIT,
  PRICE_INFO_DISPLAY,
  NO_INFORMATION,
  ORDER_SERVICE_MODAL_INFO,
  PRICE_EXPLANATION,
  PRIVACY_POLICY_AND_TERMS,
  APPROVAL_TOGGLE_BUTTON,
  ORDER_SERVICE_COMMENT,
  FLEETS,
  MEMBERSHIP_CARD,
  APPROVAL_HISTORY
} from "../constants/namespaces"

const {
  enApprovalsEmptyState,
  enApprovalsForm,
  enApprovalsFormHeader,
  enApprovalsFormFields,
  enApprovalsFormField,
  enApprovalsInfoModal,
  enApprovalsFormNotes,
  enApprovalsFormTotals,
  enApprovalsFormPaymentMethod,
  enApprovalsFormCancel,
  enApprovalsFormSubmit,
  enPriceInfoDisplay,
  enNoInformation,
  enOrderServiceModalInfo,
  enPriceExplanation,
  enPrivacyPolicyAndTerms,
  enApprovalToggleButton,
  enOrderServiceComment
} = locales.en
const {
  frApprovalsEmptyState,
  frApprovalsForm,
  frApprovalsFormHeader,
  frApprovalsFormFields,
  frApprovalsFormField,
  frApprovalsInfoModal,
  frApprovalsFormNotes,
  frApprovalsFormTotals,
  frApprovalsFormPaymentMethod,
  frApprovalsFormCancel,
  frApprovalsFormSubmit,
  frPriceInfoDisplay,
  frNoInformation,
  frOrderServiceModalInfo,
  frPriceExplanation,
  frPrivacyPolicyAndTerms,
  frApprovalToggleButton,
  frOrderServiceComment
} = locales.fr

// Even though this product doesn't currently support multiple languages, this library is needed
// to be able to pass the `t` function from i18next to caradvise_shared_components
i18n.use(initReactI18next).init({
  lng: EN_LOCALE,
  ns: {
    namespaces: [
      COMMON,
      FMC_DASHBOARD,
      SITE_HEADER,
      DASHBOARD,
      FMC_USERS,
      FLEET_IMPERSONATION,
      VEHICLE_INDEX,
      SERVICES,
      SHOPS,
      SETTINGS,
      MAINTENANCE_HISTORY,
      PAYMENT,
      VEHICLE,
      SCHEDULE_REQUEST,
      SHOP_ORDERS,
      APPROVAL_EMPTY_STATE,
      APPROVALS_FORM,
      APPROVALS_FORM_HEADER,
      APPROVALS_FORM_FIELDS,
      APPROVALS_FORM_FIELD,
      APPROVALS_INFO_MODEL,
      APPROVALS_FORM_NOTES,
      APPROVALS_FORM_TOTALS,
      APPROVALS_FORM_PAYMENT_METHOD,
      APPROVALS_FORM_CANCEL,
      APPROVALS_FORM_SUBMIT,
      PRICE_INFO_DISPLAY,
      NO_INFORMATION,
      ORDER_SERVICE_MODAL_INFO,
      PRICE_EXPLANATION,
      PRIVACY_POLICY_AND_TERMS,
      APPROVAL_TOGGLE_BUTTON,
      ORDER_SERVICE_COMMENT,
      FLEETS,
      MEMBERSHIP_CARD,
      APPROVAL_HISTORY
    ],
    defaultNS: COMMON
  },
  resources: {
    en: {
      common: enCommon,
      fmcDashboard: enFMCDashboard,
      siteHeader: enSiteHeader,
      dashboard: enDashboard,
      fmcUsers: enFMCUsers,
      fleetImpersonation: enFleetImpersonation,
      vehicleIndex: enVehicleIndex,
      services: enServices,
      shops: enShops,
      settings: enSettings,
      maintenanceHistory: enMaintenanceHistory,
      payment: enPayment,
      vehicle: enVehicle,
      scheduleRequest: enScheduleRequest,
      shopOrders: enShopOrders,
      approvalsEmptyState: enApprovalsEmptyState,
      approvalsForm: enApprovalsForm,
      approvalsFormHeader: enApprovalsFormHeader,
      approvalsFormFields: enApprovalsFormFields,
      approvalsFormField: enApprovalsFormField,
      approvalsInfoModal: enApprovalsInfoModal,
      approvalsFormNotes: enApprovalsFormNotes,
      approvalsFormTotals: enApprovalsFormTotals,
      approvalsFormPaymentMethod: enApprovalsFormPaymentMethod,
      approvalsFormCancel: enApprovalsFormCancel,
      approvalsFormSubmit: enApprovalsFormSubmit,
      approvalToggleButton: enApprovalToggleButton,
      priceInfoDisplay: enPriceInfoDisplay,
      noInformation: enNoInformation,
      orderServiceComment: enOrderServiceComment,
      orderServiceModalInfo: enOrderServiceModalInfo,
      priceExplanation: enPriceExplanation,
      privacyPolicyAndTerms: enPrivacyPolicyAndTerms,
      fleets: enFleets,
      membershipCard: enMembershipCard,
      approvalHistory: enApprovalHistory
    },
    "fr-CA": {
      common: frCaCommon,
      fmcDashboard: frCaFMCDashboard,
      siteHeader: frCaSiteHeader,
      dashboard: frCaDashboard,
      fmcUsers: frCaFMCUsers,
      fleetImpersonation: frCaFleetImpersonation,
      vehicleIndex: frCaVehicleIndex,
      services: frCaServices,
      shops: frCaShops,
      settings: frCaSettings,
      maintenanceHistory: frCaMaintenanceHistory,
      payment: frCaPayment,
      vehicle: frCaVehicle,
      scheduleRequest: frCaScheduleRequest,
      shopOrders: frCaShopOrders,
      approvalsEmptyState: frApprovalsEmptyState,
      approvalsForm: frApprovalsForm,
      approvalsFormHeader: frApprovalsFormHeader,
      approvalsFormFields: frApprovalsFormFields,
      approvalsFormField: frApprovalsFormField,
      approvalsInfoModal: frApprovalsInfoModal,
      approvalsFormNotes: frApprovalsFormNotes,
      approvalsFormTotals: frApprovalsFormTotals,
      approvalsFormPaymentMethod: frApprovalsFormPaymentMethod,
      approvalsFormCancel: frApprovalsFormCancel,
      approvalsFormSubmit: frApprovalsFormSubmit,
      approvalToggleButton: frApprovalToggleButton,
      priceInfoDisplay: frPriceInfoDisplay,
      noInformation: frNoInformation,
      orderServiceComment: frOrderServiceComment,
      orderServiceModalInfo: frOrderServiceModalInfo,
      priceExplanation: frPriceExplanation,
      privacyPolicyAndTerms: frPrivacyPolicyAndTerms,
      fleets: frCaFleets,
      membershipCard: frCaMembershipCard,
      approvalHistory: frCaApprovalHistory
    },
    "en-CA": {
      common: enCaCommon,
      fmcDashboard: enCaFMCDashboard,
      siteHeader: enCaSiteHeader,
      dashboard: enCaDashboard,
      fmcUsers: enCaFMCUsers,
      fleetImpersonation: enCaFleetImpersonation,
      vehicleIndex: enCaVehicleIndex,
      services: enCaServices,
      shops: enCaShops,
      settings: enCaSettings,
      maintenanceHistory: enCaMaintenanceHistory,
      payment: enCaPayment,
      vehicle: enCaVehicle,
      scheduleRequest: enCaScheduleRequest,
      shopOrders: enCaShopOrders,
      approvalsEmptyState: enApprovalsEmptyState,
      approvalsForm: enApprovalsForm,
      approvalsFormHeader: enApprovalsFormHeader,
      approvalsFormFields: enApprovalsFormFields,
      approvalsFormField: enApprovalsFormField,
      approvalsInfoModal: enApprovalsInfoModal,
      approvalsFormNotes: enApprovalsFormNotes,
      approvalsFormTotals: enApprovalsFormTotals,
      approvalsFormPaymentMethod: enApprovalsFormPaymentMethod,
      approvalsFormCancel: enApprovalsFormCancel,
      approvalsFormSubmit: enApprovalsFormSubmit,
      approvalToggleButton: enApprovalToggleButton,
      priceInfoDisplay: enPriceInfoDisplay,
      noInformation: enNoInformation,
      orderServiceComment: enOrderServiceComment,
      orderServiceModalInfo: enOrderServiceModalInfo,
      priceExplanation: enPriceExplanation,
      privacyPolicyAndTerms: enPrivacyPolicyAndTerms,
      fleets: enCaFleets,
      membershipCard: enCaMembershipCard,
      approvalHistory: enCaApprovalHistory
    }
  },
  fallbackLng: EN_LOCALE,
  interpolation: {
    escapeValue: false
  }
})

export default i18n
