import React, { useState } from "react"

const CollapsibleList = ({ items, alwaysVisibleCount = 5, applySort = true, t }) => {
  const [showAllItems, setShowAllItems] = useState(false)
  const itemsCount = showAllItems ? items.length : alwaysVisibleCount
  const sortedItems = applySort ? items.sort() : items

  return (
    <>
      {sortedItems.slice(0, itemsCount).map((item, index) => (
        <div key={`collapsible-item-${index}`}>{item}</div>
      ))}
      {items.length > alwaysVisibleCount && (
        <div className="link" onClick={() => setShowAllItems(!showAllItems)}>
          {`${t("seeLabel")} ${showAllItems ? `${t("lessLabel")}` : `${t("moreLabel")}`}`}
        </div>
      )}
    </>
  )
}

export default CollapsibleList
