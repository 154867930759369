// NOTE: The data listed here may be out of date. Confirm data before using.

export const mockPaymentData = [
  {
    id: 1,
    type: "bill_to",
    name: "Home Office",
    billing_address: "1 Development Way",
    billing_zip: "60011",
    credit_card_cvc: null,
    credit_card_expiration: null,
    credit_card_number: null,
    email: "home.office@example.com"
  },
  {
    id: 2,
    type: "credit_card",
    name: "Jim Miller's Credit Card",
    billing_address: "1 Infinite Loop",
    billing_zip: "60089",
    credit_card_cvc: "123",
    credit_card_expiration: "12/2020",
    credit_card_number: "**** **** **** 3145",
    email: "jim.miller@example.com"
  }
]
