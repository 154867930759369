import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { routerPaths } from "../../constants/paths"
import { Redirect } from "react-router"

import Alert from "../../components/shared/Alert"
import ApplicationLayout from "../../layouts/ApplicationLayout"
import UserList from "../../components/users/UserList"
import LoadingError from "../../components/shared/LoadingError"
import LoadingThrobber from "../../components/shared/LoadingThrobber"
import { Header, Segment } from "semantic-ui-react"
import { isFMCUser } from "../../helpers/userRoleHelpers"
import { withTranslation } from "react-i18next"
import AlertModal from "../../components/shared/AlertModal"
import { FMC_USER } from "../../constants/application"

class FMCUsers extends Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    isLoadingError: PropTypes.bool.isRequired,
    users: PropTypes.array.isRequired
  }

  constructor(props) {
    super(props)

    const locationStateData = this.props.location.state || {}

    this.state = {
      alertMessage: locationStateData.alertMessage || "",
      alertType: locationStateData.alertType || "default",
      openAlertModal: false,
      userIdToDelete: null
    }
  }

  componentDidMount = async () => {
    this.loadUsers()
  }

  loadUsers = async () => {
    await this.props.dispatch({ type: "FMC_USERS_LOAD_SAGA" })
  }

  onDeleteUser = (userId) => {
    this.setState({ openAlertModal: true, userIdToDelete: userId })
  }

  handleModalState = () => {
    this.setState({ openAlertModal: false })
  }

  onDeleteUserSuccess(data) {
    this.props.history.push({
      state: {
        alertMessage: data.alertMessage,
        alertType: data.alertType
      }
    })
    this.loadUsers()
  }

  afterDeleteUser(status, data) {
    if (status === "success") {
      this.onDeleteUserSuccess(data)
    } else {
      this.onRequestFailure(data)
    }
  }

  onRequestFailure = (data) => {
    this.setState({
      alertMessage: data.alertMessage,
      alertType: data.alertType
    })
  }

  onRequestSuccess(data) {
    this.setState({
      alertMessage: data.alertMessage,
      alertType: data.alertType
    })
  }

  shouldRenderContent() {
    const { isLoading, isLoadingError, users, authenticationToken, currentUser } = this.props

    return (
      !isLoading &&
      !isLoadingError &&
      users &&
      authenticationToken &&
      currentUser &&
      isFMCUser(currentUser)
    )
  }

  renderContent() {
    const { users, currentUser, t } = this.props

    if (currentUser && !isFMCUser(currentUser)) {
      // TODO: extract this work to the Router itself.
      return <Redirect to={routerPaths.dashboard} />
    } else if (this.shouldRenderContent()) {
      return (
        <div>
          <span />
          <Header size="small" className={"dash-hd"}>
            {t("usersLabel")}
          </Header>
          <Segment.Group>
            <Segment className="tab-container">
              <UserList
                users={users.filter((user) => isFMCUser(user))}
                onDelete={this.onDeleteUser}
                loadUsers={this.loadUsers}
                currentUser={currentUser}
                isFMC={true}
              />
            </Segment>
          </Segment.Group>
        </div>
      )
    } else {
      return null
    }
  }

  render() {
    const { isLoading, isLoadingError } = this.props

    const { alertMessage, alertType, openAlertModal, userIdToDelete } = this.state

    return (
      <ApplicationLayout>
        <Alert message={alertMessage} type={alertType} visible={alertMessage !== ""} />

        <LoadingThrobber visible={!this.shouldRenderContent()} />

        <LoadingError visible={!isLoading && isLoadingError} />

        {this.renderContent()}

        {openAlertModal ? (
          <AlertModal
            openAlertModal={openAlertModal}
            hideModal={this.handleModalState}
            idToDelete={userIdToDelete}
            afterDelete={this.afterDeleteUser}
            that={this}
            modelFrom={FMC_USER}
          />
        ) : null}
      </ApplicationLayout>
    )
  }
} // class Dashboard

const mapStateToProps = (state) => ({
  users: state.users.fmcUsers,
  isLoading: state.application.isLoading,
  isLoadingError: state.application.isLoadingError,
  currentUser: state.users.currentUser,
  authenticationToken:
    state.users && state.users.currentUser && state.users.currentUser.authentication_token
})

export default connect(mapStateToProps)(withTranslation("fmcUsers")(FMCUsers))
