import React, { Component } from "react"
import PropTypes from "prop-types"
import "../../assets/stylesheets/components/sessions/_privacy_policy.scss"
class PrivacyPolicy extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <div class="privacy_policy content">
        <h2>Shell Fleet Maintenance Hub </h2>
        <h2>end user license agreement</h2>
        <p>
          This Shell Fleet Maintenance Hub End User License Agreement (<b>“EULA”</b>) is between
          Equilon Enterprises LLC d/b/a Shell Oil Products US (<b>“Shell,” “we,” “our,” or “us”</b>)
          and any customer of Shell (the <b>“Customer”, “you”, or “your”</b>) that downloads, uses,
          or accesses any{" "}
          <b>
            <i>
              <u>Shell Fleet Maintenance Hub</u>
            </i>
          </b>{" "}
          applications or websites (including, without limitation, Shell Fleet Maintenance Hub
          Mobile Application, Shell Fleet Maintenance Hub Manager Dashboard, and Shell Fleet
          Maintenance Hub Manager Mobile Application) (<b>“Apps”</b>) and the related software and
          services (collectively with the Apps, the <b>“Platform”</b>), including all user manuals
          and any other related materials provided in connection with the Platform, in electronic or
          other form, that describe the Platform or its use or specifications (the{" "}
          <b>“Documentation”</b>). For purposes of this EULA, all references to “Customer,” “you,”
          or “your” shall also include any employees, representatives, agents, or contractors of
          Customer that are designated as authorized users by Customer or otherwise use the Platform
          on behalf of Customer (“Authorized Users”) unless the specific context requires otherwise.
        </p>
        <p>
          PLEASE READ THIS EULA CAREFULLY. IN PARTICULAR, PLEASE REVIEW SECTIONS 1, 2, 15, and 19,
          WHICH CONTAIN PROVISIONS GOVERNING HOW CLAIMS YOU MAY HAVE AGAINST SHELL WILL BE RESOLVED,
          AND SECTION 9, WHICH CONTAINS PROVISIONS REGARDING AUTOMATIC MEMBERSHIP RENEWALS.
        </p>
        <p>
          ACCESS TO THE PLATFORM IS LIMITED TO CUSTOMERS OF SHELL in the United States. Individual
          Customers must be at least eighteen years of age or the age of majority in Customer’s
          jurisdiction and reside in the United States. If you are not a Customer or Authorized User
          of a Customer, you must refrain from registering for, accessing, or using the Platform.
          Shell reserves all rights and remedies under applicable law arising from unauthorized use
          of the Platform.
        </p>
        <p>
          BY CHECKING THE "ACCEPT" BOX YOU: (i) REPRESENT THAT YOU ARE DULY AUTHORIZED TO ACCESS AND
          USE THE PLATFORM AND TO ACCEPT THE TERMS AND CONDITIONS IN THIS EULA ON BEHALF OF
          CUSTOMER; AND (ii) ACCEPT THE TERMS AND CONDITIONS IN THIS EULA AND AGREE THAT CUSTOMER IS
          LEGALLY BOUND BY THEM. IF YOU DO NOT AGREE TO THE TERMS OF THIS EULA, DO NOT CHECK THE
          "ACCEPT" BOX AND YOU WILL HAVE NO LICENSE TO, AND MUST NOT ACCESS OR USE, THE PLATFORM.{" "}
        </p>
        <ol type="1">
          <li>
            <h3>
              <b>
                <u>Third Party Content and Vendors:</u>
              </b>
            </h3>
            <p>
              In providing the Platform pursuant to this EULA, Shell is making available to Customer
              the software, services, and content provided by CarAdvise LLC (<b>“Third Party”</b>)
              (the <b>“Third Party Content”</b>). Shell takes no responsibility for and makes no
              warranty regarding the Third Party Content
            </p>
            <p>
              THE THIRD PARTY CONTENT MAY INCLUDE RECOMMENDATIONS, GUIDANCE, , PRICING INFORMATION,
              REVIEWS, AND/OR SCHEDULING SERVICES FOR THIRD PARTY VEHICLE MAINTENANCE AND OTHER
              VENDORS (<b>“VENDORS”</b>) THAT CUSTOMER MAY ELECT TO USE AT ITS SOLE OPTION. WHILE
              SHELL OR THIRD PARTY MAY COLLECT AND PROCESS FEES FOR TRANSACTIONS BY A CUSTOMER WITH
              A VENDOR, SHELL IS NOT A PARTY TO THE ACTUAL TRANSACTION WITH A VENDOR AND MAKES NO
              REPRESENTATION OR WARRANTY AS TO THE THIRD PARTY CONTENT OR VENDORS.{" "}
            </p>
            <p>
              CUSTOMER ACCESSES ALL THIRD PARTY CONTENT AND VENDOR INFORMATION AT CUSTOMER’S SOLE
              RISK. SHELL HAS NO LIABILITY FOR ANY ERRORS OR OMISSIONS IN SUCH CONTENT OR
              INFORMATION, OR FOR ANY DAMAGES OR LOSS CUSTOMER MIGHT SUFFER IN CONNECTION WITH THIRD
              PARTY CONTENT AND VENDOR INFORMATION. CUSTOMER IS SOLELY RESPONSIBLE FOR CUSTOMER’S
              INTERACTIONS WITH ANY VENDORS.{" "}
            </p>
            <p>
              Additional terms and conditions with respect to Third Party and the Third Party
              Content may be found at{" "}
              <a href="https://caradvise.com/terms">https://caradvise.com/terms</a>. By using the
              Third Party Content including with respect to Vendors Customer has also agreed to the
              Third Party’s terms and conditions.{" "}
              <b>
                <i>
                  In the event of a conflict between the Third Party’s terms and conditions and this
                  EULA with respect to Shell and Customer, this EULA shall prevail.
                </i>
              </b>
            </p>
          </li>
          <li>
            <h3>
              <b>
                <u>No Warranty:</u>
              </b>
            </h3>
            <p>
              USE OF THE PLATFORM IS VOLUNTARY. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW,
              THE PLATFORM AND ALL INFORMATION OR SERVICES PROVIDED AS PART OF IT (INCLUDING ANY
              THIRD PARTY CONTENT, VENDOR INFORMATION, GUIDANCE, SCHEDULING INFORMATION, PRICING
              INFORMATION OR ESTIMATES, DOCUMENTS, MATERIALS, SERVICES PROVIDED BY A VENDOR, PAYMENT
              HANDLING, OR OTHER CONTENT (COLLECTIVELY AND, TOGETHER WITH THE DOCUMENTATION, THE{" "}
              <b>“SERVICES”</b>)) ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS.
            </p>
            <p>
              NEITHER THE PLATFORM NOR THE SERVICES CAN BE CUSTOMIZED TO FULFILL THE NEEDS OF EACH
              AND EVERY CUSTOMER OR AUTHORIZED USER. SHELL HEREBY DISCLAIMS ALL WARRANTIES AND
              REPRESENTATIONS, EITHER EXPRESS OR IMPLIED, WITH RESPECT TO THE PLATFORM OR SERVICES
              INCLUDING, WITHOUT LIMITATION, ANY WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A
              PARTICULAR PURPOSE; FEATURES; QUALITY; NON-INFRINGEMENT OF ANY INTELLECTUAL PROPERTY
              OF ANY PARTY; TITLE; COMPATIBILITY; PERFORMANCE; SECURITY; GOOD AND WORKMANLIKE
              SERVICES; COMPLIANCE WITH ANY LAW, REGULATION, OR CODE; OR ACCURACY. SHELL DOES NOT
              WARRANT THE PLATFORM OR SERVICES WILL BE ACCURATE, COMPLETE, CURRENT, ERROR-FREE, OR
              THAT USE OF THE PLATFORM OR SERVICES WILL HAVE ANY PARTICULAR RESULT.
            </p>
            <p>
              SHELL DOES NOT REPRESENT OR WARRANT THAT THE USE OF THIS PLATFORM, SERVICES, OR OF ANY
              FILE OR PROGRAM AVAILABLE FOR DOWNLOAD AND/OR EXECUTION FROM OR VIA THIS PLATFORM WILL
              BE COMPATIBLE WITH ALL HARDWARE AND PLATFORM THAT MAY BE USED BY YOU. SHELL DOES NOT
              WARRANT THAT YOUR USE OF THE PLATFORM WILL BE UNINTERRUPTED OR THAT THE OPERATION OF
              OR ACCESS TO THE PLATFORM WILL BE ERROR OR BUG FREE OR FREE FROM VIRUSES OR OTHER
              MALICIOUS CODE, OR THAT ALL ERRORS CAN OR WILL BE CORRECTED.{" "}
            </p>
            <p>
              NEITHER THE PLATFORM NOR THE SERVICES ARE A SUBSTITUTE FOR YOUR OWN PROFESSIONAL
              JUDGMENT OR YOUR OWN ASSESSMENT OF THE SPECIFIC MAINTENANCE NEEDS OF OR ISSUES WITH
              YOUR FLEET OF COMMERCIAL VEHICLES. RELIANCE ON THE SERVICES OR ANY INFORMATION
              PROVIDED VIA THE PLATFORM IS SOLELY AT YOUR OWN DISCRETION AND RISK.
            </p>
          </li>
          <li>
            <h3>
              <b>
                <u>License Grant</u>
              </b>
            </h3>
            <p>
              Subject to your strict compliance with this EULA, Shell hereby grants you a
              non-exclusive, non-transferable, non-sublicensable, limited license to use the
              Platform solely in accordance with the Documentation, as installed on the equipment
              provided by you. The foregoing license will terminate immediately on the earlier to
              occur of:{" "}
            </p>
            <ul>
              <li>termination by Shell; or</li>
              <li>your decision to terminate this EULA and stop using the Platform.</li>
              <li>
                the expiration or earlier termination of this EULA between Shell and Customer; or
              </li>
              <li>
                your ceasing to be authorized by Shell to use the Platform for any or no reason.
              </li>
            </ul>
          </li>
          <li>
            <h3>
              <b>
                <u>Use Restrictions:</u>
              </b>
            </h3>
            <p>You shall not, directly or indirectly: </p>
            <p>(a) use the Platform except as set forth in Section 3, “License Grant”;</p>
            <p>(b) copy the Platform, in whole or in part;</p>
            <p>
              (c) modify, translate, adapt or otherwise create derivative works or improvements,
              whether or not patentable, of the Platform or any part thereof;
            </p>
            <p>
              (d) combine the Platform or any part thereof with, or incorporate the Platform or any
              part thereof in, any other programs, software or applications;
            </p>
            <p>
              (e) reverse engineer, disassemble, decompile, decode or otherwise attempt to derive or
              gain access to the source code of the Platform or any part thereof;{" "}
            </p>
            <p>
              (f) remove, delete, alter or obscure any trademarks or any copyright, trademark,
              patent or other intellectual property or proprietary rights notices from the Platform
              or Services, including any copy thereof;
            </p>
            <p>
              (g) rent, lease, lend, sell, sublicense, assign, distribute, publish, transfer or
              otherwise provide any access to or use of the Platform or any features or
              functionality of the Platform, for any reason, to any other person or entity,
              including any subcontractor, independent contractor, affiliate or service provider,
              whether or not over a network and whether or not on a hosted basis, including in
              connection with the internet, web hosting, wide area network (WAN), virtual private
              network (VPN), virtualization, time-sharing, service bureau, software as a service,
              cloud or other technology or service;
            </p>
            <p>
              (h) use the Platform in, or in association with, the design, construction, maintenance
              or operation of any hazardous environments or systems, including:{" "}
            </p>
            <ol type="i">
              <li>power generation systems;</li>
              <li>
                aircraft navigation or communication systems, air traffic control systems or any
                other transport management systems;
              </li>
              <li>
                safety-critical applications, including medical or life-support systems, vehicle
                operation applications or any police, fire or other safety response systems; and
              </li>
              <li>military or aerospace applications, weapons systems or environments;</li>
              <li>
                use the Platform in violation of any federal, state or local law, regulation or
                rule; or
              </li>
              <li>
                use the Platform for purposes of competitive analysis of the Platform, the
                development of a competing software product or service or any other purpose that is
                to the Shell's commercial disadvantage.{" "}
              </li>
            </ol>
          </li>
          <li>
            <h3>
              <b>
                <u>Changes to the EULA and Platform:</u>
              </b>
            </h3>
            <p>
              Shell may revise and update this EULA from time to time and in our sole discretion.
              All changes are effective immediately and apply to all access to and use of the
              Platform and Services thereafter.
            </p>
            <p>
              Your continued use of the Platform or interaction with the Services following the
              posting of the revised EULA means that you accept and agree to the changes.
            </p>
          </li>
          <li>
            <h3>
              <b>
                <u>Compliance Measures:</u>
              </b>
            </h3>
            <p>
              The Platform may contain technological copy protection or other security features
              designed to prevent unauthorized use of the Platform, including features to protect
              against use of the Platform :
            </p>
            <p>
              (a) beyond the scope of the license granted to pursuant to Section 3 “License Grant”;
              or{" "}
            </p>
            <p>(b) prohibited under Section 4 “Use Restrictions”.</p>
            <p>
              You shall not, and shall not attempt to, remove, disable, circumvent or otherwise
              create or implement any workaround to, any such copy protection or security features.
            </p>
          </li>
          <li>
            <h3>
              <b>
                <u>Termination:</u>
              </b>
            </h3>
            <p>
              (a) You may terminate this EULA at any time provided you remove all copies of the
              Platform from all of your equipment. If you attempt to terminate this EULA while there
              are still outstanding Services ordered under your password or account, this EULA shall
              not terminate until such Services have been performed or otherwise canceled as
              permitted by us. You remain responsible for payment for all Services provided but not
              yet invoiced at the time of termination and you authorize Third Party or us (without
              notice to you, unless required by applicable law), to collect the then-applicable fees
              and any taxes using the payment method Third Party has on record for you.{" "}
            </p>
            <p>
              (b) Shell may at any time, in its sole discretion, terminate the EULA with you, delete
              your profile and any of your User Generated Content and/or restrict your use of all or
              any part of the Platform at any time, for any or no reason, without notice and without
              liability to you or anyone else, or if :
            </p>
            <ol type="i">
              <li>
                you have breached any provision of the EULA or intend to breach any provision;{" "}
              </li>
              <li>Shell is required to do so by law; </li>
              <li>
                Shell is transitioning to no longer providing the Platform to users in the country
                in which you are resident or from which you use the Platform; or
              </li>
              <li>
                the provision of the Platform to you by Shell is, in the sole opinion of Shell, no
                longer commercially viable.
              </li>
            </ol>

            <p>
              (c) After any line of action pursuant to Section 5(b) above, you are not allowed to
              create a new account to circumvent the termination, deletion or restriction. You
              understand and agree that some of your User Generated Content may continue to appear
              on or through the Platform or may persist in backup copies even after your account
              and/or access to the Platform is terminated.{" "}
            </p>
            <p>
              (d) Subject to local applicable laws, Shell reserves the right to suspend or deny, in
              its sole discretion, your access to all or any portion of the Platform without notice
              if you breach any provision of the EULA or any applicable law or if your conduct, in
              the sole opinion of Shell, impacts Shell's name or reputation or violates the rights
              of those of another party.
            </p>
            <p>
              (e) When the EULA come to an end, all of the legal rights, obligations and liabilities
              that you and Shell have benefited from, been subject to (or which have accrued over
              time while the EULA has been in force) or which are expressed to continue
              indefinitely, shall be unaffected by this cessation, and the provisions of this clause
              shall continue to apply to such rights, obligations and liabilities indefinitely.
            </p>
            <p>
              (f) If we terminate this EULA, we will give you a prorated refund based on the number
              of days remaining in your membership unless we terminate your membership for conduct
              that we determine, in our discretion, violates the EULA, violates any applicable law,
              involves fraud or misuse of the Platform or Services, or is harmful to our interests
              or another user.{" "}
            </p>
          </li>
          <li>
            <h3>
              <b>
                <u>Acknowledgments:</u>
              </b>
            </h3>
            <p>By Accepting this EULA, you acknowledge your understanding that : </p>
            <p>
              (a) Your use of the Platform and the Services is voluntary as between you and Shell;{" "}
            </p>
            <p>
              (b){" "}
              <b>
                <u>
                  NEITHER CUSTOMER NOR ANY AUTHORIZED USER OF CUSTOMER IS AN EMPLOYEE, AGENT, OR
                  PARTNER OF SHELL, ITS AFFILIATES OR ITS PARENT COMPANY, AND NO EMPLOYMENT OR
                  AGENCY RELATIONSHIP IS CREATED BY YOUR USE OF THE PLATFORM OR THE SERVICES;
                </u>
              </b>
            </p>
            <p>
              (c) Shell does not control the manner or means by which you use the Platform or the
              Services;
            </p>
            <p>
              (d) Shell will not be responsible for any costs, including but not limited to (i) data
              charges or fees, associated with use of any personal mobile device to Use the Platform
              or access its Services, or (ii) charges, fees, or taxes for Services, membership
              plans, subscriptions, or any other content or services purchased through the Platform;
              and
            </p>
            <p>
              (e) Shell, its parent company and various affiliates are separate legal entities. When
              you use the Services, “Shell” is sometimes used for convenience where references are
              made to those entities individually or collectively. Likewise, the words “we”, “us”
              and “our” may also be used. These references do not reflect the operational or
              corporate structure of, or the relationship between Shell, its parent company and its
              various affiliates.
            </p>
          </li>
          <li>
            <h3>
              <b>
                <u>Fees and Renewal:</u>
              </b>
            </h3>
            <p>
              In using the Platform and any account created thereunder, you may make purchases of
              Services, membership plans, subscriptions, and other content or services. Fees for
              such purchases will be stated at the time of your purchase or sign-up, as applicable,
              and provided in our help pages or the Platform. The fees for membership plans may
              vary. Fees may be subject to tax and are non-refundable except in the specific
              circumstances described in this EULA. We reserve the right to adjust pricing in any
              manner and at any time as we may determine in our sole and absolute discretion. Except
              as otherwise expressly provided for in this EULA, any price changes or changes to your
              subscription or membership plan will take effect following notice to you.
            </p>
            <p>
              Third Party will determine acceptable payment methods and facilitate and process
              payments. Credit cards will be accepted for payment of your membership fees and any
              Services. Any use of a credit card is subject to the credit card terms and conditions
              provided by the issuing bank. Please do not sign up for the program with a debit card.
              Also known as a “check” or "ATM" card, a debit card typically has the word “debit”
              printed on the face of the card.
            </p>
            <p>
              <b>
                If your membership plan or subscription involves a recurring payment of a fee,
                unless you notify us or Third Party before a charge that you want to cancel or do
                not want to automatically renew your membership or subscription, you understand it
                will automatically continue and you authorize Third Party or us (without notice to
                you, unless required by applicable law) to collect the then-applicable fees and any
                taxes using any payment method Third Party has on record for you.
              </b>
            </p>
            <p>
              <b>
                We sometimes offer certain Customers various trial or other promotional memberships
                which are subject to no or reduced fees for a trial period. Such trial or other
                promotional memberships are subject to this EULA except as otherwise stated in the
                promotional offers. We reserve the right, in our sole discretion, to determine your
                eligibility. Trial members may, at any time, choose not to continue to paid
                membership by cancelling prior to the end of the trial period. If you do not cancel
                your membership prior to the end of the trial period, you understand that your
                membership will automatically continue and you authorize Third Party and us (without
                notice to you, unless required by applicable law) to collect the then-applicable
                fees and any taxes using any payment method that is on file for you.{" "}
              </b>
              If a trial offer or a promotion requires you to have a valid payment instrument on
              file, as determined by Third Party, Third Party may validate that payment method
              including by requesting a temporary authorization from the financial institution
              issuing your payment instrument. If Third Party or we determine that your payment
              instrument is invalid, without limiting any of our other rights, we may revoke any
              benefits or content you may have received as part of the trial or promotional offer.
            </p>
            <p>
              If all payment methods on file for you are declined for payment of your membership or
              subscription fees or Services, we may cancel your membership or subscription or any
              Services purchased, as applicable, unless you provide us with a payment method. If you
              provide us with a new payment method and are successfully charged before your
              membership or subscription is cancelled, your new membership or subscription period
              will be based on the original renewal date and not the date of the successful charge.{" "}
            </p>
            <p>
              <b>
                <u>
                  THIRD PARTY IS SOLELY RESPONSIBLE FOR THE SECURITY OF ALL PAYMENTS, INCLUDING
                  CREDIT CARD, PAYMENT METHOD INFORMATION, AND ALL OTHER PERSONAL INFORMATION, AND
                  WE EXPRESSLY DISCLAIM ANY LIABILITY FOR ANY DAMAGE THAT MAY RESULT SHOULD ANY
                  INFORMATION BE RELEASED TO ANY THIRD PARTIES, AND YOU AGREE TO HOLD US HARMLESS
                  FOR ANY DAMAGES THAT MAY RESULT THEREFROM.{" "}
                </u>
              </b>
            </p>
            <p>
              No refunds or credits will be provided once your payment method has been charged,
              except that at our sole discretion, refunds or credits may be granted in extenuating
              circumstances, as a result of specific promotions or to correct any errors we have
              made. For any amounts that we determine you owe us, we may (i) offset any amounts that
              are payable by you to us (in reimbursement or otherwise) against any payments we make
              to you or amounts we may owe you; (ii) invoice you for amounts due to us, in which
              case you will pay the invoiced amounts upon receipt; (iii) reverse any credits to your
              bank account; or (iv) collect payment or reimbursement from you by any other lawful
              means.{" "}
            </p>
          </li>
          <li>
            <h3>
              <b>
                <u>Fleet Manager Tools:</u>
              </b>
            </h3>
            <p>
              Customer may be provided access to certain administrative tools (
              <b>"Fleet Manager Tools"</b>), including, but not limited, the ability to :
            </p>
            <p>
              (a) access all information provided by, or resulting from the use of the Platform by,
              Customer or such Customer’s Authorized Users , including, but not limited to, vehicle
              and driver information, maintenance information and recommendations, all Services
              purchased by Fleet Users, costs of Services purchased by Fleet Users, and other
              transaction information (collectively, the <b>“Fleet User Data”</b>);{" "}
            </p>
            <p>
              (b) approve Authorized Users and grant Authorized Users access to Fleet Manager Tools;
            </p>
            <p>(c) upload and modify vehicle information;</p>
            <p>(d) view Vendor information;</p>
            <p>
              (e) view all Services requested by Authorized Users and approve or decline such
              Services;
            </p>
            <p>(f) schedule Services for Authorized Users;</p>
            <p>
              (g) set approved payment methods for Authorized Users, subject to the terms of this
              EULA and
            </p>
            <p>(h) view and pay invoices for Services requested by Authorized Users.</p>
            <p>
              Customer shall remain liable for all purchases made by Authorized Users of Services,
              membership plans, subscriptions, other content, or services.
            </p>
            <p>
              Each Customer and Authorized User consents to the use of the Fleet Manager Tools for
              the applicable Customer.
              <b>
                <u>
                  CUSTOMER IS RESPONSIBLE FOR THE SECURITY OF ALL AUTHORIZED USER DATA AND
                  AUTHORIZED USER PERSONAL INFORMATION, AND WE EXPRESSLY DISCLAIM ANY LIABILITY FOR
                  ANY DAMAGE THAT MAY RESULT SHOULD ANY INFORMATION BE RELEASED TO ANY THIRD
                  PARTIES, AND CUSTOMER AGREES TO HOLD US HARMLESS FOR ANY DAMAGES THAT MAY RESULT
                  THEREFROM.
                </u>
              </b>
            </p>
          </li>
          <li>
            <h3>
              <b>
                <u>Data Privacy; Collection and Use of Information:</u>
              </b>
            </h3>
            <p>
              (a) By clicking to accept or agree to this EULA, or by your use of the Platform, you
              accept and agree to be bound and to abide by these Terms and Shell’s Privacy Policy
              (“Privacy Policy”), found at
              <a href="https://www.shell.us/privacy/b2b-notice.html">
                {" "}
                https://www.shell.us/privacy/b2b-notice.html
              </a>
              , which is incorporated into this EULA by this reference. Shell reserves the right to
              make changes to its Privacy Policy; any such changed policies will be posted at
              <a href="https://www.shell.us/privacy.html"> https://www.shell.us/privacy.html</a> .
              If you do not want to agree to this EULA or the Privacy Policy, you must not access or
              use the Platform or the Services.
            </p>
            <p>
              (b) Shell may, directly or indirectly through the services of others, collect and
              store information regarding your use of the Platform, driver locations, and about
              equipment on which the Platform is installed or through which it otherwise is accessed
              and used, including, without limitation, by means of (i) providing maintenance and
              support services and (ii) security measures included in the Platform as described in
              Section 6, “Compliance Measures”.
            </p>
            <p>
              (c) You agree that Shell may use such information for any purpose related to any use
              of the Platform by you, including but not limited to: (i) improving the performance of
              the Platform or developing updates; (ii) verifying compliance with the terms of this
              EULA and enforcing Shell's rights, including all intellectual property rights in and
              to the Platform; (iii) offering complimentary and additional services or customer
              value propositions; (iv) conducting marketing and advertising activities as further
              described in the Privacy Policy.{" "}
            </p>
            <p>
              (d) If you choose to purchase Services, memberships, subscriptions, or other content
              or services, you agree that Third Party or we may collect, process and remit the fees
              for such Services, memberships, subscriptions, or other content or services to third
              parties (such as, without limitation, the Third Party, Vendors, or other third parties
              designated by Shell) through the Platform.{" "}
            </p>
            <p>
              (e) You may have the option to import into the Platform your Shell Account Manager
              profile data, including, but not limited to, vehicle information, maintenance history,
              location information, payment information, driver information, and any other
              information in your Shell Account Manager profile . If you choose to import your Shell
              Commercial Fleet account data, you agree that Shell (and third parties designated by
              Shell including Third Party) may request and process such data, and that the third
              party holding such data may provide the data to Shell or third parties designated by
              Shell.{" "}
            </p>
            <p>
              (f) You agree that Customer may have access to Fleet User Data through use of the
              Fleet Manager Tools.{" "}
            </p>
          </li>
          <li>
            <h3>
              <b>
                <u>Application Stores:</u>
              </b>
            </h3>
            <p>
              (a) By downloading and/or using the Platform, you agree that you have entered into an
              agreement with us, and not with Apple or Google. Third Party is solely responsible for
              any product warranties, if any, pertaining to the Third Party Content and any product
              warranties if any, pertaining to the Platform, whether express or implied by law, to
              the extent they are not otherwise effectively disclaimed in this EULA or Third Party
              terms and conditions.{" "}
            </p>
            <p>
              (b) By downloading the Platform from the App Store or from Google Play, Shell grants
              you a non-transferable license to use the Platform on any iPhone, iPod touch or other
              relevant device that you own or control as permitted by the usage rules of the Google
              Play Store, the App Store, the App Store terms of service and the Google Play Store
              terms of service.
            </p>
            <p>
              (c) The parties, namely you and Shell, acknowledge that neither Apple nor Google have
              any obligation whatsoever to furnish any maintenance or support services with respect
              to the Platform.
            </p>
            <p>
              (d) In the event of any failure of the Platform to conform to any applicable warranty
              and where the warranty relates to your use of a version of the Platform downloaded
              through the App Store you may notify Apple, and Apple will refund the purchase price
              for the Platform to you (if any); and, to the maximum extent permitted by applicable
              law, Apple will have no other warranty obligation whatsoever with respect to the
              Platform, and any other claims, losses, liabilities, damages, costs or expenses
              attributable to any failure of the app to conform to any warranty, if any, will be our
              sole responsibility.
            </p>
            <p>
              (e) The parties acknowledge that Third Party and not Apple or Google, is responsible
              for addressing any of your claims relating to the Platform or your possession and/or
              operation of the Platform, including, but not limited to: (i) product liability claims
              made in respect of the Platform; (ii) any claim that the Platform fails to conform to
              any applicable legal or regulatory requirement; and (iii) claims arising under
              consumer protection or similar legislation in respect of the Platform.{" "}
            </p>
            <p>
              (f) The parties acknowledge that Third Party, and not Apple or Google, is responsible
              for any claim that the Platform infringes any third party intellectual property rights
              and that Third Party, and not Apple or Google, will be solely responsible for the
              investigation, defence, settlement or discharge of any intellectual property
              infringement claim.
            </p>
            <p>
              (g) The parties each acknowledge and agree that Apple and Google, and Apple’s and
              Google’s respective subsidiaries, are third party beneficiaries of this EULA, and that
              Apple and Google will each have the right (and will be deemed to have accepted the
              right) to enforce this EULA against you as a third party beneficiary of this EULA.
            </p>
            <p>
              (h) Apple and App Store are trademarks of Apple Inc., registered in the U.S. and other
              countries. Google and Google Play are trademarks of Google Inc.
            </p>
            <p>
              (i) For the avoidance of doubt, the parties state that nothing in this clause is
              intended to limit, exclude or modify any other provision of this EULA.
            </p>
          </li>
          <li>
            <h3>
              <b>
                <u>Intellectual Property Rights:</u>
              </b>
            </h3>
            <p>
              The Platform and Services contain material which are protected by copyright and/or
              other intellectual property rights. All copyright and other intellectual property
              rights in this material are either owned by Shell or have been licensed to it by the
              owner(s) or licensee of those rights so that it can use this material as part of the
              Platform. No Shell Content may be copied, reproduced, republished, uploaded,
              broadcast, posted, transmitted, or distributed except as expressly permitted by this
              Agreement. Use of Shell Content in any other way or for any purpose other than as
              permitted in this Agreement is a violation of Shell's copyrights and other proprietary
              rights. Permission for all other uses of Shell Content must be obtained from Shell in
              advance and in writing. All content, interfaces, graphics, code, design rights, and
              other intellectual property rights, in each case, whether registered or unregistered,
              other than the marks of third parties, and related goodwill shall remain the property
              of Shell or its licensor.
            </p>
            <p>
              YOU UNDERSTAND THAT YOU HAVE NO RIGHTS TO THE PLATFORM, SHELL CONTENT, SHELL
              DOCUMENTATION, SHELL MARKS, OR ANY OTHER SHELL PROPERTY EXCEPT AS INDICATED IN THIS
              AGREEMENT. SHELL TAKES THE PROTECTION OF INTELLECTUAL PROPERTY RIGHTS, INCLUDING
              COPYRIGHTS, VERY SERIOUSLY. SHELL WILL TERMINATE YOUR ACCESS TO, OR USE OF, ALL OR ANY
              PART OF THE PLATFORM, WITHOUT NOTICE TO YOU, IF YOU INFRINGE OR MISAPPROPRIATE THE
              INTELLECTUAL PROPERTY RIGHTS, INCLUDING COPYRIGHTS, OF SHELL OR OTHERS. FURTHER, SHELL
              RESERVES ALL OTHER RIGHTS WITH RESPECT TO ITS INTELLECTUAL PROPERTY.
            </p>
            <p>
              The Platform also contains trademarks, including the mark "Shell" and the Shell
              emblem. All trademarks included on this web Platform belong to Shell or have been
              licensed to it by the owner(s) of those trade marks for use on the Platform. No
              license, immunity or other right under or in respect of any intellectual property
              (including without limitation patent rights, copyright, registered or unregistered
              trademarks, database rights and rights in designs), information or technology is
              granted to you under this EULA. Marks of third parties may appear on the Platform,
              when referring to those entities or their products or services. Third party marks
              displayed on the Platform are owned by their respective owners.
            </p>
            <p>
              Shell does not wish to receive confidential or proprietary information from any user
              through its use of the Platform. If you are (or are employed or retained by) a
              competitor of Shell, you agree not to submit any competitively sensitive information,
              including, but not limited to, information with respect to your (or your employer’s)
              customer lists, supply, inventory, output, territories, markets, prices, and other
              sales arrangements for services and products that compete with services and products
              of Shell. Should you respond with information such as feedback data, questions,
              comments, suggestions, or the like regarding the Platform or any products, services,
              promotions, programs or policies, such information shall be deemed to be
              nonconfidential and will become the property of Shell, and Shell shall have no
              obligation of any kind with respect to such information and shall be free to
              reproduce, use, disclose, publish, broadcast, and distribute the information to others
              without any limitation whatsoever.{" "}
            </p>
            <p>
              You acknowledge that the Platform is provided under license, and not sold, to you. You
              do not acquire any ownership interest in the Platform under this EULA, or any other
              rights to the Platform other than to use the Platform in accordance with the license
              granted under this EULA, subject to all terms, conditions and restrictions. Shell and
              its licensors and service providers reserve and shall retain their entire right, title
              and interest in and to the Platform and all intellectual property rights arising out
              of or relating to the Platform. You shall safeguard all Platform (including all copies
              thereof) from infringement, misappropriation, theft, misuse or unauthorized access.{" "}
            </p>
          </li>
          <li>
            <h3>
              <b>
                <u>User Generated Content:</u>
              </b>
            </h3>
            <p>
              The Platform may allow you to enter, post, submit, or link to certain content
              (including, for example, text, data, software, photographs, images, graphics, files,
              videos, audio, sound, music, messages, materials, works of authorship, etc.) and
              provide certain comments, ratings, and reviews for others to see (collectively, "User
              Content"). Shell does not claim any ownership rights in any User Content that you
              enter, post, submit, provide, or link to on or through the Platform. By entering,
              posting, submitting, providing, or linking to User Content on or through the Platform,
              however, you grant Shell and Shell hereby accepts a worldwide, perpetual, irrevocable,
              non-exclusive, transferrable, sub-licensable, fully-paid up, and royalty free license
              to use, sell, reproduce, create derivative works from, combine with other works,
              alter, modify, delete from, add to, translate, distribute copies, display, perform,
              and publish the User Content and your name in connection with such use of your User
              Content in any and all media and in any manner, in whole or in part, without any
              restriction or responsibilities to you. In addition, by entering, posting, submitting,
              providing, or linking to User Content, you hereby release Shell from any claims that
              such use, as authorized above, violates any rights which you may have under applicable
              law, and you understand that you will not be entitled to any compensation for any use
              of your User Content. Further, by entering, posting, submitting, providing, or linking
              to User Content, you hereby represent, warrant, and agree as follows:{" "}
            </p>
            <p>
              (a) I own and control all of the rights to the User Content or otherwise have (a) the
              legal rights to enter, post, submit, provide, or link to the User Content using the
              Platform and (b) the right to grant Shell the license specified in this Agreement.{" "}
            </p>
            <p>
              (b) The User Content that I enter, post, submit, provide, or link to does not, and
              will not, result in a breach of contract between me and any other person.{" "}
            </p>
            <p>
              (c) I will pay all royalties, fees, and any other monies owing to any person due to
              any of the User Content that I enter, post, submit, provide, or link to using the
              Platform.{" "}
            </p>
            <p>(d) None of the User Content is subject to confidentiality obligations. </p>
            <p>
              (e) The User Content that I enter, post, submit, provide, or link to does not violate
              the privacy rights, publicity rights, copyrights, contract rights, intellectual
              property rights, or any other rights of any person.{" "}
            </p>
            <p>
              (f) The User Content is truthful, accurate, not misleading, and offered in good faith.{" "}
            </p>
            <p>
              (g) I am solely responsible for all of the User Content that I enter, post, submit,
              provide, link to, or otherwise make available in any way by or through the Platform.{" "}
            </p>
            <p>
              (h) I will not post another person's sensitive identification documents, financial
              information, or other personal data without that person's permission.{" "}
            </p>
            <p>
              (i) I will not enter, post, submit, provide, or link to User Content that is obscene
              or may be hateful or offensive on racial, ethnic, sexual, or any other grounds, is
              harmful, vulgar, or distasteful, or is defamatory, libelous, or invades another
              person's privacy.{" "}
            </p>
            <p>
              (j) I will not use Shell's intellectual property (including, for example, SHELL, and
              certain other brands, trademarks, and services marks that are the property of Shell or
              Shell’s Affiliates) without Shell's permission.{" "}
            </p>
            <p>
              (k) I understand that Shell can, in its sole discretion, monitor User Content that I
              and other users enter, post, submit, provide, or link to using the Platform.{" "}
            </p>
            <p>
              (l) I understand that Shell can, without notice and in its sole discretion, remove any
              User Content that we believe is obscene, offensive, libelous, defamatory, or violates
              the law or any of the terms and conditions of this Agreement. In addition, I
              understand that Shell can, in its sole discretion, remove any User Conduct if we
              believe that doing so will improve the Platform.{" "}
            </p>
            <p>
              (m) I understand that Shell reserves the right to force forfeiture of the username of
              any account that becomes inactive or any username that violates the trademark or other
              intellectual property rights of any other person.{" "}
            </p>
            <p>
              (n) I understand that Shell can suspend or terminate my use of the Platform if I fail
              to follow the rules in this Agreement.{" "}
            </p>
            <p>
              <b>NOTICE :</b>Shell has no obligation to respond to any of your User Content. In
              addition, Shell is not responsible for any User Content, including, for the truth,
              completeness, objectivity, or usefulness of such User Content. Shell does not endorse
              any User Content, has not verified the identity of persons using the Platform, and
              does not screen, monitor, edit, or review User Content before it becomes accessible on
              or through the Platform. Accordingly, you may be exposed to content from other users
              that is offensive or objectionable.{" "}
            </p>
          </li>
          <li>
            <h3>
              <b>
                <u>Security:</u>
              </b>
            </h3>
            <p>
              You may receive a password to access certain areas of the Platform. You are
              responsible for maintaining the confidentiality of the password, and are fully
              responsible for all activities that occur under your password. You must not disclose
              your password to any third party, nor allow any unauthorized person to access the
              Platform under your username and/or security password. You agree to (a) immediately
              notify Shell of any unauthorized use of your password or any other breach of security,
              and (b) ensure that you fully log off from the Platform at the end of each session.
              The Platform does not require you to change your password, however, Shell encourages
              you to periodically change your password. Shell cannot and will not be liable for any
              loss or damage arising from your failure to comply with this section.
            </p>
            <p>
              In the event of a data privacy incident or other data security breach, YOU AGREE THAT
              SHELL MAY PROVIDE YOU WITH ANY NOTICES REQUIRED UNDER APPLICABLE LAW, IF ANY, VIA THE
              Platform AND/OR THE E-MAIL PROVIDED IN CONNECTION WITH YOUR ACCOUNT. You retain the
              sole responsibility for monitoring your e-mail account and keeping your e-mail address
              on file up-to-date.{" "}
            </p>
          </li>
          <li>
            <h3>
              <b>
                <u>Disclaimer of Liability; Indemnity:</u>
              </b>
            </h3>
            <p>
              IN NO EVENT WILL SHELL OR ITS AFFILIATES, OR ANY OF ITS OR THEIR RESPECTIVE LICENSORS
              OR SERVICE PROVIDERS, BE LIABLE TO YOU FOR ANY USE OF, INTERRUPTION, DELAY OR
              INABILITY TO USE OR ACCESS THE PLATFORM, SERVICES OR ANY USER GENERATED CONTENT. TO
              THE MAXIMUM EXTENT PERMITTED BY LAW, SHELL AND ITS AFFILIATES WILL NOT BE LIABLE FOR
              ANY (A) SPECIAL, INDIRECT OR CONSEQUENTIAL LOSS, COST, EXPENSE OR DAMAGE, (B) ACTUAL
              DAMAGES OR LOSS OF ACTUAL OR ANTICIPATED PROFIT, SALES, SAVINGS, USE, BUSINESS,
              BUSINESS OPPORTUNITY OR REVENUE, (C) LOSSES CAUSED BY BUSINESS INTERRUPTION; (D) LOSS
              OR CORRUPTION OF DATA, INFORMATION OR PLATFORM; OR (E) DAMAGE TO GOODWILL ARISING OUT
              OF OR IN CONNECTION WITH ANY USE OF THE PLATFORM, THE SERVICES, USER GENERATED
              CONTENT, OR THIS EULA, WHETHER AT COMMON LAW, UNDER CONTRACT, TORT (INCLUDING
              NEGLIGENCE), IN EQUITY, PURSUANT TO STATUTE OR OTHERWISE, EVEN IF SUCH COST, EXPENSE,
              LOSS OR DAMAGE WAS REASONABLY FORESEEABLE OR MIGHT REASONABLY HAVE BEEN CONTEMPLATED
              BY YOU AND/OR SHELL .
            </p>
            <p>
              SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL
              DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU. YOU MAY ALSO HAVE
              OTHER RIGHTS, WHICH VARY FROM STATE TO STATE.
            </p>
            <p>
              To the fullest extent permissible by applicable law, you shall indemnify, defend, and
              hold harmless Shell, from and against any and all claims, liabilities, costs, losses,
              damages and expenses (including, without limitation, legal fees and disbursements) of
              every kind and nature resulting from or in connection with your use of the Platform,
              the Services, any User Generated Content, or for your reliance on any other
              information or advice contained on it.{" "}
            </p>
          </li>
          <li>
            <h3>
              <b>
                <u>Confidentiality:</u>
              </b>
            </h3>
            <p>
              You acknowledge and agree that the Services and information contained in or provided
              via the Platform are confidential to Shell. You agree to maintain such Services and
              information and advice in strict confidence and not to disclose or provide access
              thereto to any unauthorized person or entity.
            </p>
          </li>
          <li>
            <h3>
              <b>
                <u>External Websites and Applications:</u>
              </b>
            </h3>
            <p>
              The Platform may include links to external websites or applications. When you follow
              such links the external website or application may appear as a full screen (in which
              case you will need to use the device button to return to this Platform) or in some
              cases it may appear within the frame of this Platform (in which case you will be able
              to return to this Platform by using the navigation buttons within the frame of this
              Platform). These links are provided in order to help you find relevant websites,
              applications, services and/or products which may be of interest to you quickly and
              easily. It is your responsibility to decide whether any services and/or products
              available through any of these websites or applications are suitable for your
              purposes. Shell is not responsible for the owners or operators of these applications
              or websites or for any goods or services they supply or for the content of their
              websites or applications and does not give or enter into any conditions, warranties or
              other terms or representations in relation to any of these or accept any liability in
              relation to any of these (including any liability arising out of any claim that the
              content of any external website or application to which this Platform includes a link
              infringes the intellectual property rights of any third party).
            </p>
          </li>
          <li>
            <h3>
              <b>
                <u>Export Regulation:</u>
              </b>
            </h3>
            <p>
              Access to the Platform and Services is restricted and controlled by Shell’s policies,
              which govern activities that involve Generally Embargoed Countries and Highly
              Restricted Countries. By accepting the terms of this EULA, you agree that you are not
              accessing, or will not access, the Platform from any of the listed Generally Embargoed
              Countries or Highly Restricted Countries. Countries classified by Shell or its
              affiliates as Generally Embargoed or Highly Restricted are: Cuba, Iran, Sudan, Syria,
              North Korea and Belarus. Should you require access to the Platform from any of the
              listed countries, contact us prior to attempting access to the Platform, using the
              information under ‘Contact Us’ below. By using this Platform, you confirm that you are
              not listed on any U.S. government list of prohibited or restricted parties.
            </p>
            <p>
              The Platform may be subject to US export control laws, including the US Export
              Administration Act and its associated regulations. You shall not, directly or
              indirectly, export, re-export or release the Platform to, or make the Platform or
              Services accessible from, any jurisdiction or country to which export, re-export or
              release is prohibited by law, rule or regulation. You shall comply with all applicable
              federal laws, regulations and rules, and complete all required undertakings (including
              obtaining any necessary export license or other governmental approval), prior to
              exporting, re-exporting, releasing or otherwise making the Platform available outside
              the US.
            </p>
          </li>
          <li>
            <h3>
              <b>
                <u>Governing Law:</u>
              </b>
            </h3>
            <p>
              This EULA shall be governed by and construed in accordance with the internal laws of
              the State of Texas without giving effect to any choice or conflict of law provision or
              rule (whether of the State of Texas or any other jurisdiction) that would cause the
              application of Laws of any jurisdiction other than those of the State of Texas. Texas
              courts will have exclusive jurisdiction over any dispute arising in relation to this
              EULA and you waive any claims of forum non-conveniens. By your acceptance of this
              EULA, you agree that any claims or disputes shall be brought only on an individual
              basis and not as a plaintiff or class member in any purported class. You also waive
              any right to a jury trial.
            </p>
          </li>
          <li>
            <h3>
              <b>
                <u>Not Authorized to Do Business in Every Jurisdiction:</u>
              </b>
            </h3>
            <p>
              Shell is not authorized to do business in every jurisdiction. Information published in
              this Platform may contain references or cross-references to goods or services that are
              not available in your state or country.
            </p>
          </li>
          <li>
            <h3>
              <b>
                <u>Assignment:</u>
              </b>
            </h3>
            <p>
              We may transfer our rights and obligations connected with the Platform to another
              organization, but this will not affect your rights or our obligations under this EULA.
              You may only transfer your rights or your obligations under this EULA to another
              person with our prior consent in writing.
            </p>
          </li>
          <li>
            <h3>
              <b>
                <u>Waiver:</u>
              </b>
            </h3>
            <p>
              If we fail to insist that you perform any of your obligations under this EULA, or if
              we do not enforce our rights against you, or if we delay in doing so, that will not
              mean that we have waived our rights against you and will not mean that you do not have
              to comply with those obligations. If we do waive a default by you, we will only do so
              in writing, and that will not mean that we will automatically waive any later default
              by you.
            </p>
          </li>
          <li>
            <h3>
              <b>
                <u>Entire Agreement; Severability:</u>
              </b>
            </h3>
            <p>
              You acknowledge that you have read and understood this EULA and that you agree to be
              bound by its terms and conditions.You further agree that this EULA, together with the
              Privacy Policy, which is hereby incorporated into this EULA by reference, constitute
              the complete and exclusive statement of the agreement between you and Shell and
              supersedes all other proposals or prior agreements oral or written, and any other
              communications relating to the subject matter of this EULA. If any provision of this
              EULA is found unenforceable, it shall not affect the validity of this EULA, which
              shall remain valid and enforceable according to its terms.
            </p>
          </li>
        </ol>
      </div>
    )
  }
} // class PrivacyPolicy

export default PrivacyPolicy
