import * as arrayHelpers from "../../helpers/arrayHelpers"
import { preparedMaintenanceHistories } from "../../helpers/maintenanceHistoryHelpers"

// NOTE: Use of vehicle(s)DataType prevents components that require extended data from trying to render stale "standard" data from the store and crashing.
// NOTE: vehicle(s)DataType:
// 'standard' => Loaded without "all" option (vehicles index)
// 'extended' => Loaded with "all" option (dashboard)

export const initialState = {
  vehicle: {},
  vehicles: [],
  vehicleDataType: "",
  vehiclesDataType: ""
}

export default function (state = initialState, action) {
  const { payload, type } = action

  switch (type) {
    case "SET_VEHICLE_DATA_TYPE":
      return {
        ...state,
        vehicleDataType: payload.dataType
      }
    case "SET_VEHICLES_DATA_TYPE":
      return {
        ...state,
        vehiclesDataType: payload.dataType
      }
    case "VEHICLE_CREATE_SUCCEEDED":
      return {
        ...state,
        vehicles: arrayHelpers.append(state.vehicles, payload.vehicle)
      }
    case "VEHICLE_DELETE_SUCCEEDED":
      return {
        ...state,
        vehicles: arrayHelpers.removeObjectById(state.vehicles, payload.vehicleId)
      }
    case "VEHICLE_LOAD_SUCCEEDED":
      return {
        ...state,
        vehicle: payload.vehicle
      }
    case "VEHICLE_UPDATE_SUCCEEDED":
      return {
        ...state,
        vehicles: arrayHelpers.replaceObjectById(
          state.vehicles,
          payload.vehicle,
          payload.vehicle.id
        )
      }
    case "VEHICLES_LOAD_SUCCEEDED":
      return {
        ...state,
        vehicles: payload.vehicles
      }
    case "VEHICLE_MAINTENANCE_HISTORIES_LOAD_SUCCEEDED":
      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          manualMaintenanceHistories: preparedMaintenanceHistories(payload.maintenanceHistories)
        }
      }
    case "VEHICLE_MAINTENANCE_HISTORY_DELETE_SUCCEEDED":
      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          manualMaintenanceHistories: arrayHelpers.removeObjectById(
            state.vehicle.manualMaintenanceHistories,
            payload.manualMaintenanceHistory.id
          )
        }
      }
    case "YEARS_LOAD_SUCCEEDED":
      return {
        ...state,
        years: payload.years
      }
    case "MAKES_LOAD_SUCCEEDED":
      return {
        ...state,
        makes: payload.makes
      }
    case "MODELS_LOAD_SUCCEEDED":
      return {
        ...state,
        models: payload.models
      }
    case "ENGINES_LOAD_SUCCEEDED":
      return {
        ...state,
        engines: payload.engines
      }
    default:
      return state
  }
}
