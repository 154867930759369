import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import moment from "moment"
import { sortBy } from "lodash"
import CollapsibleList from "../shared/CollapsibleList"
import * as arrayHelpers from "../../helpers/arrayHelpers"
import { isOwnerManagerOrAdmin } from "../../helpers/activeMaintenanceHelpers"
import { Button, Grid, Header, Divider } from "semantic-ui-react"
import TextField from "../../elements/TextField"
import LoadingThrobber from "../shared/LoadingThrobber"
import { withTranslation } from "react-i18next"

export function GeotabDatabaseUrlDescription({ t }) {
  return (
    <p>
      {t("exampleUrlDesc")}
      <span style={{ textDecoration: "underline" }}>
        https://www101.telematics.shell.com/my_database_name/
      </span>
      ,{t("exampleUrlDesc1")} <strong>{t("exampleUrlDesc2")}</strong>
    </p>
  )
}

class GeotabTelematicsData extends Component {
  static propTypes = {
    isSubmitting: PropTypes.bool,
    onDelete: PropTypes.func.isRequired,
    onSubmitDatabaseName: PropTypes.func.isRequired,
    currentUser: PropTypes.object,
    geotabSyncEvents: PropTypes.array,
    fleet: PropTypes.object
  }

  static defaultProps = {
    allowActions: true,
    isSubmitting: false,
    displayButtons: true
  }

  constructor(props) {
    super(props)

    const { fleet } = props
    this.state = {
      isEditing: (fleet.geotab_database_name || "").length === 0,
      databaseName: fleet.geotab_database_name
    }
  }

  componentDidMount() {
    this.props.dispatch({ type: "LOAD_GEOTAB_SYNC_EVENTS_SAGA" })
  }

  handleClickCancel = () => {
    const { fleet } = this.props
    this.setState({ databaseName: fleet.geotab_database_name, isEditing: false })
  }

  handleClickDelete = () => {
    const { onSubmitDatabaseName, t } = this.props
    const shouldDelete = window.confirm(t("common:deleteAlertLabel"))
    if (shouldDelete) {
      this.setState({ databaseName: "" }, () => {
        onSubmitDatabaseName("")
      })
    }
  }

  renderActions() {
    const { fleet, onSubmitDatabaseName, t } = this.props
    const { isEditing, isSubmitting, databaseName } = this.state
    if (isEditing) {
      return (
        <>
          <span
            className="link"
            disabled={!isSubmitting}
            onClick={() => onSubmitDatabaseName(databaseName)}
          >
            {t("common:saveLabel")}
          </span>
          &nbsp;&nbsp;
          <span className="link" onClick={this.handleClickCancel}>
            {t("cancelLabel")}
          </span>
        </>
      )
    } else {
      return (
        <>
          <span className="link" onClick={() => this.setState({ isEditing: true })}>
            {t("editLabel")}
          </span>
          &nbsp;&nbsp;
          <span className="link" onClick={this.handleClickDelete}>
            {t("deleteLabel")}
          </span>
        </>
      )
    }
  }

  render() {
    const {
      geotabSyncEvents,
      onCreateGeotabSyncEvent,
      isLoading,
      onSubmitDatabaseName,
      isSubmitting,
      fleet,
      currentUser,
      t
    } = this.props
    const { geotab_database_name } = fleet
    const hasDatabaseName = (geotab_database_name || "").length > 0
    const { isEditing, databaseName } = this.state

    return (
      <React.Fragment>
        <Divider hidden />
        {isLoading || isSubmitting ? (
          <LoadingThrobber visible noMargin />
        ) : (
          <>
            <Header as="h3">{t("shellGeoTabLabel")}</Header>
            <p>{t("shellGeoTabDesc")}</p>
            <p>{t("shellGeoTabDesc1")}</p>
            <p>{t("shellGeoTabDesc2")}</p>
            <Header as="h3">{t("databaseNameLabel")}</Header>
            <GeotabDatabaseUrlDescription t={t} />

            {/* Set the link once we receive from Blake at Shell */}
            {/*!hasDatabaseName && <span className="link">Why do we need this?</span>*/}

            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ flex: 1 }}>
                <TextField
                  onChange={(event) => {
                    // Don't allow whitespace or slashes
                    this.setState({ databaseName: event.target.value.replaceAll(/[\/\s]/g, "") })
                  }}
                  value={databaseName}
                  disabled={!isEditing}
                />
              </div>
              <div style={{ flex: 2, paddingLeft: "10px" }}>
                {isOwnerManagerOrAdmin(currentUser) && this.renderActions()}
              </div>
            </div>
            {!hasDatabaseName && (
              <div style={{ paddingTop: "10px" }}>
                <Button
                  onClick={() => onSubmitDatabaseName(databaseName)}
                  disabled={isSubmitting}
                  size="tiny"
                >
                  {t("addDatabaseLabel")}
                </Button>
              </div>
            )}

            <Header as="h3">{t("recentVehicleSyncsLabel")}:</Header>
            <div style={{ paddingBottom: 10 }}>
              {geotabSyncEvents.length > 0 ? (
                <CollapsibleList
                  items={sortBy(geotabSyncEvents, "id")
                    .reverse()
                    .map((event) => {
                      return `${moment(event.created_at).format("YYYY-MM-DD, h:mma")}: ${
                        event.status
                      }`
                    })}
                  applySort={false}
                />
              ) : (
                <em>{t("noSyncEventsLabel")}</em>
              )}
            </div>

            {hasDatabaseName && (
              <Button onClick={onCreateGeotabSyncEvent} disabled={isSubmitting}>
                {t("syncDatabaseLabel")}
              </Button>
            )}
          </>
        )}
      </React.Fragment>
    )
  }
} // class GeotabTelematicsData

const mapStateToProps = (state) => ({
  fleet: state.fleets.fleet,
  currentUser: state.users.currentUser,
  isLoading: state.geotabSyncEvents.isLoading,
  geotabSyncEvents: state.geotabSyncEvents.events
})

export default connect(mapStateToProps)(withTranslation("settings")(GeotabTelematicsData))
