import React, { Component } from "react"
import { connect } from "react-redux"

import { navigateTo } from "../../helpers/navigationHelpers"

import Alert from "../../components/shared/Alert"
import NoSessionLayout from "../../layouts/NoSessionLayout"
import LoadingThrobber from "../../components/shared/LoadingThrobber"
import SignUpForm from "../../components/sessions/SignUpForm"

import { FLEET } from "../../constants/application"

class SessionNewSignup extends Component {
  constructor(props) {
    super(props)
    const locationStateData = this.props.location.state || {}
    this.state = {
      alertMessage: locationStateData.alertMessage || "",
      alertType: locationStateData.alertType || "default",
      isSubmitting: false
    } //state

    this.navigateTo = navigateTo.bind(this)
    this.navigateToSignIn = navigateTo.bind(this, "signIn")
    this.navigatePrivacyPolicy = navigateTo.bind(this, "privacyPolicy")
    this.onFormSubmit = this.onFormSubmit.bind(this)

    const path = locationStateData.path

    this.state.redirectPath = path
  } //constructor

  componentDidMount() {
    const url = new URL(window.location.href)
    const params = new URLSearchParams(url.search)
    const message = params.get("error_message")

    if (message) this.setState({ alertMessage: message, alertType: "error" })
  } //componentDidMount

  onFormSubmit(formData) {
    this.setState({ isSubmitting: true })
    this.props.dispatch({
      type: "SIGN_UP_SAGA",
      payload: { formData: formData },
      callback: this.afterFormSubmit.bind(this)
    })
  } //onFormSubmit

  afterFormSubmit(status, data) {
    this.setState({ isSubmitting: false })

    if (status === "success") {
      this.onFormSubmitSuccess(data)
    } else {
      this.onFormSubmitFailure(data)
    }
  } //afterFormSubmit

  onFormSubmitSuccess(data) {
    console.log("----------", this.props.isFleetMode)
    if (this.state.redirectPath) this.props.history.push({ pathname: this.state.redirectPath })
    else if (data.roles.includes("FMC")) {
      if (this.props.isFleetMode)
        this.props.dispatch({
          type: "FMC_FLIP_SAGA",
          callback: null
        })
      this.navigateTo("fmcDashboard")
    } else {
      if (!this.props.isFleetMode)
        this.props.dispatch({
          type: "FMC_FLIP_SAGA",
          callback: null
        })
      this.navigateTo("dashboard")
    }
  }

  onFormSubmitFailure(data) {
    this.setState({
      alertMessage: data.alertMessage || "",
      alertType: data.alertType
    })
  }

  render() {
    const { alertMessage, alertType, isSubmitting } = this.state
    const { dispatch, userSelectedLanguage } = this.props
    return (
      <NoSessionLayout page="signupPage">
        <div className={"no-session-content"} style={{ minWidth: "49.4%" }}>
          <Alert message={alertMessage} type={alertType} visible={alertMessage !== ""} />

          <LoadingThrobber visible={isSubmitting} />
          {!isSubmitting && (
            <SignUpForm
              isSubmitting={isSubmitting}
              onSubmit={this.onFormSubmit}
              navigateToSignIn={this.navigateToSignIn}
              navigatePrivacyPolicy={this.navigatePrivacyPolicy}
              userSelectedLanguage={userSelectedLanguage}
              dispatch={dispatch}
            />
          )}
        </div>
      </NoSessionLayout>
    ) //return
  } //render
} //class SessionNewSignup

const mapStateToProps = (state) => ({
  isFleetMode: state.application.mode === FLEET || state.application.mode === undefined,
  userSelectedLanguage: state.users ? state.users.userSelectedLanguage : ""
})

export default connect(mapStateToProps)(SessionNewSignup)
