import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { navigationPaths } from "../../constants/paths"
import { getSubdomain, getStyles } from "../shared/StylesheetInjector"
import { faArrowLeft } from "@fortawesome/pro-light-svg-icons"
import HeaderLinks from "./HeaderLinks"
import { Dropdown, Menu, Header, Responsive, Checkbox } from "semantic-ui-react"
import { FLEET, FMC } from "../../constants/application"
import { isFmcPath } from "../../helpers/pathHelpers"
import { FMC_FLEET_ADMIN, FMC_FLEET_MANAGER } from "../../constants/roles"
import { isEqual } from "lodash"
import { CANDA_COUNTRY_CODE, EN_LOCALE, FR_LOCALE } from "../../constants/users"
import { isFleetMaintenanceHubCanada } from "../../helpers/affiliationHelpers"

import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"
import { setLanguage } from "../../helpers/translationHelpers"

const styles = getStyles()

class SiteHeader extends Component {
  state = { toggle: false }

  toggleButton = async () => {
    const { currentUser, dispatch } = this.props
    const language = this.state.toggle ? EN_LOCALE : FR_LOCALE
    this.setState((prevState) => ({ toggle: !prevState.toggle }))
    dispatch({ type: "SET_USER_LANGUAGE", payload: { language } })
    setLanguage(language, CANDA_COUNTRY_CODE)
    await dispatch({
      type: "USER_CREATE_OR_UPDATE_SAGA",
      payload: { ...currentUser, ...{ language } },
      callback: this.afterToggle
    })
  }

  afterToggle = () => {
    const { dispatch, match, vehicle } = this.props
    if (match.path === "/vehicles/:id") {
      dispatch({
        type: "NEXT_MAINTENANCE_INTERVAL_SAGA",
        payload: { vehicleIds: [vehicle.id] }
      })
    }
    if (match.path === "/orders/:id/approvals") {
      dispatch({
        type: "SHOP_ORDER_LOAD_PREVIEW",
        payload: {
          priceAsApprovalsLogic: true,
          shopOrderId: Number(match.params.id)
        }
      })
    }
    dispatch({
      type: "SERVICE_REQUEST_GET_ALL"
    })
  }

  static propTypes = {
    fleet: PropTypes.object.isRequired
  }

  shouldLoadFleetData() {
    const { fleet } = this.props
    return fleet.constructor === Object && Object.keys(fleet).length === 0
  }

  componentDidMount() {
    const { currentUser = {}, dispatch, isFMC } = this.props
    if (this.shouldLoadFleetData()) {
      dispatch({
        type: "FLEET_LOAD_SAGA",
        payload: { fleetId: currentUser.fleet_id }
      })
    }
    if (isFMC === undefined) {
      dispatch({
        type: "CURRENT_USER_LOAD_SAGA",
        payload: {}
      })
    }
    currentUser.language == EN_LOCALE
      ? this.setState({ toggle: false })
      : this.setState({ toggle: true })
  }

  componentDidUpdate(prevProps) {
    const { currentUser } = this.props
    if (!isEqual(prevProps.currentUser, currentUser)) {
      currentUser.language == EN_LOCALE
        ? this.setState({ toggle: false })
        : this.setState({ toggle: true })
    }
  }

  flipFMC = () => {
    this.props.dispatch({
      type: "FMC_FLIP_SAGA",
      payload: { mode: FMC }
    })
  }

  isNotFleetAmerica = () => {
    const sub = getSubdomain()
    return sub != "fleetamerica"
  }

  clickChild = (e) => e.target.children[0] && e.target.children[0].click()

  render() {
    const { fleet, isFMC, isFleetMode, currentUser = {}, t } = this.props
    const toggle = this.state.toggle

    const currentUserName = [currentUser.firstName, currentUser.lastName].filter((a) => a).join(" ")
    const shortened = currentUserName
      .split(" ")
      .map((s) => (s[0] ? s[0].toUpperCase() : null))
      .join("")
      .slice(0, 2)

    return (
      <Menu borderless size="massive" className="borderless-outline site-header">
        {isFleetMode && fleet && <Menu.Item className="fleet-label">{fleet.name}</Menu.Item>}
        <Menu.Menu position="right">
          {isFleetMaintenanceHubCanada && (
            <Menu.Item>
              EN &nbsp;
              <Checkbox toggle checked={toggle} onClick={this.toggleButton} />
              &nbsp; FR
            </Menu.Item>
          )}
          {isFMC && (isFleetMode || !isFmcPath()) && (
            <Menu.Item>
              <Link
                style={{ color: styles.primaryColor }}
                to={navigationPaths.fmcDashboard()}
                onClick={this.flipFMC}
              >
                <FontAwesomeIcon icon={faArrowLeft} />
                &nbsp; {t("backToFmcLabel")}
              </Link>
            </Menu.Item>
          )}
          <Dropdown className="circle" item text={<span>{shortened}</span>} icon={null}>
            <Dropdown.Menu>
              <Dropdown.Header>
                <Header>{currentUserName}</Header>
              </Dropdown.Header>
              <Responsive maxWidth={990}>
                <Dropdown.Item onClick={this.clickChild}>
                  <Link to={navigationPaths.dashboard()}>Dashboard</Link>
                </Dropdown.Item>
                <Dropdown.Item onClick={this.clickChild}>
                  <Link to={navigationPaths.vehicleIndex()}>Vehicles</Link>
                </Dropdown.Item>
                <Dropdown.Item onClick={this.clickChild}>
                  <Link to={navigationPaths.servicesIndex()}>Services</Link>
                </Dropdown.Item>
                <Dropdown.Item onClick={this.clickChild}>
                  <Link to={navigationPaths.shopIndex()}>Shops</Link>
                </Dropdown.Item>
                <Dropdown.Item onClick={this.clickChild}>
                  <Link to={navigationPaths.settingsIndex()}>Settings</Link>
                </Dropdown.Item>
                {isFMC && (
                  <Dropdown.Item onClick={this.clickChild}>
                    <Link to={navigationPaths.fmcDashboard()}>FMC Dashboard</Link>
                  </Dropdown.Item>
                )}
              </Responsive>
              <Dropdown.Item onClick={this.clickChild}>
                <Link to={navigationPaths.userPreferences()}>{t("userPreferencesLabel")}</Link>
              </Dropdown.Item>
              {this.isNotFleetAmerica() && (
                <Dropdown.Item onClick={this.clickChild}>
                  <Link to={navigationPaths.membershipCard()}>{t("membershipCardLabel")}</Link>
                </Dropdown.Item>
              )}
              <Dropdown.Item onClick={this.clickChild}>
                <Link to={navigationPaths.signOut()}>{t("signOutLabel")}</Link>
              </Dropdown.Item>
              {fleet.fleet_links && fleet.fleet_links.length > 0 && <Dropdown.Divider />}
              <HeaderLinks fleetLinks={fleet.fleet_links} />
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Menu>
      </Menu>
    )
  }
} // class SiteHeader

const mapStateToProps = (state) => ({
  currentUser: state.users.currentUser,
  fleet: state.fleets.fleet,
  isFleetMode: state.application.mode === FLEET && !isFmcPath(),
  isFMC:
    state.users &&
    state.users.currentUser &&
    state.users.currentUser.roles &&
    state.users.currentUser.roles.find(
      (role) => role === "FMC" || role === FMC_FLEET_ADMIN || role === FMC_FLEET_MANAGER
    ),
  vehicle: state.vehicles.vehicle
})

export default connect(mapStateToProps)(withRouter(withTranslation("siteHeader")(SiteHeader)))
