import React, { Component } from "react"
import ActionCable from "actioncable"
import * as sessionHelpers from "../../helpers/sessionHelpers"
import api from "../../utilities/api"

function withWebSocketConnection(WrappedComponent, channelName) {
  return class extends Component {
    constructor(props) {
      super(props)

      if (!window.App)
        window.App = {
          listeners: {}
        }
    }

    componentDidMount() {
      const currentUserId = sessionHelpers.getCookie("fleetmd_current_user_id")
      const authToken = sessionHelpers.getCookie("fleetmd_authentication_token")

      const protocol = process.env.NODE_ENV === "development" ? "ws" : "wss"
      const url = `${protocol}://${api.apiUrl}/cable?user_id=${currentUserId}&authentication_token=${authToken}`

      if (window.App && !window.App.cable) {
        window.App.cable = ActionCable.createConsumer(url)
        window.App.cable.subscriptions.create(channelName, {
          connected: () => {},
          disconnected: () => {},
          received: (data) => {
            window.App.listeners[channelName].forEach((wrappedComponentInstance) => {
              if (wrappedComponentInstance && wrappedComponentInstance.onDataReceived) {
                wrappedComponentInstance.onDataReceived(data)
              }
            })
          }
        })
      }
    }

    proc(wrappedComponentInstance) {
      // NOTE: For a wrapped component to do stuff with data received via websocket,
      // the wrapped component MUST implement an instance method called onDataReceived.

      if (wrappedComponentInstance && wrappedComponentInstance.onDataReceived) {
        if (!window.App.listeners[channelName]) {
          window.App.listeners[channelName] = []
        }
        window.App.listeners[channelName].push(wrappedComponentInstance)
      } else if (wrappedComponentInstance && !wrappedComponentInstance.onDataReceived) {
        throw "WrappedComponent must implement method onDataReceived"
      }
    }

    render() {
      return <WrappedComponent {...this.props} ref={this.proc.bind(this)} />
    }
  }
}

export default withWebSocketConnection
