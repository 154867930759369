import * as arrayHelpers from "../../helpers/arrayHelpers"

export const initialState = {
  user: {},
  userRoles: [],
  users: [],
  shellNewUser: {},
  currentUser: {}
}

export default function (state = initialState, action) {
  const { payload, type } = action

  switch (type) {
    case "USER_CREATE_SUCCEEDED":
      return {
        ...state,
        user: payload.user,
        users: arrayHelpers.prepend(state.users, payload.user)
      }
    case "USER_DELETE_SUCCEEDED":
      return {
        ...state,
        users: arrayHelpers.removeObjectById(state.users, payload.user.id)
      }
    case "USER_ROLES_LOAD_SUCCEEDED":
      return {
        ...state,
        userRoles: payload.userRoles
      }
    case "USER_UPDATE_SUCCEEDED":
      return {
        ...state,
        users: arrayHelpers.replaceObjectById(state.users, payload.user, payload.user.id)
      }
    case "USER_LOAD_SUCCEEDED":
      return {
        ...state,
        user: payload.user
      }
    case "CURRENT_USER_LOAD_SUCCEEDED":
      return {
        ...state,
        currentUser: { ...state.currentUser, ...payload.currentUser }
      }
    case "CURRENT_USER_LOAD_PAYMENT_METHODS_SUCCEEDED":
      const userWithData = {
        ...state.currentUser,
        ...payload.data
      }

      return {
        ...state,
        currentUser: userWithData
      }
    case "USERS_LOAD_SUCCEEDED":
      const id = payload.currentUserId
      return {
        ...state,
        users: payload.users
      }
    case "FMC_USERS_LOAD_SUCCEEDED":
      return {
        ...state,
        fmcUsers: payload.fmcUsers
      }
    case "SHELL_NEW_USER_FORM":
      return {
        ...state,
        shellNewUser: payload.data
      }

    case "SET_USER_LANGUAGE":
      return {
        ...state,
        userSelectedLanguage: payload.language
      }

    default:
      return state
  }
}
