import { MANUAL_MAINTENANCE_TYPE } from "../constants/services"
import { PAID, SERVICE_COMPLETED } from "../constants/statuses"

export function manualMaintenanceHistoriesWithVehicle(manualMaintenanceHistories, vehicles) {
  return manualMaintenanceHistories.map((history) => {
    const vehicle = (vehicles || []).find((vehicle) => vehicle.id === history.vehicle_id)
    history.vehicle = vehicle || {}
    return history
  })
}

export function preparedMaintenanceHistories(maintenanceHistories) {
  return maintenanceHistories.map((history) => {
    const orderServices = history.order_services || []
    const service = orderServices[0] || {}
    return {
      ...history,
      maintenance_type: MANUAL_MAINTENANCE_TYPE,
      status: PAID,
      miles: (service.odometer_config || {}).quantity,
      order_services: orderServices.map((service) => ({ ...service, status: SERVICE_COMPLETED }))
    }
  })
}
