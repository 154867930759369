import React, { Component } from "react"
import PropTypes from "prop-types"
import { Modal, Button, Header, Segment, Form, Message, Divider } from "semantic-ui-react"
import * as stringHelpers from "../../helpers/stringHelpers"
import SpecializedFormInput from "./SpecializedFormInput"
import { withTranslation } from "react-i18next"
import { isEqual } from "lodash"

class EditForm extends Component {
  static propTypes = {
    getNewFormState: PropTypes.func.isRequired,
    editFields: PropTypes.object,
    allFields: PropTypes.object.isRequired,
    saga: PropTypes.string.isRequired,
    object: PropTypes.object.isRequired,
    objectName: PropTypes.string.isRequired,
    completionCallback: PropTypes.func.isRequired,
    asModal: PropTypes.bool
  }

  static defaultProps = {
    object: {},
    objectName: "Record",
    completionCallback: () => {}
  }

  constructor(props) {
    super(props)

    this.resetState()

    this.handleSaveOnEnter = this.handleSave.bind(this, { isOnEnterCreate: false, validate: false })
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps, this.props)) {
      const state = this.state || {}
      if (this.props.object.id)
        state.steps = this.props.editFields ? this.props.editFields : this.props.allFields
      else state.steps = this.props.allFields

      if (!this.state) this.state = state
      else this.setState(state)
    }
  }

  resetState() {
    let state = this.state || {}

    state = {
      formData: this.props.getNewFormState(this.props.object),
      errors: {},
      submitErrors: [],
      currentStep: 1
    }

    if (this.props.object.id)
      state.steps = this.props.editFields ? this.props.editFields : this.props.allFields
    else state.steps = this.props.allFields

    if (!this.state) this.state = state
    else this.setState(state)
  }

  getAllFields = () => {
    let allFields = []
    const steps = this.state.steps
    Object.keys(steps).forEach((step) => (allFields = allFields.concat(steps[step])))
    return allFields
  }

  onFieldChange = async (field, possibleMomentObject, event) => {
    let updatedFormData = { ...this.state.formData }

    if (possibleMomentObject._isAMomentObject)
      updatedFormData[field.fieldName] = possibleMomentObject.format()
    else if (event.type === "checkbox")
      if (typeof updatedFormData[field.fieldName] === "object") {
        updatedFormData[field.fieldName][event.name].value = event.checked
      } else updatedFormData[field.fieldName][event.name] = event.value
    else updatedFormData[field.fieldName] = event.value

    let allFields = this.getAllFields()

    const dependentFields = allFields.filter(
      (f) =>
        f.field !== field.fieldName && f.dependent && f.dependent.indexOf(field.fieldName) !== -1
    )

    dependentFields.map((f) => (updatedFormData[f.field] = null))

    await Promise.all(
      dependentFields.map((f) => f.callback(...f.dependent.map((d) => updatedFormData[d])))
    )

    this.setState({ formData: updatedFormData })
  }

  onEnterCreateCall = async (field, event) => {
    if (event.key === "Enter" && !this.state.formData.id && field.onEnterCreateCall) {
      this.handleSaveOnEnter()
    }
  }

  handleOpen = async () => {
    this.setState({ modalOpen: true, loading: true, currentStep: 1 })
    const { formData } = this.state
    const allFields = this.getAllFields()
    const independentFields = allFields.filter((f) => !f.dependent && f.callback)
    const completedDependentFields = allFields.filter(
      (f) =>
        f.dependent &&
        f.dependent.filter((dependentOn) => formData[dependentOn] === null).length === 0
    )
    const tasks = [
      ...independentFields.map((f) => f.callback()),
      ...completedDependentFields.map((f) => f.callback(...f.dependent.map((d) => formData[d])))
    ]
    await Promise.all(tasks)
    // Dont judge me, dick. setState loading false causes a re-render way before the props update happens from redux.
    // This makes a nice little animation that people appreciate!
    setTimeout(() => this.setState({ loading: false }), this.props.object.id ? 1000 : 0)
  }

  handleClose = () => {
    this.setState({ modalOpen: false })
    this.resetState()
  }

  handleSave = async ({ isOnEnterCreate = true, validate = true }) => {
    if (validate) {
      const errors = this.validate()
      if (Object.keys(errors).length > 0) {
        this.setState({ errors })
        return
      }
    }

    this.setState({ loading: true })

    let formData = { ...this.state.formData }
    //Transform text_notification_preferences nested checkbox data type back into normal state
    Object.keys(formData).forEach((k) => {
      if (typeof formData[k] === "object" && !Array.isArray(formData[k])) {
        formData[k] = { ...formData[k] }
        Object.keys(formData[k]).forEach(
          (checkboxInput) => (formData[k][checkboxInput] = formData[k][checkboxInput].value)
        )
      }
    })

    let afterRequestData = await this.props.dispatch({
      type: this.props.saga,
      payload: formData,
      callback: this.afterFormSubmit.bind(this, isOnEnterCreate)
    })
  }

  afterFormSubmit = async (isOnEnterCreate, successState, data) => {
    if (data.error || data.errors || (data.alertMessage && data.alertType === "error"))
      this.setState({
        loading: false,
        submitErrors: [
          !isOnEnterCreate
            ? {
                message: this.props.t("vehicle:vinValidationLabel"),
                header: this.props.t("vehicle:vinLookUpValidationLabel")
              }
            : {
                message: data.error || data.errors || data.alertMessage,
                header: "Submission Failed"
              }
        ],
        currentStep: 1
      })
    else {
      if (!isOnEnterCreate) {
        await this.setState({
          formData: this.props.getNewFormState(data.vehicle),
          submitErrors: []
        })
        this.handleOpen()
      } else {
        this.setState({ loading: false, modalOpen: false })
        this.props.completionCallback()
      }
    }
  }

  validateEmail(str, fieldName, formData, t) {
    return !str || stringHelpers.isEmail(str)
      ? { success: true }
      : { error: t("emailValidationLabel") }
  }

  validatePhone(str, fieldName, formData, t) {
    return !str || stringHelpers.isPhoneNumber(str)
      ? { success: true }
      : { error: t("phoneValidationLabel") }
  }

  validateZip(str, fieldName, formData, t) {
    return !str || stringHelpers.isZip(str)
      ? { success: true }
      : { error: t("zipCodeValidationLabel") }
  }

  validatePassword(str, fieldName, formData, t) {
    const otherField = fieldName.match(/confirmation/)
      ? fieldName.replace("_confirmation", "")
      : fieldName + "_confirmation"
    if (formData[otherField] != str) return { error: t("passwordMatchLabel") }
    else return { success: true }
  }

  validate = () => {
    const { steps, currentStep, formData } = this.state
    const fieldsChecking = steps[currentStep]
    let errors = {}
    fieldsChecking.forEach((field) => {
      const val = formData[field.fieldName]
      if (!field.optional && !val && val !== false)
        errors[field.fieldName] = this.props.t("mustSelectLabel")

      if (field.specialValidations)
        field.specialValidations.forEach((type) => {
          const validator = this["validate" + stringHelpers.capitalCase(type)]
          if (validator) {
            const result = validator(val, field.fieldName, formData, this.props.t)
            if (result.error) errors[field.fieldName] = result.error
          }
        })
    })
    return errors
  }

  handleNext = () => {
    const errors = this.validate()
    if (Object.keys(errors).length > 0) this.setState({ errors })
    else this.setState({ errors, currentStep: this.state.currentStep + 1 })
  }

  handleBack = () => {
    this.setState({ currentStep: this.state.currentStep - 1 })
  }

  renderForm() {
    const { object, objectName, t } = this.props
    const { steps, currentStep, formData, errors, loading, submitErrors } = this.state
    const anySubmitErrors = submitErrors.length > 0

    return (
      <React.Fragment>
        <Header textAlign="center" className="primary-color">
          {object.id ? t("editLabel") + `${objectName}` : t("addLabel") + `${objectName}`}
        </Header>
        <Form loading={loading} error={anySubmitErrors}>
          {submitErrors.map((err) => (
            <Message error={anySubmitErrors} header={err.header} content={err.message} />
          ))}
          {steps[currentStep].map((field, i) => (
            <SpecializedFormInput
              key={`${field.fieldName}-i`}
              formData={this.state.formData}
              field={field}
              onEnterCreateCall={this.onEnterCreateCall.bind(this, field)}
              onFieldChange={this.onFieldChange.bind(this, field)}
              outerProps={this.props /*For cache access*/}
              error={errors[field.fieldName]}
              t={t}
            />
          ))}
        </Form>
      </React.Fragment>
    )
  }

  renderButtons() {
    const { steps, currentStep } = this.state
    const final = steps[currentStep + 1] === undefined
    const first = currentStep === 1
    const { object, t } = this.props

    return (
      <React.Fragment>
        {!first && (
          <Button floated="left" onClick={this.handleBack}>
            {t("backLabel")}
          </Button>
        )}
        <Button onClick={final ? this.handleSave : this.handleNext}>
          {final ? (object.id ? t("saveLabel") : t("addLabel")) : t("nextLabel")}
        </Button>
      </React.Fragment>
    )
  }

  renderFlat() {
    return (
      <Segment>
        {this.renderForm()}
        <Divider hidden />
        {this.renderButtons()}
      </Segment>
    )
  }

  renderModal() {
    const { children } = this.props
    return (
      <Modal
        size="mini"
        trigger={
          React.Children.map(children, (c) =>
            React.cloneElement(c, { onClick: this.handleOpen })
          )[0]
        }
        open={this.state.modalOpen}
        closeIcon
        onClose={this.handleClose}
      >
        <Modal.Content>{this.renderForm()}</Modal.Content>
        <Modal.Actions>{this.renderButtons()}</Modal.Actions>
      </Modal>
    )
  }

  render() {
    if (this.props.asModal) return this.renderModal()
    else return this.renderFlat()
  }
} // class EditForm

export default withTranslation("common")(EditForm)
