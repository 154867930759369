import { combineReducers } from "redux"
import application from "./application"
import fleets from "./fleets"
import geotabSyncEvents from "./geotabSyncEvents"
import maintenanceHistories from "./maintenanceHistories"
import maintenancePolicies from "./maintenancePolicies"
import maintenanceIntervals from "./maintenanceIntervals"
import payments from "./payments"
import serviceRequests from "./serviceRequests"
import services from "./services"
import shops from "./shops"
import users from "./users"
import vehicles from "./vehicles"
import shopOrders from "./shopOrders"
import shopOrderActivityItems from "./shopOrderActivityItems"
import paymentMethods from "./paymentMethods"
import affiliations from "./affiliationsReducer"

const combinedReducer = combineReducers({
  /* NOTE: Add other reducers here */
  affiliations,
  application,
  fleets,
  geotabSyncEvents,
  maintenanceHistories,
  maintenancePolicies,
  payments,
  serviceRequests,
  services,
  shops,
  users,
  vehicles,
  shopOrders,
  shopOrderActivityItems,
  paymentMethods,
  maintenanceIntervals
})

export default combinedReducer
