import React, { Component } from "react"
import PropTypes from "prop-types"
import {
  SERVICE_COMPLETED,
  SERVICE_APPROVED,
  SERVICE_AWAITING_APPROVAL,
  SERVICE_INITIATED,
  SERVICE_REJECTED,
  SERVICE_DELETED
} from "../../constants/statuses"
import { MANUAL_MAINTENANCE_TYPE } from "../../constants/services"
import moment from "moment"
import * as numberHelpers from "../../helpers/numberHelpers"
import { Table } from "semantic-ui-react"

import ScheduleServiceButton from "../shared/ScheduleServiceButton"
import { isOwnerManagerOrAdmin } from "../../helpers/activeMaintenanceHelpers"
import { Date_Format } from "../../constants/years"
import { isFleetMaintenanceHubCanada } from "../../helpers/affiliationHelpers"
import { withTranslation } from "react-i18next"

class MaintenanceHistoryRecord extends Component {
  static propTypes = {
    record: PropTypes.object.isRequired
  }

  formattedDate(language) {
    const {
      record: { appointment_datetime = "" }
    } = this.props

    return isFleetMaintenanceHubCanada()
      ? moment(appointment_datetime).format(Date_Format.YYYY_MM_DD)
      : moment(appointment_datetime).format(Date_Format.MM_DD_YYYY)
  }

  formattedShopInfo() {
    const { shop, custom_shop_name } = this.props.record
    if (custom_shop_name) return custom_shop_name
    if (!shop) return ""

    return `${shop.name} ${shop.city}, ${shop.state}`
  }

  savedServices() {
    return this.props.record.order_services.filter(
      (os) => os.status === SERVICE_REJECTED || os.status === SERVICE_DELETED
    )
  }

  completedServices() {
    return this.props.record.order_services.filter((os) => {
      return (
        [SERVICE_APPROVED, SERVICE_AWAITING_APPROVAL, SERVICE_INITIATED, SERVICE_COMPLETED].indexOf(
          os.status
        ) !== -1
      )
    })
  }

  checkForActiveOrder(event) {
    const { active_order } = this.props.vehicle

    if (active_order) {
      event.preventDefault()
      alert(
        "There is already an active order for this vehicle. Please complete before you schedule."
      )
    }
  }

  onDelete = (recordId) => {
    const { id } = this.props.record
    const confirmed = window.confirm("Are you sure you want to delete?")

    if (confirmed) {
      this.props.dispatch({
        type: "MAINTENANCE_HISTORY_DELETE_SAGA",
        payload: {
          recordId: id
        },
        callback: this.props.afterDeleteMaintenanceHistory
      })
    }
  }

  render() {
    const {
      vehicle,
      record,
      t,
      currentUser: { language = "" }
    } = this.props

    const completed = this.completedServices()
    const saved = this.savedServices()
    const total = completed
      .reduce((acc, r) => (acc += parseFloat(r.total ? r.total : 0)), 0)
      .toFixed(2)
    let miles = record && record.miles ? record.miles : null
    if (!record.miles) {
      miles = record.vehicle.odometer_config ? record.vehicle.odometer_config.quantity : null
    }
    let kilometers =
      record && record.vehicle && record.vehicle.odometer_config
        ? record.vehicle.odometer_config.kilometers
        : null
    if (!kilometers) {
      const orderServiceIndex = record.order_services.length
        ? record.order_services.findIndex((service) => service.vehicle_id === record.vehicle_id)
        : -1
      kilometers =
        orderServiceIndex !== -1 && record.order_services[orderServiceIndex].odometer_config
          ? record.order_services[orderServiceIndex].odometer_config.kilometers
          : null
    }

    return (
      <Table.Row>
        <Table.Cell>{this.formattedDate(language)}</Table.Cell>
        <Table.Cell singeLine>{this.formattedShopInfo()}</Table.Cell>
        <Table.Cell>
          {isFleetMaintenanceHubCanada()
            ? numberHelpers.formatWithCommas(kilometers)
            : numberHelpers.formatWithCommas(miles)}
        </Table.Cell>
        <Table.Cell>{completed.map((service) => service.name).join(", ")}</Table.Cell>
        <Table.Cell>{saved.map((service) => service.name).join(", ")}</Table.Cell>
        <Table.Cell>{isFleetMaintenanceHubCanada() ? `$${total} CAD` : `$${total}`}</Table.Cell>
        <Table.Cell singleLine>
          {this.savedServices().length > 0 && (
            <ScheduleServiceButton
              vehicle={vehicle}
              maintenance={{ services: this.savedServices() }}
              text={t("scheduleSavedServicesLabel")}
            />
          )}
        </Table.Cell>
        {isOwnerManagerOrAdmin(this.props.currentUser) &&
          record.maintenance_type == MANUAL_MAINTENANCE_TYPE && (
            <Table.Cell>
              <span className="link" onClick={this.onDelete}>
                {t("deleteLabel")}
              </span>
            </Table.Cell>
          )}
      </Table.Row>
    )
  }
} // class MaintenanceHistoryRecord

export default withTranslation("common")(MaintenanceHistoryRecord)
