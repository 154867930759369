import { call, put, takeEvery } from "redux-saga/effects"

import api from "../../utilities/api"
import * as apiErrorHelpers from "../../helpers/apiErrorHelpers"
import * as sessionHelpers from "../../helpers/sessionHelpers"

const GEOTAB_SYNC_EVENTS_URL = "/geotab_sync_events"

export function* loadGeotabSyncEvents(action) {
  try {
    yield put({ type: "LOAD_GEOTAB_SYNC_EVENTS_REQUESTED" })
    yield put({ type: "SET_GEOTAB_SYNC_EVENTS_LOADING" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    const response = yield call(api.utility.get, api.path(GEOTAB_SYNC_EVENTS_URL))

    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "LOAD_GEOTAB_SYNC_EVENTS_SUCCEEDED",
      payload: { geotabSyncEvents: response.body }
    })
    yield put({ type: "UNSET_GEOTAB_SYNC_EVENTS_LOADING" })
  } catch (error) {
    const errors = apiErrorHelpers.formatInternalErrors(error)

    yield put({ type: "LOAD_GEOTAB_SYNC_EVENTS_FAILED" })
    yield put({ type: "UNSET_GEOTAB_SYNC_EVENTS_LOADING" })

    apiErrorHelpers.consoleLog(errors)
  }
} // function* loadGeotabSyncEvents

export function* createGeotabSyncEvent(action) {
  try {
    yield put({ type: "CREATE_GEOTAB_SYNC_EVENT_REQUESTED" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    const response = yield call(api.utility.post, api.path(GEOTAB_SYNC_EVENTS_URL))

    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "CREATE_GEOTAB_SYNC_EVENT_SUCCEEDED",
      payload: { geotabSyncEvent: response.body }
    })

    const afterRequestData = {
      alertMessage:
        "Syncing Shell Telematics data. You can navigate away while the sync is in progress.",
      alertType: "notice"
    }

    yield call(action.callback, "success", afterRequestData)
  } catch (error) {
    const errors = apiErrorHelpers.formatInternalErrors(error)

    yield put({ type: "CREATE_GEOTAB_SYNC_EVENT_FAILED" })

    const afterRequestData = {
      alertMessage: apiErrorHelpers.toString(errors),
      alertType: "error"
    }

    yield call(action.callback, "failure", afterRequestData)

    apiErrorHelpers.consoleLog(errors)
  }
} // function* createGeotabSyncEvent

export default function* geotabSyncEventSaga() {
  yield takeEvery("LOAD_GEOTAB_SYNC_EVENTS_SAGA", loadGeotabSyncEvents)
  yield takeEvery("CREATE_GEOTAB_SYNC_EVENT_SAGA", createGeotabSyncEvent)
}
