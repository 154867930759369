import { mockPaymentData } from "../../mocks/paymentData"

export const initialState = {
  // payments: []

  // TODO: (MPH) Replace with API requested data.
  payments: mockPaymentData
}

export default function (state = initialState, action) {
  // eslint-disable-next-line
  const { payload, type } = action

  switch (type) {
    default:
      return state
  }
}
