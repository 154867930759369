import { call, put, takeEvery, take } from "redux-saga/effects"
import moment from "moment"

import api from "../../utilities/api"
import * as apiErrorHelpers from "../../helpers/apiErrorHelpers"
import * as sessionHelpers from "../../helpers/sessionHelpers"
import { loadMaintenanceHistories } from "./maintenanceHistorySaga"

import { ACTIVE_STATUSES } from "../../constants/statuses"
import { constants } from "caradvise_shared_components"
const { AWAITING_APPROVAL, APPROVAL, INITIATED_BY_USER, ACTIVE, STATUS } = constants.orders
const SCHEDULED_STATUSES_FROM_RETAIL = [AWAITING_APPROVAL, APPROVAL, INITIATED_BY_USER, ACTIVE].map(
  (string) => STATUS[string]
)
const ORDERS_URL = "/orders"

export function* loadShopOrders(action) {
  try {
    const payload = action.payload || {}
    const payloadOptions = payload.options || {}
    const skipUnsetLoading = payload.skipUnsetLoading

    yield put({ type: "SET_LOADING" })
    yield put({ type: "UNSET_LOADING_ERROR" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    const url = `${ORDERS_URL}/index`
    let body = {}
    if (payloadOptions.captureActive) {
      body.state_id = SCHEDULED_STATUSES_FROM_RETAIL
    }

    if (payloadOptions.startDate) {
      body.appointment_datetime_from = payloadOptions["startDate"]
      body.appointment_datetime_to = payloadOptions["endDate"]
    }

    const shopOrdersResponse = yield call(api.utility.post, api.path(url), { body: body })

    apiErrorHelpers.catchErrors(shopOrdersResponse.body)

    yield put({
      type: "SHOP_ORDERS_LOAD_SUCCEEDED",
      payload: {
        shopOrders: shopOrdersResponse.body
      }
    })

    if (payloadOptions.withMaintenanceHistory) {
      const { startDate, endDate } = payloadOptions
      yield call(loadMaintenanceHistories, { payload: { startDate, endDate } })
    }

    if (!skipUnsetLoading) yield put({ type: "UNSET_LOADING" })
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "LOADING_FAILED" })
    yield put({ type: "SET_LOADING_ERROR" })
    yield put({ type: "UNSET_LOADING" })

    apiErrorHelpers.consoleLog(errors)
  }
} // function* loadShopOrders

export function* loadShopOrder(action) {
  try {
    yield put({ type: "SET_LOADING" })
    yield put({ type: "UNSET_LOADING_ERROR" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })
    const payload = action.payload || {}

    yield put({ type: "USERS_LOAD_REQUESTED" })

    const usersRequest = {
      url: "/fleet_users",
      body: {}
    }

    const usersResponse = yield call(api.utility.get, api.path(usersRequest.url), {
      body: usersRequest.body
    })

    apiErrorHelpers.catchErrors(usersResponse.body)
    yield put({
      type: "USERS_LOAD_SUCCEEDED",
      payload: {
        users: usersResponse.body
      }
    })

    ////////////////////////
    // LOAD SHOP ORDER DATA/
    ////////////////////////

    yield put({ type: "SHOP_ORDER_LOAD_REQUESTED" })

    const shopOrdersRequest = {
      url: `${ORDERS_URL}/${action.payload.shopOrderId}/preview`,
      body: {
        order: { order_services: action.payload.orderServices },
        price_as_approvals_logic: action.payload.priceAsApprovalsLogic
      }
    }

    const shopOrdersResponse = yield call(api.utility.post, api.path(shopOrdersRequest.url), {
      body: shopOrdersRequest.body
    })

    apiErrorHelpers.catchErrors(shopOrdersResponse.body)
    yield put({
      type: "SHOP_ORDER_LOAD_SUCCEEDED",
      payload: {
        shopOrder: shopOrdersResponse.body
      }
    })
    ////////////////////////////

    ////////////////////////////
    // LOAD CURRENT USER DATA //
    ////////////////////////////

    yield put({ type: "CURRENT_USER_LOAD_PAYMENT_METHODS_SAGA" })

    ////////////////////////////

    if (payload.withPriceRanges) {
      const shopOrder = shopOrdersResponse.body || {}
      const services = shopOrder.order_services || []

      yield call(loadShopOrderPriceRanges, {
        payload: {
          skipLoadingCalls: true,
          serviceNames: services.map((s) => s.name),
          vehicleId: shopOrder.vehicle_id
        }
      })
    }

    yield put({ type: "UNSET_LOADING" })
    if (action.callback) yield call(action.callback, "success", shopOrdersResponse.body)
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "LOADING_FAILED" })
    yield put({ type: "SET_LOADING_ERROR" })
    yield put({ type: "UNSET_LOADING" })

    apiErrorHelpers.consoleLog(errors)
  }
} // function* loadShopOrder

export function* loadShopOrderPriceRanges(action) {
  try {
    const payload = action.payload || {}
    if (!payload.skipLoadingCalls) {
      yield put({ type: "SET_LOADING" })
      yield put({ type: "UNSET_LOADING_ERROR" })
    }

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    /////////////////////////////////////
    // LOAD SHOP ORDER PRICE RANGES DATA/
    /////////////////////////////////////

    yield put({ type: "SHOP_ORDER_PRICE_RANGES_LOAD_REQUESTED" })

    const request = {
      url: "/services/search",
      body: {
        query: {
          type: "name",
          where: payload.serviceNames
        },
        vehicle_id: payload.vehicleId
      }
    }

    const response = yield call(api.utility.post, api.path(request.url), { body: request.body })

    apiErrorHelpers.catchErrors(response.body)
    yield put({
      type: "SHOP_ORDER_PRICE_RANGES_LOAD_SUCCEEDED",
      payload: {
        priceRanges: response.body
      }
    })
    ////////////////////////////

    if (!payload.skipLoadingCalls) yield put({ type: "UNSET_LOADING" })
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "LOADING_FAILED" })
    yield put({ type: "SET_LOADING_ERROR" })
    yield put({ type: "UNSET_LOADING" })

    apiErrorHelpers.consoleLog(errors)
  }
} // function* loadShopOrderPriceRanges

export function* downloadShopOrders(action) {
  const payload = action.payload || {}
  const { startDate, endDate } = payload
  const authToken = sessionHelpers.getApplicationCookie("authentication_token")

  let url = `${ORDERS_URL}.csv?auth_token=${authToken}&completed=true&appointment_datetime_from=${startDate}&appointment_datetime_to=${endDate}`

  window.open(api.path(url))
}

export function* loadOrderPreview(action) {
  yield put({ type: "SHOP_ORDER_LOAD_REQUESTED" })

  const shopOrdersRequest = {
    url: `${ORDERS_URL}/${action.payload.shopOrderId}/preview`,
    body: {
      order: { order_services: action.payload.orderServices },
      price_as_approvals_logic: action.payload.priceAsApprovalsLogic
    }
  }

  const shopOrdersResponse = yield call(api.utility.post, api.path(shopOrdersRequest.url), {
    body: shopOrdersRequest.body
  })

  apiErrorHelpers.catchErrors(shopOrdersResponse.body)
  yield put({
    type: "SHOP_ORDER_LOAD_SUCCEEDED",
    payload: {
      shopOrder: shopOrdersResponse.body
    }
  })
}

export default function* shopOrdersSaga() {
  yield takeEvery("SHOP_ORDERS_LOAD_SAGA", loadShopOrders)
  yield takeEvery("SHOP_ORDERS_DOWNLOAD_SAGA", downloadShopOrders)
  yield takeEvery("SHOP_ORDER_LOAD_SAGA", loadShopOrder)
  yield takeEvery("SHOP_ORDER_PRICE_RANGES_LOAD_SAGA", loadShopOrderPriceRanges)
  yield takeEvery("SHOP_ORDER_LOAD_PREVIEW", loadOrderPreview)
}
