import React, { Component } from "react"
import PropTypes from "prop-types"
import { Modal, Button, Header, Container, Icon, Select } from "semantic-ui-react"
import BraintreeWebDropIn from "braintree-web-drop-in"
import { selectOptions } from "../../helpers/userHelpers"
import {
  BRAINTREE_EN_US_LOCALE,
  BRAINTREE_FR_CANADA_LOCALE,
  FR_LOCALE
} from "../../constants/users"

const BRAINTREE_CONTAINER = "payment-form"

// TODO: this class can be extracted as a common component, if it is refactored to accept props
// for the "Drivers" dropdown data.  this.props.users and the formData attr name would become props.
// Otherwise, it's a generic overlay modal that is reusable.
export default class BraintreeModal extends Component {
  static propTypes = {
    modalOpen: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    saveCallback: PropTypes.func.isRequired,
    users: PropTypes.array.isRequired,
    clientToken: PropTypes.string
  }

  state = {
    formData: {
      customer_ids: this.props.users.map((u) => u.id)
    }
  }

  onChange(field, value) {
    let updatedFormData = { ...this.state.formData }
    updatedFormData[field] = value
    this.setState({ formData: updatedFormData })
  }

  createBraintreeDropIn() {
    return new Promise((resolve, reject) => {
      return BraintreeWebDropIn.create(
        {
          authorization: this.props.clientToken,
          container: `#${BRAINTREE_CONTAINER}`,
          paypal: { flow: "vault" },
          locale:
            this.props.language == FR_LOCALE ? BRAINTREE_FR_CANADA_LOCALE : BRAINTREE_EN_US_LOCALE
        },
        (error, instance) => {
          if (error) console.log(error)
          resolve(instance)
        }
      )
    })
  }

  async setupBraintree() {
    const btInstance = await this.createBraintreeDropIn()
    if (btInstance) await this.setState({ btInstance: btInstance })
  }

  async componentWillUnmount() {
    const { btInstance } = this.state
    if (btInstance) {
      await btInstance.teardown()
    }
  }

  handleSave = async () => {
    return new Promise((resolve, reject) => {
      this.state.btInstance.requestPaymentMethod(async (error, payload) => {
        if (error && error.message) {
          window.alert(`Error: ${error.message}`)
          resolve(false)
        } else {
          await this.props.saveCallback(payload.nonce, this.state.formData)
          this.setState({ formData: { customer_ids: [] } })
          resolve(true)
        }
      })
    })
  }

  render() {
    const { modalOpen, closeModal, users, t } = this.props
    const { btInstance, formData } = this.state

    return (
      <Modal size="tiny" open={modalOpen} closeIcon={<Icon name="close" onClick={closeModal} />}>
        <Modal.Content>
          <Header textAlign="center" className="primary-color">
            {t("addPaymentLabel")}
          </Header>
          <div id={BRAINTREE_CONTAINER}></div>
        </Modal.Content>
        <Modal.Actions>
          <Container textAlign="center">
            <div style={{ marginBottom: "20px" }}>
              <Select
                onChange={(_, data) => this.onChange("customer_ids", data.value)}
                options={selectOptions(users, { semantic: true })}
                placeholder={"Drivers"}
                value={formData.customer_ids}
                multiple
              />
            </div>
            <Button size="tiny" onClick={this.handleSave} style={{ width: "155px" }}>
              {t("addLabel")}
            </Button>
          </Container>
        </Modal.Actions>
      </Modal>
    )
  }
} // class BraintreeModal
