import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import MaintenanceHistoryRecord from "./MaintenanceHistoryRecord"
import { Table } from "semantic-ui-react"
import { withTranslation } from "react-i18next"
import { isFleetMaintenanceHubCanada } from "../../helpers/affiliationHelpers"

class MaintenanceHistory extends Component {
  static propTypes = {
    vehicle: PropTypes.object.isRequired
  }

  componentDidMount() {
    const { dispatch, vehicle } = this.props
    dispatch({
      type: "MAINTENANCE_HISTORY_LOAD_SAGA",
      payload: { vehicleId: vehicle.id }
    })
  }

  renderHistoryRecords() {
    const { vehicle, maintenanceHistory } = this.props

    return maintenanceHistory.map((record, index) => {
      return (
        <MaintenanceHistoryRecord
          key={index}
          record={record}
          vehicle={vehicle}
          afterDeleteMaintenanceHistory={this.props.afterDeleteMaintenanceHistory}
          {...this.props}
        />
      )
    })
  }

  render() {
    const { t } = this.props
    return (
      <Table className="borderless">
        <Table.Header>
          <Table.Row>
            {[
              t("dateLabel"),
              t("shopLabel"),
              isFleetMaintenanceHubCanada() ? t("dashboard:kilometersLabel") : "Miles",
              t("completedServicesLabel"),
              t("savedServicesLabel"),
              t("costLabel"),
              ""
            ].map((s) => (
              <Table.HeaderCell singleLine>{s}</Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>{this.renderHistoryRecords()}</Table.Body>
      </Table>
    )
  }
} // class MaintenanceHistory

const mapStateToProps = (state) => {
  const vehicle = state.vehicles.vehicle

  return {
    vehicle: vehicle,
    currentUser: state.users.currentUser,
    maintenanceHistory: (vehicle.maintenance_history || []).concat(
      vehicle.manualMaintenanceHistories || []
    )
  }
}

export default connect(mapStateToProps)(withTranslation("vehicle")(MaintenanceHistory))
