import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Redirect } from "react-router-dom"
import { routerPaths } from "../../constants/paths"

import Alert from "../../components/shared/Alert"
import NoSessionLayout from "../../layouts/NoSessionLayout"
import LoadingThrobber from "../../components/shared/LoadingThrobber"
import ShellNewUserForm from "../../components/users/ShellNewUserForm"

class ShellNewUser extends Component {
  state = {
    alertMessage: "",
    alertType: "default",
    isSubmitting: false,
    signedIn: false
  }

  onFormSubmit = (formData) => {
    const { id, authToken } = this.props
    this.setState({ isSubmitting: true }, () => {
      this.props.dispatch({
        type: "SHELL_USER_UPDATE_SAGA",
        payload: { ...formData, authToken, id },
        callback: this.afterRequest
      })
    })
  }

  afterRequest = (status, data) => {
    this.setState({ isSubmitting: false }, () => {
      if (status === "success") {
        this.props.dispatch({
          type: "SIGN_IN_SAGA",
          payload: { formData: { key: data.authenticationToken } },
          callback: () => this.setState({ signedIn: true })
        })
      } else {
        this.setState({
          alertMessage: data.alertMessage,
          alertType: data.alertType
        })
      }
    })
  }

  render() {
    const { alertMessage, alertType, isSubmitting, signedIn } = this.state
    const { firstName, lastName, cellPhone, email, fleetName, fleetNameEditable } = this.props

    if (signedIn) {
      return <Redirect to={routerPaths.dashboard} />
    }

    return (
      <NoSessionLayout>
        <div className={"left-aligned-form-container"}>
          <Alert message={alertMessage} type={alertType} visible={alertMessage !== ""} />
          <LoadingThrobber visible={isSubmitting} />

          {!isSubmitting && (
            <ShellNewUserForm
              isSubmitting={isSubmitting}
              onSubmit={this.onFormSubmit}
              firstName={firstName}
              lastName={lastName}
              cellPhone={cellPhone}
              email={email}
              fleetName={fleetName}
              fleetNameEditable={fleetNameEditable}
            />
          )}
        </div>
      </NoSessionLayout>
    )
  }
} // class ShellNewUser

const mapStateToProps = (state) => {
  const shellNewUser = state.users.shellNewUser || {}

  return {
    firstName: shellNewUser.firstName,
    lastName: shellNewUser.lastName,
    cellPhone: shellNewUser.cellPhone,
    email: shellNewUser.email,
    fleetName: shellNewUser.fleetName,
    fleetNameEditable: shellNewUser.fleetNameEditable,
    authToken: shellNewUser.authToken
  }
}

export default connect(mapStateToProps)(ShellNewUser)
