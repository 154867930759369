import React, { Component } from "react"
import PropTypes from "prop-types"
import { Menu } from "semantic-ui-react"
import { Link } from "react-router-dom"

class NavItem extends Component {
  static propTypes = {
    content: PropTypes.node.isRequired,
    linkPath: PropTypes.string.isRequired,
    active: PropTypes.bool
  }

  static defaultProps = {
    active: false
  }

  render() {
    const { linkPath, content, active } = this.props
    return (
      <Link to={linkPath}>
        <Menu.Item active={active}>{content}</Menu.Item>
      </Link>
    )
  }
} // class NavItem

export default NavItem
