import { call, put, takeEvery, take } from "redux-saga/effects"
import api from "../../utilities/api"
import * as apiErrorHelpers from "../../helpers/apiErrorHelpers"
import * as sessionHelpers from "../../helpers/sessionHelpers"

export function* createOrUpdateVehicle(action) {
  try {
    yield put({ type: "VEHICLE_CREATE_OR_UPDATE_REQUESTED" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    const request = {
      url: "/vehicles/" + (action.payload.id ? action.payload.id : ""),
      body: { vehicle: action.payload }
    }

    const response = yield call(
      action.payload.id ? api.utility.put : api.utility.post,
      api.path(request.url),
      { body: request.body }
    )

    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "VEHICLE_CREATE_OR_UPDATE_SUCCEEDED",
      payload: {
        vehicle: response.body.vehicle
      }
    })

    const afterRequestData = {
      vehicle: response.body.vehicle
    }

    yield call(action.callback, "success", afterRequestData)
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "VEHICLE_CREATE_OR_UPDATE_FAILED" })

    const afterRequestData = {
      error: apiErrorHelpers.toString(errors)
    }

    yield call(action.callback, "failure", afterRequestData)

    apiErrorHelpers.consoleLog(errors)
  }
} // function* createVehicle

export function* bulkUpsertVehicles(action) {
  try {
    yield put({ type: "BULK_UPSERT_VEHICLES_REQUESTED" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    const request = {
      url: "/vehicles/bulk_upsertions",
      body: { fileData: action.payload }
    }

    const response = yield call(api.utility.post, api.path(request.url), { body: request.body })

    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "BULK_UPSERT_VEHICLES_SUCCEEDED",
      payload: {
        success: response.success
      }
    })

    const afterRequestData = {
      alertMessage: "Bulk Upload Successfuly Submitted. Your file is currently being processed.",
      alertType: "success"
    }

    yield call(action.callback, "success", afterRequestData)
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "BULK_UPSERT_VEHICLES_FAILED" })

    const afterRequestData = {
      error: apiErrorHelpers.toString(errors),
      alertMessage: "Sorry, we couldn't process that submission. Please try again.",
      alertType: "error"
    }

    yield call(action.callback, "failure", afterRequestData)

    apiErrorHelpers.consoleLog(errors)
  }
} // function* bulkUpsertVehicles

export function* deleteVehicle(action) {
  try {
    yield put({ type: "VEHICLE_DELETE_REQUESTED" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    const vehicleId = action.payload.vehicleId

    const request = {
      url: `/vehicles/${vehicleId}`,
      body: {}
    }

    const response = yield call(api.utility.del, api.path(request.url), { body: request.body })

    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "VEHICLE_DELETE_SUCCEEDED",
      payload: {
        vehicle: response.body
      }
    })

    const afterRequestData = {
      alertMessage: "Vehicle deleted",
      alertType: "success"
    }

    yield call(action.callback, "success", afterRequestData)
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "VEHICLE_DELETE_FAILED" })

    const afterRequestData = {
      alertMessage: apiErrorHelpers.toString(errors),
      alertType: "error"
    }

    yield call(action.callback, "failure", afterRequestData)

    apiErrorHelpers.consoleLog(errors)
  }
} // function* deleteVehicle

export function* loadVehicle(action) {
  try {
    const defaultOptions = {
      dataType: "standard"
    }

    const payloadOptions = action.payload.options || {}

    const options = {
      ...defaultOptions,
      ...payloadOptions
    }

    yield put({ type: "SET_LOADING" })
    yield put({ type: "UNSET_LOADING_ERROR" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    ////////////////////////////
    //   LOAD VEHICLE DATA    //
    ////////////////////////////
    yield put({ type: "VEHICLE_LOAD_REQUESTED" })

    const vehicleId = action.payload.vehicleId

    let url = `/vehicles/${vehicleId}`
    const vehicleRequest = {
      url: url,
      body: {}
    }

    if (options["dataType"] === "extended") {
      yield put({ type: "MAINTENANCE_INTERVAL_SAGA", payload: { vehicleIds: [vehicleId] } })
      vehicleRequest.body.include = "all"
    }

    const vehicleResponse = yield call(api.utility.get, api.path(vehicleRequest.url), {
      body: vehicleRequest.body
    })

    apiErrorHelpers.catchErrors(vehicleResponse.body)

    yield put({
      type: "VEHICLE_LOAD_SUCCEEDED",
      payload: {
        vehicle: vehicleResponse.body
      }
    })

    yield put({
      type: "SET_VEHICLE_DATA_TYPE",
      payload: {
        dataType: options["dataType"]
      }
    })
    ////////////////////////////

    ////////////////////////////
    //   LOAD VEHICLES DATA   //
    ////////////////////////////

    yield put({ type: "VEHICLES_LOAD_REQUESTED" })

    const vehiclesRequest = {
      url: "/vehicles",
      body: {}
    }

    const vehiclesResponse = yield call(api.utility.get, api.path(vehiclesRequest.url), {
      body: vehiclesRequest.body
    })

    apiErrorHelpers.catchErrors(vehiclesResponse.body)

    yield put({
      type: "VEHICLES_LOAD_SUCCEEDED",
      payload: {
        vehicles: vehiclesResponse.body
      }
    })

    yield put({
      type: "SET_VEHICLES_DATA_TYPE",
      payload: { dataType: "standard" }
    })
    ////////////////////////////

    ////////////////////////////
    //    LOAD USERS DATA     //
    ////////////////////////////
    yield put({ type: "USERS_LOAD_REQUESTED" })

    const usersRequest = {
      url: "/fleet_users",
      body: {}
    }

    const usersResponse = yield call(api.utility.get, api.path(usersRequest.url), {
      body: usersRequest.body
    })

    apiErrorHelpers.catchErrors(usersResponse.body)

    yield put({
      type: "USERS_LOAD_SUCCEEDED",
      payload: {
        users: usersResponse.body
      }
    })
    ////////////////////////////

    ////////////////////////////
    //   LOAD SERVICES DATA   //
    ////////////////////////////
    yield put({ type: "SERVICES_LOAD_REQUESTED" })

    const servicesRequest = {
      url: "/services",
      body: { basic: true }
    }

    const servicesResponse = yield call(api.utility.get, api.path(servicesRequest.url), {
      body: servicesRequest.body
    })

    apiErrorHelpers.catchErrors(servicesResponse.body)

    yield put({
      type: "SERVICES_LOAD_SUCCEEDED",
      payload: {
        services: servicesResponse.body
      }
    })
    ////////////////////////////

    yield put({ type: "UNSET_LOADING" })
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "VEHICLE_LOAD_FAILED" })
    yield put({ type: "SET_LOADING_ERROR" })
    yield put({ type: "UNSET_LOADING" })

    apiErrorHelpers.consoleLog(errors)
  }
} // function* loadVehicle

export function* loadDropdown(type, getUrl, action) {
  yield put({ type: `${type.toUpperCase()}_LOAD_REQUESTED` })

  api.setAuthApi({
    Authorization: sessionHelpers.getApplicationCookie("authentication_token")
  })

  try {
    const vehiclesResponse = yield call(api.utility.get, api.path(getUrl(action)), {})

    apiErrorHelpers.catchErrors(vehiclesResponse.body)

    yield put({
      type: `${type.toUpperCase()}_LOAD_SUCCEEDED`,
      payload: {
        [type]: vehiclesResponse.body // Got lucky, no fancy plurals used here.
      }
    })
  } catch (errors) {
    errors = apiErrorHelpers.formatInternalErrors(errors)
    yield put({
      type: `${type.toUpperCase()}_LOAD_SUCCEEDED`,
      payload: {
        [type]: []
      }
    })
    apiErrorHelpers.consoleLog(errors)
  }
}

function getMakesUrl(action) {
  return "/vehicles/years/" + action.payload.yearId + "/makes"
}

function getModelsUrl(action) {
  const { yearId, makeId } = action.payload
  return "/vehicles/years/" + yearId + "/makes/" + makeId + "/models"
}

function getEnginesUrl(action) {
  const { yearId, makeId, modelId, subModelId } = action.payload
  return (
    "/vehicles/years/" +
    yearId +
    "/makes/" +
    makeId +
    "/models/" +
    modelId +
    "/submodels/" +
    subModelId
  )
}

function getYearsUrl(action) {
  return "/vehicles/years"
}

export function* loadVehicles(action) {
  try {
    yield put({ type: "SET_LOADING" })
    yield put({ type: "UNSET_LOADING_ERROR" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    ////////////////////////
    // LOAD VEHICLES DATA //
    ////////////////////////

    yield put({ type: "VEHICLES_LOAD_REQUESTED" })

    const vehiclesRequest = {
      url: "/vehicles",
      body: {}
    }

    if (action.payload && action.payload.addMaintenance) {
      vehiclesRequest.body.request = "active_order"
    }

    const vehiclesResponse = yield call(api.utility.get, api.path(vehiclesRequest.url), {
      body: vehiclesRequest.body
    })

    apiErrorHelpers.catchErrors(vehiclesResponse.body)

    yield put({
      type: "VEHICLES_LOAD_SUCCEEDED",
      payload: {
        vehicles: vehiclesResponse.body
      }
    })

    yield put({
      type: "SET_VEHICLES_DATA_TYPE",
      payload: { dataType: "standard" }
    })
    ////////////////////////

    ////////////////////////
    //  LOAD USERS DATA   //
    ////////////////////////
    yield put({ type: "USERS_LOAD_REQUESTED" })

    const usersRequest = {
      url: "/fleet_users",
      body: {}
    }

    const usersResponse = yield call(api.utility.get, api.path(usersRequest.url), {
      body: usersRequest.body
    })

    apiErrorHelpers.catchErrors(usersResponse.body)

    yield put({
      type: "USERS_LOAD_SUCCEEDED",
      payload: {
        users: usersResponse.body
      }
    })
    ////////////////////////

    yield put({ type: "UNSET_LOADING" })
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "LOADING_FAILED" })
    yield put({ type: "SET_LOADING_ERROR" })
    yield put({ type: "UNSET_LOADING" })

    apiErrorHelpers.consoleLog(errors)
  }
} // function* loadVehicles

export function* updateVehicle(action) {
  try {
    yield put({ type: "VEHICLE_UPDATE_REQUESTED" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    const vehicleId = action.payload.formData.id

    const request = {
      url: `/vehicles/${vehicleId}`,
      body: {
        vehicle: {
          ...action.payload.formData
        }
      }
    }

    const response = yield call(api.utility.put, api.path(request.url), { body: request.body })

    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "VEHICLE_UPDATE_SUCCEEDED",
      payload: {
        vehicle: response.body.vehicle
      }
    })

    const afterRequestData = {
      alertMessage: "Vehicle updated",
      alertType: "success"
    }

    yield call(action.callback, "success", afterRequestData)
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "VEHICLE_UPDATE_FAILED" })

    const afterRequestData = {
      alertMessage: apiErrorHelpers.toString(errors),
      alertType: "failure"
    }

    yield call(action.callback, "failure", afterRequestData)

    apiErrorHelpers.consoleLog(errors)
  }
} // function* updateVehicle

export default function* vehicleSaga() {
  yield takeEvery("VEHICLE_CREATE_OR_UPDATE_SAGA", createOrUpdateVehicle)
  yield takeEvery("VEHICLE_DELETE_SAGA", deleteVehicle)
  yield takeEvery("VEHICLE_LOAD_SAGA", loadVehicle)
  yield takeEvery("VEHICLE_UPDATE_SAGA", updateVehicle)
  yield takeEvery("VEHICLE_BULK_UPSERT_SAGA", bulkUpsertVehicles)
  yield takeEvery("VEHICLES_LOAD_SAGA", loadVehicles)
  yield takeEvery("YEARS_LOAD_SAGA", loadDropdown.bind(this, "years", getYearsUrl))
  yield takeEvery("MAKES_LOAD_SAGA", loadDropdown.bind(this, "makes", getMakesUrl))
  yield takeEvery("MODELS_LOAD_SAGA", loadDropdown.bind(this, "models", getModelsUrl))
  yield takeEvery("ENGINES_LOAD_SAGA", loadDropdown.bind(this, "engines", getEnginesUrl))
}
