import React, { Component } from "react"
import { Container, Header } from "semantic-ui-react"
import PropTypes from "prop-types"

class LoadingThrobber extends Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired
  }

  render() {
    const { visible, noMargin, loadingMessage } = this.props
    const style = noMargin ? { margin: 0 } : {}

    if (visible) {
      return (
        <div className={"spinner_container"} style={style}>
          <div className={"spinner"}></div>
          <Header className={"spinner_message"}>{loadingMessage}</Header>
        </div>
      )
    } else {
      return null
    }
  }
} // class LoadingThrobber

export default LoadingThrobber
