import React, { Component } from "react"
import PropTypes from "prop-types"

import VehicleRecall from "./VehicleRecall"
import { Table } from "semantic-ui-react"
import { withTranslation } from "react-i18next"

class VehicleRecalls extends Component {
  static propTypes = {
    recalls: PropTypes.array.isRequired,
    vehicle: PropTypes.object.isRequired
  }

  renderRecalls() {
    const { recalls, vehicle } = this.props

    return recalls.reverse().map((recall, index) => {
      return <VehicleRecall key={index} recall={recall} vehicle={vehicle} />
    })
  }

  render() {
    const { recalls, t } = this.props
    if (recalls.length === 0)
      return <p style={{ textAlign: "center" }}>{t("noVehicleRecallsLabel")}</p>

    return (
      <Table className="borderless">
        <Table.Header>
          <Table.Row>
            {["Date", "Recall", "Summary"].map((s) => (
              <Table.HeaderCell singleLine>{s}</Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>{this.renderRecalls()}</Table.Body>
      </Table>
    )
  }
} // class VehicleRecalls

export default withTranslation("vehicle")(VehicleRecalls)
