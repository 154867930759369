import React, { Component } from "react"
import PropTypes from "prop-types"
import { Icon, Input } from "semantic-ui-react"

import "../assets/stylesheets/elements/_eye_icon.scss"

class PasswordField extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,

    className: PropTypes.string,
    disabled: PropTypes.bool,
    obfuscated: PropTypes.bool.isRequired,
    placeholder: PropTypes.string
  }

  static defaultProps = {
    className: "",
    disabled: false,
    obfuscated: true,
    placeholder: "",
    error: false
  }

  type() {
    const { obfuscated } = this.props

    return obfuscated ? "password" : "text"
  }

  //getMargin function is used to fix css for eye icon on iphone5 / galaxy fold phone which has screen size less than 325
  getMargin() {
    return window.innerWidth <= 325 ? 35 : 10 //if browser window size is less than 325 apply 35 px margin to the right of eye icon.
  }

  render() {
    const {
      className,
      disabled,
      onChange,
      placeholder,
      value,
      error,
      onIconClick,
      onKeyPress
    } = this.props

    return (
      <Input
        icon={
          <Icon
            aria-label="Show password or hide password"
            tabIndex="0"
            name="eye"
            link
            size="large"
            onClick={onIconClick}
            onKeyDown={onKeyPress}
            style={{ marginRight: `${this.getMargin()}px` }}
          />
        }
        error={error}
        fluid
        className={className}
        disabled={disabled}
        onChange={onChange}
        placeholder={placeholder}
        type={this.type()}
        value={value}
      />
    )
  }
} // class PasswordField

export default PasswordField
