import React from "react"
import { connect } from "react-redux"
import { constants } from "caradvise_shared_components"
import { withTranslation } from "react-i18next"
const { WEX } = constants.paymentProviders

class WexPaymentList extends React.Component {
  componentDidMount() {
    this.props.dispatch({ type: "CURRENT_USER_LOAD_PAYMENT_METHODS_SAGA" })
  }

  render() {
    const { hasPaymentMethod, paymentProviderType, t } = this.props

    if (hasPaymentMethod == null || paymentProviderType !== WEX) return null
    else if (hasPaymentMethod) return <strong>{t("wexPaymentCardLabel")}</strong>
    else
      return (
        <div>
          <strong>{t("noWexPaymentMethod")}</strong>
          <div>{t("contactCaradviseLabel")}</div>
        </div>
      )
  }
}

const mapStateToProps = (state) => {
  const currentUser = state.users.currentUser || {}

  return {
    hasPaymentMethod: currentUser.has_payment_method,
    paymentProviderType: currentUser.payment_provider_type
  }
}

export default connect(mapStateToProps)(withTranslation("payment")(WexPaymentList))
