import React, { Component } from "react"
import PropTypes from "prop-types"
import { sortBy } from "lodash"
import UserListItem from "./UserListItem"
import { Table, Button, Input } from "semantic-ui-react"
import UserModal from "./UserModal"
import debounce from "debounce"
import * as vehicleHelpers from "../../helpers/vehicleHelpers"
import * as arrayHelpers from "../../helpers/arrayHelpers"
import { isOwnerManagerOrAdmin } from "../../helpers/activeMaintenanceHelpers"
import InfoLabel from "../shared/InfoLabel"
import { withTranslation } from "react-i18next"

class UserList extends Component {
  static propTypes = {
    users: PropTypes.array.isRequired,
    loadUsers: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.debouncedSetQuickFilterText = debounce(this.setQuickFilterText, 300)
  }

  state = {}

  setQuickFilterText = (event) => {
    const escapedText = event.target.value.match(/[^\\]+/)
    this.setState({ quickFilterText: (escapedText || [])[0] })
  }

  filteredUsers = (users) => {
    const { quickFilterText } = this.state
    const regexp = new RegExp(quickFilterText, "i")
    if (quickFilterText)
      return arrayHelpers.uniq(
        users
          .filter((u) =>
            Object.keys(u).find((key) => u[key] && u[key].match && u[key].match(regexp))
          )
          .concat(
            users.filter((u) =>
              u.vehicles.find((v) => vehicleHelpers.formattedName(v).match(regexp))
            )
          )
          .concat(
            users.filter((u) => {
              return u.roles.find((r) => (r.name ? r.name.match(regexp) : r.match(regexp)))
            })
          )
      )
    else return users
  }

  renderListItems() {
    const { users, loadUsers, onDelete, currentUser, isFMC } = this.props

    return sortBy(this.filteredUsers(users), "email").map((user, index) => {
      return (
        <UserListItem
          key={index}
          user={user}
          loadUsers={loadUsers}
          onDelete={onDelete}
          currentUser={currentUser}
          isFMC={isFMC}
        />
      )
    })
  }

  render() {
    const { loadUsers, user, currentUser, isFMC, t } = this.props
    const fleetsLabel = t("fleetsLabel")
    // FMC user list doesn't list Vehicles but instead Fleets which the user can approve orders for
    const headers = [
      t("nameLabel"),
      t("rolesLabel"),
      t("emailLabel"),
      t("phoneLabel"),
      isFMC ? fleetsLabel : t("common:vehiclesLabel"),
      ""
    ]

    return (
      <React.Fragment>
        <Input
          className="search"
          icon="search"
          onChange={this.setQuickFilterText}
          placeholder={t("searchUsersLabel")}
        />
        {isOwnerManagerOrAdmin(currentUser) && (
          <UserModal
            completionCallback={loadUsers}
            user={user}
            isFMC={isFMC}
            currentUser={currentUser}
          >
            <Button floated="right" size="tiny">
              {t("addNewUserLabel")}
            </Button>
          </UserModal>
        )}
        <Table className="borderless">
          <Table.Header>
            <Table.Row>
              {headers.map((header) => (
                <Table.HeaderCell singleLine>
                  {header}
                  {header === fleetsLabel && (
                    <InfoLabel
                      field={{
                        helpModal: <div>{t("userDescLabel")}</div>
                      }}
                    />
                  )}
                </Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>
          <Table.Body>{this.renderListItems()}</Table.Body>
        </Table>
      </React.Fragment>
    )
  }
} // class UserList

export default withTranslation("fmcUsers")(UserList)
