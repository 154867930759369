import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import ActiveMaintenances from "../../components/dashboard/ActiveMaintenances"
import Alert from "../../components/shared/Alert"
import ApplicationLayout from "../../layouts/ApplicationLayout"
import Disclaimer from "../../components/shared/Disclaimer"
import LoadingError from "../../components/shared/LoadingError"
import LoadingThrobber from "../../components/shared/LoadingThrobber"

class ActiveMaintenanceIndex extends Component {
  // NOTE: The submission/cancelation logic for "Active Maintenance" approvals in this component is mirrored in /views/dashboard/dashboard.js

  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    isLoadingError: PropTypes.bool.isRequired,
    users: PropTypes.array.isRequired,
    vehicles: PropTypes.array.isRequired,
    vehiclesDataType: PropTypes.string.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      alert: "",
      alertType: "default",
      isSubmitting: false
    }
  }

  async componentWillMount() {
    this.loading = true
    await this.fetchDashboardData()
    this.loading = false
  }

  afterSubmit(status, data) {
    this.setState({ isSubmitting: false })

    if (status === "success") {
      this.onSubmitSuccess(data)
    } else {
      this.onSubmitFailure(data)
    }
  }

  fetchDashboardData() {
    this.props.dispatch({
      type: "DASHBOARD_LOAD_SAGA",
      payload: {
        users: ["id", "name", "cellPhone", "firstName", "lastName"],
        vehicles: [
          "id",
          "last_maintenance_miles",
          "license_plate_number",
          "license_plate_state",
          "make",
          "model",
          "year",
          "other_id",
          {
            active_order: [
              "id",
              "status",
              "appointment_datetime",
              "appointment_datetime_pretty",
              "miles",
              "total",
              "po_number",
              "shop_comments",
              "customer_comments",
              "can_cancel",
              "can_approve",
              "approver_user_ids",
              "cancel_user_ids",
              {
                shop: ["id", "name", "main_phone_number", "address_line1", "city", "state", "zip"]
              },
              {
                order_services: ["id", "name", "status"]
              }
            ]
          }
        ]
      }
    })
  }

  onAcceptAllSubmit(submissionData) {
    this.setState({ isSubmitting: true })

    this.props.dispatch({
      type: "MAINTENANCE_APPROVAL_SAGA",
      payload: { submissionData: submissionData },
      callback: this.afterSubmit.bind(this)
    })
  }

  onCancelationSubmit(submissionData) {
    this.setState({ isSubmitting: true })

    this.props.dispatch({
      type: "MAINTENANCE_CANCELATION_SAGA",
      payload: { submissionData: submissionData },
      callback: this.afterSubmit.bind(this)
    })
  }

  onSubmitFailure(data) {
    this.setState({
      alert: data.alert,
      alertType: data.alertType
    })
  }

  onSubmitSuccess(data) {
    this.setState({
      alert: data.alert,
      alertType: data.alertType
    })

    // NOTE: Refreshes "Active Maintenance" data.
    this.fetchDashboardData()
  }

  shouldRenderContent() {
    const { isLoading, isLoadingError, vehiclesDataType } = this.props

    return !isLoading && !isLoadingError && vehiclesDataType === "extended" && !this.loading
  }

  totalActiveMaintenanceCount() {
    const { vehicles } = this.props

    let count = 0

    vehicles.forEach((vehicle) => {
      if (vehicle.active_order) {
        count += 1
      }
    })

    return count
  }

  renderContent() {
    const { users, vehicles } = this.props

    const { isSubmitting } = this.state

    if (this.shouldRenderContent()) {
      return (
        <div className={"dash-approvals-container"}>
          <div className={"dash-hd"}>
            <div>ACTIVE MAINTENANCE</div>

            <Disclaimer
              message={
                "Maintenance requests can only be canceled before the vehicle is received by the shop"
              }
              visible={this.totalActiveMaintenanceCount() > 0}
            />
          </div>

          <ActiveMaintenances
            isSubmitting={isSubmitting}
            onAcceptAllSubmit={this.onAcceptAllSubmit.bind(this)}
            onCancelationSubmit={this.onCancelationSubmit.bind(this)}
            users={users}
            vehicles={vehicles}
          />
        </div>
      )
    } else {
      return null
    }
  }

  render() {
    const { isLoading, isLoadingError } = this.props

    const { alert, alertType } = this.state

    return (
      <ApplicationLayout>
        <Alert message={alert} type={alertType} visible={alert !== ""} />

        <LoadingThrobber visible={isLoading} />

        <LoadingError visible={!isLoading && isLoadingError} />

        {this.renderContent()}
      </ApplicationLayout>
    )
  }
} // class ActiveMaintenanceIndex

const mapStateToProps = (state) => ({
  isLoading: state.application.isLoading,
  isLoadingError: state.application.isLoadingError,
  users: state.users.users,
  vehicles: state.vehicles.vehicles,
  vehiclesDataType: state.vehicles.vehiclesDataType
})

export default connect(mapStateToProps)(ActiveMaintenanceIndex)
