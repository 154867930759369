export const PAGINATION_AMOUNT = 50

export function dynamicGridHeight({ items }) {
  const gridHeights = {
    header: 56,
    row: 48,
    bottomPadding: 15
  }

  let height

  if (items && items.length > 0) {
    let itemAmount = items.length > PAGINATION_AMOUNT ? PAGINATION_AMOUNT : items.length
    height = gridHeights.header + gridHeights.row * (itemAmount + 2)
  } else {
    height = gridHeights.header + 450
  }

  height = height + gridHeights.bottomPadding

  return `${height}px`
}
