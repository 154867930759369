import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import { navigationPaths } from "../../constants/paths"

import Alert from "../../components/shared/Alert"
import ApplicationLayout from "../../layouts/ApplicationLayout"
import LoadingError from "../../components/shared/LoadingError"
import LoadingThrobber from "../../components/shared/LoadingThrobber"
import MaintenanceHistory from "../../components/vehicles/MaintenanceHistory"
import MaintenancePolicies from "../../components/maintenance_policies/MaintenancePolicies"
import MaintenanceSchedule from "../../components/vehicles/MaintenanceSchedule"

import VehicleInfo from "../../components/vehicles/VehicleInfo"
import VehicleRecalls from "../../components/vehicles/VehicleRecalls"
import { Grid, Menu, Segment, Image, Header, Divider } from "semantic-ui-react"
import * as vehicleHelpers from "../../helpers/vehicleHelpers"
import ScheduleServiceButton from "../../components/shared/ScheduleServiceButton"
import { withTranslation } from "react-i18next"

class VehicleShow extends Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    isLoadingError: PropTypes.bool.isRequired,
    services: PropTypes.array.isRequired,
    users: PropTypes.array.isRequired,
    vehicle: PropTypes.object.isRequired,
    vehicles: PropTypes.array.isRequired
  }

  /* Components are functions it covers updates */
  state = {
    alertMessage: "",
    alertType: "default",
    vehicleId: this.props.match.params.id,
    activePage: "overview",
    pages: [
      {
        name: "overview",
        label: "vechicleOverviewLabel",
        component: () => (
          <VehicleInfo loadVehicle={this.fetchVehicleData} vehicle={this.props.vehicle} />
        )
      },
      {
        name: "next_maintenance",
        label: "nextMaintenanceLabel",
        component: () => (
          <MaintenanceSchedule
            vehicle={this.props.vehicle}
            serviceSchedulesByVehicle={this.props.maintenanceIntervals.service_schedules_by_vehicle}
            language={this.props.language}
            dispatch={this.props.dispatch}
          />
        )
      },
      {
        name: "maintenance_history",
        label: "maintenanceHistoryLabel",
        component: () => (
          <MaintenanceHistory
            afterDeleteMaintenanceHistory={this.afterDeleteMaintenanceHistory}
            {...this.props}
          />
        )
      },
      {
        name: "policies",
        label: "policiesLabel",
        component: () => (
          <MaintenancePolicies
            allowActions={false}
            onDelete={this.onMaintenancePolicyDelete.bind(this)}
            onRedirect={this.navigateToPolicies.bind(this)}
            onSubmit={this.onMaintenancePolicySubmit.bind(this)}
            policies={this.props.vehicle.policies}
            services={this.props.services}
            users={this.props.users}
            vehicle={this.props.vehicle}
            vehicles={this.props.vehicles}
            language={this.props.language}
          />
        )
      },
      {
        name: "recalls",
        label: "recallsLabel",
        component: () => (
          <VehicleRecalls vehicle={this.props.vehicle} recalls={this.props.vehicle.recalls} />
        )
      }
    ]
  }

  componentDidMount() {
    this.fetchVehicleData()
  }

  afterSubmit(status, data) {
    if (status === "success") {
      this.onSubmitSuccess(data)
    } else {
      this.onSubmitFailure(data)
    }
  }

  fetchVehicleData = () => {
    const { vehicleId } = this.state

    this.props.dispatch({
      type: "VEHICLE_LOAD_SAGA",
      payload: {
        vehicleId: vehicleId,
        options: {
          dataType: "extended"
        }
      }
    })
  }

  navigateToPolicies() {
    this.props.history.push({
      pathname: navigationPaths.settingsIndex()
    })
  }

  onMaintenanceApprovalSubmit(submissionData) {
    this.props.dispatch({
      type: "MAINTENANCE_APPROVAL_SAGA",
      payload: { submissionData: submissionData },
      callback: this.afterSubmit.bind(this)
    })
  }

  onMaintenanceUpdateSubmit(submissionData) {
    this.props.dispatch({
      type: "MAINTENANCE_UPDATE_SAGA",
      payload: { submissionData: submissionData },
      callback: this.afterSubmit.bind(this)
    })
  }

  onMaintenanceCancelationSubmit(submissionData) {
    this.props.dispatch({
      type: "MAINTENANCE_CANCELATION_SAGA",
      payload: { submissionData: submissionData },
      callback: this.afterSubmit.bind(this)
    })
  }

  onMaintenancePolicyDelete(policyId) {
    this.props.dispatch({
      type: "MAINTENANCE_POLICY_DELETE_SAGA",
      payload: { policyId: policyId },
      callback: this.afterSubmit.bind(this)
    })
  }

  onMaintenancePolicySubmit(formData) {
    let sagaType

    if (formData.id === null) {
      sagaType = "MAINTENANCE_POLICY_CREATE_SAGA"
    } else {
      sagaType = "MAINTENANCE_POLICY_UPDATE_SAGA"
    }

    this.props.dispatch({
      type: sagaType,
      payload: { formData: formData, t: this.props.t },
      callback: this.afterSubmit.bind(this)
    })
  }

  onSubmitFailure(data) {
    this.setState({
      alertMessage: data.alertMessage,
      alertType: data.alertType
    })

    window.scrollTo(0, 0)
  }

  onSubmitSuccess(data) {
    this.setState({
      alertMessage: data.alertMessage,
      alertType: data.alertType
    })

    // NOTE: Refreshes "Active Maintenance" & "Maintenance Policies" data.
    this.fetchVehicleData()
  }

  shouldRenderContent() {
    const { isLoading, isLoadingError, vehicle, vehicleDataType } = this.props

    // NOTE:  Prevents the component from attempting to render content body prior to API load.
    const vehiclePresent = Object.keys(vehicle).length !== 0

    return vehiclePresent && !isLoading && !isLoadingError && vehicleDataType === "extended"
  }

  handlePageChange = (page) => {
    this.setState({ activePage: page })
  }

  afterDeleteMaintenanceHistory = (status, data) => {
    if (status === "success") {
      this.onDeleteMaintenanceHistorySuccess(data)
    } else {
      this.onRequestFailure(data)
    }
  }

  onDeleteMaintenanceHistorySuccess = (data) => {
    this.setState({
      alertMessage: data.alertMessage,
      alertType: data.alertType
    })
  }

  onRequestFailure = (data) => {
    this.setState({
      alertMessage: data.alertMessage,
      alertType: data.alertType
    })
  }

  renderContent() {
    const { vehicle, t } = this.props
    let { activePage, pages } = this.state

    if (this.shouldRenderContent()) {
      return (
        <div>
          <Alert
            message={t("noPoliciesLabel")}
            type={"warning"}
            visible={vehicle.policies && vehicle.policies.length === 0}
          />
          <Segment.Group>
            <Segment className="menu-container">
              <Menu pointing secondary>
                {pages.map((page) => (
                  <Menu.Item
                    name={t(page.label)}
                    active={page.name === activePage}
                    onClick={this.handlePageChange.bind(this, page.name)}
                  />
                ))}

                <Menu.Item position="right" className="skinny-menu-button">
                  <ScheduleServiceButton floated="right" vehicle={vehicle} />
                </Menu.Item>
              </Menu>
            </Segment>
            <Segment className="tab-container">
              <Grid stackable>
                <Grid.Row>
                  <Grid.Column width={11}>
                    <Header size="large">{vehicleHelpers.formattedName(vehicle)}</Header>
                    <Divider hidden />
                    {pages.find((page) => page.name === activePage).component()}
                  </Grid.Column>
                  <Grid.Column width={4} textAlign="right" verticalAlign="middle">
                    <Image src={vehicle.dashboard_url || "/images/no_car_large.svg"} />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          </Segment.Group>
        </div>
      )
    } else {
      return null
    }
  }

  render() {
    const { isLoading, isLoadingError } = this.props

    const { alertMessage, alertType } = this.state

    return (
      <ApplicationLayout>
        <Alert message={alertMessage} type={alertType} visible={alertMessage !== ""} />

        <LoadingThrobber visible={isLoading} />

        <LoadingError visible={!isLoading && isLoadingError} />

        {this.renderContent()}
      </ApplicationLayout>
    )
  }
} // class VehicleShow

const mapStateToProps = (state) => ({
  isLoading: state.application.isLoading,
  isLoadingError: state.application.isLoadingError,
  services: state.services.services,
  users: state.users.users,
  vehicle: state.vehicles.vehicle,
  vehicleDataType: state.vehicles.vehicleDataType,
  vehicles: state.vehicles.vehicles,
  maintenanceIntervals: state.maintenanceIntervals,
  language: state.users.currentUser ? state.users.currentUser.language : ""
})

export default connect(mapStateToProps)(withTranslation("vehicle")(VehicleShow))
