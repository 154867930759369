import React, { Component } from "react"
import PropTypes from "prop-types"
import EditForm from "../shared/EditForm"
import { connect } from "react-redux"
import * as userHelpers from "../../helpers/userHelpers"
import { isFleetAmerica, isFleetMaintenanceHubCanada } from "../../helpers/affiliationHelpers"
import LoadingThrobber from "../shared/LoadingThrobber"
import { withTranslation } from "react-i18next"

class UserForm extends Component {
  sharedFields = (user, t) => {
    return [
      { placeholder: t("firstNameLabel"), optional: false, fieldName: "firstName" },
      { placeholder: t("lastNameLabel"), optional: false, fieldName: "lastName" },
      {
        placeholder: t("emailLabel"),
        optional: false,
        fieldName: "email",
        specialValidations: ["email"]
      },
      {
        placeholder: t("phoneNumberLabel"),
        optional: false,
        fieldName: "cellPhone",
        specialValidations: ["phone"]
      },
      {
        placeholder: isFleetMaintenanceHubCanada() ? t("codePostalLabel") : t("zipCodeLabel"),
        optional: false,
        fieldName: "zip",
        specialValidations: ["zip"]
      },
      {
        placeholder: t("carCountLabel"),
        optional: false,
        fieldName: "vehiclesTotal"
      },
      {
        placeholder: t("passwordLabel"),
        optional: true,
        fieldName: "password",
        specialValidations: ["password"]
      },
      {
        placeholder: t("passwordConfirmationLabel"),
        optional: true,
        fieldName: "password_confirmation",
        specialValidations: ["password"]
      }
    ]
  }

  notificationPreferenceFields = () => {
    const { t } = this.props
    return [
      {
        placeholder: (
          <strong>
            <br />
            {t("textPreferencesLabel")}
          </strong>
        ),
        optional: true,
        fieldName: "text_notification_preference_attributes"
      },
      {
        placeholder: (
          <strong>
            <br />
            {t("emailPreferencesLabel")}
          </strong>
        ),
        optional: true,
        fieldName: "email_notification_preference_attributes"
      }
    ]
  }

  allFields = (user, t) => {
    return {
      1: [...this.sharedFields(user, t), ...this.notificationPreferenceFields()]
    }
  }

  fmcFields = (user, t) => {
    return {
      1: [...this.sharedFields(user, t)]
    }
  }

  static propTypes = {
    user: PropTypes.object.isRequired,
    completionCallback: PropTypes.func.isRequired
  }

  static defaultProps = {
    user: {},
    completionCallback: () => {}
  }

  getNewFormState = (user) => {
    let textNotificationPreference
    let emailNotificationPreference
    let defaultTextNotificationPreference = userHelpers.DEFAULT_TEXT_NOTIFICATION_PREFERENCE
    let defaultEmailNotificationPreference = userHelpers.DEFAULT_EMAIL_NOTIFICATION_PREFERENCE

    if (isFleetAmerica()) {
      delete defaultTextNotificationPreference.one_hour_reminder
    }

    if (user.text_notification_preference) {
      if (isFleetAmerica()) {
        delete user.text_notification_preference.one_hour_reminder
      }

      textNotificationPreference = { ...user.text_notification_preference }
      userHelpers.mapPreferencesToDefaults(
        textNotificationPreference,
        defaultTextNotificationPreference
      )
    }

    if (user.email_notification_preference) {
      emailNotificationPreference = { ...user.email_notification_preference }
      userHelpers.mapPreferencesToDefaults(
        emailNotificationPreference,
        defaultEmailNotificationPreference
      )
    }
    return {
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      cellPhone: user.cellPhone,
      zip: user.zip,
      vehiclesTotal: user.vehiclesTotal,
      password: null,
      password_confirmation: null,
      text_notification_preference_attributes:
        textNotificationPreference || defaultTextNotificationPreference,
      email_notification_preference_attributes:
        emailNotificationPreference || defaultEmailNotificationPreference
    }
  }

  render() {
    const { user, t } = this.props
    const userLoaded = Object.keys(user).length > 0
    // EditForm depends on certain user data, and it doesn't re-render when user data loads
    if (!userLoaded) return <LoadingThrobber />

    const isFMCUser = user && user.roles && user.roles.includes("FMC")
    const fields = isFMCUser ? this.fmcFields(user, t) : this.allFields(user, t)
    return (
      <EditForm
        objectName="User"
        object={user}
        getNewFormState={this.getNewFormState}
        allFields={fields}
        editFields={fields}
        saga={"USER_CREATE_OR_UPDATE_SAGA"}
        {...this.props}
      />
    )
  }
} // class UserForm

const mapStateToProps = (state) => {
  return {}
}

export default connect(mapStateToProps)(withTranslation("common")(UserForm))
