import React, { Component } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"

import iconAlert from "../../assets/images/icon-alert.png"
import { navigationPaths } from "../../constants/paths"
import * as vehicleHelpers from "../../helpers/vehicleHelpers"
import { Segment } from "semantic-ui-react"

class Recall extends Component {
  static propTypes = {
    recall: PropTypes.object.isRequired,
    vehicle: PropTypes.object.isRequired
  }

  render() {
    const { recall, vehicle } = this.props

    return (
      <Link className={"row-link"} to={navigationPaths.vehicleShow(vehicle.id)}>
        <Segment className={"dash-upcoming-vehicles-container"}>
          <div className={"recall-info"}>
            <div className={"recall-icon"}>
              <div className={"arrow-container"}>
                <img alt={""} src={iconAlert} className={"icon-alert"} />
              </div>
            </div>

            <div className={"dash-upcoming-vehicle"}>
              <strong>{vehicleHelpers.formattedName(vehicle)}</strong>
              <br />
              {recall.component || recall.payload.Component}
            </div>
          </div>

          {/* NOTE: The recall schedule button has been disabled until further notice. */}
          {/* <div className={'recall-schedule'}>
            <Link
              className={'button--schedule'}
              to={{
                pathname: navigationPaths.serviceRequestNew(),
                state: { vehicleId: vehicle.id }
              }}
            >
              SCHEDULE
            </Link>
          </div> */}
        </Segment>
      </Link>
    )
  }
} // class Recall

export default Recall
