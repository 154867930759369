import { call, put, takeEvery } from "redux-saga/effects"

import api from "../../utilities/api"
import * as apiErrorHelpers from "../../helpers/apiErrorHelpers"
import * as sessionHelpers from "../../helpers/sessionHelpers"

export function* loadMaintenanceHistories(action) {
  try {
    const { payload } = action
    const { skipLoading, startDate, endDate, vehicleId } = payload

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    const url = `/manual_maintenance_histories`
    let body = {}
    if (startDate) {
      body.appointment_datetime_from = startDate
      body.appointment_datetime_to = endDate
    }
    if (vehicleId) body.vehicle_id = vehicleId

    const response = yield call(api.utility.get, api.path(url), { body: body })

    apiErrorHelpers.catchErrors(response.body)

    if (vehicleId) {
      yield put({
        type: "VEHICLE_MAINTENANCE_HISTORIES_LOAD_SUCCEEDED",
        payload: { maintenanceHistories: response.body }
      })
    } else {
      yield put({
        type: "MAINTENANCE_HISTORIES_LOAD_SUCCEEDED",
        payload: { maintenanceHistories: response.body }
      })
    }
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "LOADING_FAILED" })
    yield put({ type: "SET_LOADING_ERROR" })
    yield put({ type: "UNSET_LOADING" })

    apiErrorHelpers.consoleLog(errors)
  }
} // function* loadMaintenanceHistories

export function* createMaintenanceHistory(action) {
  const formData = action.payload

  try {
    yield put({
      type: "MAINTENANCE_HISTORY_CREATE_REQUESTED"
    })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    const request = {
      url: `/vehicles/${formData.vehicle_id}/vehicle_services`,
      body: {
        ...formData,
        with_manual_history: true
      }
    }

    const response = yield call(api.utility.post, api.path(request.url), { body: request.body })

    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: `MAINTENANCE_HISTORY_CREATE_SUCCEEDED`,
      payload: {
        maintenanceHistory: response.body
      }
    })

    const afterRequestData = {
      alertMessage: "Maintenance History created",
      alertType: "success"
    }

    yield call(action.callback, "success", afterRequestData)
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "MAINTENANCE_HISTORY_CREATE_FAILED" })

    const afterRequestData = {
      alertMessage: apiErrorHelpers.toString(errors),
      alertType: "error"
    }

    yield call(action.callback, "failure", afterRequestData)

    apiErrorHelpers.consoleLog(errors)
  }
} // function* createMaintenanceHistory

export function* deleteMaintenanceHistory(action) {
  try {
    yield put({ type: "VEHICLE_MAINTENANCE_HISTORY_DELETE_REQUESTED" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    const recordId = action.payload.recordId

    const request = {
      url: `/manual_maintenance_histories/${recordId}`,
      body: {}
    }

    const response = yield call(api.utility.del, api.path(request.url), { body: request.body })

    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "VEHICLE_MAINTENANCE_HISTORY_DELETE_SUCCEEDED",
      payload: {
        manualMaintenanceHistory: response.body
      }
    })

    const afterRequestData = {
      alertMessage: "Maintenance History deleted",
      alertType: "success"
    }

    yield call(action.callback, "success", afterRequestData)
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "VEHICLE_MAINTENANCE_HISTORY_DELETE_FAILED" })

    const afterRequestData = {
      alertMessage: apiErrorHelpers.toString(errors),
      alertType: "error"
    }

    yield call(action.callback, "failure", afterRequestData)

    apiErrorHelpers.consoleLog(errors)
  }
} // function* deleteMaintenanceHistory

export default function* maintenanceHistorySaga() {
  yield takeEvery("MAINTENANCE_HISTORY_LOAD_SAGA", loadMaintenanceHistories)
  yield takeEvery("MAINTENANCE_HISTORY_CREATE_SAGA", createMaintenanceHistory)
  yield takeEvery("MAINTENANCE_HISTORY_DELETE_SAGA", deleteMaintenanceHistory)
}
