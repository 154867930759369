import React, { Component } from "react"
import PropTypes from "prop-types"
import debounce from "debounce"
import Select from "../../elements/Select"
import TextField from "../../elements/TextField"
import { Checkbox } from "semantic-ui-react"
import FleetFormButtons from "./FleetFormButtons"
import { withTranslation } from "react-i18next"

import * as fleetPaymentTypeHelpers from "../../helpers/fleetPaymentTypeHelpers"

class FleetForm extends Component {
  static propTypes = {
    fleet: PropTypes.object.isRequired,
    onDelete: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,

    isSubmitting: PropTypes.bool,
    paymentTypes: PropTypes.array
  }

  static defaultProps = {
    isSubmitting: false,
    paymentTypes: []
  }

  constructor(props) {
    super(props)

    const { fleet } = props
    const { id, name, other_id, region, rep } = fleet
    this.state = {
      formData: {
        id,
        name,
        otherId: other_id,
        paymentType: this.initialPaymentType(),
        region,
        rep,
        shareWexNumber: false
      },
      isEditing: this.initialIsEditing()
    }
    // debounce the onChange of the Checkbox component to make it set properly
    this.debouncedOnShareWexNumberChange = debounce(this.onShareWexNumberChange, 100)
  }

  allowSubmit() {
    const { name, paymentType } = this.state.formData

    return !this.props.isSubmitting && name.length !== 0 && paymentType.length !== 0
  }

  handleSubmit() {
    const { onSubmit } = this.props

    const { formData } = this.state

    onSubmit(formData)

    // NOTE: Resets to "not editing" mode for update form submit, without showing "edit" button after new form submit.
    if (formData.id !== null) {
      this.setState({ isEditing: false })
    }
  }

  initialIsEditing() {
    const { id } = this.props.fleet
    return id === null
  }

  initialPaymentType() {
    const { fleet, paymentTypes } = this.props

    const defaultPaymentType = ""

    let paymentType = fleet.fleet_payment_type

    // NOTE: If only one payment type option, preselect it.
    if (!paymentType && paymentTypes.length === 1) {
      paymentType = paymentTypes[0].name
    }

    return paymentType || defaultPaymentType
  }

  onCancel() {
    let updatedFormData = { ...this.state.formData }
    const { fleet } = this.props
    const { id, name, other_id, region, rep } = fleet

    updatedFormData = {
      id,
      name,
      otherId: other_id,
      paymentType: this.initialPaymentType(),
      shareWexNumber: false,
      region,
      rep
    }

    this.setState({
      formData: updatedFormData,
      isEditing: false
    })
  }

  onChange(field, value) {
    let updatedFormData = { ...this.state.formData }
    updatedFormData[field] = value

    this.setState({ formData: updatedFormData })
  }

  onShareWexNumberChange = (_event, syntheticEvent) => {
    this.onChange("shareWexNumber", syntheticEvent.checked)
  }

  toggleEditing() {
    const { isEditing } = this.state
    this.setState({ isEditing: !isEditing })
  }

  render() {
    const { onDelete, paymentTypes, canShareWexNumber, t } = this.props

    const { formData, isEditing } = this.state

    const { id, name, otherId, paymentType, region, rep, shareWexNumber } = formData

    return (
      <div>
        <div className={"section_header"}>{t("fleetImpersonation:fleetInformationLabel")}</div>

        <div className={"fleet_form"}>
          <div className={"row row--aligned"}>
            <div className={"column--11"}>
              <div className={"row"}>
                <div className={"column--12"}>
                  <TextField
                    disabled={!isEditing}
                    onChange={(event) => this.onChange("name", event.target.value)}
                    placeholder={t("nameLabel")}
                    value={name}
                  />
                </div>
              </div>

              <div className={"row"}>
                <div className={"column--12"}>
                  <TextField
                    disabled={!isEditing}
                    onChange={(event) => this.onChange("otherId", event.target.value)}
                    placeholder={t("otherIdLabel")}
                    value={otherId}
                  />
                </div>
              </div>

              <div className={"row"}>
                <div className={"column--12"}>
                  <TextField
                    disabled={!isEditing}
                    onChange={(event) => this.onChange("region", event.target.value)}
                    placeholder={t("regionLabel")}
                    value={region}
                  />
                </div>
              </div>

              <div className={"row"}>
                <div className={"column--12"}>
                  <TextField
                    disabled={!isEditing}
                    onChange={(event) => this.onChange("rep", event.target.value)}
                    placeholder={t("repLabel")}
                    value={rep}
                  />
                </div>
              </div>

              <div className={"row"}>
                <div className={"column--12"}>
                  <Select
                    disabled={!isEditing}
                    onChange={(event) => this.onChange("paymentType", event.target.value)}
                    options={fleetPaymentTypeHelpers.selectOptions(paymentTypes)}
                    placeholder={t("paymentTypeLabel")}
                    value={paymentType}
                  />
                </div>
              </div>

              {canShareWexNumber && (
                <div className={"row"}>
                  <div className={"column--12"}>
                    <Checkbox
                      label={"Share Wex account number"}
                      onChange={this.debouncedOnShareWexNumberChange}
                      checked={shareWexNumber}
                    />
                  </div>
                </div>
              )}
              <FleetFormButtons
                allowSubmit={this.allowSubmit()}
                isEditing={isEditing}
                onCancel={this.onCancel.bind(this)}
                onDelete={() => onDelete(this.state.formData.id)}
                onEdit={this.toggleEditing.bind(this)}
                onSubmit={this.handleSubmit.bind(this)}
                fleetId={id}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
} // class FleetForm

export default withTranslation(["fleets"])(FleetForm)
