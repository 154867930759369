import { call, put, takeEvery } from "redux-saga/effects"

import api from "../../utilities/api"
import * as apiErrorHelpers from "../../helpers/apiErrorHelpers"
import * as sessionHelpers from "../../helpers/sessionHelpers"

export function* maintenanceUpdate(action) {
  try {
    yield put({
      type: "MAINTENANCE_UPDATE_REQUESTED"
    })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    const orderId = action.payload.submissionData.id

    const request = {
      url: `/orders/${orderId}`,
      body: {
        ...action.payload.submissionData
      }
    }

    const response = yield call(api.utility.put, api.path(request.url), { body: request.body })

    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "MAINTENANCE_UPDATE_SUCCEEDED",
      payload: { order: response.body }
    })

    const afterRequestData = {
      alertMessage: "Maintenance Updated",
      alertType: "success"
    }

    yield call(action.callback, "success", afterRequestData)
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({
      type: "MAINTENANCE_UPDATE_FAILED"
    })

    const afterRequestData = {
      alertMessage: apiErrorHelpers.toString(errors),
      alertType: "error"
    }

    yield call(action.callback, "failure", afterRequestData)

    apiErrorHelpers.consoleLog(errors)
  }
} // maintenanceApproval()

export function* maintenanceApproval(action) {
  try {
    yield put({
      type: "MAINTENANCE_APPROVAL_REQUESTED"
    })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    const orderId = action.payload.submissionData.id

    const request = {
      url: `/orders/${orderId}`,
      body: {
        ...action.payload.submissionData
      }
    }

    const response = yield call(api.utility.put, api.path(request.url), { body: request.body })

    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "MAINTENANCE_APPROVAL_SUCCEEDED"
    })

    const afterRequestData = {
      alertMessage: "Maintenance Approved",
      alertType: "success"
    }

    yield call(action.callback, "success", afterRequestData)
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({
      type: "MAINTENANCE_APPROVAL_FAILED"
    })

    const afterRequestData = {
      alertMessage: apiErrorHelpers.toString(errors),
      alertType: "error"
    }

    yield call(action.callback, "failure", afterRequestData)

    apiErrorHelpers.consoleLog(errors)
  }
} // maintenanceApproval()

export function* maintenanceCancelation(action) {
  try {
    yield put({ type: "MAINTENANCE_CANCELATION_REQUESTED" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    const orderId = action.payload.submissionData.id

    const request = {
      url: `/orders/${orderId}`,
      body: {
        ...action.payload.submissionData
      }
    }

    const response = yield call(api.utility.put, api.path(request.url), { body: request.body })

    apiErrorHelpers.catchErrors(response.body)

    yield put({ type: "MAINTENANCE_CANCELATION_SUCCEEDED" })

    const afterRequestData = {
      alertMessage: "Maintenance Canceled",
      alertType: "success"
    }

    yield call(action.callback, "success", afterRequestData)
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({
      type: "MAINTENANCE_CANCELATION_FAILED"
    })

    const afterRequestData = {
      alertMessage: apiErrorHelpers.toString(errors),
      alertType: "error"
    }

    yield call(action.callback, "failure", afterRequestData)

    apiErrorHelpers.consoleLog(errors)
  }
} // maintenanceCancelation()

export default function* maintenanceApprovalSaga() {
  yield takeEvery("MAINTENANCE_UPDATE_SAGA", maintenanceUpdate)
  yield takeEvery("MAINTENANCE_APPROVAL_SAGA", maintenanceApproval)
  yield takeEvery("MAINTENANCE_CANCELATION_SAGA", maintenanceCancelation)
}
