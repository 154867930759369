import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import debounce from "debounce"
import { Input, Button, Table, Message } from "semantic-ui-react"
import * as arrayHelpers from "../../helpers/arrayHelpers"
import BraintreeModal from "../shared/BraintreeModal"
import LoadingThrobber from "../shared/LoadingThrobber"
import MiniLoader from "../shared/MiniLoader"
import PaymentMethod from "./PaymentMethod"
import { DRIVER, MANAGER } from "../../constants/roles"
import { withTranslation } from "react-i18next"
import AlertModal from "../shared/AlertModal"
import { PAYMENT } from "../../constants/application"

const HeadersRow = ({ t }) => {
  const headers = [
    { text: t("cardLabel"), width: 4 },
    { text: t("addedByLabel"), width: 3 },
    { text: t("expirationLabel"), width: 3 },
    { text: t("driversLabel"), width: 5 },
    { text: "", width: 1 }
  ]

  return (
    <Table.Row>
      {headers.map((obj, i) => {
        return (
          <Table.HeaderCell singleLine key={i} width={obj.width}>
            {obj.text}
          </Table.HeaderCell>
        )
      })}
    </Table.Row>
  )
}

class PaymentList extends Component {
  constructor(props) {
    super(props)

    this.debouncedSetQuickFilterText = debounce(this.setQuickFilterText, 300)
    this.braintreeModal = React.createRef()
  }

  state = {
    quickFilterText: "",
    modalOpen: false,
    editingId: null,
    delayedAddPaymentButton: false,
    openAlertModal: false,
    paymentIdToDelete: null
  }

  static propTypes = {
    paymentMethods: PropTypes.array.isRequired,
    users: PropTypes.array.isRequired
  }

  componentDidMount() {
    this.props.dispatch({ type: "PAYMENT_METHODS_LOAD_SAGA" })
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isLoading && prevProps.isLoading != this.props.isLoading) {
      this.delayedAddPaymentButton()
    }
  }

  delayedAddPaymentButton = (event) => {
    setTimeout(() => this.setState({ delayedAddPaymentButton: true }), 4000)
  }

  setQuickFilterText = (event) => {
    this.setState({ quickFilterText: event.target.value })
  }

  filteredPaymentMethods = () => {
    const { quickFilterText } = this.state
    const { paymentMethods } = this.props
    if (quickFilterText === "") return paymentMethods

    const regexp = new RegExp(quickFilterText, "i")
    return arrayHelpers.uniq(
      paymentMethods.filter((pm) =>
        Object.keys(pm).find((key) => pm[key].match && pm[key].match(regexp))
      )
    )
  }

  openModal = async () => {
    await this.setState({ modalOpen: true })
    this.braintreeModal.current.setupBraintree()
  }

  closeModal = () => {
    this.setState({ modalOpen: false })
  }

  setEditingId = (id) => {
    this.setState({ editingId: id })
  }

  addPaymentMethod = async (nonce, formData) => {
    await this.props.dispatch({
      type: "PAYMENT_METHOD_CREATE_SAGA",
      payload: { nonce: nonce, formData: formData }
    })
    this.closeModal()
  }

  updatePaymentMethod = async (id, formData) => {
    await this.props.dispatch({
      type: "PAYMENT_METHOD_UPDATE_SAGA",
      payload: { id: id, formData: formData }
    })
    this.setState({ editingId: null })
  }

  deletePaymentMethod = (id, has_active_orders) => {
    if (has_active_orders) {
      this.props.setAlert({
        alertMessage: this.props.t("paymentMethodErrorLabel"),
        alertType: "error"
      })
      return
    }
    this.setState({ openAlertModal: true, paymentIdToDelete: id })
  }

  handleModalState = () => {
    this.setState({ openAlertModal: false })
  }

  render() {
    const { clientToken, isLoading, users, currentUser, paymentMethods, t, language } = this.props
    const { editingId, openAlertModal, paymentIdToDelete } = this.state
    return (
      <React.Fragment>
        <Input
          className="search"
          icon="search"
          onChange={this.setQuickFilterText}
          placeholder={t("searchPaymentsLabel")}
        />
        <BraintreeModal
          ref={this.braintreeModal}
          clientToken={clientToken}
          modalOpen={this.state.modalOpen}
          closeModal={this.closeModal}
          saveCallback={this.addPaymentMethod}
          users={users.filter(function (item) {
            if (
              (item.roles.length == 1 &&
                !item.roles.includes(DRIVER) &&
                !item.roles.includes(MANAGER)) ||
              (item.roles.length == 2 &&
                JSON.stringify(item.roles.sort()) !== JSON.stringify([DRIVER, MANAGER])) ||
              item.roles.length > 2
            )
              return item
          })}
          t={t}
          language={language}
        />
        {!isLoading && this.state.delayedAddPaymentButton ? (
          <>
            <Button
              disabled={isLoading || editingId}
              onClick={this.openModal}
              floated="right"
              size="tiny"
            >
              {t("addNewPaymentLabel")}
            </Button>
          </>
        ) : !isLoading && !this.state.delayedAddPaymentButton ? (
          <div style={{ float: "right" }}>
            <MiniLoader inverted={false} />
            {t("pleaseWaitLabel")}..
          </div>
        ) : (
          ""
        )}
        <LoadingThrobber visible={isLoading} noMargin />
        {!isLoading && paymentMethods.length === 0 && <Message>{t("noPaymentDescLabel")}</Message>}
        {!isLoading && paymentMethods.length > 0 && (
          <Table className="borderless">
            <Table.Header>
              <HeadersRow t={t} />
            </Table.Header>
            <Table.Body>
              {this.filteredPaymentMethods().map((paymentMethod) => {
                const { id, token, customer_ids } = paymentMethod

                return (
                  <PaymentMethod
                    key={token}
                    setEditingId={this.setEditingId}
                    onUpdate={this.updatePaymentMethod}
                    onDelete={this.deletePaymentMethod}
                    paymentMethod={paymentMethod}
                    usersOnPaymentMethod={users.filter(
                      (u) => customer_ids && customer_ids.includes(u.id)
                    )}
                    allUsers={users.filter(function (item) {
                      if (
                        ((item.roles.length == 1 &&
                          !item.roles.includes(DRIVER) &&
                          !item.roles.includes(MANAGER)) ||
                          (item.roles.length == 2 &&
                            JSON.stringify(item.roles.sort()) !==
                              JSON.stringify([DRIVER, MANAGER])) ||
                          item.roles.length > 2) &&
                        item.id !== currentUser.id
                      )
                        return item
                    })}
                    isEditing={editingId === id}
                    currentUserId={currentUser.id}
                    currentUser={currentUser}
                  />
                )
              })}
            </Table.Body>
          </Table>
        )}
        {openAlertModal ? (
          <AlertModal
            openAlertModal={openAlertModal}
            hideModal={this.handleModalState}
            idToDelete={paymentIdToDelete}
            modelFrom={PAYMENT}
          />
        ) : null}
      </React.Fragment>
    )
  }
} // PaymentList

// TODO: figure out the race condition in the application-level isLoading attr to be able to use
// `isLoading: state.application.isLoading,`
const mapStateToProps = (state) => ({
  isLoading: state.paymentMethods.isLoading,
  clientToken: state.paymentMethods.clientToken,
  paymentMethods: state.paymentMethods.paymentMethods,
  language: state.users.currentUser ? state.users.currentUser.language : ""
})

export default connect(mapStateToProps)(withTranslation("settings")(PaymentList))
