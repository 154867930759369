export const initialState = {
  loading: false,
  service_schedules_by_vehicle: {},
  executedForFleetId: null
}

export default function (state = initialState, action) {
  // eslint-disable-next-line
  const { payload, type } = action

  switch (type) {
    case "MAINTENANCE_INTERVAL_SUCCEEDED":
      return {
        ...state,
        loading: payload.loading,
        service_schedules_by_vehicle: {
          ...state.service_schedules_by_vehicle,
          ...payload.service_schedules_by_vehicle
        },
        executedForFleetId: payload.fleetId
      }
    default:
      return state
  }
}
