export function description(paymentTypes, targetPaymentTypeName) {
  let value = ""

  paymentTypes.forEach((paymentType) => {
    if (paymentType.name === targetPaymentTypeName) {
      value = paymentType.description
    }
  })

  return value
}

export function selectOptions(paymentTypes) {
  let options = []

  paymentTypes.forEach((paymentType) => {
    options.push({
      label: paymentType.description,
      value: paymentType.name
    })
  })

  return options
}
