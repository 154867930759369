import React from "react"
import {
  FIRESTONE,
  HIBDON_TIRES_PLUS,
  MICHEL_TIRES_PLUS,
  TIRES_PLUS,
  WHEEL_WORKS
} from "../constants/shops"

import i18n from "../utilities/i18n"
import { formatCurrency } from "../utilities/currency"
import { some } from "lodash"

export function formattedAddress(shop) {
  if (shop == null) return ""

  return `${shop.address_line1}, ${shop.city} ${shop.state} ${shop.zip}`
}

export function formattedName(shop) {
  if (!shop) return null

  let name = `${shop.name}`
  if (shop.city && shop.state) name += ` ${shop.city}, ${shop.state}`
  return name
}

export function googleMapLink(shop) {
  const { address_line1, city, state, zip } = shop

  const formattedAddress = address_line1.replace(/\s/g, "+")

  return `https://www.google.com/maps/place/${formattedAddress},+${city},+${state}+${zip}`
}

export function selectOptions(shops, options = {}) {
  let shopOptions = options.includeBlank ? [{ value: null, label: "" }] : []

  shops.forEach((shop) => {
    shopOptions.push({
      label: `${shop.name} (${shop.address_line1} ${shop.city} ${shop.state})`,
      value: shop.id
    })
  })

  return shopOptions
}

export function UnavailableCompanyMessage(company, t) {
  const unavailableShops = [
    FIRESTONE,
    HIBDON_TIRES_PLUS,
    MICHEL_TIRES_PLUS,
    TIRES_PLUS,
    WHEEL_WORKS
  ]

  if (unavailableShops.includes(company.reference_id)) {
    return (
      <div>
        <strong>
          <p style={{ color: "#FF0000" }}>
            {t("scheduleHelperDesc", { company_name: company.name })}
          </p>
        </strong>
        <br />
      </div>
    )
  }
}

// ==============Code Taken from Retail App Start==================================

const textifiedPrice = ({ price, company }) => {
  return [null, undefined, ""].includes(price) ? noPriceLabel(company) : formatCurrency(price)
}

const summedEstimateConsideringNull = (priceEstimates, attr) => {
  if (
    priceEstimates == null ||
    priceEstimates.length === 0 ||
    some(priceEstimates, (pe) => pe[attr] == null)
  )
    return null

  return priceEstimates.reduce((acc, val) => {
    return acc + Number(val[attr])
  }, 0)
}

export function withPricing(shops) {
  return (shops || []).map((shop) => {
    const shopPriceEstimate = summedEstimateConsideringNull(
      shop.price_estimates,
      "shop_price_estimate"
    )
    const retailPriceEstimate = summedEstimateConsideringNull(
      shop.price_estimates,
      "retail_price_estimate"
    )
    const retailSavings = (shop.company || {}).retail_savings
    const shopSavings = savingsFromDiscount(shopPriceEstimate, retailSavings)

    return {
      ...shop,
      shopPriceEstimate: shopPriceEstimate,
      shopPriceText: textifiedPrice({ price: shopPriceEstimate, company: shop.company }),
      shopSavings: shopSavings,
      shopSavingsText: formatCurrency(shopSavings),
      retailPriceEstimate: retailPriceEstimate,
      retailPriceText: textifiedPrice({ price: retailPriceEstimate, company: shop.company })
    }
  })
}

export function savingsFromDiscount(price, discount) {
  if (!price || !discount) return null

  const decimalSavings = parseFloat(discount) / 100.0
  // This gives the savings that are `discount` percent less than some original price which we back into.
  return price * (decimalSavings / (1 - decimalSavings))
}

export function noPriceLabel(company = {}) {
  return company.pricing_disabled && company.retail_savings
    ? i18n.t("common:percentOffRetailLbl", { percent: parseInt(company.retail_savings) })
    : i18n.t("common:priceAtShopLbl")
}

// ==============Code Taken from Retail App End==================================
