import { getSubdomain } from "../components/shared/StylesheetInjector"
import {
  CARVANA,
  FLEET_AMERICA,
  FLEET_MAINTENANCE_HUB,
  FLEET_MAINTENANCE_HUB_CANADA,
  ZUBIE
} from "../constants/application"

export const SHELL_REFERENCE_ID = "shell"

export function isCarvana() {
  return getSubdomain() === CARVANA
}

export function isFleetAmerica() {
  return getSubdomain() === FLEET_AMERICA
}

export function isFleetMaintenanceHub() {
  return getSubdomain() === FLEET_MAINTENANCE_HUB
}

export function isFleetMaintenanceHubCanada() {
  return getSubdomain() === FLEET_MAINTENANCE_HUB_CANADA
}

export function isZubie() {
  return getSubdomain() === ZUBIE
}

export function shellSignInUrl() {
  if (process.env.REACT_APP_ENVIRONMENT === "production") {
    return "https://www.shellaccountmanager.com"
  } else if (process.env.REACT_APP_ENVIRONMENT === "staging") {
    return "https://shell.sac.wexfleetweb.com/online"
  } else return ""
}

export function isShell(affiliation = {}) {
  return affiliation.root_reference_id === SHELL_REFERENCE_ID
}
