import React, { Component } from "react"
import PropTypes from "prop-types"
import * as agGridHelpers from "../../helpers/agGridHelpers"
import { agGridColumnDefs, agGridRowData } from "../../constants/fleets"
import { navigationPaths } from "../../constants/paths"
import { Link } from "react-router-dom"
import { Segment } from "semantic-ui-react"
import { AgGridReact } from "ag-grid-react"
import { withTranslation } from "react-i18next"
import CustomNoRowsOverlay from "../shared/CustomNoRowsOverlay"

class FleetList extends Component {
  static propTypes = {
    fleets: PropTypes.array.isRequired
  }

  defaultCellRenderer(cellParams) {
    const vehicleId = cellParams.data.id

    return (
      <Link className={"ag-grid-link-column"} to={navigationPaths.fleetEdit(vehicleId)}>
        <div>{cellParams.value}</div>
      </Link>
    )
  }
  render() {
    const { fleets, t } = this.props

    return (
      <Segment
        className={"ag-theme-material"}
        style={{
          height: agGridHelpers.dynamicGridHeight({ items: fleets })
        }}
      >
        <AgGridReact
          columnDefs={agGridColumnDefs(this.defaultCellRenderer, t)}
          defaultColDef={{
            sortable: true,
            filter: true
          }}
          rowData={agGridRowData(fleets)}
          rowSelection={"single"}
          frameworkComponents={{
            customNoRowsOverlay: CustomNoRowsOverlay
          }}
          noRowsOverlayComponent={"customNoRowsOverlay"}
          noRowsOverlayComponentParams={{
            translationFile: "fleets",
            label: "noFleetsLabel"
          }}
          cacheQuickFilter={true}
          pagination={true}
          paginationPageSize={agGridHelpers.PAGINATION_AMOUNT}
          suppressMenuHide={true}
          suppressMovableColumns={false}
          suppressCellSelection={true}
        ></AgGridReact>
      </Segment>
    )
  }
} // class FleetList

export default withTranslation("fleets")(FleetList)
