import React from "react"
import { Input, Grid, Button, Checkbox } from "semantic-ui-react"
import VehicleModal from "./VehicleModal"
import BulkUploadModal from "../../components/shared/BulkUploadModal"
import { useTranslation } from "react-i18next"

export default function VehicleSearch({
  debouncedOnFilterChange,
  resetFiltering,
  loadVehicles,
  filtersPresent,
  sizeToFit,
  debouncedOnSizeToFitChange
}) {
  const { t } = useTranslation("vehicleIndex")

  return (
    <Grid columns="equal" className={"ag-theme-search"} stackable>
      <Grid.Column textAlign="left">
        <Grid stackable>
          <Grid.Column width={6}>
            <Input
              className="search"
              onChange={debouncedOnFilterChange}
              icon="search"
              placeholder={t("searchVechilesLabel")}
            />
          </Grid.Column>
          <Grid.Column width={3} verticalAlign="middle">
            <Checkbox
              label={t("sizeToFitLabel")}
              onChange={debouncedOnSizeToFitChange}
              checked={sizeToFit}
            />
          </Grid.Column>
        </Grid>
      </Grid.Column>
      <Grid.Column textAlign="right">
        {
          <Button className="white-button" onClick={resetFiltering}>
            {t("resetFiltersLabel")}
          </Button>
        }
        <Button.Group>
          <VehicleModal completionCallback={loadVehicles}>
            <Button> {t("addVehicleLabel")}</Button>
          </VehicleModal>
          <Button.Or text={t("orLabel")} />
          <BulkUploadModal completionCallback={loadVehicles}>
            <Button className="last-button">{t("bulkUploadLabel")}</Button>
          </BulkUploadModal>
        </Button.Group>
      </Grid.Column>
    </Grid>
  )
}
