import * as React from "react"
import { Component } from "react"
import { Button, ButtonProps, Label } from "semantic-ui-react"
import * as uuid from "uuid"

export class FileUploadButton extends Component {
  constructor(props) {
    super(props)
    this.id = uuid.v1()
  }

  openFile = () => {
    document.getElementById(this.id).click()
  }

  render() {
    return (
      <div>
        <Button htmlFor={this.id} content={this.props.content} onClick={this.openFile} />
        <input hidden id={this.id} type="file" onChange={this.props.onChangeFile} />
      </div>
    )
  }
}

export default FileUploadButton
