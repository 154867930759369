import { FLEET, FMC } from "../../constants/application"

export const initialState = {
  isLoading: false,
  isLoadingError: false,
  isInnerLoading: false,
  mode: localStorage.getItem("mode") || FLEET
}

export default function (state = initialState, action) {
  // eslint-disable-next-line
  const { payload, type } = action

  switch (type) {
    case "SET_LOADING":
      return {
        ...state,
        isLoading: true
      }
    case "SET_LOADING_ERROR":
      return {
        ...state,
        isLoadingError: true
      }
    case "UNSET_LOADING":
      return {
        ...state,
        isLoading: false
      }
    case "UNSET_LOADING_ERROR":
      return {
        ...state,
        isLoadingError: false
      }
    case "SET_INNER_LOADING":
      return {
        ...state,
        isInnerLoading: true
      }
    case "UNSET_INNER_LOADING":
      return {
        ...state,
        isInnerLoading: false
      }
    case "TOGGLE_MOBILE_MENU":
      return {
        ...state,
        mobileMenuOpen: !state.mobileMenuOpen
      }
    case "FMC_FLIP":
      let mode = action.payload && action.payload.mode
      const newMode = mode || (state.mode == FMC ? FLEET : FMC)
      localStorage.setItem("mode", newMode)

      return {
        ...state,
        mode: newMode,
        isLoadingError: false
      }
    default:
      return state
  }
}
