import React from "react"
import { Segment, Grid } from "semantic-ui-react"
import * as maintenanceHelpers from "../../helpers/maintenanceHelpers"
import { PAST_DUE, DUE_SOON } from "../../helpers/maintenanceHelpers"
import { useTranslation } from "react-i18next"

export default function UpcomingServiceSummaries({
  onClickDueSoon,
  onClickPastDue,
  vehicles,
  onClickAll,
  serviceSchedulesByVehicle
}) {
  const maintenanceData = maintenanceHelpers.upcomingMaintenanceData({
    vehicles,
    serviceSchedulesByVehicle
  })
  const pastDueCount = maintenanceData.filter(({ maintenance, type, vehicle }) => type === PAST_DUE)
    .length
  const dueSoonCount = maintenanceData.filter(({ maintenance, type, vehicle }) => type === DUE_SOON)
    .length
  const { t } = useTranslation("dashboard")

  return (
    <React.Fragment>
      <span />
      <Grid columns="equal" stackable>
        <Grid.Column>
          <Segment onClick={onClickPastDue} vertical className={"dash-stats-item item-width"}>
            {pastDueCount}
            <span>{t("pastDueLabel")}</span>
          </Segment>
        </Grid.Column>
        <Grid.Column>
          <Segment onClick={onClickDueSoon} vertical className={"dash-stats-item item-width"}>
            {dueSoonCount}
            <span>{t("dueSoonLabel")}</span>
          </Segment>
        </Grid.Column>
        <Grid.Column>
          <Segment onClick={onClickAll} vertical className={"dash-stats-item item-width"}>
            {pastDueCount + dueSoonCount}
            <span>{t("services:aggregateTotalLabel")}</span>
          </Segment>
        </Grid.Column>
      </Grid>
    </React.Fragment>
  )
}
