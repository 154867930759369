import { isFleetMaintenanceHubCanada } from "../helpers/affiliationHelpers"

export const formatWithCommas = (number) => {
  if (number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  } else {
    return "-"
  }
}

export const formattedPrice = (price) => {
  if (price == null) return ""

  return typeof price === "number"
    ? isFleetMaintenanceHubCanada()
      ? `$${formatWithCommas(price.toFixed(2))} CAD`
      : `$${formatWithCommas(price.toFixed(2))}`
    : isFleetMaintenanceHubCanada()
    ? `$${formatWithCommas(parseFloat(price).toFixed(2))} CAD`
    : `$${formatWithCommas(parseFloat(price).toFixed(2))}`
}
