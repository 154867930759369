import React, { Component } from "react"
import PropTypes from "prop-types"

import * as arrayHelpers from "../../helpers/arrayHelpers"
import { navigationPaths } from "../../constants/paths"

import { Link } from "react-router-dom"
import { withTranslation } from "react-i18next"

class ServiceRequestGoBackButton extends Component {
  static propTypes = {
    previousFormData: PropTypes.object.isRequired
  }

  render() {
    const { t } = this.props
    return (
      <Link
        className={"button--alternate"}
        to={{
          pathname: navigationPaths.serviceRequestNew(),
          state: {
            previousFormData: this.props.previousFormData
          }
        }}
      >
        {t("goBackLabel")}
      </Link>
    )
  }
} // class ServiceRequestGoBackButton

export default withTranslation("vehicle")(ServiceRequestGoBackButton)
