import React, { Component } from "react"
import PropTypes from "prop-types"

import Button from "../../elements/Button"
import LabeledCheckbox from "../../elements/LabeledCheckbox"
import PasswordField from "../../elements/PasswordField"

class ResetPasswordForm extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    resetToken: PropTypes.string.isRequired,

    isSubmitting: PropTypes.bool
  }

  static defaultProps = {
    isSubmitting: false
  }

  constructor(props) {
    super(props)

    this.state = {
      formData: {
        newPassword: "",
        newPasswordConfirmation: "",
        resetToken: this.props.resetToken
      },
      obfuscateFields: true
    }
  }

  allowSubmit() {
    const { isSubmitting } = this.props

    return !isSubmitting
  }

  onFieldChange(field, value) {
    let updatedFormData = { ...this.state.formData }
    updatedFormData[field] = value
    this.setState({ formData: updatedFormData })
  }

  onObfuscationChange() {
    const { obfuscateFields } = this.state
    this.setState({ obfuscateFields: !obfuscateFields })
  }

  render() {
    const { onSubmit, t } = this.props

    const { formData, obfuscateFields } = this.state

    const { newPassword, newPasswordConfirmation } = formData

    return (
      <div className={"reset_password_form"}>
        <div className={"row"}>
          <div className={"column--12"}>
            <PasswordField
              className={"password_field"}
              obfuscated={obfuscateFields}
              onChange={(event) => this.onFieldChange("newPassword", event.target.value)}
              placeholder={t('newPasswordLabel')}
              value={newPassword}
            />
          </div>
        </div>

        <div className={"row"}>
          <div className={"column--12"}>
            <PasswordField
              className={"password_field"}
              obfuscated={obfuscateFields}
              onChange={(event) =>
                this.onFieldChange("newPasswordConfirmation", event.target.value)
              }
              placeholder={t('newPasswordConfirmLabel')}
              value={newPasswordConfirmation}
            />
          </div>
        </div>

        <div className={"row"}>
          <div className={"column--12"}>
            <LabeledCheckbox
              label={t('showPasswordsLabel')}
              onChange={() => this.onObfuscationChange()}
              checked={!obfuscateFields}
            />
          </div>
        </div>

        <div>
          <div className={"column--12"}>
            <Button
              className={"button--primary reset_password_form__button"}
              disabled={!this.allowSubmit()}
              label={t('submitLabel')}
              onClick={() => onSubmit(this.state.formData)}
            />
          </div>
        </div>
      </div>
    )
  }
} // class ResetPasswordForm

export default ResetPasswordForm
