import React, { Component } from "react"
import PropTypes from "prop-types"

import Checkbox from "./Checkbox"

class LabeledCheckbox extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,

    checked: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool
  }

  static defaultProps = {
    checked: false,
    className: "",
    disabled: false
  }

  render() {
    const { checked, className, disabled, label, onChange } = this.props

    return (
      <label className={className}>
        <Checkbox checked={checked} disabled={disabled} onChange={onChange} />
        {label}
      </label>
    )
  }
} // class LabeledCheckbox

export default LabeledCheckbox
