// NOTE: "Application" functions will add an application prefix to all cookie names. If this is undesired, the standard functions are available.

const cookiePrefix = "fleetmd"

export function deleteApplicationCookie(name) {
  const prefixedName = prefixCookieName(name)
  const result = deleteCookie(prefixedName)
  return result
}

function deleteCookie(name) {
  // This function will attempt to remove a cookie from all paths.
  var pathBits = window.location.pathname.split("/")
  var pathCurrent = " path="

  // do a simple pathless delete first.
  document.cookie = name + "=; expires=Thu, 01-Jan-1970 00:00:01 GMT;"

  for (var i = 0; i < pathBits.length; i++) {
    pathCurrent += (pathCurrent.substr(-1) != "/" ? "/" : "") + pathBits[i]
    document.cookie = name + "=; expires=Thu, 01-Jan-1970 00:00:01 GMT;" + pathCurrent + ";"
  }
}

export function deleteApplicationCookies(names) {
  const prefixedNames = names.map((name) => prefixCookieName(name))
  const result = deleteCookies(prefixedNames)
  return result
}

export function deleteCookies(names) {
  names.forEach((name) => deleteCookie(name))
  return true
}

export function getApplicationCookie(name) {
  const prefixedName = prefixCookieName(name)
  const result = getCookie(prefixedName)
  return result
}

export function getCookie(name) {
  let key = `${name}=`
  let decodedCookie = decodeURIComponent(document.cookie)
  let cookieArray = decodedCookie.split(";")
  let cookieVal, currentCookie

  for (let i = 0; i < cookieArray.length; i++) {
    currentCookie = cookieArray[i]

    while (currentCookie.charAt(0) === " ") {
      currentCookie = currentCookie.substring(1)
    }

    if (currentCookie.indexOf(key) === 0) {
      cookieVal = currentCookie.substring(key.length, currentCookie.length)
      if (cookieVal !== "") return cookieVal
    }
  }

  return ""
}

export function prefixCookieName(name) {
  return `${cookiePrefix}_${name}`
}

export function setApplicationCookie(name, value) {
  const prefixedName = prefixCookieName(name)
  const result = setCookie(prefixedName, value)
  return result
}

export function setCookie(name, value) {
  // NOTE: An explicit path needs to be set to properly set cookies for Internet Explorer.
  document.cookie = `${name}=${value}; path=/;`
  return true
}

export function userSignedIn() {
  const token = getApplicationCookie("authentication_token")

  return ![null, undefined, ""].includes(token)
}
