import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Header, Grid, Container, Divider } from "semantic-ui-react"
import VehicleModal from "./VehicleModal"
import { StateNamesAndAbbreviations } from "../../constants/states"
import * as vehicleHelpers from "../../helpers/vehicleHelpers"
import { FR_LOCALE } from "../../constants/users"
import { withTranslation } from "react-i18next"
import { isFleetMaintenanceHubCanada } from "../../helpers/affiliationHelpers"

class VehicleInfo extends Component {
  static propTypes = {
    vehicle: PropTypes.object.isRequired
  }

  render() {
    const { vehicle, loadVehicle, language, t } = this.props
    let state = StateNamesAndAbbreviations.find(
      (s) => s.abbreviation === vehicle.license_plate_state
    )

    return (
      <Container>
        <Header size="medium">
          {t("vehicleInformationLabel")}&nbsp;&nbsp;&nbsp;
          <VehicleModal completionCallback={loadVehicle} vehicle={vehicle}>
            <span className="link">{t("common:editLabel")}</span>
          </VehicleModal>
        </Header>
        <Divider />
        <Grid compact columns="equal" padded="horizontally">
          <Grid.Column>
            <p>
              {t("vinLabel")}: {vehicle.vin || "-"}
            </p>
            <p>
              {t("dashboard:plateLabel")} #: {vehicleHelpers.formattedLicensePlateNumber(vehicle)}
            </p>
            <p>
              {t("stateLabel")}: {(state && state.name) || vehicle.license_plate_state || "-"}
            </p>
            <p>
              {t("expirationDateLabel")}:{" "}
              {vehicleHelpers.formattedLicensePlateExpiration(vehicle, language)}
            </p>
          </Grid.Column>
          <Grid.Column>
            <p>
              {`${t("dashboard:mileageLabel")}`}: {vehicleHelpers.formattedMiles(vehicle)}
            </p>
            <p>
              {isFleetMaintenanceHubCanada()
                ? `CPK: $${vehicleHelpers.formattedCostPerMile(vehicle)} CAD`
                : `CPM: $${vehicleHelpers.formattedCostPerMile(vehicle)}`}
            </p>
            <p>
              {t("lastMaintenanceLabel")}:{" "}
              {vehicleHelpers.formattedLastMaintenanceDate(vehicle, language)}
            </p>
          </Grid.Column>
        </Grid>
      </Container>
    )
  }
} // class VehicleInfo

const mapStateToProps = (state) => ({
  language: state.users.currentUser && state.users.currentUser.language
})

export default connect(mapStateToProps)(withTranslation("vehicle")(VehicleInfo))
