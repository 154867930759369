import React, { Component } from "react"
import PropTypes from "prop-types"

import Recall from "./Recall"

class Recalls extends Component {
  static propTypes = {
    vehicles: PropTypes.array.isRequired,

    limit: PropTypes.number
  }

  static defaultProps = {
    limit: null
  }

  totalRecallCount() {
    const { vehicles } = this.props

    let count = 0

    vehicles.forEach((vehicle) => {
      count += vehicle.recalls.length
    })

    return count
  }

  renderRecalls() {
    const { limit, vehicles } = this.props

    const renderLimit = limit || this.totalRecallCount()

    let renderedRecallCount = 0
    let recallsToRender = []

    vehicles.forEach((vehicle) => {
      vehicle.recalls.forEach((recall) => {
        if (renderedRecallCount < renderLimit) {
          recallsToRender.push(<Recall recall={recall} vehicle={vehicle} />)

          renderedRecallCount += 1
        }
      })
    })

    return recallsToRender
  }

  render() {
    return <div className={"dash-upcoming"}>{this.renderRecalls()}</div>
  }
} // class Recalls

export default Recalls
