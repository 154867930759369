import React, { PureComponent } from "react"
import { Membership } from "caradvise_shared_components"
import { getStyles, getSubdomain } from "../../components/shared/StylesheetInjector"
import * as sessionHelpers from "../../helpers/sessionHelpers"
import ApplicationLayout from "../../layouts/ApplicationLayout"
import { isFleetMaintenanceHub } from "../../helpers/affiliationHelpers"
import ReactSVG from "react-svg"
import { withTranslation } from "react-i18next"

const styles = getStyles()
class MembershipPanel extends PureComponent {
  state = {
    hideChrome: false
  }

  hideChrome = async () => await this.setState({ hideChrome: true })

  showChrome = async () => await this.setState({ hideChrome: false })

  getPhone = (sub) => {
    // TODO change to this when it goes live. 1-833-ZUBIESM
    const phones = {
      zubie: "1-844-923-8473"
    }

    if (!phones[sub]) return "1-844-923-8473"
    else return phones[sub]
  }

  render() {
    const { t } = this.props
    const currentUserName = sessionHelpers.getApplicationCookie("current_user_name")
    const sub = getSubdomain()
    const phone = this.getPhone(sub)
    let width = 160
    let height = 17
    let imgPath = `${sub}_card_logo.svg`
    if (isFleetMaintenanceHub()) {
      width = 320
      height = 25
      imgPath = "shell_no_session_logo.svg"
    }

    const panel = (
      <Membership.MembershipPanel
        logo={<ReactSVG src={`/images/${imgPath}`} svgStyle={{ width: width, height: height }} />}
        primaryColor={styles.carAdviseOrange}
        hideChrome={this.hideChrome}
        showChrome={this.showChrome}
        forceHideChrome={this.state.hideChrome}
        name={currentUserName}
        gradient={{
          start: styles.primaryColorGradientEnd,
          end: styles.primaryColorGradientStart
        }}
      >
        <br />
        <p>
          {t("membershipAssistanceDescLabel")}{" "}
          <span style={{ fontWeight: "bold", color: styles.primaryColor }}>{phone}.</span>
        </p>
      </Membership.MembershipPanel>
    )

    if (this.state.hideChrome) return panel
    else
      return (
        <ApplicationLayout>
          <div style={{ padding: "20px" }}>{panel}</div>
        </ApplicationLayout>
      )
  }
}

export default withTranslation("membershipCard")(MembershipPanel)
