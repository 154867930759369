import React, { Component } from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMapMarkerAlt } from "@fortawesome/pro-solid-svg-icons"
import { withTranslation } from "react-i18next"
export class CurrentLocation extends Component {
  constructor(props) {
    super(props)

    this.getUsersLocation = this.getUsersLocation.bind(this)
  }

  getUsersLocation() {
    // First check if navigator has permissions interface, only supported in Chrome & Firefox as of writing.
    if (navigator && navigator.permissions) {
      navigator.permissions.query({ name: "geolocation" }).then((result) => {
        if (result.state === "granted" || result.state === "prompt") {
          this.props.onLocationDataRequested()
          this.getLocation()
        } else if (result.state === "denied") {
          this.alertGeolocationUnpermitted()
        }
      })
    } else {
      // If user is for some reason using IE or Safari,just ask for permissions
      this.getLocation()
    }
  }

  getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (data) => {
          this.props.onLocationDataObtained(data)
        },
        (error) => {
          if (error && error.code === error.PERMISSION_DENIED) {
            this.props.onLocationDataError()
            this.alertGeolocationUnpermitted()
          }
        }
      )
    }
  }

  alertGeolocationUnpermitted() {
    alert(this.props.t("enableLocationLabel"))
  }

  render() {
    const { visible, t } = this.props

    return (
      <div
        className={`current-location__container ${
          !visible ? "current-location__container--hidden" : ""
        }`}
        onMouseDown={this.getUsersLocation}
      >
        <FontAwesomeIcon icon={faMapMarkerAlt} /> &nbsp;
        <p>{t("useMyLocationLabel")}</p>
      </div>
    )
  }
}

CurrentLocation.propTypes = {
  onLocationDataObtained: PropTypes.func.isRequired,
  onLocationDataRequested: PropTypes.func,
  onLocationDataError: PropTypes.func
}

CurrentLocation.defaultProps = {
  onLocationDataRequested: () => {},
  onLocationDataError: () => {}
}

export default withTranslation("common")(CurrentLocation)
