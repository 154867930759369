import React, { Component } from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"

class LoadingError extends Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired
  }

  render() {
    const { visible, t } = this.props

    if (visible) {
      return <div className={"loading_error"}>{t("somethingWentWrongelabel")}</div>
    } else {
      return null
    }
  }
} // class LoadingError

export default withTranslation("common")(LoadingError)
