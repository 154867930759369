import React, { Component } from "react"
import PropTypes from "prop-types"

import { Table } from "semantic-ui-react"
import ScheduleServiceButton from "../shared/ScheduleServiceButton"
import { isFleetMaintenanceHubCanada } from "../../helpers/affiliationHelpers"

class MaintenanceScheduleItem extends Component {
  static propTypes = {
    upcomingMaintenance: PropTypes.object.isRequired,
    vehicle: PropTypes.object.isRequired,
    language: PropTypes.object.isRequired
  }

  render() {
    const { upcomingMaintenance, vehicle, language } = this.props

    return (
      <Table.Row>
        <Table.Cell>{upcomingMaintenance.name}</Table.Cell>
        <Table.Cell>
          {isFleetMaintenanceHubCanada()
            ? upcomingMaintenance.interval_kilometers
            : upcomingMaintenance.interval}
        </Table.Cell>
        <Table.Cell>
          <ScheduleServiceButton maintenance={[upcomingMaintenance]} vehicle={vehicle} />
        </Table.Cell>
      </Table.Row>
    )
  }
} // class MaintenanceScheduleItem

export default MaintenanceScheduleItem
