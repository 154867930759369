import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import Alert from "../../components/shared/Alert"
import ApplicationLayout from "../../layouts/ApplicationLayout"
import LoadingError from "../../components/shared/LoadingError"
import LoadingThrobber from "../../components/shared/LoadingThrobber"
import MaintenancePolicies from "../../components/maintenance_policies/MaintenancePolicies"
import GeotabTelematicsData from "../../components/geotab_telematics/GeotabTelematicsData"
import { Segment, Menu, Header } from "semantic-ui-react"
import UserList from "../../components/users/UserList"
import PaymentList from "../../components/payments/PaymentList"
import WexPaymentList from "../../components/payments/WexPaymentList"
import { isOwnerManagerOrAdmin } from "../../helpers/activeMaintenanceHelpers"
import { isWex } from "../../helpers/userHelpers"
import { isFleetMaintenanceHub } from "../../helpers/affiliationHelpers"
import { SUPER_ADMIN } from "../../constants/roles"
import { withTranslation } from "react-i18next"
import AlertModal from "../../components/shared/AlertModal"
import { SETTINGS_USER } from "../../constants/application"

class SettingsIndex extends Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    isLoadingError: PropTypes.bool.isRequired,
    policies: PropTypes.array.isRequired,
    services: PropTypes.array.isRequired,
    users: PropTypes.array.isRequired,
    vehicles: PropTypes.array.isRequired,
    paymentMethods: PropTypes.array.isRequired
  }

  state = {
    activePage: "users",
    alertMessage: "",
    alertType: "default",
    isSubmitting: false,
    openAlertModal: false,
    userIdToDelete: null
  }

  componentDidMount() {
    this.loadSettings()
  }

  defaultPages() {
    return [
      {
        name: "users",
        label: "usersLabel",
        component: () => {
          return (
            <UserList
              users={this.props.users.filter((u) => {
                if (u.roles == null) return false

                return !u.roles.find((role) => {
                  // Roles seems to sometimes be coming in as an object, sometimes as a string.
                  // TODO: standardize this
                  return [role, role.name].find((r) => [SUPER_ADMIN].includes(r))
                })
              })}
              onDelete={this.onDeleteUser}
              loadUsers={this.loadUsers}
              currentUser={this.props.currentUser}
            />
          )
        }
      },
      {
        name: "policies",
        label: "policiesLabel",
        component: () => (
          <MaintenancePolicies
            isSubmitting={this.state.isSubmitting}
            onDelete={this.onDeletePolicy}
            onSubmit={this.onSubmitPolicy}
            policies={this.props.policies}
            services={this.props.services}
            users={this.props.users}
            vehicles={this.props.vehicles}
            currentUser={this.props.currentUser}
            language={this.props.language}
          />
        )
      }
    ]
  }

  allPages() {
    let pages = this.defaultPages()
    const { fleet, currentUser } = this.props

    if (fleet && fleet.pays_through_platform && isOwnerManagerOrAdmin(currentUser)) {
      pages.push(this.paymentsPage())
    }

    if (isFleetMaintenanceHub()) {
      pages.push(this.geotabTelematicsDatabasePage())
    }

    return pages
  }

  geotabTelematicsDatabasePage() {
    return {
      name: "geotabTelematicsData",
      label: "shellTelematicsLabel",
      component: () => (
        <GeotabTelematicsData
          isSubmitting={this.state.isSubmitting}
          onSubmitDatabaseName={this.onSubmitGeotabDatabaseName}
          onCreateGeotabSyncEvent={this.onCreateGeotabSyncEvent}
        />
      )
    }
  }

  paymentsPage() {
    return {
      name: "payments",
      label: "paymentLabel",
      component: () => {
        const { users, currentUser } = this.props

        if (isWex(currentUser)) return <WexPaymentList currentUser={currentUser} />
        else
          return (
            <PaymentList users={users} currentUser={currentUser} setAlert={this.onRequestFailure} />
          )
      }
    }
  }

  handlePageChange = async (page) => {
    await this.setState({ activePage: page })
  }

  afterRequest(status, data) {
    this.setState({ isSubmitting: false })

    if (status === "success") {
      this.onRequestSuccess(data)
    } else {
      this.onRequestFailure(data)
    }
  }

  loadUsers = async () => {
    await this.props.dispatch({
      type: "USERS_LOAD_SAGA"
    })
  }

  loadSettings = async () => {
    await this.props.dispatch({
      type: "MAINTENANCE_POLICIES_LOAD_SAGA"
    })
  }

  onDeleteUser = (userId) => {
    this.setState({ openAlertModal: true, userIdToDelete: userId })
  }

  onDeleteUserSuccess(data) {
    this.props.history.push({
      state: {
        alertMessage: data.alertMessage,
        alertType: data.alertType
      }
    })
  }

  afterDeleteUser(status, data) {
    if (status === "success") {
      this.onDeleteUserSuccess(data)
    } else {
      this.onRequestFailure(data)
    }
  }

  handleModalState = () => {
    this.setState({ openAlertModal: false })
  }

  onDeletePolicy = (policyId) => {
    this.setState({ isSubmitting: true })

    this.props.dispatch({
      type: "MAINTENANCE_POLICY_DELETE_SAGA",
      payload: { policyId: policyId, t: this.props.t },
      callback: this.afterRequest.bind(this)
    })
  }

  onCreateGeotabSyncEvent = () => {
    this.setState({ isSubmitting: true })
    this.props.dispatch({
      type: "CREATE_GEOTAB_SYNC_EVENT_SAGA",
      callback: this.afterRequest.bind(this)
    })
  }

  onSubmitGeotabDatabaseName = (databaseName) => {
    this.setState({ isSubmitting: true })
    this.props.dispatch({
      type: "FLEET_UPDATE_SAGA",
      payload: { id: this.props.fleet.id, databaseName },
      callback: this.afterRequest.bind(this)
    })
  }

  onRequestFailure = (data) => {
    this.setState({
      alertMessage: data.alertMessage,
      alertType: data.alertType
    })
  }

  onRequestSuccess(data) {
    this.setState({
      alertMessage: data.alertMessage,
      alertType: data.alertType
    })

    this.loadSettings()
  }

  onSubmitPolicy = (formData) => {
    this.setState({ isSubmitting: true })

    let sagaType

    if (formData.id === null) {
      sagaType = "MAINTENANCE_POLICY_CREATE_SAGA"
    } else {
      sagaType = "MAINTENANCE_POLICY_UPDATE_SAGA"
    }

    this.props.dispatch({
      type: sagaType,
      payload: { formData: formData, t: this.props.t },
      callback: this.afterRequest.bind(this)
    })
  }

  shouldRenderContent() {
    const { isLoading, isLoadingError } = this.props
    const { isSubmitting } = this.state

    return (
      (!isLoading && !isLoadingError && !isSubmitting) ||
      (!isLoadingError && this.state.activePage === "payments")
    )
  }

  renderContent() {
    const { isLoading, t } = this.props
    const { isSubmitting, activePage } = this.state
    const pages = this.allPages()
    const page = pages.find((p) => p.name === activePage)

    return (
      <div>
        <span />
        <Header size="small" className={"dash-hd"}>
          {t("settingsLabel")}
        </Header>
        <Segment.Group>
          <Segment className="menu-container">
            <Menu pointing secondary>
              {pages.map((page) => {
                return (
                  <Menu.Item
                    name={t(page.label)}
                    active={page.name === activePage}
                    onClick={this.handlePageChange.bind(this, page.name)}
                  />
                )
              })}
            </Menu>
          </Segment>
          <Segment className="tab-container">
            {!["payments", "geotabTelematicsData"].includes(activePage) &&
            (isSubmitting || isLoading) ? (
              <LoadingThrobber visible />
            ) : (
              page.component()
            )}
          </Segment>
        </Segment.Group>
      </div>
    )
  }

  render() {
    const { isLoading, isLoadingError } = this.props

    const { alertMessage, alertType, openAlertModal, userIdToDelete } = this.state

    return (
      <ApplicationLayout>
        <Alert message={alertMessage} type={alertType} visible={alertMessage !== ""} />

        {!isLoading && isLoadingError ? <LoadingError visible /> : this.renderContent()}
        {openAlertModal ? (
          <AlertModal
            openAlertModal={openAlertModal}
            hideModal={this.handleModalState}
            idToDelete={userIdToDelete}
            afterDelete={this.afterDeleteUser}
            that={this}
            modelFrom={SETTINGS_USER}
          />
        ) : null}
      </ApplicationLayout>
    )
  }
} // class SettingsIndex

const mapStateToProps = (state) => ({
  isLoading: state.application.isLoading,
  isLoadingError: state.application.isLoadingError,
  policies: state.maintenancePolicies.policies,
  services: state.services.services,
  users: state.users.users,
  userRoles: state.users.userRoles,
  vehicles: state.vehicles.vehicles,
  fleet: state.fleets.fleet,
  currentUser: state.users.currentUser,
  language: state.users.currentUser ? state.users.currentUser.language : ""
})

export default connect(mapStateToProps)(withTranslation("settings")(SettingsIndex))
