import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import * as arrayHelpers from "../../helpers/arrayHelpers"
import * as vehicleHelpers from "../../helpers/vehicleHelpers"
import { formatCurrency } from "../../utilities/currency"
import { Header, List, Container, Divider } from "semantic-ui-react"
import MiniLoader from "../shared/MiniLoader"
import { withTranslation } from "react-i18next"

class ServiceRequestSummary extends Component {
  static propTypes = {
    previousFormData: PropTypes.object.isRequired
  }

  shopName() {
    const { shop } = this.props.previousFormData

    if (shop) {
      return `${shop.name} (${shop.address_line1} ${shop.city} ${shop.state})`
    } else {
      return ""
    }
  }

  vehicleName() {
    const { vehicle } = this.props.previousFormData

    if (vehicle) {
      return vehicleHelpers.formattedName(vehicle, { displayOtherID: true })
    } else {
      return ""
    }
  }

  renderServices() {
    const { order_services } = this.props.previousFormData
    const { servicePrices, servicePricesLoading } = this.props

    return order_services.map((order_service, index) => {
      let matchingPrice = servicePrices.find((price) => price.id === order_service.id)
      if (matchingPrice && matchingPrice.price_estimates) {
        matchingPrice = matchingPrice.price_estimates
      } else {
        matchingPrice = []
      }
      let servicePrice
      let serviceName = order_service.name

      if (order_service.hasOwnProperty("position")) {
        serviceName += ` (${order_service.position})`

        servicePrice = matchingPrice.find((pe) => pe.position === order_service.position) || {}
        servicePrice = formatCurrency(servicePrice.shop_price_estimate)
      } else {
        servicePrice = matchingPrice[0]
        if (servicePrice && servicePrice.shop_price_estimate) {
          servicePrice = formatCurrency(servicePrice.shop_price_estimate)
        } else if (servicePrice) {
          servicePrice = this.props.t("common:priceAtShopLbl")
        }
      }

      return (
        <div className="service_request_summary__service" key={index}>
          {serviceName}
          {servicePricesLoading ? (
            <MiniLoader inverted={false} />
          ) : (
            <div className="approvals_form_field__price_shop" style={{ marginLeft: "10px" }}>
              {servicePrice}
            </div>
          )}
        </div>
      )
    })
  }

  render() {
    const { customer_comments } = this.props.previousFormData
    const { t } = this.props
    return (
      <div>
        <Container fluid>
          <Header as="h3">{t("dashboard:vehicleLabel")}:</Header>
          <p>{this.vehicleName()}</p>

          <Header as="h3">{t("shops:shopLabel")}:</Header>
          <p>{this.shopName()}</p>

          <Header as="h3">{t("maintenanceHistory:servicesLabel")}</Header>
          <p>{this.renderServices()}</p>

          <Header as="h3">{t("customerCommentsLabel")}:</Header>
          <p>{customer_comments}</p>
        </Container>
      </div>
    )
  }
} // class ServiceRequestSummary

const mapStateToProps = (state) => {
  return {
    servicePrices: state.services.servicePrices || [],
    servicePricesLoading: state.services.servicePricesLoading
  }
}

export default connect(mapStateToProps)(withTranslation("vehicle")(ServiceRequestSummary))
