import React, { Component } from "react"
import PropTypes from "prop-types"

import ActiveMaintenance from "./ActiveMaintenance"

class ActiveMaintenances extends Component {
  static propTypes = {
    onAcceptAllSubmit: PropTypes.func.isRequired,
    onCancelationSubmit: PropTypes.func.isRequired,
    users: PropTypes.array.isRequired,
    vehicles: PropTypes.array.isRequired,

    isSubmitting: PropTypes.bool,
    limit: PropTypes.number
  }

  static defaultProps = {
    isSubmitting: false,
    limit: null
  }

  totalActiveMaintenanceCount() {
    const { vehicles } = this.props

    let count = 0

    vehicles.forEach((vehicle) => {
      if (vehicle.active_order) {
        count += 1
      }
    })

    return count
  }

  renderActiveMaintenances() {
    const {
      isSubmitting,
      limit,
      onAcceptAllSubmit,
      onCancelationSubmit,
      users,
      vehicles
    } = this.props

    const renderLimit = limit || this.totalActiveMaintenanceCount()

    let componentsToRender = []

    vehicles.forEach((vehicle) => {
      if (vehicle.active_order) {
        if (componentsToRender.length < renderLimit) {
          componentsToRender.push(
            <ActiveMaintenance
              isSubmitting={isSubmitting}
              key={componentsToRender.length}
              onAcceptAllSubmit={onAcceptAllSubmit}
              onCancelationSubmit={onCancelationSubmit}
              users={users}
              vehicle={vehicle}
            />
          )
        }
      }
    })

    return componentsToRender
  }

  render() {
    return <div>{this.renderActiveMaintenances()}</div>
  }
} // class ActiveMaintenances

export default ActiveMaintenances
