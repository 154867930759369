import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { StateNamesAndAbbreviations } from "../../constants/states"
import { years } from "../../constants/years"
import EditModal from "../shared/EditModal"
import * as userRoleHelpers from "../../helpers/userRoleHelpers"
import * as vehicleHelpers from "../../helpers/vehicleHelpers"
import * as userHelpers from "../../helpers/userHelpers"
import { getSubdomain } from "../shared/StylesheetInjector"
import { FMC, FMC_FLEET_ADMIN, FMC_FLEET_MANAGER, SUPER_ADMIN } from "../../constants/roles"
import { sortBy } from "lodash"
import { withTranslation } from "react-i18next"

import { List } from "semantic-ui-react"

class UserModal extends Component {
  allFields = () => {
    const { isFMC, t } = this.props
    const rolesHelper = (
      <List
        items={[
          "Owner: Owner of the Fleet.",
          "Admin: Administrator of the Fleet. Add users, vehicles and set policies.",
          "Driver: Fleet Driver. Can view assigned vehicle.",
          "Manager: Manager of the Fleet. Can set policies and assign drivers."
        ]}
      />
    )
    const fmcRolesHelper = (
      <List
        items={[
          "FMC: Owner of the Fleet.",
          "FMC Admin: Administrator of the Fleet. Add users, vehicles and set policies.",
          "FMC Manager: Manager of the Fleet. Can set policies and assign drivers."
        ]}
      />
    )
    const approvalFleetsHelper = (
      <div>
        When a vehicle gets service that exceeds dollar amounts set by policies, an FMC may need to
        approve. Select which fleets this user should be notified about.
      </div>
    )

    let opts = {
      1: [
        { placeholder: t("firstNameLabel"), optional: false, fieldName: "firstName" },
        { placeholder: t("lastNameLabel"), optional: false, fieldName: "lastName" },
        {
          placeholder: t("emailLabel"),
          optional: false,
          fieldName: "email",
          specialValidations: ["email"]
        },
        {
          placeholder: t("phoneNumberLabel"),
          optional: false,
          fieldName: "cellPhone",
          specialValidations: ["phone"]
        }
      ]
    }
    if (!isFMC) {
      opts[1].push({
        placeholder: t("rolesLabel"),
        optional: true,
        fieldName: "roles",
        valueSet: "userRoles",
        helpModal: rolesHelper
      })
      opts[1].push({
        placeholder: t("vehiclesLabel"),
        optional: true,
        fieldName: "vehicle_ids",
        valueSet: "vehicles"
      })
      opts[2] = [
        {
          placeholder: <strong>{t("textPreferencesLabel")}</strong>,
          optional: true,
          fieldName: "text_notification_preference_attributes"
        }
      ]

      opts[3] = [
        {
          placeholder: <strong>{t("emailPreferencesLabel")}</strong>,
          optional: true,
          fieldName: "email_notification_preference_attributes"
        }
      ]
    }
    if (isFMC) {
      opts[1].push({
        placeholder: t("rolesLabel"),
        fieldName: "roles",
        valueSet: "userRoles",
        helpModal: fmcRolesHelper
      })
      opts[1].push({
        placeholder: t("fleetsLabel"),
        optional: true,
        fieldName: "approvalFleetIds",
        valueSet: "fleets",
        helpModal: approvalFleetsHelper
      })
    }

    return opts
  }

  static propTypes = {
    user: PropTypes.object.isRequired,
    completionCallback: PropTypes.func.isRequired
  }

  static defaultProps = {
    user: {},
    completionCallback: () => {}
  }

  isFleetAmerica = () => {
    const sub = getSubdomain()
    return sub == "fleetamerica"
  }

  getNewFormState = (user) => {
    let textNotificationPreference
    let emailNotificationPreference
    let defaultTextNotificationPreference = userHelpers.DEFAULT_TEXT_NOTIFICATION_PREFERENCE
    let defaultEmailNotificationPreference = userHelpers.DEFAULT_EMAIL_NOTIFICATION_PREFERENCE

    const { isFMC, currentUser } = this.props

    if (this.isFleetAmerica()) {
      delete defaultTextNotificationPreference.one_hour_reminder
    }

    if (user.text_notification_preference) {
      if (this.isFleetAmerica()) {
        delete user.text_notification_preference.one_hour_reminder
      }
      textNotificationPreference = { ...user.text_notification_preference }
      userHelpers.mapPreferencesToDefaults(
        textNotificationPreference,
        defaultTextNotificationPreference
      )
    }

    if (user.email_notification_preference) {
      emailNotificationPreference = { ...user.email_notification_preference }
      userHelpers.mapPreferencesToDefaults(
        emailNotificationPreference,
        defaultEmailNotificationPreference
      )
    }

    let roles = (user.roles || []).map((r) => (typeof r === "string" ? r : r.name))

    let newFormState = {
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      cellPhone: user.cellPhone,
      other_id: user.other_id,
      roles: roles,
      vehicle_ids: user.vehicles ? user.vehicles.map((v) => v.id) : [],
      approvalFleetIds: isFMC ? (user.approval_fleets || []).map((fleet) => fleet.id) : null,
      payment_id: user.payment_id,
      text_notification_preference_attributes:
        textNotificationPreference || defaultTextNotificationPreference,
      email_notification_preference_attributes:
        emailNotificationPreference || defaultEmailNotificationPreference
    }
    if (isFMC) {
      newFormState = {
        ...newFormState,
        affiliationId: currentUser && currentUser.affiliation_id
      }
    }

    return newFormState
  }

  render() {
    const { user, userRoles, isFMC, currentUser } = this.props
    const roles = [FMC_FLEET_ADMIN, FMC_FLEET_MANAGER, FMC]
    const without_fmc_roles = [FMC_FLEET_ADMIN, FMC_FLEET_MANAGER]

    return (
      <>
        {(user.roles != undefined && !isFMC && user.roles.some((r) => roles.indexOf(r) >= 0)) ||
        (user.roles != undefined &&
          isFMC &&
          currentUser.roles.some((r) => without_fmc_roles.indexOf(r) >= 0) &&
          !currentUser.roles.includes(FMC)) ? (
          ""
        ) : (
          <EditModal
            objectName="User"
            object={user}
            getNewFormState={this.getNewFormState}
            allFields={this.allFields()}
            editFields={this.allFields()}
            saga={"USER_CREATE_OR_UPDATE_SAGA"}
            {...this.props}
          ></EditModal>
        )}
      </>
    )
  }
} // class UserModal

const mapStateToProps = (state) => {
  const { userRoles } = state.users
  const { vehicles } = state.vehicles
  const { fleets } = state.fleets
  return {
    userRoles: userRoles.map((role) => ({ key: role.id, value: role.name, text: role.name })) || [],
    vehicles: vehicleHelpers.selectOptions(vehicles, { semantic: true }),
    fleets: sortBy(fleets, "name").map((fleet) => {
      return { key: fleet.id, value: fleet.id, text: fleet.name }
    })
  }
}

export default connect(mapStateToProps)(withTranslation("common")(UserModal))
