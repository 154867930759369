export const initialState = {
  services: [],
  servicePrices: [],
  servicePricesLoading: false
}

export default function (state = initialState, action) {
  // eslint-disable-next-line
  const { payload, type } = action

  switch (type) {
    case "SERVICES_LOAD_SUCCEEDED":
      return {
        ...state,
        services: action.payload.services
      }
    case "SERVICE_COSTS_LOAD_SUCCEEDED":
      return {
        ...state,
        serviceCosts: action.payload.serviceCosts
      }
    case "SERVICE_PRICES_LOAD_SUCCEEDED":
      return {
        ...state,
        servicePrices: action.payload.servicePrices
      }
    case "SET_SERVICE_PRICES_LOADING":
      return {
        ...state,
        servicePricesLoading: true
      }
    case "UNSET_SERVICE_PRICES_LOADING":
      return {
        ...state,
        servicePricesLoading: false
      }
    default:
      return state
  }
}
