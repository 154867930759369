import React, { Component } from "react"

import Nav from "../components/shared/Nav"
import SiteHeader from "../components/shared/SiteHeader"
import { Grid, Responsive } from "semantic-ui-react"
import { connect } from "react-redux"

class ApplicationLayout extends Component {
  render() {
    const { children, mobileMenuOpen } = this.props

    return (
      <Grid columns="equal">
        <Nav />
        <Grid.Column className="main">
          <Grid.Row>
            <SiteHeader />
          </Grid.Row>
          <Grid.Row stretched className="content">
            {children}
          </Grid.Row>
        </Grid.Column>
      </Grid>
    )
  }
} // class ApplicationLayout

const mapStateToProps = (state) => ({
  mobileMenuOpen: state.application.mobileMenuOpen
})

export default connect(mapStateToProps)(ApplicationLayout)
