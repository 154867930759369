import * as maintenanceHelpers from "../helpers/maintenanceHelpers"
import * as vehicleHelpers from "../helpers/vehicleHelpers"
import * as shopHelpers from "../helpers/shopHelpers"
import { PAID_STATUSES, COMPLETED, PAID, DISPLAY_STATUSES } from "./statuses"

import moment from "moment"
import { FR_LOCALE } from "./users"
import { Date_Format } from "./years"
import { KILOMETERS, MILES } from "./vehicles"
import { isFleetMaintenanceHubCanada } from "../helpers/affiliationHelpers"

export const ACTIVE = "active"
export const PAST = "past"
export const UPCOMING = "upcoming"
export const SCHEDULE_BUTTON_FIELD = "schedule_now"

export const MANUAL_MAINTENANCE_TYPE = "Manually Entered"
export const FLEETADVISE_MAINTENANCE_TYPE = "FleetAdvise"

export function orderGridColumnDefs(
  page,
  servicesCellRenderer,
  schedulerCellRenderer,
  statusCellRenderer,
  t
) {
  const widths = {
    xsmall: 125,
    small: 150,
    medium: 175,
    large: 200,
    xlarge: 225,
    xxlarge: 250,
    xxxlarge: 300
  }

  let arr = [
    {
      headerName: t("vehicle:dateLabel"),
      field: "appointment_datetime",
      width: widths.medium,
      filter: "agDateColumnFilter",
      valueFormatter: (data) =>
        isFleetMaintenanceHubCanada() && data.value
          ? data.value.format(Date_Format.YYYY_MM_DD)
          : data.value
          ? data.value.format("L")
          : ""
    },
    {
      headerName: t("dashboard:vehicleLabel"),
      field: "vehicle",
      width: widths.xlarge
    },
    {
      headerName: t("vehicle:vinLabel"),
      field: "vin",
      width: widths.medium
    },
    {
      headerName: t("dashboard:otherIdLabel"),
      field: "other_id",
      width: widths.medium
    },
    {
      headerName: t("shops:shopLabel"),
      field: "shop",
      width: widths.medium,
      resizable: true
    },
    {
      headerName: t("settings:servicesLabel"),
      field: "services",
      width: widths.xxxlarge,
      resizable: true,
      cellRendererFramework: servicesCellRenderer
    },
    {
      headerName: "PO #",
      field: "po_number",
      width: widths.medium
    },
    {
      headerName: t("vehicle:costLabel"),
      field: "total",
      width: widths.medium,
      valueFormatter: (data) =>
        isFleetMaintenanceHubCanada() && data.value
          ? `$${data.value} CAD`
          : data.value
          ? `$${data.value}`
          : ""
    },
    {
      headerName: t("invoiceLabel"),
      field: "invoice_number",
      width: widths.medium
    },
    {
      headerName: t("maintenanceTypeLabel"),
      field: "maintenance_type",
      width: widths.medium
    }
  ]

  if (page !== PAST)
    arr.splice(1, 0, {
      headerName: t("timeLabel"),
      field: "time",
      width: widths.medium
    })

  arr.splice(arr.length - 1, 0, {
    headerName: t("statusLabel"),
    field: "status",
    width: widths.xxlarge,
    cellRendererFramework: statusCellRenderer
  })

  return arr
}

export function orderGridRowData(shopOrders, language) {
  return shopOrders.map((order) => {
    const vehicle = order.vehicle
    return {
      appointment_datetime: moment(order.appointment_datetime).startOf("day"),
      vehicle: vehicleHelpers.formattedName(vehicle),
      shop: shopHelpers.formattedName(order.shop),
      other_id: vehicle.other_id || "N/A",
      po_number: order.po_number,
      status: {
        status: PAID_STATUSES.indexOf(order.status) === -1 ? order.status : COMPLETED,
        id: order.id
      },
      time: order.appointment_time_pretty,
      invoice_number: order.invoice_number,
      services: order.order_services
        .filter((os) => os.status !== "rejected" && os.status !== "deleted")
        .map((s) => `${s.name}${s.position ? ` (${s.position})` : ""}`)
        .join(", "),
      total: order.total && parseFloat(order.total).toFixed(2),
      // NOTE: The ID column does not get displayed because there is no mapping for it in the columnDefs, however, providing here makes it available to the row's data attribute.
      id: order.id,
      maintenance_type: order.maintenance_type || FLEETADVISE_MAINTENANCE_TYPE,
      vin: vehicle.vin,
      vehicle_id: vehicle.id,
      language
    }
  })
}

export function maintGridColumnDefs(
  page,
  servicesCellRenderer,
  schedulerCellRenderer,
  statusCellRenderer,
  t
) {
  const widths = {
    xsmall: 125,
    small: 150,
    medium: 175,
    large: 200,
    xlarge: 225,
    xxlarge: 250,
    xxxlarge: 300
  }

  return [
    {
      headerName: t("dashboard:vehicleLabel"),
      field: "vehicle",
      width: widths.xlarge
    },
    {
      headerName: t("dashboard:otherIdLabel"),
      field: "other_id",
      width: widths.medium
    },
    {
      headerName: t("vehicle:vinLabel"),
      field: "vin",
      width: widths.medium
    },
    {
      headerName: isFleetMaintenanceHubCanada() ? t("kilometersLabel") : "Miles",
      field: isFleetMaintenanceHubCanada() ? KILOMETERS : MILES,
      width: widths.small
    },
    {
      headerName: t("dueAtLabel"),
      field: "due_at",
      width: widths.small
    },
    {
      headerName: t("dueStatusLabel"),
      field: "due_status",
      width: widths.medium,
      filter: "agTextColumnFilter"
    },
    {
      headerName: t("settings:servicesLabel"),
      field: "services",
      width: widths.xxxlarge,
      resizable: true,
      cellRendererFramework: servicesCellRenderer
    },
    {
      headerName: "",
      field: SCHEDULE_BUTTON_FIELD,
      width: widths.xlarge,
      resizable: true,
      cellRendererFramework: schedulerCellRenderer
    }
  ]
}

export function maintGridRowData(maintenancesAndVehicles, language) {
  return maintenancesAndVehicles
    .map(({ vehicle, maintenance, type }) => ({
      id: vehicle.id,
      vehicle: vehicleHelpers.formattedName(vehicle),
      other_id: vehicle.other_id || "N/A",
      [isFleetMaintenanceHubCanada() ? KILOMETERS : MILES]: isFleetMaintenanceHubCanada()
        ? vehicle.kilometers
        : vehicle.miles,
      due_at: maintenanceHelpers.earliestMaintenanceDate(maintenance, language) || "N/A",
      due_status: type,
      services: maintenance
        .map((s) => `${s.name}${s.position ? ` (${s.position})` : ""}`)
        .join(", "),
      schedule_now: { maintenance: maintenance, vehicle: vehicle }
    }))
    .sort((a, b) =>
      isInteger(a.due_at) && isInteger(b.due_at)
        ? a.due_at - b.due_at
        : isInteger(a.due_at)
        ? -1
        : 1
    )
}

function isInteger(n) {
  return n % 1 === 0
}
