import React, { Component } from "react"

import { navigationPaths } from "../../constants/paths"
import { connect } from "react-redux"

import { getSubdomain, getStyles } from "./StylesheetInjector"
import ReactSVG from "react-svg"
import { Grid, Menu } from "semantic-ui-react"
import NavItem from "./NavItem"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faHome,
  faCar,
  faTools,
  faMapMarkerAlt,
  faCog,
  faUserFriends,
  faTachometer
} from "@fortawesome/pro-light-svg-icons"
import { FLEET, FMC } from "../../constants/application"
import { isFmcPath } from "../../helpers/pathHelpers"
import { isFMCUser } from "../../helpers/userRoleHelpers"
import {
  isFleetMaintenanceHub,
  isCarvana,
  isFleetMaintenanceHubCanada
} from "../../helpers/affiliationHelpers"

const styles = getStyles()

class Nav extends Component {
  accountTabIsActive() {
    const pathName = this.pathName()

    if (/\/account/.test(pathName)) {
      return true
    }

    return false
  }

  dashboardTabIsActive() {
    const pathName = this.pathName()
    if (pathName === navigationPaths.dashboard()) {
      return true
    }

    return false
  }

  fmcDashboardTabIsActive() {
    const pathName = this.pathName()

    if (pathName === navigationPaths.fmcDashboard()) {
      return true
    }

    return false
  }

  fmcUsersTabIsActive() {
    const pathName = this.pathName()

    if (pathName === navigationPaths.fmcUsers()) {
      return true
    }

    return false
  }

  pathName() {
    return window.location.pathname
  }

  shopsTabIsActive() {
    const pathName = this.pathName()

    if (/\/service_request/.test(pathName)) {
      return true
    }
    if (/\/shops/.test(pathName)) {
      return true
    }

    return false
  }

  servicesTabIsActive() {
    const pathName = this.pathName()

    if (/\/services/.test(pathName)) {
      return true
    }

    return false
  }

  settingsTabIsActive() {
    const pathName = this.pathName()

    if (/\/settings/.test(pathName)) {
      return true
    }

    return false
  }

  vehiclesTabIsActive() {
    const pathName = this.pathName()

    if (/\/vehicles/.test(pathName)) {
      return true
    }

    return false
  }

  render() {
    const { isFMC, isFMCMode } = this.props
    const activeColor = styles.iconColorActive
    const inactiveColor = styles.iconColorInactive
    let sub = getSubdomain()
    let size = 86
    if (isFleetMaintenanceHub() || isFleetMaintenanceHubCanada()) size = 60
    let extraStyles = {}
    // Add white background to the Carvana logo
    if (isCarvana()) extraStyles = { backgroundColor: "white", padding: "9px 7px" }

    return (
      <Grid.Column className="navbar" only="computer" centered>
        <Menu borderless vertical className="borderless-outline">
          <div style={{ backgroundColor: "white !important" }}>
            <Menu.Header>
              <ReactSVG
                src={`/images/${sub}_logo.svg`}
                svgStyle={{ ...extraStyles, width: size, height: size }}
              />
            </Menu.Header>
          </div>

          {this.props.isFleetMode && (
            <React.Fragment>
              <NavItem
                active={this.dashboardTabIsActive()}
                content={
                  <FontAwesomeIcon
                    icon={faHome}
                    color={this.dashboardTabIsActive() ? activeColor : inactiveColor}
                    size="3x"
                  />
                }
                linkPath={navigationPaths.dashboard()}
              />

              <NavItem
                active={this.vehiclesTabIsActive()}
                content={
                  <FontAwesomeIcon
                    icon={faCar}
                    color={this.vehiclesTabIsActive() ? activeColor : inactiveColor}
                    size="3x"
                  />
                }
                linkPath={navigationPaths.vehicleIndex()}
              />

              <NavItem
                active={this.servicesTabIsActive()}
                content={
                  <FontAwesomeIcon
                    icon={faTools}
                    color={this.servicesTabIsActive() ? activeColor : inactiveColor}
                    size="3x"
                  />
                }
                linkPath={navigationPaths.servicesIndex()}
              />

              <NavItem
                active={this.shopsTabIsActive()}
                content={
                  <FontAwesomeIcon
                    icon={faMapMarkerAlt}
                    color={this.shopsTabIsActive() ? activeColor : inactiveColor}
                    size="3x"
                  />
                }
                linkPath={navigationPaths.shopIndex()}
              />

              <NavItem
                active={this.settingsTabIsActive()}
                content={
                  <FontAwesomeIcon
                    icon={faCog}
                    color={this.settingsTabIsActive() ? activeColor : inactiveColor}
                    size="3x"
                  />
                }
                linkPath={navigationPaths.settingsIndex()}
              />
            </React.Fragment>
          )}

          {isFMC && isFMCMode && (
            <React.Fragment>
              <NavItem
                active={this.fmcDashboardTabIsActive()}
                content={
                  <FontAwesomeIcon
                    icon={faTachometer}
                    color={this.fmcDashboardTabIsActive() ? activeColor : inactiveColor}
                    size="3x"
                  />
                }
                linkPath={navigationPaths.fmcDashboard()}
              />
              <NavItem
                active={this.fmcUsersTabIsActive()}
                content={
                  <FontAwesomeIcon
                    icon={faUserFriends}
                    color={this.fmcUsersTabIsActive() ? activeColor : inactiveColor}
                    size="3x"
                  />
                }
                linkPath={navigationPaths.fmcUsers()}
              />
            </React.Fragment>
          )}
        </Menu>
      </Grid.Column>
    )
  }
} // class Nav

const mapStateToProps = (state) => {
  const user = state.users && state.users.currentUser
  const isFMC = isFMCUser(user)

  return {
    isFMC: isFMC,
    isFMCMode: state.application.mode === FMC && isFMC && isFmcPath(),
    isFleetMode: state.application.mode === FLEET || (isFMC && !isFmcPath())
  }
}

export default connect(mapStateToProps)(Nav)
