import React, { Component } from "react"
import PropTypes from "prop-types"

class Disclaimer extends Component {
  static propTypes = {
    message: PropTypes.string.isRequired,

    className: PropTypes.string,
    visible: PropTypes.bool
  }

  static defaultProps = {
    className: "",
    visible: true
  }

  className() {
    return `disclaimer ${this.props.className}`.trim()
  }

  render() {
    const { message, visible } = this.props

    if (visible) {
      return <div className={this.className()}>{message}</div>
    } else {
      return null
    }
  }
} // class Disclaimer

export default Disclaimer
