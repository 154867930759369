import React, { Component } from "react"
import PropTypes from "prop-types"

class Checkbox extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,

    checked: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool
  }

  static defaultProps = {
    checked: false,
    className: "",
    disabled: false
  }

  render() {
    const { checked, className, disabled, onChange } = this.props

    return (
      <input
        type="checkbox"
        checked={checked}
        className={className}
        disabled={disabled}
        onChange={onChange}
      />
    )
  }
} // class Checkbox

export default Checkbox
