import React, { Component } from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"

class NoResults extends Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    additionalMessage: PropTypes.string,
    className: PropTypes.string,
    label: PropTypes.string
  }

  static defaultProps = {
    additionalMessage: "",
    className: "",
    label: "resultsLabel"
  }

  className() {
    const { className } = this.props

    return `no_results_panel ${className}`
  }

  render() {
    const { label, visible, t } = this.props

    if (visible) {
      return <div className={this.className()}>{t("noLabel") + " " + t(`${label}`)}</div>
    } else {
      return null
    }
  }
} // class NoResults

export default withTranslation("common")(NoResults)
