import React, { Component } from "react"

import PropTypes, { bool } from "prop-types"

import { EMAIL_REGEX, ZIP_CODE_REGEX, PHONE_NUMBER_REGEX } from "../../utilities/stringHelpers"

import TextField from "../../elements/TextField"
import PasswordField from "../../elements/PasswordField"
import Button from "../../elements/Button"
import Checkbox from "../../elements/Checkbox"
import Alert from "../../components/shared/Alert"
import ErrorField from "../../elements/ErrorField"
import { Link } from "react-router-dom"
import "../../assets/stylesheets/components/sessions/_sign_up_form.scss"
import { getSubdomain } from "../shared/StylesheetInjector"
import { withTranslation } from "react-i18next"
import { isFleetMaintenanceHubCanada } from "../../helpers/affiliationHelpers"
import { CANDA_COUNTRY_CODE, EN_LOCALE, FR_LOCALE } from "../../constants/users"
import { Checkbox as SematicCheckbox } from "semantic-ui-react"
import { setLanguage } from "../../helpers/translationHelpers"

class SignUpForm extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool
  }

  static defaultProps = {
    isSubmitting: false
  }
  constructor(props) {
    super(props)

    this.state = {
      formData: {
        firstName: {
          value: ""
        },
        lastName: {
          value: ""
        },
        email: {
          value: ""
        },
        password: {
          value: "",
          passwordInvisible: true
        },
        confirmPassword: {
          value: "",
          passwordInvisible: true
        },
        phoneNumber: {
          value: ""
        },
        companyName: {
          value: ""
        },
        carCount: {
          value: ""
        },
        zipCode: {
          value: ""
        }
      },
      displayError: {
        //to display appropriate errors for the different fields
        confirmPassword: false,
        password: false,
        minLengthPassword: false,
        carCount: false,
        email: false,
        firstName: false,
        lastName: false,
        zipCode: false,
        companyName: false,
        phoneNumber: false
      }, //displayError
      checkboxChecked: false,
      formError: false,
      typingTimer: null,
      toggle: false
    } //state
  } //constructor

  componentDidMount() {
    if (this.props.userSelectedLanguage === FR_LOCALE) {
      this.setState({ toggle: true })
    }
  }

  onFirstName = (event) => {
    this.onFieldChange("firstName", event.target.value)
  }
  onLastName = (event) => {
    this.onFieldChange("lastName", event.target.value)
  }
  onEmail = (event) => {
    this.onFieldChange("email", event.target.value)
  }
  onPassword = (event) => {
    this.onFieldChange("password", event.target.value)
  }
  onConfirmPassword = (event) => {
    this.onFieldChange("confirmPassword", event.target.value)
  }
  onPhoneNumber = (event) => {
    this.onFieldChange("phoneNumber", event.target.value)
  }
  onCompanyName = (event) => {
    this.onFieldChange("companyName", event.target.value)
  }
  onCarCount = (event) => {
    this.onFieldChange("carCount", event.target.value)
  }
  onZipCode = (event) => {
    this.onFieldChange("zipCode", event.target.value)
  }
  onCheckbox = (event) => {
    this.setState({ checkboxChecked: !this.state.checkboxChecked })
  }
  onFieldChange = (field, value) => {
    let updatedFormData = { ...this.state.formData }
    let updatedFormField = { ...updatedFormData[field] }
    updatedFormField.value = value
    updatedFormData[field] = { ...updatedFormField }
    this.setState({ formData: updatedFormData })
    clearTimeout(this.state.typingTimer)
    this.state.typingTimer = setTimeout(() => {
      if (value) {
        this.isFieldValueValid(field, value)
      }
    }, 500)
  }

  isFieldValueValid = (field, value) => {
    let formError = this.state.formError
    switch (field) {
      case "firstName":
        if (this.state.formData.firstName.value === "") {
          this.setDisplayError("firstName", true)
          formError = true
        } else {
          this.setDisplayError("firstName", false)
        }
        break
      case "lastName":
        if (this.state.formData.lastName.value === "") {
          this.setDisplayError("lastName", true)
          formError = true
        } else {
          this.setDisplayError("lastName", false)
        }
        break
      case "email":
        if (this.state.formData.email.value === "" || !this.validateEmail()) {
          this.setDisplayError("email", true)
          formError = true
        } else {
          this.setDisplayError("email", false)
        }
        break
      case "password":
        if (this.state.formData.password.value === "" || !this.passwordMinLength()) {
          if (!this.passwordMinLength()) {
            //if user enters password less than length 6.
            this.setDisplayError("minLengthPassword", true)
            formError = true
          } else {
            this.setDisplayError("password", true)
            formError = true
          }
        } else {
          this.setDisplayError("minLengthPassword", false)
          this.setDisplayError("password", false)
        }
        break
      case "confirmPassword":
        if (this.state.formData.confirmPassword.value === "" || !this.validatePassword()) {
          this.setDisplayError("confirmPassword", true)
          formError = true
        } else {
          this.setDisplayError("confirmPassword", false)
        }
        break
      case "phoneNumber":
        if (this.state.formData.phoneNumber.value === "" || !this.validatePhoneNumber()) {
          this.setDisplayError("phoneNumber", true)
          formError = true
        } else {
          this.setDisplayError("phoneNumber", false)
        }
        break
      case "companyName":
        if (this.state.formData.companyName.value === "") {
          this.setDisplayError("companyName", true)
          formError = true
        } else {
          this.setDisplayError("companyName", false)
        }
        break
      case "carCount":
        if (this.state.formData.carCount.value === "" || !this.validateCarCount()) {
          this.setDisplayError("carCount", true)
          formError = true
        } else {
          this.setDisplayError("carCount", false)
        }
        break
      case "zipCode":
        if (this.state.formData.zipCode.value === "" || !this.validateZipCode()) {
          this.setDisplayError("zipCode", true)
          formError = true
        } else {
          this.setDisplayError("zipCode", false)
        }
        break
    }
  }
  componentWillUnmount() {
    clearTimeout(this.state.typingTimer)
  }

  onEyeIconClick = (field) => {
    let updatedFormData = { ...this.state.formData }
    let updatedFormField = { ...updatedFormData[field] }
    updatedFormField.passwordInvisible = !updatedFormField.passwordInvisible
    updatedFormData[field] = { ...updatedFormField }
    this.setState({ formData: updatedFormData })
  }

  onSignupButtonClick = () => {
    this.submitTheData(this.state.formData)
  }

  submitTheData = (formData) => {
    if (this.isFormValid()) {
      let data = {
        firstName: formData.firstName.value,
        lastName: formData.lastName.value,
        email: formData.email.value.toLowerCase(),
        password: formData.password.value,
        confirmPassword: formData.confirmPassword.value,
        phoneNumber: formData.phoneNumber.value,
        companyName: formData.companyName.value,
        carCount: formData.carCount.value,
        zipCode: formData.zipCode.value
      }
      this.props.onSubmit(data)
    }
  }

  isAllDataEntered = () => {
    for (let field in this.state.formData) {
      if (this.state.formData[field].value === "") return false
    }
    return true
  }

  isFormValid = () => {
    let formError = this.state.formError
    if (this.state.formData.firstName.value === "") {
      this.setDisplayError("firstName", true)
      formError = true
    } else {
      this.setDisplayError("firstName", false)
    }
    if (this.state.formData.email.value === "" || !this.validateEmail()) {
      this.setDisplayError("email", true)
      formError = true
    } else {
      this.setDisplayError("email", false)
    }
    if (this.state.formData.companyName.value === "") {
      this.setDisplayError("companyName", true)
      formError = true
    } else {
      this.setDisplayError("companyName", false)
    }
    if (this.state.formData.lastName.value === "") {
      this.setDisplayError("lastName", true)
      formError = true
    } else {
      this.setDisplayError("lastName", false)
    }
    if (this.state.formData.carCount.value === "" || !this.validateCarCount()) {
      this.setDisplayError("carCount", true)
      formError = true
    } else {
      this.setDisplayError("carCount", false)
    }
    if (this.state.formData.password.value === "" || !this.passwordMinLength()) {
      if (!this.passwordMinLength()) {
        //if user enters password less than length 6.
        this.setDisplayError("minLengthPassword", true)
        formError = true
      } else {
        this.setDisplayError("password", true)
        formError = true
      }
    } else {
      this.setDisplayError("minLengthPassword", false)
      this.setDisplayError("password", false)
    }
    if (this.state.formData.phoneNumber.value === "" || !this.validatePhoneNumber()) {
      this.setDisplayError("phoneNumber", true)
      formError = true
    } else {
      this.setDisplayError("phoneNumber", false)
    }
    if (this.state.formData.confirmPassword.value === "" || !this.validatePassword()) {
      this.setDisplayError("confirmPassword", true)
      this.makeConfirmPasswordEmpty()
      formError = true
    } else {
      this.setDisplayError("confirmPassword", false)
    }
    if (this.state.formData.zipCode.value === "" || !this.validateZipCode()) {
      this.setDisplayError("zipCode", true)
      formError = true
    } else {
      this.setDisplayError("zipCode", false)
    }
    if (formError == false && !this.state.checkboxChecked) {
      formError = true
      alert("Please accept terms and conditions ")
    }

    return !formError
  } //isFormValid

  setDisplayError = (field, booleanValue) => {
    this.setState((state) => ({
      displayError: this.getUpdatedDisplayError(state, field, booleanValue)
    }))
  } //setDisplayError

  getUpdatedDisplayError = (state, field, booleanValue) => {
    let updatedDisplayerror = { ...state.displayError }
    updatedDisplayerror[field] = booleanValue === true ? true : false
    return updatedDisplayerror
  }

  validatePassword = () => {
    const password = this.state.formData.password.value
    const confirmPassword = this.state.formData.confirmPassword.value
    return password === confirmPassword
  }
  passwordMinLength = () => {
    const password = this.state.formData.password.value
    return password.length >= 6
  }

  validateCarCount = () => {
    const carCount = this.state.formData.carCount.value
    return carCount % 1 === 0 && carCount >= 0
  }

  validateEmail = () => {
    const email = this.state.formData.email.value
    return EMAIL_REGEX.test(email)
  }

  validatePhoneNumber = () => {
    const phoneNumber = this.state.formData.phoneNumber.value
    return PHONE_NUMBER_REGEX.test(phoneNumber)
  }

  validateZipCode = () => {
    const zipCode = this.state.formData.zipCode.value
    return ZIP_CODE_REGEX.test(zipCode)
  }

  makeConfirmPasswordEmpty = () => {
    this.setState((state) => ({
      formData: this.getUpdatedFormData(state)
    }))
  }

  getUpdatedFormData = (state) => {
    let updatedFormData = { ...state.formData }
    let updatedConfirmPassword = { ...updatedFormData.confirmPassword }
    updatedConfirmPassword.value = ""
    updatedFormData.confirmPassword = { ...updatedConfirmPassword }
    return updatedFormData
  }

  allowSubmit = () => {
    const { isSubmitting } = this.props
    return !isSubmitting
  }

  handleKeyPress = (event, fieldName) => {
    if (event.key === "Enter") {
      this.onEyeIconClick(fieldName)
    }
  }

  toggleButton = () => {
    this.setState((prevState) => ({ toggle: !prevState.toggle }))
    const preferences = this.state.toggle
      ? { language: EN_LOCALE, country: CANDA_COUNTRY_CODE }
      : { language: FR_LOCALE, country: CANDA_COUNTRY_CODE }
    setLanguage(preferences["language"], preferences["country"])
    this.props.dispatch({ type: "SET_USER_LANGUAGE", payload: { language: preferences.language } })
  }

  render() {
    const { displayError, formData, checkboxChecked, toggle } = this.state
    const {
      confirmPassword: displayConfirmPasswordError,
      password: displayPasswordError,
      minLengthPassword: displayMinLengthPasswordError,
      carCount: displayCarCountError,
      zipCode: displayZipCodeError,
      firstName: displayFirstNameError,
      lastName: displayLastNameError,
      companyName: displayCompanyNameError,
      phoneNumber: displayPhoneNumberError,
      email: displayEmailError
    } = displayError
    const {
      firstName: { value: firstName },
      lastName: { value: lastName },
      email: { value: email },
      password: { value: password, passwordInvisible },
      confirmPassword: { value: confirmPassword, passwordInvisible: confirmPasswordInvisible },
      phoneNumber: { value: phoneNumber },
      carCount: { value: carCount },
      zipCode: { value: zipCode },
      companyName: { value: companyName }
    } = formData
    const { t } = this.props

    const subDomain = getSubdomain()
    const isDomainShellCanada = isFleetMaintenanceHubCanada()
    const inputClass = "placeholder-color-change"
    const shellColor = subDomain === "shell" ? "#696969" : "black"
    const labelColor = subDomain === "shell" ? "#666666" : "black"

    return (
      <div
        className={"sign-in-form"}
        style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        {isDomainShellCanada && (
          <div className={"sign-in-submit"} style={{ padding: "2px" }}>
            EN &nbsp;
            <SematicCheckbox toggle checked={toggle} onClick={this.toggleButton} />
            &nbsp; FR
          </div>
        )}
        <h1 className={"signup-text"} style={{ color: `${shellColor}` }}>
          {t("signUpLabel")}
        </h1>

        <div className={"signup-fields"}>
          <div style={{ alignSelf: "flex-start", width: "100%", marginBottom: "30px" }}>
            <p style={{ color: `${shellColor}`, fontSize: "17px", marginBottom: "5px" }}>
              <h3>{t("generalInformationLabel")}</h3>
            </p>
            <p>{t("allFieldsMandatoryLabel")}</p>
          </div>

          <label style={{ color: `${labelColor}` }} className={"field-labels"}>
            {t("companyNameLabel")}
          </label>
          <TextField
            className={`sign-in-input ${inputClass}`}
            onChange={this.onCompanyName}
            placeholder={t("enterCompanyNameLabel")}
            value={companyName}
            error={displayCompanyNameError}
          />
          <ErrorField
            displayError={displayCompanyNameError}
            className={"error-field"}
            label={t("requiredFieldLabel")}
          />

          <label style={{ color: `${labelColor}` }} className={"field-labels"}>
            {t("carCountLabel")}
          </label>
          <TextField
            className={`sign-in-input ${inputClass}`}
            onChange={this.onCarCount}
            placeholder={t("enterCarCountLabel")}
            value={carCount}
            error={displayCarCountError}
          />
          <ErrorField
            displayError={displayCarCountError}
            className={"error-field"}
            label={t("carCountValidationLabel")}
          />

          <div style={{ width: "100%", marginBottom: "25px", marginTop: "10px" }}>
            <p style={{ color: `${shellColor}`, fontSize: "17px" }}>
              <b>{t("accountOwnerLabel")}</b>
            </p>
          </div>

          <div className={"firstname-lastname-labels"}>
            <label
              style={{ color: `${labelColor}`, marginRight: "35%" }}
              className={"field-labels "}
            >
              {t("firstNameLabel")}
            </label>
            <label style={{ color: `${labelColor}` }} className={"field-labels last-name-label"}>
              {t("lastNameLabel")}
            </label>
          </div>

          <div className={"firstname-and-lastName"}>
            <TextField
              className={`sign-in-input ${inputClass}`}
              onChange={this.onFirstName}
              placeholder={t("firstNameLabel")}
              value={firstName}
              error={displayFirstNameError}
            />
            <label
              style={{ color: `${labelColor}` }}
              className={"field-labels last-name-label-forsmalldevices"}
            >
              {t("lastNameLabel")}
            </label>
            <TextField
              className={`sign-in-input ${inputClass}`}
              onChange={this.onLastName}
              placeholder={t("lastNameLabel")}
              value={lastName}
              error={displayLastNameError}
            />
          </div>
          <ErrorField
            displayError={displayFirstNameError || displayLastNameError}
            className={"error-field"}
            label={t("nameValidationLabel")}
          />

          <label style={{ color: `${labelColor}` }} className={"field-labels"}>
            {t("emailLabel")}
          </label>
          <TextField
            className={`sign-in-input ${inputClass}`}
            onChange={this.onEmail}
            placeholder={t("emailPlaceholderLabel")}
            value={email}
            error={displayEmailError}
          />
          <ErrorField
            displayError={displayEmailError}
            className={"error-field"}
            label={t("emailValidateLabel")}
          />

          <label style={{ color: `${labelColor}` }} className={"field-labels"}>
            {t("phoneNumberLabel")}
          </label>
          <TextField
            type={"tel"}
            className={`sign-in-input ${inputClass}`}
            onChange={this.onPhoneNumber}
            placeholder={`${t("phoneNumberLabel")} -2342344444`}
            value={phoneNumber}
            error={displayPhoneNumberError}
          />
          <ErrorField
            displayError={displayPhoneNumberError}
            className={"error-field"}
            label={t("phoneValidateLabel")}
          />

          <label style={{ color: `${labelColor}` }} className={"field-labels"}>
            {isDomainShellCanada ? t("codePostalLabel") : t("zipCodeLabel")}
          </label>
          <TextField
            className={`sign-in-input ${inputClass}`}
            onChange={this.onZipCode}
            placeholder={`${isDomainShellCanada ? t("codePostalLabel") : t("zipCodeLabel")} -11101`}
            value={zipCode}
            error={displayZipCodeError}
          />
          <ErrorField
            displayError={displayZipCodeError}
            className={"error-field"}
            label={t("zipCodeValidationLabel")}
          />

          <label style={{ color: `${labelColor}` }} className={"field-labels"}>
            {t("passwordLabel")}
          </label>
          <PasswordField
            className={`sign-in-input ${inputClass}`}
            onChange={this.onPassword}
            placeholder={t("passwordPlaceholderLabel")}
            value={password}
            obfuscated={passwordInvisible}
            onIconClick={() => this.onEyeIconClick("password")}
            error={displayPasswordError || displayMinLengthPasswordError}
            onKeyPress={(event) => this.handleKeyPress(event, "password")}
          />
          <ErrorField
            displayError={displayMinLengthPasswordError}
            className={"error-field"}
            label={t("passwordValidationLabel")}
          />

          <label style={{ color: `${labelColor}` }} className={"field-labels"}>
            {t("confirmPasswordLabel")}
          </label>
          <PasswordField
            className={`sign-in-input ${inputClass}`}
            onChange={this.onConfirmPassword}
            placeholder={t("confirmPasswordPlaceholderLabel")}
            value={confirmPassword}
            error={displayConfirmPasswordError}
            obfuscated={confirmPasswordInvisible}
            onIconClick={() => this.onEyeIconClick("confirmPassword")}
            onKeyPress={(event) => this.handleKeyPress(event, "confirmPassword")}
          />
          <ErrorField
            displayError={displayConfirmPasswordError}
            className={"error-field"}
            label={t("passwordMatchFailLabel")}
          />

          <div className={"checkbox-signupbutton"}>
            <Checkbox className={"checkbox"} onChange={this.onCheckbox} checked={checkboxChecked} />
            <div className={"t-and-c-container"}>
              <p style={{ fontSize: "11px" }}>
                {t("readAcceptTermLabel")}
                <div>
                  <Link
                    to="/privacy_policy"
                    target="_blank"
                    aria-label="Terms and Conditions open in new window"
                    style={{ textDecoration: "underline", color: "unset" }}
                  >
                    <u>{t("termsConditionsLabel")}</u>
                  </Link>
                </div>
              </p>
            </div>
            <div style={{ marginLeft: "50px" }}>
              <Button label={t("signUpLabel")} onClick={this.onSignupButtonClick} />
            </div>
          </div>
          <Alert message={""} type="notice" extraType="signup--page" visible={true}>
            <span>
              {t("alreadyCustomerLabel?")}
              {
                <button className={"link"} onClick={this.props.navigateToSignIn}>
                  {t("signInLabel")}
                </button>
              }
            </span>
          </Alert>
        </div>
      </div>
    )
  } //render
} //class SignUpForm

export default withTranslation("common")(SignUpForm)
