import React, { Component } from "react"
import PropTypes from "prop-types"

import ReactTooltip from "react-tooltip"
import { Dropdown } from "semantic-ui-react"

class HeaderLink extends Component {
  static propTypes = {
    description: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }

  tooltipContent() {
    const { description } = this.props

    return <div>{description}</div>
  }

  tooltipId() {
    const { name } = this.props

    return name.toLowerCase().replace(" ", "-")
  }

  open = () => {
    window.open(this.props.href, "_blank")
  }

  render() {
    const { name } = this.props
    return (
      <Dropdown.Item onClick={this.open}>
        <span data-tip={""} data-for={this.tooltipId()}>
          <a className={"header_link"}>{name}</a>
          <ReactTooltip
            id={this.tooltipId()}
            effect={"float"}
            getContent={() => this.tooltipContent()}
            place={"bottom"}
            type={"info"}
          />
        </span>
      </Dropdown.Item>
    )
  }
} // class HeaderLink

export default HeaderLink
