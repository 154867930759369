import React, { Component } from "react"
import PropTypes from "prop-types"

import * as activeMaintenanceHelpers from "../../helpers/activeMaintenanceHelpers"

import Button from "../../elements/Button"

class ActiveMaintenanceCancelButton extends Component {
  static propTypes = {
    onCancelationSubmit: PropTypes.func.isRequired,
    users: PropTypes.array.isRequired,
    vehicle: PropTypes.object.isRequired,

    isSubmitting: PropTypes.bool
  }

  static defaultProps = {
    isSubmitting: false
  }

  allowSubmit() {
    const { isSubmitting } = this.props

    return !isSubmitting
  }

  confirmCancelation() {
    return window.confirm("Are you sure you want cancel?")
  }

  formattedCancelationContacts() {
    const { users, vehicle } = this.props

    return activeMaintenanceHelpers.formattedCancelationContacts(vehicle.active_order, users)
  }

  formattedCancelationData() {
    const activeMaintenance = this.props.vehicle.active_order

    return {
      id: activeMaintenance.id,
      status: "cancel"
    }
  }

  onCancelation(event) {
    // NOTE: This prevents routing by the encapsulating link.
    event.preventDefault()

    const { onCancelationSubmit } = this.props

    if (this.permitCancelation()) {
      if (this.confirmCancelation()) {
        onCancelationSubmit(this.formattedCancelationData())
      }
    }
  }

  permitCancelation() {
    const currentUserCanCancel = this.props.vehicle.active_order.can_cancel

    if (currentUserCanCancel) {
      return true
    } else {
      alert(`Please contact for cancelation:\n${this.formattedCancelationContacts()}`)
      return false
    }
  }

  render() {
    return (
      <Button
        className={"button--alternate maintenance_approval__dashboard_button"}
        disabled={!this.allowSubmit()}
        label={"CANCEL"}
        onClick={(event) => this.onCancelation(event)}
      />
    )
  }
} // class ActiveMaintenanceCancelButton

export default ActiveMaintenanceCancelButton
