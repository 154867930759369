import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import Alert from "../../components/shared/Alert"
import LoadingError from "../../components/shared/LoadingError"
import LoadingThrobber from "../../components/shared/LoadingThrobber"
import ApplicationLayout from "../../layouts/ApplicationLayout"
import { Grid } from "semantic-ui-react"
import OrderStatusWidget from "../../components/dashboard/OrderStatusWidget"
import MaintenanceWidget from "../../components/dashboard/MaintenanceWidget"
import VehiclesWidget from "../../components/dashboard/VehiclesWidget"
import ServiceCostsWidget from "../../components/dashboard/ServiceCostsWidget"
import MileageCostsWidget from "../../components/dashboard/MileageCostsWidget"
import ScheduleServiceButton from "../../components/shared/ScheduleServiceButton"
import { navigationPaths } from "../../constants/paths"
import { withTranslation } from "react-i18next"

class Dashboard extends Component {
  // NOTE: The submission/cancelation logic for "Active Maintenance" approvals in this component is mirrored in /views/dashboard/active_maintenance_index.js (The "View All")

  static propTypes = {
    fleet: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isLoadingError: PropTypes.bool.isRequired,
    users: PropTypes.array.isRequired,
    vehicles: PropTypes.array.isRequired,
    vehiclesDataType: PropTypes.string.isRequired
  }

  constructor(props) {
    super(props)

    // NOTE: locationStateData is instantiated as a variable here rather than referencing this.props.location.state.someObj directly because the state key may not be present when navigating to this page from a <Link> that does not explicitly set it.
    const locationStateData = this.props.location.state || {}

    this.state = {
      alert: locationStateData.alert || "",
      alertType: locationStateData.alertType || "default",
      isSubmitting: false
    }
  }

  async componentWillMount() {
    // TODO figure out a better way to do this, for some reason hitting back
    // react-router lets the thing render before set loading event fires in Dashboard saga
    // and explosions happen
    this.loading = true
    await this.fetchDashboardData()
    this.loading = false
  }

  afterSubmit(status, data) {
    this.setState({ isSubmitting: false })

    if (status === "success") {
      this.onSubmitSuccess(data)
    } else {
      this.onSubmitFailure(data)
    }
  }

  fetchDashboardData() {
    this.props.dispatch({
      type: "DASHBOARD_LOAD_SAGA",
      payload: {
        users: ["id", "name", "cellPhone", "firstName", "lastName"],
        vehicles: {
          only: [
            "id",
            "last_maintenance_miles",
            "dashboard_mobile_url",
            "license_plate_number",
            "license_plate_state",
            "make",
            "model",
            "year",
            "other_id",
            "miles",
            "cost_per_mile",
            {
              active_order: ["status", "id"]
            }
          ],
          preload: ["shop_orders", { fuel_api_vehicle: ["fuel_api_vehicle_shots"] }]
        }
      }
    })
  }

  locationStateData() {
    return this.props.location.state || {}
  }

  onAcceptAllSubmit(submissionData) {
    this.setState({ isSubmitting: true })

    this.props.dispatch({
      type: "MAINTENANCE_APPROVAL_SAGA",
      payload: { submissionData: submissionData },
      callback: this.afterSubmit.bind(this)
    })
  }

  onCancelationSubmit(submissionData) {
    this.setState({ isSubmitting: true })

    this.props.dispatch({
      type: "MAINTENANCE_CANCELATION_SAGA",
      payload: { submissionData: submissionData },
      callback: this.afterSubmit.bind(this)
    })
  }

  onSubmitFailure(data) {
    this.setState({
      alert: data.alert,
      alertType: data.alertType
    })
  }

  onSubmitSuccess(data) {
    this.setState({
      alert: data.alert,
      alertType: data.alertType
    })

    // NOTE: Refreshes "Active Maintenance" data.
    this.fetchDashboardData()
  }

  navigateToVehicle = (vehicle) => {
    this.props.history.push({
      pathname: navigationPaths.vehicleShow(vehicle.id)
    })
  }

  navigateToServicesByMonth = (data) => {
    this.props.history.push({
      pathname: navigationPaths.servicesIndex(),
      state: {
        startingPage: "past",
        startingDate: data.date
      }
    })
  }

  shouldRenderContent() {
    const { isLoading, isLoadingError, vehicles, users, serviceCosts } = this.props

    return (
      !isLoading &&
      !isLoadingError &&
      !this.anyArrayNotPresent([vehicles, users, serviceCosts]) &&
      !this.loading
    )
  }

  anyArrayNotPresent = (arrs) => arrs.filter((arr) => arr === undefined || arr === null).length > 0

  renderContent() {
    const { vehicles, serviceCosts, maintenanceIntervals, language } = this.props

    const { isSubmitting } = this.state

    if (this.shouldRenderContent()) {
      return (
        <div>
          <Grid columns="equal" stackable style={{ maxWidth: "1200px", flexDirection: "column" }}>
            <Grid.Row
              verticalAlign="center"
              style={{
                marginBottom: "0",
                paddingBottom: "0",
                flexDirection: "row-reverse"
              }}
            >
              <Grid.Column>
                <ScheduleServiceButton floated="right" />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{ paddingTop: "0" }}>
              <Grid.Column>
                <Grid columns="equal" stackable style={{ flexDirection: "column" }}>
                  <Grid.Row stretched>
                    <Grid.Column className="dashboard-cols">
                      <OrderStatusWidget
                        orders={vehicles.map((v) => v.active_order).filter((o) => o)}
                      />
                    </Grid.Column>
                    <Grid.Column className="dashboard-cols">
                      <MaintenanceWidget
                        maintenanceIntervals={maintenanceIntervals}
                        vehicles={vehicles}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row stretched>
                    <Grid.Column className="dashboard-cols">
                      <ServiceCostsWidget
                        costs={serviceCosts}
                        navigateToServicesByMonth={this.navigateToServicesByMonth}
                        language={language}
                      />
                    </Grid.Column>
                    <Grid.Column className="dashboard-cols">
                      <MileageCostsWidget
                        vehicles={vehicles}
                        navigateTo={this.navigateToVehicle}
                        language={language}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
              <Grid.Column width={5}>
                <VehiclesWidget maintenanceIntervals={maintenanceIntervals} vehicles={vehicles} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      )
    } else {
      return null
    }
  }

  render() {
    const { isLoading, isLoadingError, t } = this.props

    const { alert, alertType } = this.state

    return (
      <ApplicationLayout>
        <Alert message={alert} type={alertType} visible={alert !== ""} />

        <LoadingThrobber
          visible={isLoading || this.renderContent() == null}
          loadingMessage={t("loadingVehicleDataLabel")}
        />

        <LoadingError visible={!isLoading && isLoadingError} />

        {this.renderContent()}
      </ApplicationLayout>
    )
  }
} // class Dashboard

const mapStateToProps = (state) => {
  const language = state.users.currentUser ? state.users.currentUser.language : ""

  return {
    fleet: state.fleets.fleet,
    isLoading: state.application.isLoading,
    isLoadingError: state.application.isLoadingError,
    users: state.users.users,
    vehicles: state.vehicles.vehicles,
    vehiclesDataType: state.vehicles.vehiclesDataType,
    serviceCosts: state.services.serviceCosts,
    maintenanceIntervals: state.maintenanceIntervals,
    language: language
  }
}

export default connect(mapStateToProps)(withTranslation("common")(Dashboard))
