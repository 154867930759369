import React, { Component } from "react"
import PropTypes from "prop-types"
import { Table } from "semantic-ui-react"

import MaintenanceScheduleItem from "./MaintenanceScheduleItem"
import ScheduleServiceButton from "../shared/ScheduleServiceButton"
import { withTranslation } from "react-i18next"

class MaintenanceSchedule extends Component {
  static propTypes = {
    vehicle: PropTypes.object.isRequired
  }

  renderScheduleItems() {
    const { vehicle, serviceSchedulesByVehicle, language, dispatch } = this.props
    return (serviceSchedulesByVehicle[vehicle.id] || []).map((upcomingService, index) => {
      return (
        <MaintenanceScheduleItem
          key={index}
          upcomingMaintenance={upcomingService}
          vehicle={vehicle}
          language={language}
          dispatch={dispatch}
        />
      )
    })
  }

  render() {
    const { vehicle, serviceSchedulesByVehicle, t } = this.props

    return (
      <Table className="borderless">
        <Table.Header>
          <Table.Row>
            {[
              t("serviceLabel"),
              t("dashboard:kilometersLabel"),
              serviceSchedulesByVehicle[vehicle.id] ? (
                <ScheduleServiceButton
                  text={t("scheduleAllLabel")}
                  maintenance={serviceSchedulesByVehicle[vehicle.id]}
                  vehicle={vehicle}
                />
              ) : (
                ""
              )
            ].map((s) => (
              <Table.HeaderCell>{s}</Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>{this.renderScheduleItems()}</Table.Body>
      </Table>
    )
  }
} // class MaintenanceSchedule

export default withTranslation("vehicle")(MaintenanceSchedule)
