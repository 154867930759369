import React, { Component } from "react"
import PropTypes from "prop-types"
import { Input } from "semantic-ui-react"

class TextField extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,

    className: PropTypes.string,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string
  }

  static defaultProps = {
    className: "",
    disabled: false,
    placeholder: "",
    error: false,
    type: "text"
  }

  type() {
    const { type } = this.props

    return type === "tel" ? "tel" : "text"
  }

  render() {
    const { className, disabled, onChange, placeholder, value, error } = this.props

    return (
      <Input
        error={error}
        fluid
        className={className}
        disabled={disabled}
        onChange={onChange}
        placeholder={placeholder}
        type={this.type()}
        value={value}
      />
    )
  }
} // class TextField

export default TextField
