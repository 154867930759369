import React from "react"
import { useTranslation } from "react-i18next"

import { Segment, Grid } from "semantic-ui-react"

import { APPROVAL, AWAITING_APPROVAL, SCHEDULED_STATUSES } from "../../constants/statuses"
export default function ActiveServicesStats({ orders }) {
  const { t } = useTranslation("dashboard")
  return (
    <Grid columns="equal" stretched stackable>
      <Grid.Row>
        <Grid.Column>
          <Segment vertical className={"dash-stats-item item-width"}>
            {orders.filter((o) => SCHEDULED_STATUSES.indexOf(o.status) !== -1).length}
            <span>{t("scheduledLabel")}</span>
          </Segment>
        </Grid.Column>
        <Grid.Column>
          <Segment vertical className={"dash-stats-item item-width"}>
            {orders.filter((o) => o.status === AWAITING_APPROVAL).length}
            <span>{t("needsApprovalLabel")}</span>
          </Segment>
        </Grid.Column>
        <Grid.Column>
          <Segment vertical className={"dash-stats-item item-width"}>
            {orders.filter((o) => o.status === APPROVAL).length}
            <span>{t("approvedLabel")}</span>
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}
