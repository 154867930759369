import React, { Component } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"

import * as activeMaintenanceHelpers from "../../helpers/activeMaintenanceHelpers"
import moment from "moment"
import * as numberHelpers from "../../helpers/numberHelpers"
import { navigationPaths } from "../../constants/paths"
import * as phoneNumberHelpers from "../../helpers/phoneNumberHelpers"
import * as shopHelpers from "../../helpers/shopHelpers"
import * as stringHelpers from "../../helpers/stringHelpers"
import * as vehicleHelpers from "../../helpers/vehicleHelpers"
import { Segment } from "semantic-ui-react"

import ActiveMaintenanceAcceptAllButton from "./ActiveMaintenanceAcceptAllButton"
import ActiveMaintenanceCancelButton from "./ActiveMaintenanceCancelButton"

class ActiveMaintenance extends Component {
  static propTypes = {
    onAcceptAllSubmit: PropTypes.func.isRequired,
    onCancelationSubmit: PropTypes.func.isRequired,
    users: PropTypes.array.isRequired,
    vehicle: PropTypes.object.isRequired,

    isSubmitting: PropTypes.bool
  }

  static defaultProps = {
    isSubmitting: false
  }

  renderButtonsOrStatus() {
    const { isSubmitting, onAcceptAllSubmit, onCancelationSubmit, users, vehicle } = this.props

    const activeMaintenance = vehicle.active_order

    if (activeMaintenanceHelpers.eligibleForCancelation(activeMaintenance)) {
      return (
        <ActiveMaintenanceCancelButton
          isSubmitting={isSubmitting}
          onCancelationSubmit={onCancelationSubmit}
          users={users}
          vehicle={vehicle}
        />
      )
    } else if (activeMaintenanceHelpers.eligibleForApprovalAndAuthorized(activeMaintenance)) {
      return (
        <ActiveMaintenanceAcceptAllButton
          isSubmitting={isSubmitting}
          onAcceptAllSubmit={onAcceptAllSubmit}
          users={users}
          vehicle={vehicle}
        />
      )
    } else {
      return (
        <div className={"dash-approvals-detail-status"}>
          <div className={"maintenance_approval__status"}>
            {stringHelpers.titleCase(activeMaintenance.status)}
          </div>
        </div>
      )
    }
  }

  render() {
    const { vehicle } = this.props

    const activeMaintenance = vehicle.active_order

    const { shop } = activeMaintenance

    return (
      <Segment.Group>
        <Segment>
          <Link className={"row-link"} to={navigationPaths.vehicleShow(vehicle.id)}>
            <div className={"row dashboard__active_maintenance_row"}>
              <div className={"column--5 dash-approvals-detail-vehicleinfo"}>
                {vehicleHelpers.formattedName(vehicle)}
                <br />
                <span className={"appt-time"}>{activeMaintenance.appointment_datetime_pretty}</span>
              </div>

              <div className={"column--1"}>
                {`${numberHelpers.formatWithCommas(activeMaintenance.miles)} mi.`}
              </div>

              <div className={"column--4"}>
                {activeMaintenance.order_services
                  .map((order_service) => order_service.name)
                  .join(", ")}
              </div>

              <div className={"column--1"}>
                {activeMaintenance.total ? `$${activeMaintenance.total}` : ""}
              </div>

              <div className={"column--1"}>{this.renderButtonsOrStatus()}</div>
            </div>
          </Link>
        </Segment>

        <Segment className={"row row--overflow"} style={{ backgroundColor: "#F7F7F7" }}>
          <div className={"column--6 active_order__column active_order__dashboard_column"}>
            <div className={"active_order__detail active_order__shop_name"}>
              <strong>{shop.name}</strong>
            </div>

            <div className={"active_order__detail"}>
              <a
                className={"address_link"}
                href={shopHelpers.googleMapLink(shop)}
                target={"_blank"}
              >
                {shopHelpers.formattedAddress(shop)}
              </a>
            </div>

            <div className={"active_order__detail"}>
              {phoneNumberHelpers.reformat(shop.main_phone_number)}
            </div>
          </div>

          <div className={"column--6 active_order__column"}>
            <div className={"active_order__detail"}>
              <strong>PO NUMBER:</strong>
              &nbsp;
              {activeMaintenance.po_number || ""}
            </div>
            <div className={"active_order__detail"}>
              <strong>SHOP NOTES:</strong>
              &nbsp;
              {activeMaintenance.shop_comments || ""}
            </div>

            <div className={"active_order__detail"}>
              <strong>CUSTOMER NOTES:</strong>
              &nbsp;
              {activeMaintenance.customer_comments || ""}
            </div>
          </div>
        </Segment>
      </Segment.Group>
    )
  }
} // class ActiveMaintenance

export default ActiveMaintenance
