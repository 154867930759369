import { isFleetMaintenanceHubCanada } from "../helpers/affiliationHelpers"
import * as numberHelpers from "../helpers/numberHelpers"
import * as vehicleHelpers from "../helpers/vehicleHelpers"

export function agGridColumnDefs(
  defaultCellRenderer,
  editCellRenderer,
  deleteCellRenderer,
  callToActionRenderer,
  t
) {
  const widths = {
    verySmall: 125,
    small: 150,
    medium: 175,
    large: 200,
    veryLarge: 225
  }

  return [
    {
      headerName: t("vehicleLabel"),
      field: "vehicle",
      width: widths.veryLarge,
      cellRendererFramework: callToActionRenderer,
      getQuickFilterText: (params) => {
        const vehicle = params.value
        return `${vehicle.year} ${vehicle.make} ${vehicle.model}`
      }
    },
    {
      headerName: t("otherIdLabel"),
      field: "other_id",
      width: widths.medium,
      cellRendererFramework: defaultCellRenderer
    },
    {
      headerName: t("vehicle:vinLabel"),
      field: "vin",
      width: widths.veryLarge,
      cellRendererFramework: defaultCellRenderer
    },
    {
      headerName: t("driversLabel"),
      field: "drivers",
      width: widths.veryLarge,
      cellRendererFramework: defaultCellRenderer
    },
    {
      headerName: isFleetMaintenanceHubCanada() ? t("dashboard:kilometersHeaderLabel") : "MILEAGE",
      field: isFleetMaintenanceHubCanada() ? KILOMETERS : MILES,
      width: widths.medium,
      cellRendererFramework: defaultCellRenderer
    },
    {
      headerName: isFleetMaintenanceHubCanada() ? "CPK" : "CPM",
      field: "cost_per_mile",
      width: widths.medium,
      cellRendererFramework: defaultCellRenderer
    },
    {
      headerName: t("lastMaintLabel"),
      field: "last_maint",
      width: widths.large,
      cellRendererFramework: defaultCellRenderer
    },
    {
      headerName: t("editLabel"),
      field: "edit_button",
      width: widths.verySmall,
      cellRendererFramework: editCellRenderer,
      suppressMenu: true,
      suppressSorting: true
    },
    {
      headerName: t("deleteLabel"),
      field: "delete_button",
      width: widths.verySmall,
      cellRendererFramework: deleteCellRenderer,
      suppressMenu: true,
      suppressSorting: true
    }
  ]
}

export function agGridRowData(vehicles, users, language) {
  return (vehicles || []).map((vehicle) => {
    return {
      vehicle: vehicle,
      plate: vehicleHelpers.formattedLicensePlateNumber(vehicle, true),
      vin: vehicle.vin,
      other_id: vehicle.other_id,
      drivers: vehicleHelpers.formattedDriverNames(vehicle, users),
      [isFleetMaintenanceHubCanada() ? KILOMETERS : MILES]: numberHelpers.formatWithCommas(
        isFleetMaintenanceHubCanada() ? vehicle.kilometers : vehicle.miles
      ),
      last_maint: vehicleHelpers.formattedLastMaintenanceDate(vehicle, language),
      cost_per_mile: vehicleHelpers.formattedCostPerMile(vehicle),
      edit_button: vehicle,
      delete_button: vehicle,

      // NOTE: The ID column does not get displayed because there is no mapping for it in the columnDefs, however, providing here makes it available to the row's data attribute.
      id: vehicle.id
    }
  })
}

export const KILOMETERS = "kilometers"
export const MILES = "miles"
