import React, { useEffect, createRef } from "react"
import { Divider, Tab, Ref } from "semantic-ui-react"
import Button from "../../elements/Button"
import { getSubdomain } from "../shared/StylesheetInjector"
import {
  isFleetMaintenanceHub,
  isFleetMaintenanceHubCanada,
  isZubie,
  shellSignInUrl
} from "../../helpers/affiliationHelpers"

const ZubieUserView = () => {
  const potentialEnv = window.location.hostname.split(getSubdomain()).pop().split(".fleetadvise")[0]
  const zubieOauthPath = `https://api${potentialEnv}.caradvise.com/api/v4/customers/auth/zubie`

  return (
    <a href={zubieOauthPath}>
      <Divider hidden />
      <Button className={"white-button"} label="Sign In With Zubie"></Button>
      <Divider hidden />
    </a>
  )
}

const ShellAdminView = () => {
  return (
    <h4 style={{ paddingTop: "15px", paddingBottom: "15px" }}>
      {`To log in through Shell SSO, please visit `}
      <br />
      <a href={shellSignInUrl()}>Shell Account Manager Online</a>
    </h4>
  )
}

const TabPane = ({ content }) => {
  return (
    <Tab.Pane className={"sign-in-tab-pane-container"} attached={false}>
      {content}
    </Tab.Pane>
  )
}

export default function UserOrAdminSignIn({ usernameAndPassword, t }) {
  let tabRef = createRef()

  let optionPanes = []

  if (isZubie()) {
    optionPanes = [
      { menuItem: "User", render: () => <TabPane content={ZubieUserView()} /> },
      { menuItem: "Admin", render: () => <TabPane content={usernameAndPassword} /> }
    ]
  }
  if (isFleetMaintenanceHubCanada()) {
    optionPanes = [
      { menuItem: t("common:userlabel"), render: () => <TabPane content={usernameAndPassword} /> }
    ]
  }
  if (isFleetMaintenanceHub()) {
    optionPanes = [
      { menuItem: "User", render: () => <TabPane content={usernameAndPassword} /> },
      { menuItem: "Admin", render: () => <TabPane content={ShellAdminView()} /> }
    ]
  }
  useEffect(() => {
    //making user and admin link tab accessable.
    tabRef.current.firstChild.firstChild.tabIndex = 0
    tabRef.current.firstChild.lastChild.tabIndex = 0
  }, [])

  return (
    <Ref innerRef={tabRef}>
      <Tab
        menu={{
          attached: true,
          secondary: true,
          pointing: true,
          tabular: true,
          style: { display: "flex", justifyContent: "center" }
        }}
        panes={optionPanes}
      />
    </Ref>
  )
}
