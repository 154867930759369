import * as arrayHelpers from "../../helpers/arrayHelpers"

export const initialState = {
  fleet: {},
  fleets: [],
  paymentTypes: [],
  fleetsLoading: false
}

export default function (state = initialState, action) {
  // eslint-disable-next-line
  const { payload, type } = action

  switch (type) {
    case "SET_FLEETS_LOADING":
      return {
        ...state,
        fleetsLoading: true
      }
    case "UNSET_FLEETS_LOADING":
      return {
        ...state,
        fleetsLoading: false
      }
    case "FLEET_CREATE_SUCCEEDED":
      return {
        ...state,
        fleet: payload.fleet,
        fleets: arrayHelpers.prepend(state.fleets, payload.fleet)
      }
    case "FLEET_DELETE_SUCCEEDED":
      return {
        ...state,
        fleets: arrayHelpers.removeObjectById(state.fleets, payload.fleet.id)
      }
    case "FLEET_UPDATE_SUCCEEDED":
      let fleet = state.fleet
      if (fleet.id === payload.fleet.id) fleet = { ...state.fleet, ...payload.fleet }

      return {
        ...state,
        fleets: arrayHelpers.replaceObjectById(state.fleets, payload.fleet, payload.fleet.id),
        fleet: fleet
      }
    case "FLEET_LOAD_SUCCEEDED":
      return {
        ...state,
        fleet: payload.fleet
      }
    case "FLEET_PAYMENT_TYPES_LOAD_SUCCEEDED":
      return {
        ...state,
        paymentTypes: payload.paymentTypes
      }
    case "FLEETS_LOAD_SUCCEEDED":
      return {
        ...state,
        fleets: payload.fleets
      }
    case "FLEET_LOAD_SUCCEEDED":
      return {
        ...state,
        fleet: action.payload.fleet
      }
    default:
      return state
  }
}
