import { constants } from "caradvise_shared_components"
const { BRAINTREE, WEX } = constants.paymentProviders

export function formattedName(user) {
  return `${user.firstName} ${user.lastName}`
}

export function selectOptions(users, opts = {}) {
  let options = []

  if (opts.semantic)
    users.forEach((user) => {
      options.push({
        value: user.id,
        key: `user-${user.id}`,
        text: formattedName(user)
      })
    })
  else
    users.forEach((user) => {
      options.push({
        label: formattedName(user),
        value: user.id
      })
    })

  return options
}

export function mapPreferencesToDefaults(preferences, defaults) {
  delete preferences.id
  delete preferences.customer_id
  Object.keys(preferences).forEach(
    (k) => (preferences[k] = { ...defaults[k], value: preferences[k] })
  )
}

export const DEFAULT_TEXT_NOTIFICATION_PREFERENCE = {
  appointment_requested: { value: false, label: "appointmentRequestedLabel" },
  appointment_confirmed: { value: false, label: "appointmentConfirmedLabel" },
  approvals_required: { value: true, label: "approvalsRequiredLabel" },
  approvals_submitted: { value: true, label: "approvalsSubmittedLabel" },
  services_completed: { label: "ordersCompletedLabel", value: false },
  services_canceled: { label: "ordersCancelledLabel", value: false },
  payment_received: { value: false, hidden: true, label: "paymentReceivedLabel" },
  twenty_four_hour_reminder: { value: false, label: "24HourReminderLabel" },
  one_hour_reminder: { value: false, label: "1HourReminderLabel" }
}

export const DEFAULT_EMAIL_NOTIFICATION_PREFERENCE = {
  appointment_requested: { value: false, label: "appointmentRequestedLabel" },
  approvals_required: { value: false, label: "approvalsRequiredLabel" },
  services_canceled: { label: "ordersCancelledLabel", value: false },
  payment_received: { value: false, label: "paymentReceivedLabel" },
  twenty_four_hour_reminder: { value: false, label: "24HourReminderLabel" }
}

const TBC_AFFILIATION_ID = 3
export function isTBC(user) {
  const affiliation = (user && user.affiliation) || {}
  const { id, parent_id } = affiliation

  return (id && id === 3) || (parent_id && parent_id === 3)
}

export function isBraintree(user) {
  return user && user.payment_provider_type && user.payment_provider_type === BRAINTREE
}

export function isWex(user) {
  return user && user.payment_provider_type && user.payment_provider_type === WEX
}
